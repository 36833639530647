import _ from 'lodash'
import { Route } from 'vue-router'
import { capitalize } from 'utils/string'

export function createSecuritiesMap (items: Array<any>) {
  return _.keyBy(items, 'securityTicker')
}

export function makeTitle (route: Route) {
  // expects the path to be /negotiations/:section/:action/:id
  const [, , section, action ] = route.path.split('/')
  const pages = {
    locates: 'Locate List',
    loans: 'Negotiation',
    rerates: 'Rerate',
    recalls: 'Recall',
    returns: 'Return',
  }
  const page = pages[section]
  return page
    ? `${capitalize(action)} ${page}`
    : 'Negotiations'
}

/**
 * Because the client models use a flattened schema more suited tables,
 * we need to rename the server-supplied `updatedProperties` property names
 * so they map property to the model properties in the client
 *
 * @param properties    A list of old string values
 * @param map           A hash of old => new values
 */
export function renameArrayItems (properties: Array<string>, map: any) {
  return properties.map(name => map[name] || name)
}
