import { Configuration, LogLevel } from '@azure/msal-browser'

/**
 * Bare-minimum options for MSAL
 */
export interface MsalConfigOptions {
  clientId: string
  authority: string
  redirectUri: string
}

/**
 * Helper to create MSAL config with bare-minimum options
 */
export function makeMsalConfig (options: MsalConfigOptions, log = false): Configuration {
  // config
  const config: Configuration = {
    // required
    auth: {
      // must match info in dashboard
      clientId: options.clientId,
      authority: options.authority,
      knownAuthorities: [
        options.authority
      ],

      // login redirect; must match path in dashboard
      redirectUri: options.redirectUri,
    },
  }

  // logging
  if (log) {
    Object.assign(config, {
      system: {
        loggerOptions: {
          logLevel: LogLevel.Verbose,
          loggerCallback,
        }
      }
    })
  }

  // return
  return config
}

/**
 * Logging function
 */
function loggerCallback (level: LogLevel, message: string, containsPii: boolean) {
  if (!containsPii) {
    const parts = message.split(' : ')
    const text = parts.pop()
    switch (level) {
      case LogLevel.Error:
        return console.error(text)

      case LogLevel.Warning:
        return console.warn(text)

      case LogLevel.Info:
        return console.info(text)

      case LogLevel.Verbose:
        return console.debug(text)
    }
  }
}
