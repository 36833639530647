import Home from './components/Home'
import { route } from 'router/helpers'
import ViewOrganization from './pages/ViewOrganization'
import EditOrganization from './pages/EditOrganization'

export default {
  components: {
    home: Home,
  },

  routes: [
    route('/organization/view/:id', ViewOrganization),
    route('/organization/create', EditOrganization, { props: { action: 'create' } }),
    route('/organization/edit/:id', EditOrganization, { props: { action: 'edit' } }),
  ]
}
