var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "user-management-form" },
    [
      _c(
        "div",
        { staticClass: "user-management-form__header" },
        [
          _c("h2", [_vm._v("Users")]),
          _c("el-switch", {
            attrs: { "active-text": "Show Disabled Users" },
            model: {
              value: _vm.showDisabledUsers,
              callback: function($$v) {
                _vm.showDisabledUsers = $$v
              },
              expression: "showDisabledUsers"
            }
          })
        ],
        1
      ),
      _c(
        "el-form",
        { attrs: { rules: _vm.formRules, model: _vm.form } },
        [
          _c(
            "el-table",
            {
              ref: "organizationTable",
              staticClass: "user-management-table",
              attrs: {
                data: _vm.users,
                "row-class-name": _vm.rowClass,
                width: "20%"
              }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "name", label: "Name" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return [
                        _vm.selectedRow === $index
                          ? _c(
                              "el-form-item",
                              { staticClass: "mt-4", attrs: { prop: "name" } },
                              [
                                _c("el-input", {
                                  ref: "nameInput",
                                  attrs: { placeholder: "Name", size: "mini" },
                                  nativeOn: {
                                    keydown: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "esc",
                                          27,
                                          $event.key,
                                          ["Esc", "Escape"]
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.cancelEdit(row, $index)
                                    }
                                  },
                                  model: {
                                    value: _vm.form.name,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "name", $$v)
                                    },
                                    expression: "form.name"
                                  }
                                })
                              ],
                              1
                            )
                          : _c("span", [_vm._v(_vm._s(row.name))])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "emailAddress", label: "Email Address" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return [
                        _vm.selectedRow === $index
                          ? _c(
                              "el-form-item",
                              {
                                staticClass: "mt-4",
                                attrs: { prop: "emailAddress" }
                              },
                              [
                                _c("el-input", {
                                  attrs: { size: "mini" },
                                  nativeOn: {
                                    keydown: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "esc",
                                          27,
                                          $event.key,
                                          ["Esc", "Escape"]
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.cancelEdit(row, $index)
                                    }
                                  },
                                  model: {
                                    value: _vm.form.emailAddress,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "emailAddress", $$v)
                                    },
                                    expression: "form.emailAddress"
                                  }
                                })
                              ],
                              1
                            )
                          : _c("span", [_vm._v(_vm._s(row.emailAddress))])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "organizationAdmin",
                  label: "Admin",
                  width: "70",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return [
                        _vm.selectedRow === $index
                          ? _c("el-checkbox", {
                              model: {
                                value: _vm.form.organizationAdmin,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "organizationAdmin", $$v)
                                },
                                expression: "form.organizationAdmin"
                              }
                            })
                          : row.organizationAdmin
                          ? _c("UiIcon", { attrs: { icon: "check" } })
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "enabled",
                  label: "Enabled",
                  width: "70",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return [
                        _vm.selectedRow === $index
                          ? _c("el-checkbox", {
                              model: {
                                value: _vm.form.enabled,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "enabled", $$v)
                                },
                                expression: "form.enabled"
                              }
                            })
                          : row.enabled
                          ? _c("UiIcon", { attrs: { icon: "check" } })
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "userType",
                  label: "User Type",
                  width: "100",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return [
                        row.userType === 2
                          ? _c("span", [_vm._v("Machine")])
                          : _c("span", [_vm._v("Person")])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "Actions", width: "170", align: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "no-shadow",
                            attrs: {
                              disabled: _vm.selectedRow === $index && !_vm.valid
                            },
                            on: {
                              click: function($event) {
                                _vm.selectedRow === $index
                                  ? _vm.updateUser()
                                  : _vm.startEdit($index)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.selectedRow === $index ? "Save" : "Edit"
                                ) +
                                "\n          "
                            )
                          ]
                        ),
                        _vm.selectedRow === $index
                          ? _c(
                              "el-button",
                              {
                                staticClass: "no-shadow",
                                on: {
                                  click: function($event) {
                                    return _vm.cancelEdit(row, $index)
                                  }
                                }
                              },
                              [_vm._v("\n            Cancel\n          ")]
                            )
                          : _vm._e(),
                        _vm.selectedRow !== $index
                          ? _c(
                              "el-button",
                              {
                                staticClass: "no-shadow",
                                on: {
                                  click: function($event) {
                                    return _vm.deleteUser($index)
                                  }
                                }
                              },
                              [_vm._v("\n            Delete\n          ")]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: _vm.selectedRow >= 0,
                    id: "addUserButton"
                  },
                  on: { click: _vm.addUser }
                },
                [_vm._v("\n        Add User\n      ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }