var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout__main", attrs: { id: "create-negotiation" } },
    [
      _c(
        "SiteAside",
        { attrs: { label: "Options" } },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.form, "label-position": "top" },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c("NegotiationComment", {
                model: {
                  value: _vm.form.comment,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "comment", $$v)
                  },
                  expression: "form.comment"
                }
              }),
              _c("UiSubmit", {
                attrs: { canSubmit: _vm.canSubmit },
                on: { submit: _vm.submit, cancel: _vm.cancel }
              }),
              _c(
                "UiFieldset",
                { attrs: { label: "Private", divided: "" } },
                [
                  _c("NegotiationNotes", {
                    model: {
                      value: _vm.form.privateNotes,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "privateNotes", $$v)
                      },
                      expression: "form.privateNotes"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "section",
        { staticClass: "layout__content panel" },
        [
          _c("h2", [_vm._v("Recall Details")]),
          _c("UiErrors"),
          _c("RecallItemsTable", {
            ref: "table",
            attrs: { action: "create", data: _vm.items.data, role: "lender" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }