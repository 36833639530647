import { LoanState } from 'modules/loans'
import { LoanRole } from 'modules/organizations'

export default class LoanFilters {
  // paging
  page: number
  pageSize: number

  // information
  reference: string
  states: Array<LoanState>
  loanRole: LoanRole
  counterPartyOrganizationIds: Array<string>

  // security
  securityId: string
  country: string

  // trade
  quantityFrom: number
  quantityTo: number
  rateFrom: number
  rateTo: number
  netDividendRequirementFrom: number
  netDividendRequirementTo: number
  currencyIds: Array<string>
  collateralBasketIds: Array<string>
  fundIds: Array<string>

  // dates
  settlementDateFrom: string | Date
  settlementDateTo: string | Date
  termEndDateFrom: string | Date
  termEndDateTo: string | Date

  constructor (data: any = {}) {
    // pagination
    this.page = data.page
    this.pageSize = data.pageSize

    // information
    this.reference = data.reference
    this.loanRole = data.loanRole
    this.states = data.states || []
    this.counterPartyOrganizationIds = data.counterPartyOrganizationIds || []

    // security
    this.securityId = data.securityId
    this.country = data.country

    // trade
    this.quantityFrom = data.quantityFrom
    this.quantityTo = data.quantityTo
    this.rateFrom = data.rateFrom
    this.rateTo = data.rateTo
    this.netDividendRequirementFrom = data.netDividendRequirementFrom
    this.netDividendRequirementTo = data.netDividendRequirementTo
    this.currencyIds = data.currencyIds
    this.collateralBasketIds = data.collateralBasketIds || []
    this.fundIds = data.fundIds

    // dates
    this.settlementDateFrom = data.settlementDateFrom
    this.settlementDateTo = data.settlementDateTo
    this.termEndDateFrom = data.termEndDateFrom
    this.termEndDateTo = data.termEndDateTo
  }
}

export const filterGroups = {
  information: [
    'reference',
    'loanRole',
    'states',
    'counterPartyOrganizationIds'
  ],
  security: [
    'securityId',
    'country',
  ],
  trade: [
    'quantityFrom',
    'quantityTo',
    'rateFrom',
    'rateTo',
    'netDividendRequirementFrom',
    'netDividendRequirementTo',
    'currencyIds',
    'collateralBasketIds',
    'fundIds',
  ],
  dates: [
    'settlementDateFrom',
    'settlementDateTo',
    'termEndDateFrom',
    'termEndDateTo',
  ],
}
