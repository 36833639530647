var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "layout__main", attrs: { id: "home" } }, [
    _c("aside", { staticClass: "layout__aside" }, [
      _c(
        "div",
        { staticClass: "flex" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.$router.push("/organization/create")
                }
              }
            },
            [_vm._v("Add Organization")]
          )
        ],
        1
      )
    ]),
    _c(
      "section",
      { staticClass: "layout__content panel" },
      [
        _c(
          "el-table",
          { attrs: { data: _vm.organizations } },
          [
            _c("el-table-column", { attrs: { label: "Name", prop: "name" } }),
            _c("el-table-column", {
              attrs: {
                label: "Type",
                prop: "organizationType",
                formatter: _vm.getType
              }
            }),
            _c("el-table-column", {
              attrs: { label: "Action", align: "center", width: "180" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return _c(
                      "div",
                      { staticClass: "flex" },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "no-shadow",
                            attrs: { size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.login(scope.row)
                              }
                            }
                          },
                          [_vm._v("View As")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "no-shadow",
                            attrs: { size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.$router.push(
                                  "/organization/view/" + scope.row.id
                                )
                              }
                            }
                          },
                          [_vm._v("Manage")]
                        )
                      ],
                      1
                    )
                  }
                }
              ])
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }