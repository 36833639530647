<template>

  <div class="layout__main" id="create-negotiation">

    <SiteAside label="Options">

      <!-- form -->
      <el-form ref="form"
               :model="form"
               label-position="top"
               @submit.prevent.native=""
      >

        <NegotiationComment v-model="form.comment" />

        <UiSubmit
          @submit="submit"
          @cancel="cancel"
          :canSubmit="canSubmit"
        />

        <UiFieldset label="Private" divided>
          <NegotiationNotes v-model="form.privateNotes" />
        </UiFieldset>

      </el-form>

    </SiteAside>

    <!-- content -->
    <section class="layout__content panel">
      <h2>Return Details</h2>

      <UiErrors/>

      <ReturnItemsTable ref="table"
                        :data="items.data"
                        role="borrower"
                        action="create"
                        :is-term="isTerm"
      />
    </section>

  </div>

</template>

<script>
import { dispatch } from 'vuex-pathify'
import ReturnItemsTable from '../../components/tables/ReturnItemsTable'
import ReturnNegotiationItem from '../../models/ReturnNegotiationItem'
import factory from './create-factory'

export default {

  extends: factory('return'),

  crumb: 'Returns',

  components: {
    ReturnItemsTable,
  },

  computed: {
    isTerm () {
      const item = this.items.data[0]
      return item
        ? !!item.loan.termEndDate
        : false
    },
  },

  methods: {
    load () {
      const { source, id } = this.$route.query
      let items = []
      switch (source) {
        case 'loans':
          items = this.$store.get('loans/selected').map(ReturnNegotiationItem.fromLoan)
          return this.setItems(items)

        case 'recall':
          return dispatch('negotiations/recall/load', id).then(() => {
            const items = this.$store
              .get('negotiations/recall/items')
              .map(recall => ReturnNegotiationItem.fromLoan(recall.loan))
            return this.setItems(items)
          })

        case 'rerate':
          return dispatch('negotiations/rerate/load', id).then(() => {
            const items = this.$store
              .get('negotiations/rerate/items')
              .map(rerate => ReturnNegotiationItem.fromLoan(rerate.loan))
            return this.setItems(items)
          })

        default:
          console.error('Unsupported conversion:', source)
      }
    },
  }
}
</script>

<style lang="scss">
</style>
