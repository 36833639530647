/**
 * Element UI button version of router-link
 * @see https://element.eleme.io/#/en-US/component/button#button
 */
export default {

  name: 'router-button',

  functional: true,

  props: {
    to: {
      type: String,
      required: true
    },
  },

  render (h, context) {
    const { data, props, parent, slots } = context
    const options = {
      props: data.attrs,
      on: {
        click: () => parent.$router.push(props.to)
      },
    }
    return h('el-button', options, slots().default)
  }
}
