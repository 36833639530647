var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout__main", attrs: { id: "dashboard" } },
    [
      _c(
        "section",
        { staticClass: "layout__content" },
        [_c("DashboardMatrix")],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }