<template>
  <div class="ui uiFormItem uiOutput" :data-type="type" :data-icon="icon">
    <template v-if="type === 'form'">
      <label class="uiLabel">{{ label }}:</label>
      <output><slot></slot>&nbsp;</output>
    </template>
    <template v-else>
      <span v-if="icon" class="uiContent__icon">
        <i class="far" :class="`fa-${icon}`"></i>
      </span>
      <span class="uiOutput__content">
        <output><slot></slot>&nbsp;</output>
        <label class="uiLabel">{{ label }}</label>
      </span>
    </template>
  </div>

</template>

<script>
export default {
  props: {
    label: String,

    icon: String,

    type: {
      type: String,
      default: 'form',
      validator (value) {
        return ['form', 'text', 'link'].includes(value)
      }
    },
  }
}
</script>

<style lang="scss">
.uiOutput {

  &[data-type="bullet"] {
    position: relative;
    margin-left: 1rem;
    margin-bottom: .75rem;

    &:before {
      position: absolute;
      content: '∙';
      display: block;
      left: -.75rem;
    }
  }

  &[data-icon] {
    display: flex;
    .uiOutput__icon {
      display: block;
    }

    .uiOutput__content {
      padding-left: .5em;
      display: inline-block;
    }

    .uiLabel {
      margin-top: .1em;
    }

    a {
      color: $color-primary;
      &:hover {
        text-decoration: underline;
      }
    }

    .far {
      font-size: 16px;
      vertical-align: center;
      color: $grey;
    }
  }


  label {
    margin-left: 0;
    margin-bottom: 3px;
  }

  output {
    display: block;
    font-size: 0.9rem;
    white-space: normal;
  }
}
</style>
