<template>
  <nav class="siteNav">

    <div class="siteNav__left">
      <router-link class="siteLink__home" to="/"></router-link>

      <template v-if="isUser">

        <router-link v-if="canLend" to="/availability-list">
          <label>Availability</label>
        </router-link>

        <router-link v-if="canBorrow" to="/long-positions">
          <label>Long Positions</label>
        </router-link>

        <router-link to="/negotiations">
          <label>Negotiations</label>
          <UiBadge :value="negotiationNotifications.length"/>
        </router-link>

        <router-link to="/loans">
          <label>Loans</label>
        </router-link>

        <router-link to="/reports">
          <label>Reports</label>
        </router-link>

        <router-link v-if="isAdmin" to="/profile">
          <label>Profile</label>
        </router-link>

      </template>
    </div>

    <div class="siteNav__right">
      <template v-if="isUser">
        <router-link class="siteLink__notifications" to="/notifications">
          <label :title="isConnected ? '' : 'Connecting...'"><i class="far" :class="notificationIcon"></i></label>
          <UiBadge :value="unseenNotifications.length"/>
        </router-link>

        <!--
        <a class="siteLink__notifications">
          <label style="font-size: .8em;"><i class="fas fa-list"></i></label>
        </a>
        -->
      </template>

      <router-link v-if="!isLoggedIn" to="/auth/login">
        <label>Log in</label>
      </router-link>

      <div v-else class="siteLink__dropdown">
        <el-dropdown trigger="click" @command="onCommand">
          <span class="el-dropdown-link" :title="organization.name">
            {{ displayName }} <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown" style="width: 230px;">
            <el-dropdown-item icon="el-icon-info" command="copy">
              <Version ref="version"/>
            </el-dropdown-item>
            <el-dropdown-item icon="el-icon-house" command="none" divided>{{ organization.name }}</el-dropdown-item>
            <el-dropdown-item icon="el-icon-user" command="/user" divided>Profile</el-dropdown-item>
            <el-dropdown-item icon="el-icon-close" command="/auth/logout">Log out</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

  </nav>
</template>

<script>
import { OrganizationMixin, UserMixin } from 'modules/organizations'
import Version from 'components/Version'

export default {

  components: {
    Version
  },

  mixins: [
    OrganizationMixin,
    UserMixin,
  ],

  computed: {
    isConnected () {
      const notifications = this.$store.state.notifications
      return notifications
        ? notifications.isConnected
        : false
    },

    notificationIcon () {
      return this.isConnected
        ? 'fa-bell'
        : 'fa-bell-slash blinking color-danger'
    },

    unseenNotifications () {
      return this.$store.state.dashboard
        ? this.$store.get('notifications/unseenForIndex')
        : []
    },

    negotiationNotifications () {
      return this.$store.state.dashboard
        ? this.$store.get('notifications/unseenForNegotiations')
        : []
    },

    displayName () {
      let name = this.userName
      if (this.isSystem && this.organization.isUser()) {
        name += ' (as ' + this.organization.name + ')'
      }
      return name
    },
  },

  methods: {
    onCommand (command) {
      switch (command) {
        case 'none':
          break

        case 'copy':
          this.$refs.version.copy()
          break

        default:
          this.$router.push(command)
      }
    }
  }
}
</script>

<style lang="scss">
// variables

$border: 1px dotted $grey-lightest;
$height: 50px;

// layout

.siteNav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.siteNav__left {
  display: flex;
  align-items: center;
}

.siteNav__right {
  display: flex;
  align-items: center;
}

// specific link items

.siteLink__home {
  display: block;
  width: 200px;
  height: $height;
  background-image: url('/assets/img/logo-web-dim.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: -12px;
  background-position-y: 50%;

  &.router-link-exact-active,
  &:hover {
    background-image: url('/assets/img/logo-web.png');
  }
}

.siteLink__notifications {
  font-size: 1.6em;
}

.siteLink__ticker {
  font-size: 1.6em;
}

.siteLink__dropdown {
  height: $height;
  padding: 0 0 0 20px;
  vertical-align: middle;
  line-height: $height;
  cursor: default;

  * {
    color: $grey;
    font-size: 1rem;
  }
}

// link formatting

.siteNav a,
.siteNav .siteLink__dropdown {
  position: relative;
  white-space: nowrap;
}

.siteNav__left a {
  border-right: $border;
}

.siteLink__dropdown,
.siteNav__right > * {
  border-left: $border;
}

.siteNav a,
.siteNav a label {
  cursor: pointer;
}

.siteNav a label {
  display: block;
  height: $height;
  padding: 0 20px;
  border-bottom: 4px solid #FFF;
  line-height: calc(#{$height} + 7px);
  color: $grey;
}

.siteNav a:hover label {
  color: $grey-darker;
}

.siteNav a.router-link-active label {
  border-bottom-color: $color-primary;
  color: $color-primary;
}

.siteNav .badge.notification {
  position: absolute;
  right: 8px;
  top: calc(50% - 20px);
}
</style>
