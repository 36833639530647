<template>
  <div class="layout__index" id="page-borrower">
    <h1>Borrower</h1>

    <UiBreadcrumbs/>

    <div class="layout__main">

      <section class="layout__content panel">
        <p>Borrower-specific page</p>
      </section>

    </div>

  </div>
</template>

<script>
export default {

  metaInfo: {
    title: 'Borrower'
  },

}
</script>
