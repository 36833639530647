import Vue from 'vue'
import ElTableColumnUnseen from './components/ElTableColumnUnseen.vue'
import notifications from './state/NotificationsStore'
import { route } from 'router/helpers'
import './routing'

Vue.component('el-table-column-unseen', ElTableColumnUnseen)
export default {
  stores: {
    notifications,
  },

  routes: [
    route('/notifications', 'notifications@Index.vue')
  ]
}
