import Handsontable from 'handsontable'
import store from 'state'
import { isFromToday, isFromTomorrow } from 'utils/validators'
import {
  makeRate,
  makeDateEditor,
  makeDate,
  makeText,
  makeThousands,
  makeRelatedRates,
} from './column-factory'
import { formatNumeric } from 'utils/formatters'

export const id = {
  title: 'ID',
  data: 'id',
  width: 80,
  type: 'text',
  editor: false,
}

export const security = makeText('Asset', 'bloomberg', 120)

export const securityPicker = {
  title: 'Asset',
  data: 'bloomberg',
  width: 140,
  renderer: 'autocomplete',
  editor: 'select',
  editorOptions: {
    keyLabel: 'name',
    keyValue: 'bloomberg',
    showValue: 'below',
    source (query, process) {
      query = query || ''
      query.trim() === ''
        ? process(Object.values(store.state.securities.items).slice(0, 8))
        : store.dispatch('securities/search', query).then(process)
    },
  }
}

export const value = {
  title: 'Value',
  data: 'value',
  width: 130,
  type: 'numeric',
  className: 'anValue',
  renderer: 'value',
  editor: false,
  readOnly: true,
  skipColumnOnPaste: true,
}

export const rate = makeRate('Rate', 'rate', true, 4)

export const tradedRates = makeRelatedRates()

export const settlementDate = makeDate('Settlement Date', 'settlementDate')

export const settlementDateEditor = makeDateEditor('Settlement Date', 'settlementDate', isFromToday)

export const termEndDate = makeDate('Term End Date', 'termEndDate')

export const termEndDateEditor = makeDateEditor('Term End Date', 'termEndDate', isFromTomorrow)

export const toLocate = {
  ...makeThousands('To Locate', undefined, 80, false),
  renderer (instance, td, row, col, prop, values, cellProperties) {
    Handsontable.renderers.TextRenderer.apply(this, arguments)
    td.setAttribute('align', 'right')
    const data = instance.getSourceDataAtRow(row)
    if (data && data.bloomberg) {
      if (data.quantityRequired) {
        const required = parseInt(data.quantityRequired)
        if (!isNaN(required)) {
          const value = required - (data.quantityLocated || 0)
          td.innerText = formatNumeric(Math.max(0, value))
        }
        else {
          td.innerText = '?'
        }
      }
      else {
        td.innerText = 'Any'
      }
    }
  }
}

export const collateral = {
  title: 'Collateral',
  data: 'collateralBasketCode',
  type: 'text',
  width: 65,
  renderer: 'autocomplete',
  editor: 'select',
  editorOptions: function (row, col, prop) {
    return {
      showValue: 'below',
      keyLabel: 'name',
      keyValue: 'code',
      source: store.state.resources.collateral
    }
  }
}

export const fund = makeText('Fund', 'fundName', 100)

export const fundPicker = {
  title: 'Fund',
  data: 'fundName',
  width: 100,
  renderer: 'autocomplete',
  editor: 'select',
  editorOptions: function (row, col, prop) {
    return {
      keyLabel: 'name',
      keyValue: 'name',
      source: store.state.resources.funds
    }
  }
}

export const remove = {
  title: 'Delete',
  data: 'remove',
  type: 'checkbox',
  width: 50,
}
