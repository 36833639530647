import { route } from '@/app/router/helpers'
import Home from './components/Home'

export default {
  components: {
    home: Home,
  },

  routes: [
    route('/options', 'borrower@Index.vue'),
  ]
}
