<template>
  <span id="version"
        class="small uppercase"
        @click="copy">Version: {{ BUILD_MODE }} | {{ BUILD_NUMBER }}</span>
</template>

<script>
import { detect } from 'detect-browser'
import copy from 'copy-text-to-clipboard'
import { BUILD_MODE, BUILD_NUMBER, ENV_NAME } from 'config/env'

export default {
  data () {
    return {
      BUILD_MODE,
      BUILD_NUMBER,
    }
  },

  methods: {
    copy () {
      const browser = detect()
      const text = [
        'Env: ' + ENV_NAME,
        'Build Mode: ' + BUILD_MODE,
        'Build Number: ' + BUILD_NUMBER,
        'Url: ' + window.location.href,
        'Browser: ' + browser.name + ' ' + browser.version,
        'OS: ' + browser.os,
      ].join('\n')
      console.log(text)
      copy(text)
      this.$message({ message: 'Application info copied to clipboard', type: 'success' })
    }
  }
}
</script>
