import { Organization } from 'modules/organizations'
import { Model } from 'core/models'

export default class User extends Model {
  public hash: string

  public name: string

  public emailAddress: string

  public organization: Organization

  public isAdmin: Boolean

  constructor (data: any = {}) {
    super(data)
    this.hash = data.hash
    this.name = data.name
    this.emailAddress = data.emailAddress
    this.isAdmin = data.organizationAdmin
  }

  public static fromServer (data: any) {
    return new User(data)
  }
}
