import Vue from 'vue'
import { isObject } from 'utils/object'
import Api from 'services/Api'

const $message = (type, message) => Vue.prototype.$message({ type, message })

/**
 * Factory function to create actions to load, upload and save data
 *
 * @param   {string}  url
 * @param   {string}  prop
 */
export function makeActions (url, prop = 'data') {
  return {
    load ({ commit }) {
      return Api.get(url)
        .then(res => {
          commit(prop, res.data)
          return res.data
        })
    },

    upload ({ commit }, file) {
      return Api.post(`${url}/file`, file)
        .then(res => {
          commit(prop, res.data)
        })
    },

    save ({ state, commit }, data) {
      return Api.post(url, data)
        .then(res => {
          $message('success', 'Data saved OK')
          commit(prop, res.data)
          return res.data
        })
        .catch(() => $message('error', 'There was a problem saving the data'))
    },
  }
}

/**
 * Factory function to create mutation to save loaded data
 *
 * @param prop
 */
export function makeMutation (prop = 'data') {
  return {
    data (state, data) {
      if (typeof prop === 'function') {
        const model = prop(data)
        Object.assign(state, model)
      }
      else {
        state[prop] = data
      }
    },
  }
}

/**
 * Factory function to make a state function to apply
 *
 * @param makeItem
 * @param prop
 */
export function makeItemsState (makeItem, prop = 'data') {
  return function (items = []) {
    return {
      [prop]: items.map(item => makeItem(item))
    }
  }
}

export function getData (data, makeItem) {
  return makeItem
    ? data.map(item => makeItem(item).toServer())
    : isObject(data) && data.hasOwnProperty('toServer')
      ? data.toServer()
      : data
}

/**
 * Factory function to create a generic store that saves
 *
 * @param url
 * @param makeState
 * @param makeItem
 */
export function makeStore (url, makeState = null, makeItem = null) {

  makeState = makeState || makeItemsState(makeItem)

  const state = makeState

  const actions = makeActions(url, 'data')

  const mutations = makeMutation(makeState)

  return {
    namespaced: true,
    state,
    actions,
    mutations,
  }

}
