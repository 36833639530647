import storage from 'local-storage'
import Vue from 'vue'
import router from '@/app/router'

/**
 * Global mixin to save and load component-specific session data
 *
 * @param Vue
 */
function session (Vue) {
  Vue.prototype.$session = {
    handlers: new Set(),

    addHandler (handler) {
      this.handlers.add(handler)
    },

    removeHandler (handler) {
      this.handlers.delete(handler)
    },

    hasHandlers () {
      return this.handlers.size > 0
    },

    load () {
      const route = storage.get('route')
      if (route) {
        storage.remove('route')
        Vue.prototype.$confirm('You have a previous session. Do you want to resume it?').then(() => {
          router.push(route)
        })
      }
    },

    save () {
      const path = router.currentRoute.path
      storage.set('route', path)
      const results = Array
        .from(this.handlers)
        .map(handler => handler())
      return Promise.all(results)
    },

    set (key, value) {
      return storage.set(key, value)
    },

    get (key) {
      return storage.get(key)
    },

    remove (key) {
      return storage.remove(key)
    }
  }
}

Vue.use(session)
