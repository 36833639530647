import Vue from 'vue'
import store from 'state/index'
import router from 'router/index'
import { route } from 'router/helpers'
import { ComponentsConfig, ModuleConfig, RoutesConfig, StoresConfig } from './interfaces'

/**
 * Install a module by path
 *
 * @param   {string}  name    The name of the module folder in modules
 */
function install (name: string) {
  const module = require(`./${name}/main`).default
  const config = typeof module === 'function'
    ? module(Vue, { store, router, route })
    : module
  if (config && typeof config === 'object') {
    register(module)
  }
}

/**
 * Register a module's routes, stores and components
 */
function register (config: ModuleConfig) {

  if (config.routes) {
    const routes: RoutesConfig = config.routes
    router.addRoutes(routes)
  }

  if (config.stores) {
    const stores: StoresConfig = config.stores
    Object.keys(stores).forEach(key => {
      store.registerModule(key, stores[key])
    })
  }

  if (config.components) {
    const components: ComponentsConfig = config.components
    Object.keys(components).forEach(key => {
      Vue.component(key, components[key])
    })
  }
}

export {
  Vue,
  install,
  register,
}
