<template>

  <div class="layout__main" id="edit-rerate">

    <SiteAside v-if="hasStaleWarning">
      <NegotiationConflict :counterParty="counterParty" @reload="reload"/>
    </SiteAside>
    <SiteAside v-else label="Options">

      <el-form ref="form"
               :model="form"
               label-position="top"
               @submit.prevent.native="">

        <NegotiationMessage :message="lastMessage"/>

        <UiFieldset v-if="state === NegotiationState.open" label="Response">
          <NegotiationResponse
            v-model="form.response"
            v-bind="responseData"
          />

          <NegotiationComment v-model="form.comment" />

          <UiSubmit v-bind="submitData" v-on="submitData" />
        </UiFieldset>

      </el-form>

      <UiFieldset v-if="canReturn" label="Further Actions">
        <div class="flex vertical">
          <router-button
            :to="`/negotiations/locates/create?source=rerate&id=${$route.params.id}`">Locate securities</router-button>
          <router-button
            :to="`/negotiations/returns/create?source=rerate&id=${$route.params.id}`">Return securities</router-button>
        </div>
      </UiFieldset>

      <UiFieldset v-if="canRecall" label="Further Actions">
        <div class="flex vertical">
          <router-button
            :to="`/negotiations/recalls/create?source=rerate&id=${$route.params.id}`">Recall securities</router-button>
        </div>
      </UiFieldset>

      <el-form>
        <UiFieldset label="Notes">
          <NegotiationNotes v-model="privateNotes" type="rerate" autosave/>
        </UiFieldset>
      </el-form>

      <NegotiationHistory v-model="messageIndex"
                          :messages="messages"
                          :open="isHistoryVisible"
                          :canToggle="isOpen"
                          @toggle="toggleHistory" />

    </SiteAside>

    <section class="layout__content panel">

      <NegotiationSummary v-bind="summaryData">
        <UiOutput label="Originator" class="capitalize">{{ loanRole }}</UiOutput>
      </NegotiationSummary>

      <UiErrors/>

      <!-- current items -->
      <RerateItemsTable
        ref="table"
        v-show="canShowLast"
        :data="items.data"
        :role="role"
        :locked="!isOpen"
      />

      <!-- previous items -->
      <RerateItemsTable
        v-show="canShowPrevious"
        :data="previous.data"
        :role="role"
        :locked="true"
      />
    </section>

  </div>

</template>

<script>
import { LoanRole } from 'modules/organizations'
import { NegotiationState } from '../../index'
import RerateItemsTable from '../../components/tables/RerateItemsTable'
import RerateNegotiationValidator from '../../validators/RerateNegotiationValidator'
import factory from './edit-factory'

export default {

  extends: factory('rerate', RerateNegotiationValidator),

  crumb: 'Rerates',

  components: {
    RerateItemsTable,
  },

  computed: {
    loanRole () {
      return LoanRole[this.originatingLoanRole]
    },

    canReturn () {
      return this.state === NegotiationState.rejected
        && this.isBorrower
        && this.originatingLoanRole === LoanRole.borrower
    },

    canRecall () {
      return this.state === NegotiationState.rejected
        && this.isLender
        && this.originatingLoanRole === LoanRole.lender
    },
  },
}
</script>
