<template>
  <div class="layout__index" id="page-long-positions">
    <h1>Long Positions</h1>
    <UiBreadcrumbs/>
    <router-view/>
  </div>
</template>

<script>
export default {

  metaInfo () {
    return {
      title: 'Long Positions'
    }
  },
}
</script>
