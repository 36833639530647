<template>
  <nav class="ui-breadcrumbs">
    <slot :segments="segments">
      <template v-for="segment in segments">
        <a v-if="segment.path"
           :key="segment.path"
           :href="segment.path"
           @click.prevent="$router.push(segment.path)"
           class="ui-breadcrumbs__item"
        >{{ segment.text }}</a>
        <span v-else :key="segment.path" class="ui-breadcrumbs__item">{{ segment.text }}</span>
      </template>
    </slot>
  </nav>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'UiBreadcrumbs',

  props: {
    data: {
      type: Array,
      validator (value) {
        return value.every(segment => segment.text) && value.slice(0, -1).every(segment => segment.path)
      }
    },

    resolvers: {
      type: Array,
      default () {
        return [
          route => get(route, 'meta.crumb'),
          'component.crumb',
          'component.metaInfo.bodyAttrs.crumb',
          'meta.head.title',
          'component.metaInfo.title',
        ]
      }
    }
  },

  computed: {
    segments () {
      // get segments
      const segments = this.value
        ? this.value
        : this.getRoutes().map(route => getSegment(route, this.resolvers))

      // ensure last segment doesn't hyperlink
      if (segments.length) {
        segments[segments.length - 1].path = ''
      }
      return segments
    }
  },

  methods: {
    getRoutes () {
      // convert matched routes into objects we need
      const matched = this.$route.matched.map(match => {
        return {
          path: match.path,
          meta: match.meta,
          component: match.components.default,
        }
      })

      // if we have matched routes, check existing routes for predecessors
      const first = matched[0]
      const routes = this.$router.options.routes || []
      const other = first
        ? routes
          .filter(route => first.path.includes(route.path))
          .filter(other => matched.every(route => route.path !== other.path))
        : []

      // finally, concatenate and order
      return _.orderBy(matched.concat(other), 'path')
    }
  }
}

/**
 * Process a route to get path and text
 *
 *  - runs registered resolvers on passed segment
 *  - handles text or function resolvers
 *
 * @param   {Object}                    route
 * @param   {Array<String | Function>}  resolvers
 * @return  {{path: string, text: string}}
 */
function getSegment (route, resolvers) {
  for (let i = 0; i < resolvers.length; i++) {
    const resolver = resolvers[i]
    const text = typeof resolver === 'function'
      ? resolver(route)
      : get(route, resolver)
    if (text) {
      return {
        text, path: route.path
      }
    }
  }
  return {
    text: route.name || route.path,
    path: route.path,
  }
}

export function get (value, path) {
  // variables
  value = value || {}
  if (typeof path === 'string') {
    path = path.split('.')
  }
  const segment = path.shift()

  // get next segment
  value = value[segment]
  if (typeof value === 'function') {
    value = value()
  }

  // return if no matches or last match
  if (!value || path.length === 0) {
    return value
  }

  // otherwise, process
  return get(value, path)
}

</script>
