import { Lookups } from 'modules/global'
import { Model } from 'core/models'

export default abstract class AbstractItem extends Model {

  public abstract readonly type: string

  protected constructor (data: any = {}) {
    super(data)
  }

  /**
   * Convert model (table row) to server JSON
   *
   * @param create    Creating a new model, or updating an existing one
   * @param lookups   A hash of lookup functions
   */
  abstract toServer (create: boolean, lookups?: Lookups): any
}
