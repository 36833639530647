var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ui uiFormItem UiRange", class: "uiRange--" + _vm.size },
    [
      _vm.label
        ? _c("label", { staticClass: "uiLabel" }, [_vm._v(_vm._s(_vm.label))])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "flex" },
        [
          _vm.type === "number"
            ? _c(
                "el-input",
                _vm._b(
                  {
                    attrs: {
                      type: "number",
                      size: _vm.size,
                      min: _vm.min,
                      max: _vm.minMax,
                      placeholder: _vm.minPlaceholder
                    },
                    on: { change: _vm.onMinChange },
                    model: {
                      value: _vm.minModel,
                      callback: function($$v) {
                        _vm.minModel = _vm._n($$v)
                      },
                      expression: "minModel"
                    }
                  },
                  "el-input",
                  _vm.$attrs,
                  false
                ),
                [
                  _vm.prefix
                    ? _c(
                        "span",
                        {
                          staticStyle: { "padding-left": "8px" },
                          attrs: { slot: "prefix" },
                          slot: "prefix"
                        },
                        [_vm._v(_vm._s(_vm.prefix))]
                      )
                    : _vm._e(),
                  _vm.suffix
                    ? _c(
                        "span",
                        {
                          staticStyle: { "padding-right": "5px" },
                          attrs: { slot: "suffix" },
                          slot: "suffix"
                        },
                        [_vm._v(_vm._s(_vm.suffix))]
                      )
                    : _vm._e()
                ]
              )
            : _c(
                "el-date-picker",
                _vm._b(
                  {
                    attrs: {
                      type: "date",
                      size: _vm.size,
                      placeholder: _vm.minPlaceholder,
                      "picker-options": _vm.minDateOptions
                    },
                    model: {
                      value: _vm.minModel,
                      callback: function($$v) {
                        _vm.minModel = $$v
                      },
                      expression: "minModel"
                    }
                  },
                  "el-date-picker",
                  _vm.$attrs,
                  false
                )
              ),
          _vm._v("\n\n      "),
          _c("span", { staticClass: "uiRange__separator" }, [
            _vm._v(_vm._s(_vm.rangeSeparator))
          ]),
          _vm.type === "number"
            ? _c(
                "el-input",
                _vm._b(
                  {
                    attrs: {
                      type: "number",
                      size: _vm.size,
                      min: _vm.maxMin,
                      max: _vm.max,
                      placeholder: _vm.maxPlaceholder
                    },
                    on: { change: _vm.onMaxChange },
                    model: {
                      value: _vm.maxModel,
                      callback: function($$v) {
                        _vm.maxModel = _vm._n($$v)
                      },
                      expression: "maxModel"
                    }
                  },
                  "el-input",
                  _vm.$attrs,
                  false
                ),
                [
                  _vm.prefix
                    ? _c(
                        "span",
                        {
                          staticStyle: { "padding-left": "8px" },
                          attrs: { slot: "prefix" },
                          slot: "prefix"
                        },
                        [_vm._v(_vm._s(_vm.prefix))]
                      )
                    : _vm._e(),
                  _vm.suffix
                    ? _c(
                        "span",
                        {
                          staticStyle: { "padding-right": "5px" },
                          attrs: { slot: "suffix" },
                          slot: "suffix"
                        },
                        [_vm._v(_vm._s(_vm.suffix))]
                      )
                    : _vm._e()
                ]
              )
            : _c(
                "el-date-picker",
                _vm._b(
                  {
                    attrs: {
                      type: "date",
                      size: _vm.size,
                      placeholder: _vm.maxPlaceholder,
                      "picker-options": _vm.maxDateOptions
                    },
                    model: {
                      value: _vm.maxModel,
                      callback: function($$v) {
                        _vm.maxModel = $$v
                      },
                      expression: "maxModel"
                    }
                  },
                  "el-date-picker",
                  _vm.$attrs,
                  false
                )
              )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }