<template>
  <span v-if="value || type === 'dot'" class="badge" :class="type">{{ value }}</span>
</template>

<script>
export default {
  props: {
    value: [Number, String],
    type: {
      type: String,
      default: 'notification',
      validator (value) {
        return !value || /^(text|number|notification|dot)$/.test(value)
      }
    },
  },
}
</script>

<style lang="scss">
.badge {
  display: inline-block;
  height: 16px;
  width: 16px;
  border-radius: 8px;
  line-height: 16px !important;
  text-align: center;
  vertical-align: text-bottom;
  background: $grey-lighter;
  color: white;
  font-weight: 900;
  font-style: normal;
  font-size: 10px;
  min-width: 8px;
}

.badge.notification {
  background: $color-danger;
}

.badge.dot {
  background: $color-secondary;
  font-size: 0;
  margin: 3px;
  width: 10px;
  height: 10px;
}

.badge.text {
  width: inherit;
  padding: 0 5px;
}

.el-tabs .badge {
  position: absolute;
  top: 0;
}
</style>
