import { Model } from 'core/models'

export default class Security extends Model {

  public name: string

  public bloomberg: string

  public sedol: string

  public currencyCode: string

  public price: number

  public lastTradedRate: number

  public lastWeekAverageTradedRate: number

  constructor (data: any = {}) {
    super(data)
    this.name = data.name || ''
    this.bloomberg = data.bloomberg || ''
    this.sedol = data.sedol || ''
    this.currencyCode = data.currencyCode || ''
    this.price = data.price || 0
    this.lastTradedRate = data.lastTradedRate || 0
    this.lastWeekAverageTradedRate = data.lastWeekAverageTradedRate || 0
  }

  static fromTable (data: Record<string, any>): Security {
    return new Security(data)
  }

  static fromServer (data: Record<string, any>) {
    data.currencyCode = data.currency.code
    return new Security(data)
  }

  static create (data: Record<string, any>) {
    return data instanceof Security
      ? data
      : Security.fromServer(data)
  }
}
