import { chunk } from 'utils/array'
import Api from 'services/Api'
import Security from '../models/Security'

class State {
  // a hash of terms and returned codes
  terms: Record<string, string[]>

  // loaded securities
  items: Record<string, Security>

  // last-traded timestamps
  lastTraded: Record<string, Number>

  constructor () {
    this.terms = {}
    this.items = {}
    this.lastTraded = {}
  }
}

const state: State = new State()

const actions = {
  /**
   * Get an exact security, locally, or search for it
   *
   * @param state
   * @param commit
   * @param dispatch
   * @param bloomberg
   */
  get ({ state, commit, dispatch }, bloomberg: string) {
    const bloombergs = state.terms[bloomberg]
    if (bloombergs) {
      return bloombergs.length === 1
        ? state.items[bloombergs[0]]
        : undefined
    }
    return dispatch('fetch', bloomberg)
      .then(() => {
        return state.items[bloomberg]
      })
  },

  /**
   * Do local then remote search for security codes
   *
   * @param state
   * @param commit
   * @param dispatch
   * @param term
   */
  search ({ state, commit, dispatch }, term: string) {
    // check for previously-searched items
    const bloombergs = state.terms[term]
    if (bloombergs) {
      return bloombergs.map(bloomberg => state.items[bloomberg])
    }

    // check for exact-match with loaded item
    const security = state.items[term]
    if (security) {
      return [security]
    }

    // otherwise fetch
    return dispatch('fetch', term)
  },

  /**
   * Fetch securities from the server
   *
   * @param state
   * @param commit
   * @param term
   */
  fetch ({ state, commit }, term): Promise<Array<Security | object>> {
    return Api
      .get('/securities/byTerm/' + term)
      .then(res => {
        const securities = res.data
        const bloombergs = securities.map(item => item.bloomberg)
        commit('term', { term, bloombergs })
        securities.forEach(security => {
          commit('item', security)
        })
        return securities
      })
  },

  fetchSecurities ({ commit }, bloombergs) {
    function fetch (bloombergs: Array<string>) {
      return Api.get('/securities/byIdentifiers/' + bloombergs.join(','))
        .then(res => {
          commit('items', res.data)
          return res
        })
    }

    const promises = chunk(bloombergs).map(fetch)
    return Promise
      .all(promises)
      .then(responses => {
        return responses.reduce((output, res) => {
          output = [...output, res.data]
          return output
        }, [])
      })
  },
}

const mutations = {
  items (state: State, securities: Array<Security | any>) {
    securities.forEach(security => {
      state.items[security.bloomberg] = Security.create(security)
    })
  },

  item (state: State, security: Security | any) {
    state.items[security.bloomberg] = Security.create(security)
  },

  term (state: State, { term, bloombergs }: { term: string, bloombergs: Array<string>}) {
    state.terms[term] = bloombergs
  },

  lastTraded (state: State, bloomberg: string) {
    state.lastTraded[bloomberg] = Date.now()
  },
}

const getters = {
  getSecurity: state => code => {
    if (typeof code === 'string') {
      return state.items[code]
    }
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}

export function getSecurity (bloomberg) {
  return state.items[bloomberg]
}

export function getSecurityById (securityId) {
  return Object.values(state.items).find(security => security.id === securityId)
}

export function getLastTraded (bloomberg) {
  return state.lastTraded[bloomberg]
}
