import { fx } from 'modules/resources'
import { getSecurity } from 'modules/securities'
import { getDefaultCurrencyCode } from 'modules/trading-profile'

export function sumLoanValues (items: Array<Record<string, any>>, quantityField: string = 'quantity'): Number {
  const defaultCurrencyCode: string = getDefaultCurrencyCode()
  return items.reduce((total, row) => {
    const security = getSecurity(row.bloomberg)
    const value = fx(security.price, security.currencyCode, defaultCurrencyCode)
    total += value * (row[quantityField] || 0)
    return total
  }, 0)
}
