import Vue from 'vue'
import { getValue, isLooseEqual } from 'utils/object'
import { last } from 'utils/collection'
import Api from 'services/Api'
import { lookups } from 'modules/global'
import { handleTableErrors } from 'modules/errors'
import { resetLoans } from 'modules/loans'
import { AbstractItem, LoanNegotiationItem, MessageType, NegotiationMessage } from '..'

export function makeActions (segment: string,
                             stateFromServer: Function,
                             itemFromServer: Function,
                             itemFromTable: Function,
                             securityPath: string | null = 'security',
                             properties?: Array<string>) {
  return {
    create ({ commit }, data) {
      data.items = data.items
        .map(itemFromTable)
        .map(item => item.toServer(true, lookups))
      return Api
        .post(`negotiations/${segment}`, data)
        .then(resetLoans)
        .catch(handleTableErrors)
    },

    /**
     * Loads in the full structure of a negotiation
     */
    load ({ state, rootState, commit, dispatch }, id) {
      return Api
        .get(`negotiations/${segment}/${id}`)
        .then(res => {
          commit('data', stateFromServer(res.data))
          commit('isStale', false)
          return dispatch('loadMessages')
        })
    },

    loadMessages ({ state, commit, dispatch }) {
      return Api.get(`negotiations/${segment}/${state.id}/messages`)
        .then(res => {
          const messages = res.data.map(NegotiationMessage.fromServer)
          const messageId = last(messages).id
          commit('messages', messages)
          return dispatch('loadItems', messageId).then(() => {
            return state.items
          })
        })
    },

    /**
     * Loads negotiation items from a single message
     */
    loadItems ({ commit, dispatch }, messageId) {
      return dispatch('fetchItems', messageId)
        .then(items => {
          commit('items', items)
        })
    },

    /**
     * Fetch negotiation items from a single message – but don't commit
     *
     * We use this when loading old messages
     */
    fetchItems ({ state, commit, dispatch }, messageId) {
      return Api.get(`negotiations/${segment}/messages/${messageId}/items?pageSize=0`)
        .then(res => {
          const items = res.data.items
          dispatch('updateSecurities', items)
          return items.map(itemFromServer)
        })
    },

    updateItems ({ state, commit, rootGetters }, { items, comment, negotiationHash, action }) {
      // payload
      const payload: any = {
        negotiationHash,
        messageType: MessageType.reply,
        comment,
        itemsUpdated: [],
        itemsRemoved: [],
      }

      // build item comparison
      items.forEach((item: LoanNegotiationItem, index) => {
        if (item.quantity === 0 || item['remove']) { // `remove` is added by the table
          payload.itemsRemoved.push(item.id)
        }
        else {
          const existing = state.items[index]
          if (existing && !isLooseEqual(item, existing, properties)) {
            payload.itemsUpdated.push(item)
          }
        }
      })

      // convert
      payload.itemsUpdated = payload.itemsUpdated
        .map(item => {
          const instance = itemFromTable(item)
          return instance.toServer(false, lookups)
        })

      // submit to server
      return Api
        .post(`negotiations/${segment}/${state.id}/messages`, payload)
        .catch(handleTableErrors)
    },

    updatePrivateNotes ({ state, commit }, privateNotes) {
      return Api
        .patch(`negotiations/${segment}/${state.id}`, { privateNotes, hash: state.hash })
        .then(res => {
          commit('hash', res.data.hash)
          return res
        })
    },

    updateSecurities ({ commit }, items) {
      // pass raw securities to securities store so prices are available
      if (securityPath) {
        const securities = items.map(item => getValue(item, securityPath))
        commit('securities/items', securities, { root: true })
      }
    },

    completeNegotiation ({ state, commit }, payload) {
      return Api
        .post(`negotiations/${segment}/${state.id}/messages`, payload)
        .catch(handleTableErrors)
    },
  }
}

export function makeMutations () {
  return {
    data (state: any, data) {
      Object.assign(state, data)
      state.messages = []
      state.items = []
    },

    messages (state: any, messages: Array<NegotiationMessage>) {
      state.messages = messages
      state.messageIndex = messages.length - 1
    },

    items (state: any, items: Array<AbstractItem>) {
      state.items = items
    },

    messageIndex (state: any, index: number) {
      state.messageIndex = index
    },

    privateNotes (state: any, value: string) {
      state.privateNotes = value
    },

    isStale (state: any, value: boolean) {
      state.isStale = value
    },

    hash (state: any, value: string) {
      state.hash = value
    }
  }
}
