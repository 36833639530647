var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ui uiFieldset", class: { divided: _vm.divided } },
    [
      _c("fieldset", { staticClass: "uiFieldset__fieldset" }, [
        _c("a", { staticClass: "anchor", attrs: { name: _vm.anchor } }),
        _vm.label || _vm.$slots.label
          ? _c(
              "legend",
              { staticClass: "uiFieldset__legend" },
              [
                _vm._t("label", [
                  _c("h3", { staticClass: "uiFieldset__heading" }, [
                    _vm._v(_vm._s(_vm.label))
                  ])
                ])
              ],
              2
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "uiFieldset__content" },
          [_vm._t("default")],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }