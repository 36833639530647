import Vue from 'vue'
import Vuex from 'vuex'
import pathify from './pathify'
import settings from './modules/settings'

// store
Vue.use(Vuex)
export default new Vuex.Store({
  strict: true,
  state: {},
  mutations: {},
  actions: {},
  modules: {
    settings,
  },
  plugins: [
    pathify.plugin
  ]
})
