var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      staticClass: "small uppercase",
      attrs: { id: "version" },
      on: { click: _vm.copy }
    },
    [
      _vm._v(
        "Version: " + _vm._s(_vm.BUILD_MODE) + " | " + _vm._s(_vm.BUILD_NUMBER)
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }