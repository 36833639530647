<template>
  <router-link class="el-button el-button--default el-button--mini ui-button"
               :class="className"
               :to="to"
               :disabled="disabled"
               tabindex="-1"
  >
    <slot></slot>
  </router-link>
</template>

<script>
const types = {
  open: '',
  updated: 'info',
  accepted: 'success',
  rejected: 'danger',
}

export default {
  props: {
    to: String,

    type: {
      type: String,
      validator (value) {
        return Object.keys(types).includes(value)
      }
    },

    disabled: Boolean
  },

  computed: {
    className () {
      return {
        'is-disabled': this.disabled,
        ['ui-button--' + (types[this.type] || '')]: true
      }
    }
  }
}

</script>
