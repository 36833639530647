<template>

  <div class="layout__main stats__image" id="home" v-if="showSplash">
  </div>

</template>

<script>
import { BUILD_MODE } from 'config/env'

export default {
  data () {
    return {}
  },

  computed: {
    showSplash () {
      return BUILD_MODE !== 'production'
    }
  },

  methods: {}
}

</script>

<style lang="scss">

</style>
