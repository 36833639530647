












export default {
  metaInfo: {
    title: 'Home'
  },
}
