import { Loading } from 'element-ui'
import { make } from 'vuex-pathify'
import { storage } from 'utils/storage'

const state = {
  isLoading: false,
  showTools: storage.get('settings/showTools', false)
}

let loader

const actions = {
  showLoader ({ commit }, state: boolean | string) {
    if (state) {
      commit('isLoading', true)
      loader = Loading.service({
        fullscreen: true,
        target: 'body',
        text: typeof state === 'string'
          ? `${state}...`
          : 'Loading Asterisk...',
        customClass: 'app-loading-spinner',
        lock: true,
      })
    }
    else {
      commit('isLoading', false)
      if (loader) {
        loader.close()
        loader = null
      }
    }
  },
}

const mutations = {
  ...make.mutations(state),

  showTools (state, show) {
    state.showTools = show
    storage.set('settings/showTools', show)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
