import Loan from './models/Loan'
import LoanItem from './models/LoanItem'
import LoanState from './enums/LoanState'
import LoanEvent from './models/LoanEvent'
import store from '@/app/state'
import { sumLoanValues } from './helpers'

export {
  Loan,
  LoanItem,
  LoanState,
  LoanEvent,
  sumLoanValues,
}

export function resetLoans (input) {
  store.commit('loans/reset')
  return input
}
