<template>

  <ui-fieldset label="Default GC Rates">
    <ui-select :options="options.countries"
               v-model="selectedGcRates"
               label="Select your countries"
               multiple
    />

    <el-row v-if="selectedGcRates.length">
      <el-col :span="6" :offset="10" class="text-center">To</el-col>
      <el-col :span="6" class="text-center rate-header">Rate</el-col>
    </el-row>

    <el-row class="gcRate-card p-4" v-for="(country, index) in selectedGcRates" :key="index">
      <el-col :span="2">
        <h4>{{ country }}</h4>
      </el-col>
      <el-col :span="12" class="gcRate-card__fieldset ">
        <el-row v-for="(tranche, trancheIndex) in model[country]"
                :key="trancheIndex" class="gc-rate-column align-center">

          <!-- from -->
          <el-col :span="12" class="text-right ">
            {{ getTrancheFrom(country, trancheIndex) }} -
          </el-col>

          <!-- to -->
          <el-col :span="10">
            <el-form-item class="gcRate-max-val-input"
            >
              <el-input v-model.number="tranche.maxLoanValue"
                        placeholder="Maximum"
                        type="number"
                        :min="getTrancheToMin(country, trancheIndex)"
                        :max="getTrancheToMax(country, trancheIndex)"
                        @change="onTrancheToChange(country, trancheIndex, $event)"
              />
            </el-form-item>
          </el-col>

          <!-- rate -->
          <el-col :span="8" class="gc-rate-column align-center ">
            <el-form-item class="gcRate-input">
              <el-input v-model.number="tranche.rate"
                        type="number"
                        :min="0"
                        @change="onTrancheRateChange(country, trancheIndex, $event)"
              />
            </el-form-item>
            %
          </el-col>
          <el-col :span="2" :offset="1" class="gc-rate-column align-center ">
            <el-button :icon="getTranche(country, trancheIndex + 1)
                                    ? 'el-icon-minus'
                                    : 'el-icon-plus'"
                       class="tranche__button no-shadow"
                       :disabled="!tranche.maxLoanValue || !tranche.rate"
                       @click="getTranche(country, trancheIndex + 1)
                                          ? removeTranche(country, trancheIndex)
                                          : addTranche(country)"/>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </ui-fieldset>

</template>

<script>
import { clamp } from 'utils/number'

function makeTranche () {
  return {
    maxLoanValue: null,
    rate: null,
  }
}

function makeRate (countries = [], existingRates = {}) {
  const gcRates = {}
  if (countries.length) {
    countries.forEach(country => gcRates[country] = existingRates[country] || [makeTranche()])
  }
  return gcRates
}

function makeModel (gcRatesByCountry = {}) {
  if (!gcRatesByCountry) return {}
  Object.keys(gcRatesByCountry).forEach(country => {
    gcRatesByCountry[country].forEach(tranche => tranche.rate = parseFloat(tranche.rate).toFixed(4))
  })
  return gcRatesByCountry
}

export default {
  components: {},

  props: {
    value: Object
  },

  data () {
    return {
      model: makeModel(this.value),
      options: {
        countries: this.$store.state.resources.countries.map(country => ({ name: country.name, id: country.value }))
      },
    }
  },

  computed: {
    // FIXME SRSLY WTF - a computed property getting one property and setting another
    selectedGcRates: {
      get () {
        return Object.keys(this.model)
      },
      set (countries) {
        this.model = makeRate(countries, this.model)
      }
    },
  },

  watch: {
    model: {
      handler: 'onChange',
      deep: true
    }
  },

  methods: {
    addTranche (country) {
      this.model[country].push(makeTranche())
    },

    removeTranche (country, index) {
      this.model[country].splice(index, 1)
    },

    getTranche (country, index) {
      return this.model[country][index]
    },

    getTrancheFrom (country, index) {
      const tranches = this.model[country]
      return index === 0
        ? 0
        : tranches[index - 1].maxLoanValue + 1
    },

    getTrancheToMin (country, index) {
      return this.getTrancheFrom(country, index) + 1
    },

    getTrancheToMax (country, index) {
      const tranches = this.model[country]
      if (index === tranches.length - 1) {
        return undefined
      }
      const nextMaxValue = tranches[index + 1].maxLoanValue
      return nextMaxValue
        ? nextMaxValue - 2
        : undefined
    },

    onTrancheToChange (country, index, value) {
      value = parseInt(value, 10)
      const tranches = this.model[country]
      const valueFrom = this.getTrancheFrom(country, index)
      if (value < valueFrom + 1) {
        tranches[index].maxLoanValue = valueFrom + 1
      }
      else if (index < tranches.length - 1) {
        const valueTo = this.getTrancheToMax(country, index)
        if (!value || value < valueFrom) {
          tranches[index].maxLoanValue = valueFrom + 1
        }
        else if (value > valueTo) {
          tranches[index].maxLoanValue = valueTo
        }
      }
    },

    onTrancheRateChange (country, index, value) {
      this.model[country][index].rate = value
        ? clamp(parseFloat(value), 0, 100).toFixed(4)
        : null
    },

    onChange () {
      const data = {}
      Object.keys(this.model)
        .forEach(country => {
          data[country] = this.model[country].filter(tranche => tranche.rate)
          if (!data[country].length) {
            delete data[country]
          }
        })
      this.$emit('change', data)
    }
  }
}

</script>

<style lang="scss" scoped>
.trading-profile-form {
  max-width: 50%;
}

.gcRate-card {
  display: flex;
  border: solid 1px $grey-lightest;
  border-radius: 5px;
}

.gcRate-card__fieldset {
  flex-grow: 1;

  .el-row {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.gc-rate-column {
  display: flex;
}

.gcRate-max-val-input,
.gcRate-input {
  margin: 0;

  //& /deep/ .el-input__inner {
  & ::v-deep .el-input__inner {
    padding-right: 0;
  }
}

.tranche-start {
  justify-self: end;
}

.gcRate-max-val-input {
  margin-left: 5px;
}

.gcRate-input {
  margin: 0 5px 0 15px;
}

.rate-header {
  margin-left: -15px;
}

.border {
  border: solid;
}

.tranche__button {
  padding-left: .75em;
  padding-right: .75em;
  font-weight: bold;
}
</style>

<style>
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  padding: 0;
}
</style>
