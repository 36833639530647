import { makeActions, makeMutations } from './store-factory'
import { AbstractNegotiationState, RerateNegotiationItem } from '..'

class State extends AbstractNegotiationState {
  public items: Array<RerateNegotiationItem> = []

  constructor (data: any = {}) {
    super(data)
  }

  static fromServer (data) {
    return new State(data)
  }
}

const state = new State()

const actions = makeActions(
  'rerates',
  State.fromServer,
  RerateNegotiationItem.fromServer,
  RerateNegotiationItem.fromTable,
  'loan.security',
  undefined,
)

const mutations = makeMutations()

const getters = {
  loans (state) {
    return state.items.map(item => item.loan)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
