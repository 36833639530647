<template>

  <div class="negotiationNotes">
    <UiText
      v-model="input"
      placeholder="Optional private notes..."
      multiline
      v-bind="info"
      maxlength="1000"
    />
  </div>

</template>

<script>
import debounce from 'lodash/debounce'

export default {
  props: {
    value: String,

    autosave: Boolean,

    type: String,
  },

  data () {
    return {
      isSaving: false,
    }
  },

  computed: {
    input: {
      get () { return this.value },
      set (value) {
        this.$emit('input', value)
        if (this.autosave) {
          this.save()
        }
      },
    },

    info () {
      if (this.isSaving) {
        return {
          help : 'Saving....',
          helpIcon: 'cloud-upload-alt',
          helpTransition: 'feedback',
        }
      }
    },
  },

  methods: {
    // additional save function; saves notes immediately
    save: debounce(function () {
      if (!this.type) {
        throw new Error('Auto-saving NegotiationNotes require the negotiation "type" parameter to be passed')
      }
      this.isSaving = true
      this.$store
        .dispatch(`negotiations/${this.type}/updatePrivateNotes`, this.input)
        .then(() => this.isSaving = false)
    }, 1000),
  }
}
</script>

<style lang="scss">
.negotiationNotes .uiText__help {
  color: $grey-darker !important;
}
</style>
