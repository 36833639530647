import { ResourceValidator, RulesProvider } from 'vendor/vee-validate'
import Hash from 'core/types/Hash'

export const names: Hash = {
  bloomberg: 'Asset',
  quantity: 'Quantity',
  rate: 'Rate',
  netDividendRequirement: 'Net Dividend Requirement',
  collateralBasketCode: 'Collateral',
  fundName: 'Fund',
}

export const rules: Hash = {
  bloomberg: '',
  quantity: 'integer|min_value:1',
  rate: 'number|between:0,100|max_precision:4',
  netDividendRequirement: 'number|between:0,1000|max_precision:3',
  collateralBasketCode: '',
  fundName: '',
}

export default class AvailabilityValidator extends ResourceValidator {
  constructor (local?: boolean) {
    super(RulesProvider.create(rules, names), local)
  }
}
