import makeIndex from 'pages/Index'
import { RouteConfig } from 'vue-router'

/**
 * Helper function to reduce boilerplate in route creation
 *
 * @param {string}    path    The route's path
 * @param {object}    page    A page component definition
 * @param {Function}  page    A function that returns a page import
 * @param {string}    page    A string path to a file in the view/pages folder
 * @param {object}    attrs   Any additional attributes
 */
export function route (path: string, page: any, attrs: any = {}) {
  return { path, component: load(page), ...attrs }
}

/**
 * Helper function to dynamically build Index page
 *
 * @param {string}        path      The route's path
 * @param {string}        id        The id of the route, for CSS hooks
 * @param {string}        title     The title of the route, for the HTML page
 * @param {RouteConfig[]} children  All child route definitions
 */
export function index (path: string, id: string, title: string, children: Array<RouteConfig>) {
  return { path, component: makeIndex(id, title), children }
}

export function child (parent: string, path: string, page: any, attrs: any = {}) {
  return route(path, page, { parent, ...attrs })
}

export function hook (path: string, beforeEnter: Function) {
  return { path, beforeEnter }
}

export function redirect (path: string, redirect: any) {
  return { path, redirect }
}

export function alias (path: string, alias: string, page: any) {
  return { path, alias, component: load(page) }
}

/**
 * Smart loader that loads app, areas or module pages via:
 *
 *    - '<path>'
 *    - '<module>@<path>'
 *    - '<borrower|lender|guest>@<path>'
 *
 * This maps to the actual folder:
 *
 *    - route('foo/edit', 'borrower:foo/Edit')
 *    - areas/borrower/pages/foo/Edit.vue
 *
 * @param page
 */
export function load (page: any) {
  if (typeof page === 'string') {
    if (page.includes('@')) {
      const [module, path] = page.split('@')
      // for some reason, webpack seems to be picky about the code used to
      // build paths. Not sure why. Perhaps it's a static analysis thing?
      // Anyway, this nested ifs structure works... so leave it!
      if (/^(borrower|lender|guest)$/.test(module)) {
        return require(`../../areas/${module}/pages/${path}`).default
      }
      return require(`../../modules/${module}/pages/${path}`).default
    }
    return require('../views/pages/' + page).default
  }
  return page
}
