<template>
  <div>
    <el-dialog title="Inactivity alert"
               class="autoLogout"
               width="520px"
               :visible="dialogVisible"
               :show-close="false"
               :close-on-click-modal="false"
               :close-on-press-escape="false"
               @close="hideWarning"
    >
      <template #default>
        <h3 class="color-danger"><strong>You appear to have been inactive for {{ inactivityMinutes }} minutes.</strong>
        </h3>
        <p>As a security precaution you will be logged out in {{ timerValue }} seconds.</p>
      </template>

      <template #footer class="dialog-footer">
        <el-button type="primary" @click="hideWarning">Cancel Logout</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import settings from 'config/logout'
import { ENV_NAME } from 'config/env'
import { storage } from 'utils/storage'

// timeout ids
let activityId
let countdownId

// timings
const inactivityMinutes = settings[ENV_NAME]
const inactivitySeconds = inactivityMinutes * 60 * 1000

export default {
  data () {
    return {
      dialogVisible: false,
      inactivityMinutes,
      inactivitySeconds,
      timerDuration: 30,
      timerValue: 0,
    }
  },

  computed: {
    documentVisible: {
      cache: false,
      get () {
        return !document.hidden
      }
    }
  },

  mounted () {
    window.AutoLogout = this
    document.addEventListener('mousemove', this.onUserActivity)
    document.addEventListener('keydown', this.onUserActivity)
    storage.on('activeAt', () => this.onTabsActivity())
  },

  unmounted () {
    document.removeEventListener('mousemove', this.onUserActivity)
    document.removeEventListener('keydown', this.onUserActivity)
    this.onUserActivity.cancel()
    this.cancelCountdown()
  },

  methods: {
    // ---------------------------------------------------------------------------------------------------------------------
    // tabs
    // ---------------------------------------------------------------------------------------------------------------------

    onTabsActivity () {
      if (this.dialogVisible) {
        this.hideWarning()
      }
      this.initUserActivity()
    },

    initTabsActivity () {
      storage.set('activeAt', new Date())
    },

    // ---------------------------------------------------------------------------------------------------------------------
    // user
    // ---------------------------------------------------------------------------------------------------------------------

    onUserActivity: debounce(function () {
      if (this.documentVisible && !this.dialogVisible) {
        this.initUserActivity()
        this.initTabsActivity()
      }
    }, 1000),

    initUserActivity: debounce(function () {
      if (!this.dialogVisible) {
        this.showWarning()
      }
    }, inactivitySeconds),

    // ---------------------------------------------------------------------------------------------------------------------
    // dialog / timer
    // ---------------------------------------------------------------------------------------------------------------------

    showWarning (timerDuration = 30) {
      this.timerDuration = timerDuration
      this.dialogVisible = true
      this.startCountdown()
    },

    hideWarning () {
      this.dialogVisible = false
      this.cancelCountdown()
      this.initTabsActivity()
    },

    startCountdown () {
      this.timerValue = this.timerDuration
      this.continueCountdown()
    },

    continueCountdown () {
      this.timerValue--
      if (this.timerValue === 0) {
        this.hideWarning()
        this.$session.save().then(() => {
          this.$router.push('/auth/logout?soft=1')
        })
      }
      else {
        countdownId = setTimeout(() => this.continueCountdown(), 1000)
      }
    },

    cancelCountdown () {
      clearInterval(countdownId)
    },
  }
}
</script>

<style lang="scss">
.autoLogout {
  h3 {
    font-size: 20px;
  }

  .el-dialog__body {
    padding-top: 1rem !important;
  }
}
</style>
