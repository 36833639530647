import { make } from 'vuex-pathify'
import { get } from 'utils/collection'
import Api from 'services/Api'
import { AbstractNegotiation, LoanNegotiation, RecallNegotiation, RerateNegotiation, ReturnNegotiation } from 'modules/negotiations'
import ResourceType, { getResourceTypeSegment } from 'modules/global/enums/ResourceType'

class State {
  items: Array<AbstractNegotiation> = []

  filters: Array<string>

  search: string

  hidden: string[]

  showHidden: boolean

  constructor () {
    this.items = []
    this.filters = []
    this.search = ''
    this.hidden = []
    this.showHidden = false
  }
}

const classes = {
  loan: LoanNegotiation,
  recall: RecallNegotiation,
  return: ReturnNegotiation,
  rerate: RerateNegotiation,
}

const state = new State()

const actions = {
  load ({ commit, dispatch }) {
    // reload every hour in case of long-lived negotiations
    setTimeout(() => dispatch('load'), 1000 * 60 * 60)

    // load this round's items
    const promises = Object
      .keys(classes)
      .map(segment => Api.get(`negotiations/${segment}s?pageSize=0`))
    return Promise.all(promises)
      .then(res => {
        const items = [
          ...res.shift().data.items.map(LoanNegotiation.fromServer),
          ...res.shift().data.items.map(RecallNegotiation.fromServer),
          ...res.shift().data.items.map(ReturnNegotiation.fromServer),
          ...res.shift().data.items.map(RerateNegotiation.fromServer),
        ]
        commit('items', items)
      })
  },

  loadOne ({ commit }, { segment, id }: { segment: string, id: string}) {
    return Api
      .get(`negotiations/${segment}s/` + id)
      .then(res => {
        const instance = classes[segment].fromServer(res.data)
        commit('item', instance)
      })
  }
}

const mutations = {
  ...make.mutations(state),

  item (state: State, newItem: AbstractNegotiation) {
    const oldItem = get(state.items, newItem.id)
    if (oldItem) {
      Object.assign(oldItem, newItem)
    } else {
      state.items.push(newItem)
    }
  },

  updateSource (state: State, source) {
    state.items.forEach(item => {
      if (item instanceof LoanNegotiation) {
        // source lists on loans are only viewable by the borrower
        if (item.sourceList && item.sourceList.id === source.id) {
          Object.assign(item.sourceList, source)
        }
      }
    })
  },

  toggleHidden (state: State, id: string) {
    const index: number = state.hidden.indexOf(id)
    index > -1
      ? state.hidden.splice(index, 1)
      : state.hidden.push(id)
  },

  toggleSearch (state , value: string) {
    state.search = value === state.search ? '' : value
  }
}

const getters = {
  getItemById: (state: State) => (id: string): AbstractNegotiation => {
    return get(state.items, id)
  },

  getNegotiationsForLocateList: state => listId => {
    return state.items.filter(item => item.sourceList && item.sourceList.id === listId)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
