import { get, remove, update } from 'utils/collection'
import Api from 'services/Api'
import Pagination from 'models/Pagination'
import { AbstractNegotiation } from 'modules/negotiations'
import { ResourceType } from 'modules/global'
import { showPageForNotification } from '../routing'
import { NotificationItem, NotificationReason, NotificationState } from '..'
import service from '../services/NotificationsService'
import store from '@/app/state'

class State {
  // the paged notifications log
  items: Array<NotificationItem>

  // other people's notifications, triggered by signal r
  unseen: Array<NotificationItem>

  // your own notifications, triggered by signal r, but stored separately so cards can show when they have been updated
  updated: Array<NotificationItem>

  // preference for index page
  showUnseenOnly: boolean

  // preference for dashboard
  showAllUpdated: boolean

  pagination: Pagination

  isConnected: boolean

  constructor () {
    this.items = []
    this.unseen = []
    this.updated = []
    this.showUnseenOnly = false
    this.showAllUpdated = false
    this.pagination = Pagination.create(50)
    this.isConnected = false
  }
}

const state = new State()

const actions = {
  init: function ({ commit, getters, dispatch }) {
    return service.init()
  },

  reconnect () {
    return service.start(true)
  },

  /**
   * Initial load of all Notifications (first batch of seen as well as unseen)
   */
  load ({ state, commit }, page = 1) {
    commit('page', page)
    const options = state.pagination.toServer()
    options.pageSize = 50
    if (state.showUnseenOnly) {
      options.state = NotificationState.unseen
    }
    Api.get('notifications', options)
      .then(res => {
        commit('items', res.data.items.map(NotificationItem.fromServer))
        commit('pagination', Pagination.fromServer(res.data))
      })
  },

  loadUnseen ({ commit, getters }) {
    Api.get('notifications', { state: NotificationState.unseen, pageSize: 0 })
      .then(res => {
        // variables
        const userName = getters.userName
        const items = res.data.items.map(NotificationItem.fromServer)

        // unseen
        const unseen = items.filter(item => item.fromUserName !== userName)
        commit('unseen', unseen)

        // updated (not loading in from the server on first load right now, but leaving code here as an example)
        // const updated = items.filter(item => item.fromUserName === userName && !item.isDeleted)
        // commit('updated', updated)
      })
  },

  /**
   * View the source item of any notification
   */
  view ({ state, getters, commit, dispatch, rootState }, id: string) {
    const notification: NotificationItem = get(state.items, id)
    if (notification) {
      if (notification.reason !== NotificationReason.deleted) {
        return showPageForNotification(notification)
      }
    }
  },

  /**
   * View source item of any notification, only if the source item is loaded
   */
  viewLoaded ({ state, getters, commit, dispatch, rootState }, id: string) {
    const notification: NotificationItem = get(state.items, id)
    if (notification) {
      const item = getters.getItemForNotification(notification)
      if (item) {
        dispatch('view', id)
      } else {
        console.log('The item for this notification is not loaded!')
      }
    }
  },

  /**
   * Remove notification from the unseen array, and update notification on server
   */
  clearUnseen ({ state, getters, commit }, id: string) {
    const userName = getters.userName
    const item: NotificationItem = get(state.unseen, id)
    if (item && !item.fromUserName !== userName && !item.seenByUserName) {
      return Api
        .patch(`notifications/${id}`)
        .then(res => {
          commit('removeUnseen', id)
          commit('updateItem', NotificationItem.fromServer(res.data))
        })
    }
  },

  clearAllUnseen ({ state, dispatch }) {
    return Promise.all(state.unseen.map(item => dispatch('clearUnseen', item.id)))
  },

  /**
   * Remove notification from the updated array
   */
  clearUpdated ({ state, commit, rootState }, id: string) {
    const item: NotificationItem = get(state.updated, id)
    if (item) {
      commit('removeUpdated', id)
    }
  },

  /**
   * Clear all notifications for a specific item
   */
  clearForItem ({ getters, dispatch }, itemId) {
    const unseen: Array<NotificationItem> = getters.getUnseenForItem(itemId)
    unseen.forEach(item => {
      return dispatch('clearUnseen', item.id)
    })

    const updated: Array<NotificationItem> = getters.getUpdatedForItem(itemId)
    updated.forEach(item => {
      return dispatch('clearUpdated', item.id)
    })
  }
}

const mutations = {
  unseen (state: State, unseen: Array<NotificationItem>) {
    state.unseen = unseen
  },

  addUnseen (state: State, item: NotificationItem) {
    state.unseen.unshift(item)
  },

  removeUnseen (state: State, id: string) {
    remove(state.unseen, id)
  },

  showUnseenOnly (state: State, showUnseenOnly: boolean) {
    state.showUnseenOnly = showUnseenOnly
  },

  updated (state: State, updated: Array<NotificationItem>) {
    if (state.showAllUpdated) {
      state.updated = updated
    } else {
      state.updated = [updated[0]]
    }
  },

  addUpdated (state: State, item: NotificationItem) {
    if (state.showAllUpdated) {
      state.updated.unshift(item)
    } else {
      state.updated = [item]
    }
  },

  removeUpdated (state: State, id: string) {
    remove(state.updated, id)
  },

  showAllUpdated (state: State, showAllUpdated: boolean) {
    state.showAllUpdated = showAllUpdated
  },

  items (state: State, items: Array<NotificationItem>) {
    state.items = items
  },

  addItem (state: State, item: NotificationItem) {
    state.items.unshift(item)
    if (state.items.length > state.pagination.pageSize) {
      state.items.pop()
    }
  },

  updateItem (state: State, item: NotificationItem) {
    const oldItem: NotificationItem = get(state.items, item.id)
    if (oldItem) {
      update(state.items, oldItem.id, item)
    }
  },

  pagination (state: State, pagination: Pagination) {
    state.pagination = pagination
  },

  page (state: State, page: number) {
    state.pagination.page = page
  },

  isConnected (state: State, value: boolean) {
    state.isConnected = value
  },
}

const getters = {
  userName (state, getters, rootState) {
    return rootState.user.name
  },

  /**
   * Get unseen notifications for loaded index page
   */
  unseenForIndex: (state, getters) => {
    return state.items
      .filter((notification: NotificationItem) => notification.fromUserName !== getters.userName)
      .filter(item => !item.seenByUserName)
  },

  /**
   * Get unseen notifications for all loaded negotiations
   */
  unseenForNegotiations (state, getters, rootState): Array<Notification> {
    if (rootState.dashboard) {
      const ids = rootState.dashboard.items
        .map((item: AbstractNegotiation) => item.id)
      return state.unseen
        .filter((notification: NotificationItem) => ids.indexOf(notification.itemId) > -1)
    }
    return []
  },

  /**
   * Get unseen notifications for one loaded negotiation
   */
  getUnseenForItem: (state: State) => (id: string) => {
    return state.unseen
      .filter(item => item.itemId === id)
      .filter(item => !item.seenByUserName)
  },

  /**
   * Get updated notifications for one loaded negotiation
   */
  getUpdatedForItem: (state: State) => (id: string) => {
    return state.updated.filter(item => item.itemId === id)
  },

  getItemForNotification: (state: State, getters, rootState, rootGetters) => (notification: NotificationItem) => {
    switch (notification.type) {
      case ResourceType.AvailabilityList:
        return

      case ResourceType.LocateList:
        return rootGetters['locateLists/getItemById'](notification.itemId)

      case ResourceType.LoanNegotiation:
      case ResourceType.RerateNegotiation:
      case ResourceType.ReturnNegotiation:
      case ResourceType.RecallNegotiation:
        return rootGetters['dashboard/getItemById'](notification.itemId)
    }
  },

}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
