import Api from 'services/Api'
import { LoanRole } from 'modules/organizations'
import OrganizationType from '../enums/OrganizationType'
import Organization from '../models/Organization'

class State {
  // isSystem is an organisation-level override to override users when we log in as System in place of that user
  // we don't store this on the organization itself, as the user will already have a type of borrower or lender
  isSystem: boolean

  current: Organization

  counterparties: Array<Organization>

  constructor () {
    this.isSystem = false
    this.current = new Organization()
    this.counterparties = []
  }
}

const state = new State()

const actions = {
  init ({ commit }) {
    return Api
      .get('organizations/current')
      .then(res => {
        commit('current', Organization.fromServer(res.data))
        const organizationType = res.data.organizationType
        if (organizationType === OrganizationType.system) {
          commit('isSystem', true)
        }
        return organizationType
      })
  },

  load ({ commit, dispatch }, all = true) {
    return dispatch('init').then(() => {
      return Api
        .get(`organizations/counterparties`)
        .then(res => {
          commit('counterparties', res.data.map(Organization.fromServer))
        })
    })
  },
}

const mutations = {
  isSystem (state: State, value: boolean) {
    state.isSystem = value
  },

  current (state: State, value: Organization) {
    state.current = value
  },

  counterparties (state: State, data: Array<Organization>) {
    state.counterparties = data
  },
}

const getters = {
  isUser (state: State) {
    return state.current.isUser()
  },

  canBorrow () {
    return state.current.canBorrow()
  },

  canLend () {
    return state.current.canLend()
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}

// ---------------------------------------------------------------------------------------------------------------------
// helpers
// ---------------------------------------------------------------------------------------------------------------------

/**
 * TYpe describing objects which may contain information about negotiating parties
 */
type Negotiated = {
  borrowerOrganization?: Organization,
  lenderOrganization?: Organization,
  borrowerId?: string,
  lenderId?: string,
}

export function canBorrow () {
  return state.current.canBorrow()
}

export function canLend () {
  return state.current.canLend()
}

/**
 * Pass in an object that may be negotiated, and find out its function, relative to the current organization
 *
 * @param negotiated
 * @returns 1, 2 or undefined
 */
export function getLoanRole (negotiated: Negotiated): LoanRole | undefined {
  if (negotiated) {
    const borrowerId = negotiated.borrowerId
      ? negotiated.borrowerId
      : negotiated.borrowerOrganization && negotiated.borrowerOrganization.id
    const lenderId = negotiated.lenderId
      ? negotiated.lenderId
      : negotiated.lenderOrganization && negotiated.lenderOrganization.id
    if (borrowerId === state.current.id) {
      return LoanRole.borrower
    }
    if (lenderId === state.current.id) {
      return LoanRole.lender
    }
  }
}

// return "borrower", "lender", or "unknown" (in case of runtime errors)
export function getLoanRoleText (negotiated: Negotiated): string {
  const func = getLoanRole(negotiated)
  if (typeof func !== 'undefined') {
    return LoanRole[func]
  }
  console.warn('Could not determine negotiation function for:', negotiated)
  return 'unknown'
}

// returns true/false
export function isLenderFor (negotiated: Negotiated): boolean {
  return getLoanRole(negotiated) === LoanRole.lender
}

// returns true/false
export function isBorrowerFor (negotiated: Negotiated): boolean {
  return getLoanRole(negotiated) === LoanRole.borrower
}
