import { AbstractItem, AbstractNegotiation, NegotiationMessage } from '../index'

/**
 * ------------------------------------------------------------------------------------------------
 * This abstract class serves as the immediate super-class for *all* Vuex Negotiation store states
 * ------------------------------------------------------------------------------------------------
 *
 * It has a specifically-designed hierarchy, the point of which is to factor-out duplicate
 * properties from top-level store State classes.
 *
 * The result is top-level Vuex stores which mirror their associated Resource, but add their *own*
 * top level properties as required.
 *
 * Thus, each individual store State class only then needs to do two things:
 *
 *    1. type its own `items` array, i.e. items: Array<NegotiationItem>
 *    2. add its own unique properties, i.e. `sourceList`
 *
 * Note that because of the way Vue's reactivity system works, ALL properties down the inheritance
 * chain will need to be set, even if they are set to undefined. Note the special TypeScript syntax
 * ?: which allows you to do this.
 *
 * Vuex will then create and make the ENTIRE state reactive.
 *
 * The inheritance hierarchy is:
 *    |
 *    +- Model                              - Base model properties
 *    |   +- id                               : string
 *    |
 *    +- AbstractNegotiation                - Common negotiation properties & getters
 *        |   +- name                          : string
 *        |   +- state                         : NegotiationState
 *        |   +- ...
 *        |
 *        +- AbstractNegotiationState       - Common store properties
 *            |   +- messages                  : Array<NegotiationMessage>
 *            |   +- items                     : Array<AbstractNegotiationItem>
 *            |
 *            +- State                      - <ResourceType>Store properties (different per store!)
 *                +- items                    : Array<CONCRETE type>
 *                +- ...
 */
export default abstract class AbstractNegotiationState extends AbstractNegotiation {
  // hash
  public hash: string

  // messages
  public messages: Array<NegotiationMessage> = []
  public messageIndex: number = 0

  // current message items
  public items: Array<AbstractItem> = []
  public isStale: boolean

  protected constructor (data: any = {}) {
    super(data)
    this.hash = data.hash
    this.messages = data.messages || []
    this.messageIndex = this.messages.length - 1
    this.items = data.items || []
    this.isStale = false
  }
}
