<template>
  <div class="ticker">
    <div class="ticker__header flex align-center">
      <h2 class="ticker__title">Ticker</h2>
      <span></span>
      <UiIcon class="ticker__searchIcon"
              icon="search"
              @click="$refs.modal.open()" />
    </div>
    <ticker-table />

    <el-pagination
      class="ticker__pagination mt-4"
      layout="prev, pager, next"
      background
      small
      hide-on-single-page
      :pager-count="5"
      :page-size="pageSize"
      :current-page="page"
      :total="total"
      @current-change="onPageChange"
    />

    <ticker-filter-modal ref="modal"
                         @update="onFiltersUpdate"
                         @close="modal = false"
    />
  </div>
</template>

<script>
import { debounce } from 'lodash'
import { sync, get } from 'vuex-pathify'
import { getPageSize } from '../state/TickerStore'
import TickerFilterModal from '../components/TickerFilterModal'
import TickerTable from '../components/TickerTable'

export default {
  components: {
    TickerTable,
    TickerFilterModal
  },

  data () {
    return {
      modal: false,
    }
  },

  computed: {
    filters: sync('ticker/filters'),
    ...sync('ticker/pagination@', [
      'pageSize',
      'page',
    ]),
    ...get('ticker/pagination@', [
      'lastPage',
      'total',
    ]),
  },

  created () {
    window.addEventListener('resize', this.onResize)
  },

  destroyed () {
    window.removeEventListener('resize', this.onResize)
  },

  watch: {
    page: 'load',
    filters: 'load',
    pageSize: 'load',
  },

  methods: {
    load: debounce(function () {
      return this.$store.dispatch('ticker/load')
    }, 300),

    onFiltersUpdate (filters) {
      this.filters = filters
    },

    onPageChange (page) {
      this.page = page
    },

    onResize () {
      const pageSize = getPageSize()
      if (pageSize !== this.pageSize) {
        this.pageSize = pageSize
      }
    },
  }
}

</script>

<style lang="scss">
.ticker {

  justify-content: space-around;

  &__title {
    flex-grow: 0;
    flex-shrink: 0;
  }

  &__pagination {
    text-align: center;
  }

  &__searchIcon {
    flex-grow: 0;
  }
}
</style>
