import { toDateString, toDateIso } from 'utils/date'
import { Lookups } from 'modules/global'
import { LoanItem } from 'modules/loans'
import { Model } from 'core/models'

export default class LocateItem extends Model {

  public bloomberg: string

  public quantityRequired: number | undefined

  public quantityLocated: number

  public settlementDate: string

  public get isRequired (): boolean {
    return this.quantityRemaining === undefined || this.quantityRemaining > 0
  }

  public get quantityRemaining (): number | undefined {
    if (typeof this.quantityRequired === 'number') {
      const value = this.quantityRequired - (this.quantityLocated || 0)
      return value >= 0
        ? value
        : 0
    }
    return undefined
  }

  constructor (data: any = {}) {
    super(data)
    this.bloomberg = data.bloomberg || ''
    this.quantityRequired = data.quantityRequired
    this.quantityLocated = data.quantityLocated
    this.settlementDate = data.settlementDate
  }

  static fromServer (data: any = {}): LocateItem {
    data.bloomberg = data.security.bloomberg
    data.settlementDate = data.settlementDate
      ? toDateString(data.settlementDate)
      : undefined
    return new LocateItem(data)
  }

  static fromTable (data): LocateItem {
    return new LocateItem(data)
  }

  static fromLoan (loan: LoanItem): LocateItem {
    const item: LocateItem = new LocateItem()
    item.bloomberg = loan.bloomberg
    item.quantityRequired = loan.quantity
    return item
  }

  toServer (lookup: Lookups): any {
    const { getSecurity } = lookup
    const security = getSecurity(this.bloomberg)
    if (!security) {
      throw new Error(`Unknown asset for code "${this.bloomberg}"`)
    }
    return {
      securityId: security.id,
      quantityRequired: this.quantityRequired
        ? this.quantityRequired
        : null,
      quantityLocated: this.quantityLocated,
      settlementDate: this.settlementDate
        ? toDateIso(this.settlementDate, true)
        : undefined,
    }
  }
}
