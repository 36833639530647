import { clone } from 'utils/object'
import Hash from 'core/types/Hash'
import { Fund } from 'modules/resources'

export default class GcRate {
  public country: string

  public fund: Fund

  public rate: number

  public maxLoanValue: number

  constructor (data: Hash) {
    this.country = data.country
    this.fund = data.fund
    this.rate = data.rate
    this.maxLoanValue = data.maxLoanValue
  }

  static fromServer (data: Hash) {
    data = clone(data)
    data.fund = Fund.fromServer(data.fund)
    return new GcRate(data)
  }

  toServer () {
    return {
      country: this.country,
      fundId: this.fund.id,
      rate: this.rate,
      maxLoanValue: this.maxLoanValue
    }
  }

  clone () {
    const data = {
      country: this.country,
      fund: this.fund.clone(),
      rate: this.rate,
      maxLoanValue: this.maxLoanValue,
    }
    return new GcRate(data)
  }
}
