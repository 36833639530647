import {
  Columns,
  makeDateEditor,
  makeQuantity,
  makeRate,
  makeTable, makeText,
  retarget
} from 'vendor/handsontable'
import { RerateNegotiationItem } from '../../../negotiations'
import { isFromToday } from 'utils/validators'
import RerateNegotiationValidator from 'modules/negotiations/validators/RerateNegotiationValidator'

function settings (vm) {
  const { action, locked } = vm

  const validator = new RerateNegotiationValidator()

  const columns = [
    makeText('Reference', 'loan.reference', 110),
    retarget(Columns.security, 'loan.bloomberg'),
    makeQuantity('Quantity', 'loan.quantity', false),
    makeDateEditor('Effective From Date', 'effectiveFromDate', isFromToday),
    makeRate('New Rate', 'rate'),
    makeRate('Old Rate', 'loanRate', false),
  ]

  if (!locked) {
    columns.push(...Columns.tradedRates)
  }

  if (action === 'edit' && !locked) {
    columns.push(Columns.remove)
  }

  return {
    columns,
    validator,
    schemaClass: RerateNegotiationItem,
    minSpareRows: 0,
  }
}

export default makeTable(settings)
