import { makeActions, makeMutations } from './store-factory'
import { AbstractNegotiationState, ReturnNegotiationItem } from '..'

class State extends AbstractNegotiationState {
  public items: Array<ReturnNegotiationItem> = []

  constructor (data: any = {}) {
    super(data)
  }

  static fromServer (data) {
    return new State(data)
  }
}

const state = new State()

const actions = makeActions(
  'returns',
  State.fromServer,
  ReturnNegotiationItem.fromServer,
  ReturnNegotiationItem.fromTable,
  null,
  ['quantity', 'fee', 'feeCurrencyCode', 'settlementDate'],
)

const mutations = makeMutations()

const getters = {}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
