import NegotiationDefaults from '../models/NegotiationDefaults'
import NetDividendRequirement from '../models/NetDividendRequirement'
import MarginRequirement from '../models/MarginRequirement'
import GcRate from '../models/GcRate'
import specials from './SpecialSecuritiesStore'
import { makeStore } from './store-factory.js'

const actions = {
  load ({ commit, dispatch }, all = false) {
    const promises = [
      dispatch('defaults/load'),
      dispatch('specials/load'),
    ]
    if (all) {
      promises.push(
        dispatch('gcRates/load'),
        dispatch('netDivs/load'),
        dispatch('marginRequirements/load'),
      )
    }
    return Promise.all(promises)
  },
}

const modules = {
  defaults: makeStore('trading-profile/other-defaults', NegotiationDefaults.fromServer),
  gcRates: makeStore('trading-profile/default-gc-rates', null, GcRate.fromServer),
  netDivs: makeStore('trading-profile/default-net-dividend-requirements', null, NetDividendRequirement.fromServer),
  marginRequirements: makeStore('trading-profile/lender-margin-requirements', null, MarginRequirement.fromServer),
  specials,
}

export default {
  namespaced: true,
  actions,
  modules,
}
