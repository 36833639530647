import { Columns, makeQuantityEditor, makeNetDiv, makeTable } from 'vendor/handsontable'
import AvailabilityItem from '../models/AvailabilityItem'
import AvailabilityValidator from '../validators/AvailabilityValidator'

function settings () {
  const validator = new AvailabilityValidator()

  const columns = [
    Columns.securityPicker,
    makeQuantityEditor('Quantity', 'quantity'),
    Columns.value,
    Columns.rate,
    Columns.collateral,
    makeNetDiv(true),
    Columns.fundPicker,
  ]

  return {
    columns,
    validator,
    schemaClass: AvailabilityItem,
    minSpareRows: 1,
  }
}

export default makeTable(settings)
