<template>
  <div class="negotiationResponse ui uiFormItem">
    <el-radio-group v-model="input" class="vertical">

      <div class="negotiationResponse__item">
        <el-radio label="reject" :disabled="!canReject">
          <span>{{ rejectText }}</span>
        <span v-if="rejectInfo" class="negotiationResponse__info">{{ rejectInfo }}</span>
        </el-radio>
      </div>

      <div class="negotiationResponse__item">
        <el-radio label="reply" :disabled="!canReply">
          <span>{{ replyText }}</span>
        <span v-if="replyInfo" class="negotiationResponse__info">{{ replyInfo }}</span>
        </el-radio>
      </div>

      <div class="negotiationResponse__item" v-if="showAccept">
        <el-radio label="accept" :disabled="!canAccept">
          <span>Accept</span>
        <span v-if="acceptInfo" class="negotiationResponse__info">{{ acceptInfo }}</span>
        </el-radio>
      </div>

    </el-radio-group>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },

    canReply: {
      type: Boolean,
      required: true,
    },

    canAccept: {
      type: Boolean,
      required: true,
    },

    canReject: {
      type: Boolean,
      required: true,
    },

    replyText: {
      type: String,
      default: 'Counter',
    },

    rejectText: {
      type: String,
      default: 'Reject',
    },

    replyInfo: {
      type: String,
      default: '',
    },

    acceptInfo: {
      type: String,
      default: '',
    },

    rejectInfo: {
      type: String,
      default: '',
    },

    showAccept: {
      type: Boolean,
      default: true,
    }
  },

  data () {
    /**
     * FIXME there seems to be a bug where not all props are being passed when they are bound using v-bind
     * Adding this data function seemed to temporarily fix this, but I don't think that is the real reason.
     */
    return {}
  },

  computed: {
    input: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    }
  },

}
</script>

<style lang="scss">
.negotiationResponse {
  margin: 0.5rem 0;

  .el-radio__label {
    font-weight: bold;
  }

  &__info {
    display: block;
    margin-top: 3px;
    margin-left: 24px;
    font-size: 12px;
    font-weight: bold;
    color: $grey-lighter;
    transition: color .3s;
    margin-bottom: 1em;
  }

  .el-radio.is-checked .negotiationResponse__info {
    color: $grey-darkest;
  }

  &__item:hover {
    .negotiationResponse__info {
      color: $grey-darkest;
    }

    .el-radio.is-disabled .negotiationResponse__info {
      color: $color-danger-darker;
    }
  }
}
</style>
