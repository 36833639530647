import Hash from 'core/types/Hash'

export default class OrganizationForm {
  // server
  public id: string = ''
  public hash: string = ''

  // address
  public displayName: string = ''
  public legalEntityName: string = ''
  public companyRegistrationNumber: string = ''
  public registeredAddress: string = ''
  public phoneNumber: string = ''

  // numbers
  public legalEntityIdentifierNumber: string = ''
  public billingCurrencyId: string = ''

  // regulatory
  public regulatorName: string = ''
  public regulatorRegistrationNumber: string = ''
  public regulatoryStatus: string = ''
  public organizationType: string = ''
  public organizationRole: string = ''

  // agent
  public authorizedAgentName: string = ''
  public authorizedAgentLegalEntityIdentifier: string = ''

  // info
  public uniqueTradeIdentifierCreator: string = ''
  public noticesAddress: string = ''
  public noticesPhoneNumber: string = ''

  // offshore
  public agentForServiceOutsideUKAddress: string = ''
  public agentForServiceOutsideUKPhoneNumber: string = ''
  public contractType: string = ''

  // unsure
  public file?: any

  constructor (data: Hash = {}) {
    Object.assign(this, data)
  }

  public static fromServer (input: Hash) {
    const output: Hash = {}
    Object.keys(input).forEach(key => {
      // flatten
      if (key === 'billingCurrency') {
        output.billingCurrencyId = input.billingCurrency ? input.billingCurrency.id : ''
      } else {
        output[key] = input[key]
      }
    })
    return new OrganizationForm(output)
  }

  public toServer () {
    return this
  }
}
