var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "panel" },
    [
      _c("h2", [_vm._v("Negotiation Defaults")]),
      _vm.model
        ? _c(
            "el-form",
            {
              staticClass: "mr-4",
              attrs: { "label-position": "left", "label-width": "50%" }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Currency" } },
                [
                  _c("ui-select", {
                    attrs: { options: _vm.options.currencies, mode: "object" },
                    model: {
                      value: _vm.form.defaultCurrency,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "defaultCurrency", $$v)
                      },
                      expression: "form.defaultCurrency"
                    }
                  })
                ],
                1
              ),
              _vm.canLend
                ? [
                    _c(
                      "el-form-item",
                      { attrs: { label: "Collateral Basket" } },
                      [
                        _c("ui-select", {
                          attrs: {
                            options: _vm.options.collateral,
                            mode: "object"
                          },
                          model: {
                            value: _vm.form.defaultCollateralBasket,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "defaultCollateralBasket", $$v)
                            },
                            expression: "form.defaultCollateralBasket"
                          }
                        })
                      ],
                      1
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _c("SaveChanges", { on: { save: _vm.save } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }