var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ContentHeader",
    { attrs: { title: _vm.title } },
    [
      !_vm.isOpen
        ? _c(
            "UiOutput",
            { staticClass: "capitalize", attrs: { label: "State" } },
            [
              _c(
                "span",
                { staticClass: "uppercase tag", class: _vm.stateText },
                [_vm._v(_vm._s(_vm.stateText))]
              )
            ]
          )
        : _vm._e(),
      _vm.role
        ? _c(
            "UiOutput",
            { staticClass: "capitalize", attrs: { label: "Role" } },
            [_vm._v(_vm._s(_vm.role))]
          )
        : _vm._e(),
      _c("UiOutput", { attrs: { label: "Reference" } }, [
        _vm._v(_vm._s(_vm.name))
      ]),
      _c("UiOutput", { attrs: { label: "Counterparty" } }, [
        _vm._v(_vm._s(_vm.counterParty.name))
      ]),
      _c("UiOutput", { attrs: { label: "Created" } }, [
        _vm._v(_vm._s(_vm._f("dateTimeHuman")(_vm.createdAt)))
      ]),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }