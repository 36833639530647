var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "profile__securities panel" },
    [
      _c("h2", [_vm._v("Specials")]),
      _c("UiErrors", { attrs: { errors: _vm.errors } }),
      _c(
        "p",
        { staticClass: "profile__upload" },
        [
          _vm._v("Click to\n    "),
          _c("UiUpload", {
            attrs: {
              href: "/profile/special-securities/upload",
              type: "text",
              text: "upload"
            },
            on: { submit: _vm.upload }
          }),
          _vm._v("\n    an Assets file.\n  ")
        ],
        1
      ),
      _c("SpecialSecuritiesTable", {
        ref: "table",
        attrs: { data: _vm.items.data }
      }),
      _c("SaveChanges", { on: { save: _vm.save } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }