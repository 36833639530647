var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("nav", { staticClass: "siteNav" }, [
    _c(
      "div",
      { staticClass: "siteNav__left" },
      [
        _c("router-link", {
          staticClass: "siteLink__home",
          attrs: { to: "/" }
        }),
        _vm.isUser
          ? [
              _vm.canLend
                ? _c("router-link", { attrs: { to: "/availability-list" } }, [
                    _c("label", [_vm._v("Availability")])
                  ])
                : _vm._e(),
              _vm.canBorrow
                ? _c("router-link", { attrs: { to: "/long-positions" } }, [
                    _c("label", [_vm._v("Long Positions")])
                  ])
                : _vm._e(),
              _c(
                "router-link",
                { attrs: { to: "/negotiations" } },
                [
                  _c("label", [_vm._v("Negotiations")]),
                  _c("UiBadge", {
                    attrs: { value: _vm.negotiationNotifications.length }
                  })
                ],
                1
              ),
              _c("router-link", { attrs: { to: "/loans" } }, [
                _c("label", [_vm._v("Loans")])
              ]),
              _c("router-link", { attrs: { to: "/reports" } }, [
                _c("label", [_vm._v("Reports")])
              ]),
              _vm.isAdmin
                ? _c("router-link", { attrs: { to: "/profile" } }, [
                    _c("label", [_vm._v("Profile")])
                  ])
                : _vm._e()
            ]
          : _vm._e()
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "siteNav__right" },
      [
        _vm.isUser
          ? [
              _c(
                "router-link",
                {
                  staticClass: "siteLink__notifications",
                  attrs: { to: "/notifications" }
                },
                [
                  _c(
                    "label",
                    {
                      attrs: { title: _vm.isConnected ? "" : "Connecting..." }
                    },
                    [
                      _c("i", {
                        staticClass: "far",
                        class: _vm.notificationIcon
                      })
                    ]
                  ),
                  _c("UiBadge", {
                    attrs: { value: _vm.unseenNotifications.length }
                  })
                ],
                1
              )
            ]
          : _vm._e(),
        !_vm.isLoggedIn
          ? _c("router-link", { attrs: { to: "/auth/login" } }, [
              _c("label", [_vm._v("Log in")])
            ])
          : _c(
              "div",
              { staticClass: "siteLink__dropdown" },
              [
                _c(
                  "el-dropdown",
                  {
                    attrs: { trigger: "click" },
                    on: { command: _vm.onCommand }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "el-dropdown-link",
                        attrs: { title: _vm.organization.name }
                      },
                      [
                        _vm._v("\n          " + _vm._s(_vm.displayName) + " "),
                        _c("i", {
                          staticClass: "el-icon-arrow-down el-icon--right"
                        })
                      ]
                    ),
                    _c(
                      "el-dropdown-menu",
                      {
                        staticStyle: { width: "230px" },
                        attrs: { slot: "dropdown" },
                        slot: "dropdown"
                      },
                      [
                        _c(
                          "el-dropdown-item",
                          { attrs: { icon: "el-icon-info", command: "copy" } },
                          [_c("Version", { ref: "version" })],
                          1
                        ),
                        _c(
                          "el-dropdown-item",
                          {
                            attrs: {
                              icon: "el-icon-house",
                              command: "none",
                              divided: ""
                            }
                          },
                          [_vm._v(_vm._s(_vm.organization.name))]
                        ),
                        _c(
                          "el-dropdown-item",
                          {
                            attrs: {
                              icon: "el-icon-user",
                              command: "/user",
                              divided: ""
                            }
                          },
                          [_vm._v("Profile")]
                        ),
                        _c(
                          "el-dropdown-item",
                          {
                            attrs: {
                              icon: "el-icon-close",
                              command: "/auth/logout"
                            }
                          },
                          [_vm._v("Log out")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }