<template>
  <output :class="{ tag: true, success: model === 100 }">{{ model }}%</output>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0
    }
  },

  computed: {
    model () {
      return Math.round(this.value || 0)
    }
  }
}
</script>
