import { AbstractNegotiation } from '..'

export default class LoanNegotiation extends AbstractNegotiation {

  public readonly type: string = 'loan'

  public readonly priority: number = 4

  public value: number

  public sourceList: any

  public useSmartUpdating: boolean

  constructor (data: any = {}) {
    super(data)
    this.value = data.value
    this.sourceList = data.sourceList
    this.useSmartUpdating = data.useSmartUpdating
  }

  static fromServer (data: any = {}): LoanNegotiation {
    return new LoanNegotiation(data)
  }

}
