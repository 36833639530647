import { cloneDeep, debounce } from 'lodash'
import Handsontable from 'handsontable'
import tippy from 'plugins/tippy'
import ResourceValidator from 'vendor/vee-validate/services/ResourceValidator'
import { isPlainObject } from 'utils/object'
import { validateArray } from 'utils/vue'
import { NegotiationItemState } from 'modules/negotiations'
import RecordSet from '../classes/RecordSet'
import menu from './table-menu'

/**
 * Creates a Vue component that mounts a HoT table instance
 *
 * Manages:
 *
 *    - table configuration
 *    - one-way data flow
 *    - validation
 *    - validation variations
 *    - cross-field validation
 *
 * HoT table setup:
 *
 *    - builds settings from factory function
 *       - columns
 *       - validator
 *       - dataSchema function
 *       - min rows
 *    - adds callbacks for
 *       - readonly columns
 *       - copy/paste protection
 *       - editor fixes
 *       - cell formatting
 *       - cell changes
 *
 * Returned component:
 *
 *    - methods
 *       - loadData()
 *       - setData()
 *       - getData()
 *       - validation
 *       - reset
 *    - events
 *       - @update: isUpdated, isValid
 *
 * @param {function}     factory    A factory function (vm => settings) that returns a settings object
 */
export default function makeTable (factory) {
  if (typeof factory !== 'function') {
    throw new Error('Parameter "settings" must be a factory function')
  }

  return {
    props: {
      data: {
        type: Array,
        default () {
          return []
        }
      },

      action: {
        type: String,
        default: 'edit',
        validator: validateArray(['create', 'edit'])
      },

      role: {
        type: String,
        validator: validateArray(['lender', 'borrower'])
      },

      locked: {
        type: Boolean,
        default: false,
      }
    },

    data () {
      return {
        models: new RecordSet(true),
        changes: new RecordSet(true),
        errors: new RecordSet(true),
        validation: {
          validators: {},
          changes: [],
        },
      }
    },

    computed: {
      className () {
        return {
          anTable: true,
          anLocked: this.locked,
        }
      }
    },

    watch: {
      data: 'loadData'
    },

    mounted () {
      this.init()
      window['$table'] = this
      if (!this.locked) {
        this.$session.addHandler(this.saveSession)
      }
    },

    destroy () {
      this.$session.removeHandler(this.saveSession)
    },

    render (h) {
      return h('section', {
        ref: 'table',
        class: this.className,
      })
    },

    methods: {

      // ---------------------------------------------------------------------------------------------------------------
      // initialisation
      // ---------------------------------------------------------------------------------------------------------------

      init () {
        this.initSettings()
        this.initMenus()
        this.initPlugins()
        this.initTable()
      },

      initSettings () {
        // HoT seems to crash if some settings (number of columns) is changed
        let settings = factory(this)

        // separate validator and settings
        const validator = settings.validator
        delete settings.validator

        // generate settings
        settings = makeSettings(settings, this.locked)

        // utility hooks
        settings.afterBeginEditing = this.afterBeginEditing
        settings.afterRefreshDimensions = this.updateHeight

        // cache read-only columns
        this.readOnlyColumns = settings.columns.reduce((output, column) => {
          if (this.isColumnReadOnly(column)) {
            output[column.data] = true
          }
          return output
        }, {})

        // validation and change hooks
        if (!this.locked) {
          settings.afterValidate = this.afterValidate
          settings.beforeChange = this.beforeChange
          settings.afterChange = (changes, source) => {
            // when loading data, changes will always be `null`
            if (source !== 'loadData') {
              this.afterChange(changes, source)
            }
          }
          settings.beforeRender = this.beforeRender
        }

        // rendering hooks
        settings.cells = this.cells
        settings.afterRenderer = this.afterRenderer

        // save for later
        this.settings = settings

        // set up validators
        if (!this.locked) {
          this.initValidator(validator)
        }
      },

      initMenus () {
        const hasSelection = () => {
          const [range] = this.hot.getSelectedRange()
          return range && range.to.row > range.from.row
        }

        const fillDownAll = {
          name: () => `Fill ${ hasSelection() ? 'Selection' : 'Down'}`,
          callback: (key, selection, clickEvent) => {
            menu.fillDown.call(this, selection, true)
          }
        }

        const fillDown = {
          name: () => `Fill ${ hasSelection() ? 'Selection' : 'Down'} (empty cells only)`,
          callback: (key, selection, clickEvent) => {
            menu.fillDown.call(this, selection)
          }
        }

        const menuItems = [
          fillDownAll,
          fillDown,
        ]

        // display correct menu for cell type
        this.settings.beforeOnCellContextMenu = (event, coords, td) => {
          // prevent menu on readonly
          const column = this.getColumnSettings(coords.col)
          let items = !this.isColumnReadOnly(column)
            ? [...menuItems]
            : []

          // show menu
          this.hot.updateSettings({ contextMenu: { items } })
        }
      },

      initPlugins () {
        // set up tippy
        const hot = this.hot
        this.tippy = tippy(this.$refs.table, {
          trigger: 'mouseenter',
          target: '[data-tooltip]',
          arrow: true,
          animateFill: false,
          duration: [50, 100],
          animation: 'fade',
          onShow: (instance) => {
            const reference = instance.reference
            const error = reference.getAttribute('data-tooltip')
            const editor = this.hot.getActiveEditor()
            const isEditing = editor && editor.state === 'STATE_EDITING'
            if (error && !isEditing) {
              instance.setContent(error)
              return true
            }
            return false
          },
        })
      },

      initTable (logHooks) {
        // table
        this.hot = new Handsontable(this.$refs.table, this.settings)

        // height
        this.updateHeight()

        // debug
        if (logHooks) {
          const runHooks = this.hot.runHooks
          this.hot.runHooks = function (...args) {
            console.log(...args)
            return runHooks.call(this.hot, ...args)
          }
        }
      },

      // ---------------------------------------------------------------------------------------------------------------
      // data i/o
      // ---------------------------------------------------------------------------------------------------------------

      /**
       * Load data, recreating settings and resetting table
       * @param {[object]}  values  An array of models
       */
      loadData (values) {
        // update table and settings
        this.initSettings()
        this.hot.updateSettings(this.settings)

        // store to compare changes later
        this.models.setItems(values)
        this.changes.clear()
        this.errors.clear()

        // duplicate the array and rebuild items in case data is passed from Vuex
        const models = [...values.map(value => new this.settings.schemaClass(value))]

        // load data into table
        this.hot.loadData(models)
        this.updateHeight()
        this.validate()
        this.emitUpdate()

        // load any session data
        if (!this.locked) {
          this.loadSession()
        }
      },

      /**
       * Set data, without resetting table
       * @param {[object]}  values    an Array of models
       */
      setData (values) {
        const changes = values.reduce((changes, model, index) => {
          Object.keys(model).forEach(key => {
            changes.push([index, key, model[key]])
          })
          return changes
        }, [])
        this.updateHeight()
        this.hot.setDataAtRowProp(changes, 'user')
      },

      getData () {
        // get data
        const data = this.hot.getSourceData()

        // trim HoT "spare" rows
        const { minSpareRows } = this.settings
        return minSpareRows > 0
          ? data.slice(0, -minSpareRows)
          : data
      },

      getRow (row) {
        return this.hot.getSourceDataAtRow(row)
      },

      reset () {
        const models = this.models.items
        Object.assign(this, this.$options.data())
        this.loadData(models)
      },

      validate () {
        return new Promise((resolve, reject) => {
          this.hot.validateCells(resolve)
        })
      },

      // ---------------------------------------------------------------------------------------------------------------
      // validation digest
      // ---------------------------------------------------------------------------------------------------------------

      initValidator (validator) {
        // check that validator is correct
        if (!(validator instanceof ResourceValidator)) {
          throw new Error('Parameter "validator" must be an instance of ResourceValidator')
        }

        // update validator
        this.validator = validator
        this.validator.setOptions(this.action, this.response, this.role)

        // store reference to self, as `this` in validation callbacks is the grid > column > cell settings
        // @see https://forum.handsontable.com/t/get-row-number-in-column-validator/653/3
        // @see https://github.com/handsontable/handsontable/issues/6298#issuecomment-537908350
        const self = this

        // assign validators
        this.settings.columns.forEach(column => {
          if (column.validator) {
            // common variables
            const prop = column.data
            const dependents = self.validator.provider.dependents

            // flag validatable columns for afterValidate callback
            self.validation.validators[prop] = true

            // use model validation for dependent fields
            if (dependents && dependents[prop]) {
              column.validator = function (value, process) {
                // variables
                const context = this
                const dep = dependents[prop]

                // build model from dependent fields only
                const model = self.getRow(context.row)
                const values = {
                  [dep]: model[dep],
                  [prop]: value,
                }

                // validate
                self.validator.validateModel(values).then(function (errors) {
                  const error = errors && errors[prop]
                  self.setCellError(context.row, prop, error)
                  process(!error)
                })
              }
            }

            // use field validation for everything else
            else {
              column.validator = function (value, process) {
                const context = this
                self.validator.validateField(value, prop).then(function (error) {
                  self.setCellError(context.row, prop, error)
                  process(!error)
                })
              }
            }

          }
        })

      },

      setCellError (row, prop, error) {
        const col = this.hot.propToCol(prop)
        const cell = this.hot.getCell(row, col)

        // for some reason, hot.getCell() does not always return a cell
        if (cell) {
          error
            ? cell.setAttribute('data-tooltip', error)
            : cell.removeAttribute('data-tooltip')
        }
      },

      afterValidate (isValid, value, row, prop, source) {
        // track errors for showing in tooltip
        if (!isValid) {
          this.errors.set(row, prop, true)
        }
        else {
          this.errors.remove(row, prop)
        }

        // cross-field validation check
        if (source !== 'user') {
          const deps = this.validator.provider.dependents
          const dep = deps && deps[prop]

          // ensure only validatable dependents are re-rendered
          if (dep && this.validation.validators[dep]) {
            this.validation.changes.push([row, dep])
          }
        }
      },

      // ---------------------------------------------------------------------------------------------------------------
      // cell rendering
      // ---------------------------------------------------------------------------------------------------------------

      /**
       * Callback for each cell when rendering
       *
       * @see https://handsontable.com/docs/7.1.1/Options.html#cells
       * @see https://handsontable.com/docs/7.1.1/Core.html#getCellMeta
       *
       * Note that this function is also called by getCellMeta() so there is no way
       * to get existing meta as that would cause an infinite loop. This may be a bug:
       *
       * @see https://forum.handsontable.com/t/3547/4
       *
       * @param {number}  row   The row index
       * @param {number}  col   The column index
       * @param {string}  prop  The column prop
       * @return {object}
       */
      cells (row, col, prop) {
        if (!this.hot) {
          return null
        }

        const meta = {}
        const rowData = this.getRow(row)
        if (rowData) {
          if (rowData.state === NegotiationItemState.removed) {
            meta.readOnly = true
          }
        }

        return meta
      },

      beforeRender () {
        // get changes (dependents for cross-field validation)
        const changes = this.validation.changes

        // if we have dependent changes, force those cells to update
        if (changes.length) {
          // get existing data
          const data = this.hot.getSourceData()

          // build updates
          const updates = []
          changes.forEach((change, index) => {
            const [row, dep] = change
            const value = data[row][dep]
            updates.push([row, dep, value])
          })

          // apply updates
          this.hot.setDataAtRowProp(updates, 'user')

          // reset changes
          this.validation.changes = []
        }
      },

      afterRenderer (td, row, col, prop, value, column) {
        if (!this.hot) {
          return
        }

        // classes
        let className = ''

        // add classes based on columns setting
        if (column) {
          // user-supplied class name
          className += ' ' + (column.className || '')

          // read only column
          if (!column.editor) { // column.readOnly ||
            className += ' anReadOnly'
          }
        }

        // add classes based on source data
        const rowData = this.getRow(row)
        if (rowData) {
          // properties were updated
          if (Array.isArray(rowData.propertiesUpdated) && rowData.propertiesUpdated.includes(prop)) {
            className += ' anUpdated'
          }

          // quantity
          if (rowData.quantity === 0 || rowData.quantityRequired === 0) {
            const error = this.errors.get(row, 'quantity') || this.errors.get(row, 'quantityRequired')
            if (!error) {
              className += ' anRemove'
            }
          }

          // special
          if (rowData.specialSecurity) {
            className += ' anSpecial'
          }

          // removing
          if (rowData.remove) {
            className += ' anRemove'
          }

          // removed
          if (rowData.state === NegotiationItemState.removed) {
            className += ' anRemoved'
          }
        }

        // cell was edited
        const edited = this.changes.get(row, column.data)
        if (edited) {
          className += ' anEdited'
        }

        // kill tippy if we don't need it
        if (td._tippy && !td.getAttribute('data-tooltip')) {
          td._tippy.destroy()
        }

        // set class on cell
        td.className += className + ' '
      },

      // ---------------------------------------------------------------------------------------------------------------
      // change digest
      // ---------------------------------------------------------------------------------------------------------------

      beforeChange (changes, source) {
        for (let i = 0; i < changes.length; i++) {
          const [row, prop, oldValue, newValue] = changes[i]

          // protect read-only columns from change
          if (this.readOnlyColumns[prop]) {
            // setting changes to null prevents HoT from updating
            changes[i] = null
          }

          // otherwise, determine changes
          else {
            const initialValue = this.models.get(row, prop)

            // changed
            if (String(newValue || '') !== String(initialValue || '')) {
              this.changes.set(row, prop, newValue)
            }
            // unchanged
            else {
              this.changes.set(row, prop, undefined)
            }
          }
        }
      },

      afterChange () {
        this.emitUpdate()
      },

      // debounce updates
      emitUpdate: debounce(function () {
        const isValid = this.errors.isEmpty()
        const hasChanged = !this.changes.isEmpty()
        this.$emit('update', hasChanged, isValid)
      }, 100),

      // ---------------------------------------------------------------------------------------------------------------------
      // session
      // ---------------------------------------------------------------------------------------------------------------------

      saveSession () {
        const key = window.location.pathname
        const data = this.changes.getRows()
        if (data.length) {
          this.$session.set(key, data)
        }
      },

      loadSession: debounce(function () {
        const key = window.location.pathname
        const data = this.$session.get(key)
        if (data) {
          const options = { type: 'info', dangerouslyUseHTMLString: true }
          this.$confirm('You have some unsaved changes from last time you viewed this page.<br>Do you want to load them?', '', options).then(() => {
            this.setData(data)
          })
          this.$session.remove(key)
        }
      }, 500),

      // ---------------------------------------------------------------------------------------------------------------
      // utilities
      // ---------------------------------------------------------------------------------------------------------------

      // update height of table to fit on screen
      updateHeight: debounce(function () {
        // table height
        const hot = this.hot
        const headerHeight = 40
        const numRows = hot.getSourceData().length
        const rowHeights = hot.getSettings().rowHeights
        const tableHeight = (rowHeights * numRows) + headerHeight

        // offset calculations
        const tableTop = 150
        const tableBottom = 60
        const windowHeight = window.innerHeight
        const availHeight = windowHeight - tableTop - tableBottom
        const height = tableHeight > availHeight
          ? availHeight
          : 'auto'

        // update
        hot.updateSettings({
          fixedColumnsLeft: 1,
          height,
        })
      }, 250),

      // before editing, resize cells to work with our updated padding
      afterBeginEditing (row, column) {
        const hot = this.hot
        const cell = hot.getCell(row, column)
        const htEditor = hot.getActiveEditor()
        const editor = htEditor.TEXTAREA

        // restyle text editor
        if (editor) {
          // override editor width
          const w = cell.clientWidth
          const width = (w - (w % 2 === 0 ? 16 : 16)) + 'px'
          editor.style.minWidth = width
          editor.style.maxWidth = width

          // append cell classes to editor
          editor.className = 'handsontableInput anPadded ' + cell.className
        }
      },

      isColumnReadOnly (column) {
        column = this.getColumnSettings(column)
        const { editor, readOnly, className } = column
        return editor === false || readOnly || (className && className.includes('anReadOnly'))
      },

      getColumnSettings (column) {
        if (typeof column === 'number') {
          return this.hot.getSettings().columns[column]
        }
        if (typeof column === 'number') {
          return this.hot.getSettings().columns.find(col => col.data === column)
        }
        return column
      },
    }
  }
}

/**
 * Helper function to make settings object for HoT table
 *
 * @param   {Object|Function}   settings    Additional settings. Expects at least a columns property
 * @param   {boolean}           readOnly    Optional flag to make table readonly
 * @return  {object}
 */
export function makeSettings (settings, readOnly) {
  const defaults = {
    // licence
    licenseKey: 'non-commercial-and-evaluation',

    // data
    data: [],

    // layout
    rowHeights: 33,
    width: '100%',
    height: 'auto',

    // allows an additional row at the bottom of the table to add more data
    minSpareRows: 1,

    // reloads data when it changes
    // BUG - seems to bork one-way data flow and blow everything up
    observeChanges: false,

    // copy / paste
    // contextMenu: ['copy', 'cut', '---------', 'remove_row'],
    contextMenu: {
      items: []
    },

    // constrains selection
    selectionMode: 'range',

    // constrains fill handle
    fillHandle: {
      autoInsertRow: false,
      direction: 'vertical'
    },
  }

  // mix in settings
  settings = {
    ...defaults,
    ...cloneDeep(settings),
  }

  // sanity check that columns are passed
  if (!settings.columns) {
    throw new Error('Parameter "settings" expects a "columns" array of objects')
  }

  // sanity check if schemaClass is actually a class
  const { schemaClass } = settings
  if (typeof schemaClass !== 'function') {
    throw new Error('Parameter "schemaClass" must be factory function')
  }

  // check that the function does generate a row
  const row = new schemaClass({})
  if (!isPlainObject(row)) {
    throw new Error('Parameter "schemaClass" must generate a new row when called with "new"')
  }

  // filter null column configs (it is valid to add null columns in column configuration)
  settings.columns = settings.columns.filter(column => !!column)

  // set readonly columns
  settings.readOnly = readOnly

  // return final object
  return settings
}
