import Handsontable from 'handsontable'

/**
 * Renders the string key of an enum
 */
export default function (instance, td, row, col, prop, value, cellProperties) {
  Handsontable.renderers.TextRenderer.apply(this, arguments)
  const values = cellProperties.enum || {}
  const text = values[value]
  td.className = cellProperties.className + ' anEnum anEnum-' + text
  td.innerHTML = text
}
