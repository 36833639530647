import Vue from 'vue'
import Api from 'services/Api'
import { remove } from 'utils/collection'
import LocateItem from '../models/LocateItem'
import { Locate } from 'modules/locate-lists'
import { lookups } from 'modules/global'
import { handleTableErrors } from 'modules/errors'

function state () {
  return new Locate()
}

const actions = {
  load ({ state, commit, dispatch }, id): Promise<Locate> {
    return Api.get(`/locate-lists/${id}`)
      .then(res => {
        const list: Locate = Locate.fromServer(res.data)
        commit('id', id)
        commit('data', list)
        return list
      })
  },

  loadItems ({ commit, dispatch }, id) {
    return Api.get(`/locate-lists/${id}/items?pageSize=0`)
      .then(res => {
        const rawItems = res.data.items
        const items = rawItems.map(LocateItem.fromServer)
        dispatch('updateSecurities', rawItems)
        commit('items', items)
        return items
      })
  },

  updateItems ({ state, commit, dispatch, rootGetters }, { items, form }) {
    // build payload for adding
    items = items
      .filter(item => item.bloomberg)
      .map(item => {
        return LocateItem
          .fromTable(item)
          .toServer(lookups)
      })

    // update
    const payload = {
      ...form,
      items,
      hash: state.hash,
    }
    return Api
      .patch(`/locate-lists/${state.id}`, payload)
      .catch(handleTableErrors)
      .then(res => {
        commit('hash', res.data.hash)
        commit('locateLists/update', form, { root: true })
        commit('dashboard/updateSource', form, { root: true })
        return res
      })
  },

  updateSecurities ({ commit }, items) {
    // pass raw securities to securities store so prices are available
    commit('securities/items', items.map(item => item.security), { root: true })
  },

  upload ({ state, commit, dispatch }, data) {
    data.append('hash', state.hash)
    return Api
      .patch(`/locate-lists/${state.id}/file`, data)
      .then(res => {
        // update hash because of changes
        commit('hash', res.data.hash)

        // alert when unknown assets
        const message = res.headers['x-asterisknetworks-information']
        if (message) {
          Vue.prototype.$notify({
            title: 'Unknown Assets',
            type: 'warning',
            duration: 0,
            message,
            dangerouslyUseHTMLString: true,
          })
        }

        // load new items
        return dispatch('loadItems', res.data.id)
      })
  },

}

const mutations = {
  clear (oldState) {
    Object.assign(oldState, state())
  },

  id (state, value) {
    state.id = value
  },

  hash (state, value) {
    state.hash = value
  },

  data (state, data) {
    Object.assign(state, data)
  },

  items (state, items) {
    state.items = items
  },

  remove (state, id) {
    remove(state.items, id)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
