import { LocateItem } from 'modules/locate-lists'
import { Organization } from 'modules/organizations'
import { Resource } from 'core/models'

export default class Locate extends Resource {

  public readonly type: string = 'locate'

  public hash: string

  public name: string

  public value: number

  public currencyCode: string

  public itemCount: number

  public createdByUserName: string

  public updatedByUserName: string

  public valuePercentageLocated: number

  public volumePercentageLocated: number

  public averagePercentageLocated: number

  public excludedLender: Organization

  public items: Array<LocateItem>

  public get isLocated () {
    return this.volumePercentageLocated === 100
  }

  constructor (data: any = {}) {
    super(data)
    this.hash = data.hash
    this.name = data.name
    this.value = data.value
    this.currencyCode = data.currencyCode
    this.itemCount = data.itemCount
    this.createdByUserName = data.createdByUserName
    this.updatedByUserName = data.updatedByUserName
    this.valuePercentageLocated = data.valuePercentageLocated
    this.volumePercentageLocated = data.volumePercentageLocated
    this.averagePercentageLocated = data.averagePercentageLocated
    this.excludedLender = data.excludedLender
  }

  static fromServer (data: any = {}): any {
    data.currencyCode = data.valueCurrency
      ? data.valueCurrency.code
      : undefined
    return new Locate(data)
  }

  toServer (): any {
    return {
      id: this.id,
      hash: this.hash,
      name: this.name,
    }
  }
}
