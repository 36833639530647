import Vue from 'vue'
import Sticky from 'vue-sticky-directive'

Vue.use(Sticky)

export default {

  functional: true,

  props: {
    top: {
      type: Number,
      default: 60,
    },

    onStick: Function
  },

  render (h, context) {

    const options = {
      class: {
        'sticky-container': true,
      },

      // @see https://www.npmjs.com/package/vue-sticky-directive
      directives: [
        {
          name: 'sticky'
        }
      ],

      attrs: {
        // make room for header
        'sticky-offset': `{ top: ${context.props.top} }`,

        // place above other elements
        'sticky-z-index': 500,

        // sticky events
        'on-stick': context.props.onStick,
      }
    }

    return h('div', options, context.slots().default)
  }
}
