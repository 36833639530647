// variables
export const env = process.env.NODE_ENV
export const isProd = env === 'production'
export const isDev = !isProd

// defaults
export const ENV_NAME = env || 'development'
export const BUILD_NUMBER = process.env.BUILD_NUMBER || 'N/A'
export const BUILD_MODE = process.env.BUILD_MODE || 'N/A'

// debug
console.log(' » node env:', ENV_NAME)
console.log(' » build mode:', BUILD_MODE)
console.log(' » build number:', BUILD_NUMBER)
