<template>
  <div class="ui uiFormItem uiText">
    <el-form-item>
      <div class="uiLabel">{{ label }}</div>

      <div class="uiText__input">
        <el-input
          v-if="multiline"
          v-bind="$attrs"
          type="textarea"
          :placeholder="placeholder"
          :autosize="{ minRows, maxRows }"
          v-model="model"
        />
        <el-input
          v-else
          v-model="model"
          v-bind="$attrs"
          :placeholder="placeholder"
          @blur="update"
        />
      </div>

      <transition :name="helpTransition">
        <div v-if="help" class="uiText__help">
          <i :class="`mr-1 fas fa-${helpIcon}`"></i>
          <span>{{ help }}</span>
        </div>
      </transition>

      <el-button
        v-if="$listeners.update"
        class="uiText__button mt-2"
        size="mini"
        type="primary"
        :disabled="oldValue === value"
        @click="update"
      >Update
      </el-button>
    </el-form-item>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    value: String,

    label: String,

    placeholder: String,

    multiline: {
      type: Boolean,
      default: false
    },

    minRows: {
      type: Number,
      default: 2,
    },

    maxRows: {
      type: Number,
      default: 10,
    },

    help: String,

    helpIcon: String,

    helpTransition: String,
  },

  data () {
    return {
      oldValue: this.value
    }
  },

  computed: {
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    update () {
      this.$emit('update', this.model)
      this.oldValue = this.model
    }
  }
}
</script>
<style lang="scss">
.uiText {

  &__input textarea {
    min-height: 70px !important;
    max-height: 300px;
  }

  &__help {
    line-height: 1.4em;
    margin-top: .7em;
    margin-left: 0.5em;
    font-size: 0.9em;
  }

  .el-input__count {
    line-height: 1rem !important;
  }

}
</style>
