<template>
  <div class="layout__index" id="page-availability-list">
    <h1>Reports</h1>
    <UiBreadcrumbs/>

    <div class="layout__main" id="edit-availability-list">

      <section class="layout__content panel">
        <h2>Reports</h2>
        <UiOutput v-for="report in reports"
                  type="text"
                  icon="file-alt"
                  :key="report.name"
                  :label="report.description"
        >
          <a :href="`/reports/${report.id}`" @click.prevent="download(report)">{{ report.name}}</a>
        </UiOutput>
      </section>

    </div>

  </div>
</template>

<script>
import Api from 'services/Api'
import { Report } from '../'

export default {

  metaInfo () {
    return {
      title: 'Reports'
    }
  },

  data () {
    return {
      reports: []
    }
  },

  beforeRouteEnter (to, from, next) {
    Api.get('reports').then(res => {
      next(vm => {
        vm.reports = res.data.map(Report.fromServer)
      })
    })
  },

  methods: {
    download (report) {
      Api.download(report.path, report.name + '.csv')
    }
  }
}
</script>
