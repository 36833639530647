<template>

  <div class="layout__main" id="loans">

    <SiteAside label="Filters">
      <LoansFilters/>
    </SiteAside>

    <section class="layout__content panel">
      <h2>Results</h2>

      <el-tabs v-model="activeTab" ref="tabs">

        <el-tab-pane name="index">

          <template slot="label">
            All loans <span class="grey-lighter" v-if="pagination.total">({{ pagination.total }})</span>
          </template>


          <section class="formControls">
            <!-- selection -->
            <el-button :disabled="selection.length === 0"
                       @click="addSelected">Add selected
            </el-button>

            <!-- pagination -->
            <div class="formControls__block">
              <el-pagination
                class="inline-block"
                style="margin-left: -10px"
                layout="prev, pager, next"
                background
                :current-page="pagination.page"
                :page-size="pagination.pageSize"
                :total="pagination.total"
                @current-change="onPageChange"
              />
            </div>

          </section>

          <LoansTable :items="items"
                      @selection-change="onSelectionChange"
                      @row-click="editItem"
                      empty-text="There are no (or no more) results for this filter combination."
                      class="mb-4"
          />

        </el-tab-pane>

        <el-tab-pane name="selected" :disabled="selected.length === 0">

          <template slot="label">
            Selected loans
            <UiBadge :value="selected.length"></UiBadge>
          </template>

          <section class="formControls">
            <!-- selection -->
            <el-button :disabled="toRemove.length === 0"
                       @click="removeSelected">Remove selected
            </el-button>

            <el-button :disabled="noSelection"
                       @click="promptClearSelected">Clear
            </el-button>

            <!-- actions -->
            <div class="formControls__block">

              <!-- Re-rate -->
              <el-button type="primary"
                         :disabled="noSelection"
                         @click="initRerate"
              >
                <i v-if="canBorrow && !canRerateSelected" class="fas fa-exclamation-triangle mr-3"></i>
                Re-rate
              </el-button>

              <!-- Return -->
              <el-button v-if="canBorrow"
                         type="primary"
                         :disabled="noSelection || !hasReturnableLoans"
                         @click="initReturn"
              >
                <i v-if="canBorrow && hasReturnableLoans && !canReturnSelected" class="fas fa-exclamation-triangle mr-3"></i>
                Return
              </el-button>

              <!-- Recall -->
              <el-button v-if="canLend"
                         type="primary"
                         :disabled="noSelection || !hasRecallableLoans"
                         @click="initRecall"
              >
                <i v-if="canLend && hasRecallableLoans && !canRecallSelected" class="fas fa-exclamation-triangle mr-3"></i>
                Recall
              </el-button>
            </div>

          </section>

          <LoansTable :items="selected"
                      @selection-change="onAddedSelectedChange"
                      @row-click="editItem"
                      empty-text="No selection. Add items from the previous tab."
          />

        </el-tab-pane>
      </el-tabs>

    </section>

  </div>

</template>

<script>
import { plural } from 'utils/string'
import { commit, dispatch, get, sync } from 'vuex-pathify'
import { OrganizationMixin } from 'modules/organizations'
import LoansFilters from '../components/LoansFilters'
import LoansTable from '../components/LoansTable'

export default {

  inheritAttrs: false,

  components: {
    LoansFilters,
    LoansTable,
  },

  mixins: [
    OrganizationMixin,
  ],

  metaInfo () {
    return {
      title: 'Search Loans',
      bodyAttrs: {
        crumb: 'Search'
      }
    }
  },

  data () {
    return {
      toRemove: []
    }
  },

  computed: {
    ...get('loans', [
      'items',
      'pagination',
    ]),

    ...sync('loans', [
      'activeTab',
      'selection',
      'selected',
    ]),

    // ---------------------------------------------------------------------------------------------------------------------
    // filters
    // ---------------------------------------------------------------------------------------------------------------------

    termLoans () {
      return this.selected.filter(loan => loan.termEndDate)
    },

    returnableLoans () {
      return this.selected.filter(loan => loan.borrowerId === this.organization.id)
    },

    recallableLoans () {
      return this.selected.filter(loan => loan.lenderId === this.organization.id)
    },

    // ---------------------------------------------------------------------------------------------------------------------
    // flags
    // ---------------------------------------------------------------------------------------------------------------------

    noSelection () {
      return this.selected.length === 0
    },

    hasTermLoans () {
      return this.termLoans.length > 0
    },

    hasReturnableLoans () {
      return this.returnableLoans.length > 0
    },

    hasRecallableLoans () {
      return this.recallableLoans.length > 0
    },

    canRerateSelected () {
      return !this.hasTermLoans
    },

    canReturnSelected () {
      return this.returnableLoans.length === this.selected.length
    },

    canRecallSelected () {
      return this.recallableLoans.length === this.selected.length
    },
  },

  watch: {
    selected: 'updateTabs',
    items: 'updateTabs',
  },

  beforeRouteEnter (to, from, next) {
    commit('loans/filters', {})
    return dispatch('loans/load')
      .then(next)
  },

  methods: {
    onPageChange (page) {
      commit('loans/page', page)
      return this.load()
    },

    onSelectionChange (selection) {
      this.selection = selection
    },

    onAddedSelectedChange (selection) {
      this.toRemove = selection
    },

    load () {
      return dispatch('loans/load')
    },

    addSelected () {
      this.selected = this.selected.concat(this.selection)
      if (this.items.length === 0 && this.pagination.total > 0) {
        commit('loans/page', this.pagination.page - 1)
      }
      return this.load()
    },

    removeSelected () {
      const ids = this.toRemove.map(item => item.id)
      this.selected = this.selected.filter(item => !ids.includes(item.id))
      return this.load()
    },

    promptClearSelected () {
      this.$confirm('Clear selected loans?', { type: 'info', title: 'Confirm' }).then(this.clearSelected)
    },

    clearSelected () {
      this.selected = []
      this.activeTab = 'index'
      return this.load()
    },

    updateTabs () {
      // element ui appears to have a bug where tabs' slot content won't update, so we have to force it
      // @see https://stackoverflow.com/questions/52984837/element-ui-view-cant-update-immediately-in-slot-area
      this.$refs.tabs.$children.forEach(child => {
        child.$nextTick(child.$forceUpdate)
      })
    },

    editItem (loan) {
      this.$router.push('/loans/view/' + loan.id)
    },

    initRerate () {
      // variables
      const { hasTermLoans } = this
      const hasOnlyTermLoans = this.termLoans.length === this.selected.length

      // message box options
      let message
      const options = {
        type: 'info',
        dangerouslyUseHTMLString: true,
      }

      // build options for a mix of loan types
      if (hasTermLoans) {
        options.title = 'Warning'
        options.type = 'warning'
        message = 'Term loans cannot be re-rated, and you have selected only term loans'

        // if only term loans, bail
        if (hasOnlyTermLoans) {
          return this.$alert(message, options)
        }

        // otherwise, offer to continue with just the open loans
        else {
          message += '<br/>Do you want to continue with just the Open Loans ?'
        }
      }

      // options for only open loans
      else {
        options.title = 'Confirm'
        message = `Rerate ${plural(this.selected.length, 'loan')}?`
      }

      // prompt user
      this.$confirm(message, options)
        .then(() => {
          if (hasTermLoans) {
            commit('loans/clearTermLoans')
          }
          this.navigate('/negotiations/rerates/create?source=loans')
        })
    },

    initRecall () {
      // options (default for success)
      let message = `Recall ${plural(this.selected.length, 'loan')}?`
      const options = {
        title: 'Confirm',
        type: 'info',
        dangerouslyUseHTMLString: true,
      }

      // update if there's a problem
      if (!this.canRecallSelected) {
        options.title = 'Warning'
        options.type = 'warning'
        const numInvalid = this.selected.length - this.recallableLoans.length
        message = `You have selected ${plural(numInvalid, 'loan')} for which you are not the lender.`
        message += `<br/>Do you want to remove ${plural(numInvalid, 'it', 'them', false)} and continue ?`
      }

      // prompt user
      this.$confirm(message, options)
        .then(() => {
          if (!this.canRecallSelected) {
            commit('loans/selected', this.recallableLoans)
          }
          this.navigate('/negotiations/recalls/create?source=loans')
        })
    },

    initReturn () {
      // options (default for success)
      let message = `Return ${plural(this.selected.length, 'loan')}?`
      const options = {
        title: 'Confirm',
        type: 'info',
        dangerouslyUseHTMLString: true,
      }

      // update if there's a problem
      if (!this.canReturnSelected) {
        options.title = 'Warning'
        options.type = 'warning'
        const numInvalid = this.selected.length - this.returnableLoans.length
        message = `You have selected ${plural(numInvalid, 'loan')} for which you are not the borrower.`
        message += `<br/>Do you want to remove ${plural(numInvalid, 'it', 'them', false)} and continue ?`
      }

      // prompt user
      this.$confirm(message, options)
          .then(() => {
            if (!this.canReturnSelected) {
              commit('loans/selected', this.returnableLoans)
            }
            this.navigate('/negotiations/returns/create?source=loans')
          })
    },

    navigate (route) {
      setTimeout(() => {
        this.$router.push(route)
      }, 200)
    }
  }
}
</script>
