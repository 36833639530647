var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "negotiationComment" },
    [
      _c("UiText", {
        attrs: {
          placeholder: "Optional public comment...",
          "show-word-limit": "",
          maxlength: "100",
          multiline: "",
          help: _vm.help,
          "help-icon": "exclamation-triangle"
        },
        model: {
          value: _vm.input,
          callback: function($$v) {
            _vm.input = $$v
          },
          expression: "input"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }