import { clone } from 'utils/object'
import Hash from 'core/types/Hash'
import { Fund } from 'modules/resources'

export default class NetDividendRequirement {
  public country: string

  public fund: Fund

  public netDividendRequirement: number

  constructor (data: Hash) {
    this.country = data.country
    this.fund = data.fund
    this.netDividendRequirement = data.netDividendRequirement
  }

  static fromServer (data: Hash) {
    data = clone(data)
    data.fund = Fund.fromServer(data.fund)
    return new NetDividendRequirement(data)
  }

  toServer () {
    return {
      country: this.country,
      fundId: this.fund.id,
      netDividendRequirement: this.netDividendRequirement
    }
  }

  clone () {
    const data = {
      country: this.country,
      fund: this.fund.clone(),
      netDividendRequirement: this.netDividendRequirement,
    }
    return new NetDividendRequirement(data)
  }
}
