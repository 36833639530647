<template>

  <el-dialog width="500px"
             title="Search and Filter"
             :visible.sync="visible"
             modal-append-to-body
             append-to-body
             lock-scroll
  >
    <el-form class="ticker-filter" @submit.prevent="submit">

      <el-form-item v-bind="fieldProps" label="Asset">
        <el-select v-model="form.securityIds"
                   multiple
                   filterable
                   remote
                   reserve-keyword
                   placeholder="Enter a search term"
                   :remote-method="search"
                   :loading="loading"
                   class="w-100">
          <el-option v-for="item in options"
                     :key="item.id"
                     :label="item.bloomberg"
                     :value="item.id">
            <span style="float: left">{{ item.bloomberg }}</span>
            <span class="select__text" style="padding-right: 20px">{{ item.name }}</span>
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item v-bind="fieldProps" class="mt--4 ml-2">
        <el-checkbox v-model="form.specialSecurities" label="1">Specials only</el-checkbox>
      </el-form-item>

      <el-form-item v-bind="fieldProps" label="Value">
        <el-input v-model="form.loanValue"
                  placeholder="Enter a value"
                  type="number"
                  :min="0">
        </el-input>
      </el-form-item>

      <el-form-item v-bind="fieldProps" class="mt--4 ml-2">
        <el-radio v-model="form.loanValueComparison" label="1">Greater than or</el-radio>
        <el-radio v-model="form.loanValueComparison" label="2">less than</el-radio>
      </el-form-item>

      <el-form-item v-bind="fieldProps" label="Rate">
        <el-input v-model="form.loanRate"
                  placeholder="Enter rate">
          <template slot="append">%</template>
        </el-input>
      </el-form-item>

      <el-form-item v-bind="fieldProps" class="mt--4 ml-2">
        <el-radio v-model="form.loanRateComparison" label="1">Greater than or</el-radio>
        <el-radio v-model="form.loanRateComparison" label="2">less than</el-radio>
      </el-form-item>

      <hr>

      <div class="align-right pt-2">
        <el-button @click="close">Cancel</el-button>
        <el-button @click="update" type="primary">Apply</el-button>
      </div>

    </el-form>
  </el-dialog>

</template>

<script>
import TickerFilters from '../models/TickerFilters'

export default {

  components: {
    TickerFilters
  },

  data () {
    return {
      visible: false,
      form: new TickerFilters(),
      options: [],
      loading: false,
      fieldProps: {
        labelPosition: 'left',
        labelWidth: '150px'
      },
    }
  },

  methods: {
    update () {
      this.$emit('update', this.form)
      this.close()
    },

    open () {
      this.visible = true
    },

    close () {
      this.visible = false
    },

    search (term) {
      if (term !== '') {
        this.loading = true
        this.$store.dispatch('securities/search', term).then((response) => {
          this.loading = false
          this.options = response
        })
      }
      else {
        this.options = []
      }
    },
  }
}

</script>

<style scoped>

.select__text {
  float: right;
  color: #8492a6;
  font-size: 13px
}
</style>
