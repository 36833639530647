<template>
  <div class="uiErrors" v-if="hasItems">
    <el-alert
      :title="title"
      :closable="false"
      type="error"
      show-icon>
      <div class="uiErrors__fields">
        <div v-for="item in items">
          <label class="uiErrors__field">{{ item.field }}</label>
          <ul class="uiErrors__messages">
            <li class="uiErrors__message" v-for="message in item.messages">{{ message }}</li>
          </ul>
        </div>
      </div>
    </el-alert>
  </div>
</template>

<script>
import { plural } from 'utils/string'
import ErrorsModel from '../models/ErrorsModel'

export default {
  props: {
    errors: ErrorsModel,

    showClose: Boolean
  },

  computed: {
    title () {
      const title = this.model.title
      if (title) {
        return title
      }
      if (this.hasItems) {
        return `Fix the following validation ${plural(this.model.messageCount, 'error', 'errors', false)} to continue:`
      }
    },

    model () {
      return this.$options.propsData.hasOwnProperty('errors')
        ? this.errors
        : this.$store.state.errors
    },

    items () {
      return this.model && this.model.items
    },

    hasItems () {
      return this.items && this.items.length
    },
  },

  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.uiErrors {
  margin-bottom: 1rem;

  .el-alert {
    align-items: flex-start;
  }

  &__fields {
    margin-left: 0;
    padding-left: 0;
    max-height: 200px;
    list-style-position: inside;
  }

  &__messages {
    padding-left: 1.5rem;
    margin: 0 0 1em 0;
    font-weight: bold;
    color: $grey-darkest;
    font-size: 0.8rem;
  }
}
</style>
