import { get } from 'vuex-pathify'

export default {
  computed: {
    organization: get('organizations/current'),

    canBorrow () {
      return this.organization.canBorrow()
    },

    canLend () {
      return this.organization.canLend()
    },

    isSystem: get('organizations/isSystem'),
  }
}
