import Vue from 'vue'
import Component from './SelectView.vue'

class SelectMediator {
  // the global mount point
  public wrapper: HTMLElement

  // Vue component
  public editor: any

  init () {
    if (!this.editor) {
      this.wrapper = this.addWrapper()
      this.editor = this.addEditor()
      this.wrapper.style.display = 'none'
    }
    return this.editor
  }

  show (left, top) {
    this.wrapper.style.top = top + 'px'
    this.wrapper.style.left = left + 'px'
    this.wrapper.style.display = ''
  }

  hide () {
    this.wrapper.style.display = 'none'
  }

  addWrapper () {
    // reuse existing
    if (this.wrapper) {
      return this.wrapper
    }

    // element
    const wrapper = document.createElement('div')
    wrapper.className = 'anSelect__container'
    Object.assign(wrapper.style, {
      top: 0,
      left: 0
    })
    document.body.append(wrapper)

    // store and return
    this.wrapper = wrapper
    return wrapper
  }

  addEditor () {
    // reuse existing
    if (this.editor) {
      return this.editor
    }

    // mount
    const mounter = document.createElement('div')
    this.wrapper.append(mounter)

    // components
    const ComponentCtor = Vue.extend(Component)
    const editor = new ComponentCtor({}).$mount(mounter)

    // store and return
    this.editor = editor
    return editor
  }
}

// This seems to be working well as a singleton, but may need revisiting!
export default new SelectMediator()

