import { get } from 'vuex-pathify'
import { Auth } from 'vendor/msal'

export default {
  computed: {
    isLoggedIn () {
      return Auth.isAuthenticated
    },

    userName () {
      return this.$store.state.user.name
    },

    isAdmin () {
      return this.$store.state.user.isAdmin
    },

    ...get('organizations', [
      'isSystem',
      'isUser',
      'canBorrow',
      'canLend',
    ]),
  },
}
