import { Hash } from 'core/types'
import { ResourceValidator, RulesProvider, ValidationOptions } from 'vendor/vee-validate'
import { setRequired } from './utils'

export default class ReturnNegotiationValidator extends ResourceValidator {
  constructor (options: ValidationOptions = {}, local?: boolean) {
    super(new ReturnRulesProvider(options), local)
  }
}

export const names: Hash = {
  quantity: 'Quantity',
  fee: 'Fee',
  feeCurrencyCode: 'Fee Currency',
  settlementDate: 'Settlement Date'
}

const baseRules: Hash = {
  quantity: 'integer|min_value:0|equal_or_less_than:loanQuantity',
  fee: 'number|min_value:0',
  feeCurrencyCode: 'alpha',
  settlementDate: 'required|from_today'
}

const requiredToAccept = {
  quantity: true,
  fee: true,
  feeCurrencyCode: true,
  settlementDate: true,
}

const dependents: Hash = {
  quantity: 'loanQuantity',
}

export class ReturnRulesProvider extends RulesProvider {

  public options: ValidationOptions

  constructor (options: ValidationOptions) {
    super(names, dependents, options)
  }

  update () {
    const { action, response } = this.options
    const rules: any = Object.assign({}, baseRules)

    if (action === 'edit') {
      rules.quantity = 'required|' + rules.quantity

      if (response === 'accept') {
        setRequired(rules, requiredToAccept)
      }
    }

    this.rules = rules
  }

}
