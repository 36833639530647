// https://stackoverflow.com/questions/8495687/split-array-into-chunks
export function chunk (input: Array<any>, length: number = 100) {
  const output: Array<any> = []
  const n: number = input.length
  let i: number = 0
  while (i < n) {
    output.push(input.slice(i, i += length))
  }

  return output
}
