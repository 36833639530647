<template>
  <section class="layout__index profile">
    <h1>Profile</h1>
    <ui-breadcrumbs/>
    <div class="layout__main" id="trading-profile">

      <div class="layout__content">

        <el-row :gutter="20">

          <el-col :span="12">
            <NegotiationDefaults/>
            <MarginRequirements v-if="canLend" class="mt-4"/>
            <GcRates v-if="canLend" class="mt-4"/>
          </el-col>

          <el-col :span="12">
            <SpecialSecurities/>
            <NetDivRequirements v-if="canLend" class="mt-4"/>
          </el-col>

        </el-row>

      </div>
    </div>
  </section>
</template>

<script>
import { get } from 'vuex-pathify'
import store from 'state'
import NegotiationDefaults from '../components/NegotiationDefaults'
import SpecialSecurities from '../components/SpecialSecurities'
import GcRates from '../components/GcRates'
import NetDivRequirements from '../components/NetDivRequirements'
import MarginRequirements from '../components/MarginRequirements'
import { OrganizationMixin } from 'modules/organizations'

export default {
  mixins: [
    OrganizationMixin,
  ],

  components: {
    NegotiationDefaults,
    SpecialSecurities,
    GcRates,
    NetDivRequirements,
    MarginRequirements,
  },

  metaInfo () {
    return {
      title: 'Profile'
    }
  },

  beforeRouteEnter (to, from, next) {
    return store.state.user.isAdmin
      ? store.dispatch('tradingProfile/load', store.state.organizations.current.canLend()).then(next)
      : next(false)
  },

  computed: {
    ...get('organizations', [
      'canLend',
    ]),
  },
}
</script>

<style lang="scss">
.profile__marginRequirements .el-input,
.profile__gcRates .el-input,
.profile__netDivs .el-input {
  max-width: 80px;
}

.profile__upload {
  font-size: .8em;
  margin-top: 0;
  padding-top: 0;
}

.profile__securities {
  ul {
    font-size: 14px;
    padding: .5rem 2rem;
  }

  li {
    line-height: 1em;
    margin-bottom: .5em;
  }
}

.profile__securitiesList {
  max-height: 500px;
  overflow-y: auto;
}
.profile__listContainer {

  h3 {
    margin-top: .5rem;
    border-bottom: 1px solid $grey-lightest;
    padding-left: .7rem;
    padding-bottom: .5rem;
  }
}

.profile__list {

  list-style: none;
  margin: 0;
  padding: 0 1rem .5rem;
  font-size: 14px;

  .header .text {
    font-weight: 700;
    font-size: 0.75em;
  }

  output,
  label {
    display: inline-block;
    width: 50%;
  }

  span {
    padding: 0 3px;
  }

  span.symbol {
    font-size: 0.75em;
    color: $grey;
  }
}
</style>
