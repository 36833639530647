var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "layout__main", attrs: { id: "loans" } }, [
    _c(
      "div",
      { staticClass: "layout__content" },
      [
        _c("UiErrors", { staticClass: "mt-2" }),
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _c("el-col", { attrs: { span: 7 } }, [
              _c(
                "div",
                { staticClass: "panel" },
                [
                  _c("LoanStateTag", {
                    staticClass: "loan__status",
                    attrs: { state: _vm.loan.state }
                  }),
                  _c("h2", [_vm._v("Information")]),
                  _c("UiOutput", { attrs: { label: "Reference" } }, [
                    _vm._v(_vm._s(_vm.loan.reference))
                  ]),
                  _c("UiOutput", { attrs: { label: "Counterparty" } }, [
                    _vm.isLender
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.loan.borrowerOrganization.name))
                        ])
                      : _c("span", [
                          _vm._v(_vm._s(_vm.loan.lenderOrganization.name))
                        ])
                  ]),
                  _c("UiOutput", { attrs: { label: "Role" } }, [
                    _vm._v(_vm._s(_vm.isLender ? "Lender" : "Borrower"))
                  ])
                ],
                1
              )
            ]),
            _c("el-col", { attrs: { span: 7 } }, [
              _c(
                "div",
                { staticClass: "panel" },
                [
                  _c("h2", [_vm._v("Asset")]),
                  _c("UiOutput", { attrs: { label: "Name" } }, [
                    _vm._v(_vm._s(_vm.loan.security.name))
                  ]),
                  _c("UiOutput", { attrs: { label: "Bloomberg" } }, [
                    _vm._v(_vm._s(_vm.loan.security.bloomberg))
                  ]),
                  _c("UiOutput", { attrs: { label: "Sedol" } }, [
                    _vm._v(_vm._s(_vm.loan.security.sedol))
                  ])
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "panel" },
                [
                  _c("h2", [_vm._v("Trade")]),
                  _c("UiOutput", { attrs: { label: "Quantity" } }, [
                    _vm._v(_vm._s(_vm.loan.quantity))
                  ]),
                  _c("UiOutput", { attrs: { label: "Net Div / Margin" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.$format("rate", _vm.loan.netDividendRequirement, 3)
                      )
                    )
                  ]),
                  _c("UiOutput", { attrs: { label: "Rate" } }, [
                    _vm._v(_vm._s(_vm.$format("rate", _vm.loan.rate)))
                  ]),
                  _c("UiOutput", { attrs: { label: "Fund" } }, [
                    _vm._v(_vm._s(_vm.loan.fund.name))
                  ]),
                  _c("UiOutput", { attrs: { label: "Currency" } }, [
                    _vm._v(_vm._s(_vm.loan.currency.name))
                  ]),
                  _c("UiOutput", { attrs: { label: "Collateral" } }, [
                    _vm._v(
                      _vm._s(_vm.loan.collateralBasket.name) +
                        " ( " +
                        _vm._s(_vm.loan.collateralBasket.code) +
                        " )"
                    )
                  ])
                ],
                1
              )
            ]),
            _c("el-col", { attrs: { span: 10 } }, [
              _c(
                "div",
                { staticClass: "panel" },
                [
                  _c("h2", [_vm._v("Dates")]),
                  _c("UiOutput", { attrs: { label: "Trade Agreed" } }, [
                    _vm._v(_vm._s(_vm.$format("dateTime", _vm.loan.createdAt)))
                  ]),
                  _c("UiOutput", { attrs: { label: "Settlement Date" } }, [
                    _vm._v(_vm._s(_vm.loan.settlementDate || "-"))
                  ]),
                  _c("UiOutput", { attrs: { label: "Settled Date" } }, [
                    _vm._v(_vm._s(_vm.loan.settledDate || "-"))
                  ]),
                  _c("UiOutput", { attrs: { label: "Term end date" } }, [
                    _vm._v(_vm._s(_vm.loan.termEndDate || "-"))
                  ]),
                  _c("UiOutput", { attrs: { label: "Closed Date" } }, [
                    _vm._v(_vm._s(_vm.loan.closedDate || "-"))
                  ])
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "panel" },
                [
                  _c("h2", [_vm._v("History")]),
                  _c("LoanEventsList", {
                    attrs: { loan: _vm.loan, events: _vm.events }
                  })
                ],
                1
              )
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }