import { Model } from 'core/models'
import OrganizationType, { canBorrow, canLend } from '../enums/OrganizationType'

export default class Organization extends Model {
  public hash: string

  public name: string

  public code: string

  public organizationType: OrganizationType

  public tradingAllowed: boolean

  public constructor (data: any = {}) {
    super(data)
    this.hash = data.hash
    this.name = data.name || 'Guest'
    this.code = data.code
    this.organizationType = data.organizationType
    this.tradingAllowed = data.tradingAllowed
  }

  static fromServer (data) {
    return new Organization(data)
  }

  canBorrow () {
    return canBorrow(this.organizationType)
  }

  canLend () {
    return canLend(this.organizationType)
  }

  isUser () {
    return this.canBorrow() || this.canLend()
  }

  isSystem () {
    return this.organizationType === OrganizationType.system
  }
}
