var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    {
      staticClass: "dashboard__card",
      attrs: {
        tabindex: "0",
        "data-type": _vm.item.type,
        "data-state": _vm.state,
        "data-updated": _vm.isUpdated,
        "data-latest": _vm.isLatest,
        "data-flagged": _vm.isFlagged,
        "data-hidden": _vm.isHidden
      },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.navigate($event)
        },
        focus: _vm.onFocus
      }
    },
    [
      _c(
        "div",
        { staticClass: "dashboard__cardMain" },
        [
          _vm.numUnseen
            ? _c("UiBadge", { attrs: { value: _vm.numUnseen } })
            : _vm._e(),
          _c("div", { staticClass: "dashboard__cardType" }, [
            _c("span", [_vm._v(_vm._s(_vm.type))])
          ]),
          _c("div", { staticClass: "dashboard__cardTitle" }, [
            _c(
              "label",
              {
                staticClass: "link",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.toggleFilter(_vm.counterpartyName)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.counterpartyName))]
            )
          ]),
          _c("div", { staticClass: "dashboard__cardInfo" }, [
            _c("p", [
              _c("label", [_vm._v("Reference: ")]),
              _c(
                "strong",
                {
                  staticClass: "link",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.toggleFilter(_vm.item.name.match(/\w+/))
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.item.name))]
              )
            ]),
            !_vm.isOpen
              ? _c("p", [
                  _c("label", [_vm._v("State: ")]),
                  _c("output", { staticClass: "tag", class: _vm.state }, [
                    _vm._v(_vm._s(_vm.state))
                  ])
                ])
              : _vm._e(),
            _vm.item.extendedSettlementDate
              ? _c("p", [
                  _c("label", [_vm._v("Warning:")]),
                  _c("output", { staticClass: "tag warning" }, [
                    _vm._v("Extended Settlement Date")
                  ])
                ])
              : _vm._e(),
            _vm.item.type === "loan"
              ? _c("p", [
                  _c("label", [_vm._v("Value: ")]),
                  _c("output", [
                    _c("span", { staticClass: "symbol" }, [
                      _vm._v(_vm._s(_vm.currencyCode))
                    ]),
                    _vm._v(" " + _vm._s(_vm._f("integer")(_vm.item.value)))
                  ])
                ])
              : _vm._e(),
            _c("p", [
              _c("label", [_vm._v("Updated: ")]),
              _c("output", { attrs: { title: _vm.updatedAt } }, [
                _vm._v(_vm._s(_vm._f("dateRelative")(_vm.updatedAt)))
              ])
            ]),
            _vm.item.type === "rerate"
              ? _c("p", [
                  _c("label", [_vm._v("Originator: ")]),
                  _c("output", [_vm._v(_vm._s(_vm.loanRole))])
                ])
              : _vm._e(),
            _vm.item.sourceList
              ? _c("p", [
                  _c("label", [_vm._v("Locate List: ")]),
                  _c(
                    "strong",
                    {
                      staticClass: "link",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.toggleFilter(_vm.item.sourceList.name)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.item.sourceList.name))]
                  )
                ])
              : _vm._e(),
            _c("p", [
              _c("label", [_vm._v("Messages: ")]),
              _c("output", [_vm._v(_vm._s(_vm.item.messageCount))])
            ])
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "dashboard__cardActions" },
        [
          _c(
            "CardButton",
            {
              attrs: { to: _vm.route, type: _vm.isOpen ? _vm.state : undefined }
            },
            [_vm._v(_vm._s(_vm.actionText))]
          ),
          _vm.numUnseen
            ? _c(
                "a",
                {
                  staticClass: "el-button--text el-button--mini ml-1",
                  attrs: { href: "#clear", tabindex: "-1" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.clearNotifications($event)
                    }
                  }
                },
                [_vm._v("Mark as read")]
              )
            : _vm._e(),
          !_vm.isOpen
            ? _c(
                "a",
                {
                  staticClass: "el-button--text el-button--mini ml-1",
                  attrs: { href: "#show/hide", tabindex: "-1" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.toggleHidden($event)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.isHidden ? "Unhide" : "Hide"))]
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }