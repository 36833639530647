import { extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import en from './locales/en'
import { isDaysFrom, isEmpty, isFromToday, isFromTomorrow } from 'utils/validators'
import { parseArray } from 'utils/string'

// ---------------------------------------------------------------------------------------------------------------------
// standard rules
// ---------------------------------------------------------------------------------------------------------------------

for (let rule in rules) {
  extend(rule, {
    ...rules[rule],
    message: en.messages[rule]
  });
}

// ---------------------------------------------------------------------------------------------------------------------
// dates
// ---------------------------------------------------------------------------------------------------------------------

extend('from_today', {
  message: en.messages.from_today,
  validate: isFromToday,
})

extend('from_tomorrow', {
  message: en.messages.from_tomorrow,
  validate: isFromTomorrow,
})

extend('later_than', {
  params: [{ name: 'target', isTarget: true}],
  message: en.messages.later_than,
  validate (value, { target }) {
    return target
      ? isDaysFrom(value, target, 1, 'after')
      : true
  },
})

extend('earlier_than', {
  params: [{ name: 'target', isTarget: true}],
  message: en.messages.earlier_than,
  validate (value, { target }) {
    return target
      ? isDaysFrom(value, target, 1, 'before')
      : true
  },
})

// ---------------------------------------------------------------------------------------------------------------------
// numbers
// ---------------------------------------------------------------------------------------------------------------------

extend('non_zero', {
  message: en.messages.non_zero,
  validate (value) {
    return value !== 0
  },
})

const rxNumber = /^-?[.0-9]+$/
extend('number', {
  message: en.messages.number,
  validate (value) {
    return rxNumber.test(value) && !isNaN(parseInt(value))
  },
})

extend('less_than', {
  params: [{ name: 'target', isTarget: true}],
  message: en.messages.less_than,
  validate (value, { target }) {
    return value < target
  },
})

extend('more_than', {
  params: [{ name: 'target', isTarget: true}],
  message: en.messages.more_than,
  validate (value, { target }) {
    return value > target
  },
})

extend('equal_or_less_than', {
  params: [{ name: 'target', isTarget: true}],
  message: en.messages.equal_or_less_than,
  validate (value, { target }) {
    return value <= target
  },
})

extend('equal_or_more_than', {
  params: [{ name: 'target', isTarget: true}],
  message: en.messages.equal_or_more_than,
  validate (value, { target }) {
    return value >= target
  },
})

extend('max_precision', {
  params: [{ name: 'length'}],
  message: en.messages.max_precision,
  validate (value, { length }) {
    value = String(value)
    if (value.indexOf('.') > -1) {
      return value.split('.').pop().length <= length
    }
    return true
  },
})

// ---------------------------------------------------------------------------------------------------------------------
// change
// ---------------------------------------------------------------------------------------------------------------------

extend('different', {
  params: ['oldValue'],
  message: en.messages.different,
  validate (value, { oldValue }) {
    return value !== oldValue
  },
})

extend('different_to', {
  params: [{ name: 'target', isTarget: true}],
  message: en.messages.different_to,
  validate (value, { target }) {
    return value !== target
  },
})

// ---------------------------------------------------------------------------------------------------------------------
// choices

extend('picked', {
  params: ['count'],
  message: en.messages.picked,
  validate (items, { count }) {
    // count
    count = parseInt(count) || 0

    // empty items
    if (isEmpty(items)) {
      items = []
    }

    // parse string input into an array
    else if (typeof items === 'string') {
      items = parseArray(items)
    }

    // bail if we don't have an array
    if (!Array.isArray(items)) {
      throw new Error('Could not convert input into an array')
    }

    // check if we have items
    return items.filter(val => val).length >= count
  },
})
