<template>
  <li class="anSelect__item el-select-dropdown__item" @mouseenter="$parent.setFocus(value)">
    <span v-html="labelHtml"
          class="anSelect__item--label"></span>
    <span v-if="showValue"
          v-html="valueHtml"
          class="anSelect__item--value"></span>
  </li>
</template>

<script>
export default {
  props: {
    value: String,
    valueHtml: String,
    labelHtml: String,
    showValue: String,
  }
}
</script>

<style scoped>

</style>
