<template>

  <article class="dashboard__card dashboard__card--locate"
           tabindex="0"
           data-type="locate"
           :data-updated="isUpdated"
           @keydown.enter="navigate"
           @focus="onFocus"
  >
    <div class="dashboard__cardMain">
      <div class="dashboard__cardType">
        <span>locate</span>
      </div>

      <div class="dashboard__cardTitle">
        <label class="link" @click.prevent="toggleFilter(item.name)">{{ item.name }}</label>
      </div>

      <div class="dashboard__cardInfo">
        <p>
          <label>Value located: </label>
          <PercentTag :value="item.valuePercentageLocated"></PercentTag>
        </p>
        <p>
          <label>Value:</label>
          <output><span class="symbol">{{ currencyCode }}</span> {{ item.value | integer }}</output>
        </p>
        <p>
          <label>Created:</label>
          <output>{{ createdAt | dateRelative }}</output>
        </p>
        <p>
          <label>Securities:</label>
          <output>{{ item.itemCount }}</output>
        </p>
        <p v-if="numNegotiations">
          <label>Negotiations:</label>
          <output>{{ numNegotiations }}</output>
        </p>
      </div>

    </div>

    <div class="dashboard__cardActions">
      <CardButton :to="route">Edit</CardButton>
      <CardButton :to="`/negotiations/loans/create?source=locate&id=${item.id}`"
                  :disabled="isDisabled">Negotiate
      </CardButton>
    </div>
  </article>

</template>

<script>
import PercentTag from 'modules/global/components/PercentTag'
import Card from './Card'
import CardButton from './CardButton'

export default {
  extends: Card,

  components: {
    CardButton,
    PercentTag,
  },

  computed: {
    isUpdated () {
      return this.$store.get('notifications/getUpdatedForItem', this.item.id).length > 0
    },

    numNegotiations () {
      return this.$store.get('dashboard/getNegotiationsForLocateList', this.item.id).length
    },

    isDisabled () {
      return this.item.isLocated
    },

    route () {
      return `/negotiations/locates/edit/${this.item.id}`
    }
  },

  methods: {
    navigate () {
      this.$router.push(this.route)
    }
  }
}

</script>
