// core
import './modules/core'

// additional functionality
import './modules/dialogs'
import './modules/table'

// styles
import './theme/index.css'
import './styles/index.scss'

// export $alert and $prompt
export * from './modules/dialogs'
