import Handsontable from 'handsontable'
import numbro from 'numbro'
import { getDefaultCurrencyCode } from 'modules/trading-profile'
import { fx } from 'modules/resources'
import { getSecurity } from 'modules/securities'

/**
 * Renders the financial value of a row i.e. EUR 3,000 based on the security, quantity and price
 */
export default function (instance, td, row, col, prop, values, cellProperties) {
  Handsontable.renderers.TextRenderer.apply(this, arguments)

  // known data
  const datum = instance.getSourceDataAtRow(row)
  const bloomberg = datum && datum.bloomberg
  const quantity = datum && (datum.quantity || datum.quantityRequired) // guess quantity field name rather than passing in

  // exit early if no data
  if (typeof quantity === 'undefined' || !bloomberg) {
    td.innerText = ''
    return
  }

  // grab related security from store to get price data
  const security = getSecurity(bloomberg)
  const currencyCode = datum.loanCurrencyCode || getDefaultCurrencyCode()

  // exit if no security
  if (!security) {
    td.innerText = 'Unknown Asset'
    return
  }

  // variables
  let text

  // fix quantity
  if (quantity === '') {
    text = '?'
  }
  else if (isNaN(parseInt(quantity)) || quantity < 0) {
    text = 'N/A'
  }

  // if we get here, we should be able to calculate a value
  else {
    const price = fx(security.price, security.currencyCode, currencyCode)
    const value = quantity * price

    text = isNaN(value)
      ? '?'
      : numbro(value).format({
          thousandSeparated: true,
          mantissa: quantity === 1 || value < 100
            ? 2
            : 0
        })
  }

  // update cell
  td.className = cellProperties.className
  td.innerHTML = `<span class="symbol">${currencyCode}</span><span class="value">${text}</span>`
}

function convert () {

}
