import { getEnumAsLabel } from 'utils/enum'

enum LoanEventType
{
  LoanAgreed = 1,
  LoanOpenSettledPendingConfirmation = 2,
  LoanOpened = 10,
  LoanCancelled = 98,
  LoanClosed = 99,
  RecallAgreed = 101,
  RecallSettled = 110,
  RecallAborted = 130,
  ReturnAgreed = 201,
  ReturnSettledPendingConfirmation = 202,
  ReturnSettled = 210,
  ReturnAborted = 230,
  ReturnCancelled = 298,
  RerateAgreed = 301,
  RerateApplied = 310,
  RerateAborted = 330
}

export function getLoanEventTypeString (type: LoanEventType): string {
  const key: string | undefined = LoanEventType[type]
  if (key) {
    const matches: RegExpMatchArray | null = key.match(/[A-Z][a-z]+/)
    if (matches) {
      return matches[0].toLowerCase()
    }
  }
  throw new Error(`Unable to derive loan event type string from type: "${type}"`)
}

export function getLoanEventTypeLabel (type: LoanEventType): string {
  return getEnumAsLabel(LoanEventType, type)
}

export default LoanEventType
