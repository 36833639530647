<template>
  <i
    class="ui uiIcon"
    :class="className"
    @click="toggle"
  ></i>
</template>

<script>
/**
 * Utility icon class
 *
 * Supports
 *
 *  - FontAwesome icons only
 *  - toggle state (icon)
 *  - hover state (opacity + cursor)
 *
 * Simplified icon specification
 *
 *  - just pass the name, i.e. lock
 *  - for toggled icon, pass pipe-separated names, i.e. lock|lock-open
 *  - icons default to `fas fa-*`, pass full icon classname if custom icons required
 *
 * Useage
 *
 *  <UiIcon icon="lock|lock-open" :state="someValue" />
 *
 */
export default {
  props: {
    state: {
      required: false,
    },

    icon: {
      type: String,
      required: true,
    },

    disabled: {
      type: Boolean,
    },
  },

  computed: {
    iconOn () {
      return this.icon.split('|').shift()
    },

    iconOff () {
      return this.icon.split('|').pop()
    },

    className () {
      // toggleable
      const canToggle = this.$listeners.click || this.icon.includes('|')
        ? 'can-toggle'
        : ''

      // checked state
      const isChecked = canToggle
        ? this.state
          ? 'is-checked'
          : 'is-unchecked'
        : ''

      // disabled state
      const isDisabled = this.disabled
        ? 'is-disabled'
        : ''

      // icon name
      const icon = canToggle
        ? this.state
          ? this.iconOn
          : this.iconOff
        : this.iconOn

      // fa icon
      const faIcon = /^(far|fas) /.test(icon)
        ? icon
        : 'fas fa-' + icon

      // return
      return [
        canToggle,
        isChecked,
        isDisabled,
        faIcon,
      ]
    }
  },

  methods: {
    toggle () {
      if (!this.disabled) {
        this.$emit('click')
      }
    },
  }
}
</script>

<style lang="scss">

.uiIcon {
  color: black;

  &.can-toggle {
    color: $grey-lighter;
    cursor: pointer;

    &.is-checked {
      color: black;
    }

    &:not(.is-disabled):hover {
      color: $grey-darker;
    }
  }

  &.is-disabled {
    opacity: 0.4;
    cursor: default;
  }
}
</style>
