import {
  Columns,
  makeCurrencyPicker,
  makeQuantity,
  makeQuantityEditor,
  makeRate,
  makeTable, makeText,
  makeThousands,
  retarget
} from 'vendor/handsontable'
import { ReturnNegotiationItem } from '../../../negotiations'
import ReturnNegotiationValidator from 'modules/negotiations/validators/ReturnNegotiationValidator'

function settings (vm) {
  const { role, action, isTerm } = vm

  const validator = new ReturnNegotiationValidator()

  // NOTE an enum would be better, but string props are clearer in components
  const isBorrower = role === 'borrower'

  const columns = [
    makeText('Reference', 'loan.reference', 110),
    retarget(Columns.security, 'loan.bloomberg'),
    makeQuantity('Loan Quantity', 'loanQuantity'),
    isTerm || (!isTerm && isBorrower)
      ? makeQuantityEditor('Quantity', 'quantity')
      : makeQuantity('Quantity', 'quantity'),
    isBorrower
      ? Columns.settlementDateEditor
      : Columns.settlementDate,
    isTerm
      ? makeCurrencyPicker('Fee Currency', 'feeCurrencyCode')
      : null,
    isTerm
      ? makeThousands('Fee Amount', 'fee', 100)
      : null,
    isTerm
      ? retarget(Columns.termEndDate, 'loan.termEndDate')
      : null,
    makeRate('Current Rate', 'loan.rate', false),
  ]

  return {
    columns,
    validator,
    schemaClass: ReturnNegotiationItem,
    minSpareRows: 0,
  }
}

export default {
  extends: makeTable(settings),
  props: {
    isTerm: Boolean,
  },
}
