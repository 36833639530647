/**
 * Inactivity timeout durations
 *
 * Note that values are in MINUTES!
 */
export default {
  development: 60, // change this to something short when testing
  production: 30,
  staging: 30,
  test: 30,
}
