var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      staticClass: "no-bg no-hover no-border",
      attrs: { data: _vm.events, size: "large" }
    },
    [
      _c("el-table-column", {
        attrs: {
          label: "Date",
          prop: "eventDate",
          width: "150",
          formatter: _vm.$formatCol("date")
        }
      }),
      _c("el-table-column", {
        attrs: { label: "Event", width: "200" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var row = ref.row
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.getLoanEventTypeLabel(row.eventType)) +
                    "\n    "
                )
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: "Actions", width: "200" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var row = ref.row
              return [
                _vm.canView(row)
                  ? _c(
                      "router-link",
                      {
                        staticClass: "el-button el-button--mini",
                        attrs: { to: _vm.getViewLink(row) }
                      },
                      [_vm._v("View\n      ")]
                    )
                  : _vm._e(),
                _vm.canCancel(row)
                  ? _c(
                      "el-button",
                      {
                        attrs: { size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.cancel(row)
                          }
                        }
                      },
                      [_vm._v("Cancel\n      ")]
                    )
                  : _vm._e()
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }