var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        hasTools: _vm.hasTools,
        hasTicker: _vm.hasTicker,
        isUser: _vm.isUser
      },
      attrs: { id: "app" }
    },
    [
      _c(
        "header",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isLoading,
              expression: "!isLoading"
            }
          ],
          attrs: { id: "header" }
        },
        [_c("div", { staticClass: "layout__center" }, [_c("SiteNav")], 1)]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isLoading,
              expression: "!isLoading"
            }
          ],
          attrs: { id: "body" }
        },
        [
          _c(
            "div",
            { staticClass: "layout__center" },
            [
              _c("main", { attrs: { id: "main" } }, [_c("router-view")], 1),
              _vm.isUser
                ? _c(
                    "ToolsPanel",
                    { attrs: { id: "tools" } },
                    [_vm.hasTicker ? _c("Ticker") : _vm._e()],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _c("AuthModal"),
      _vm.isLoggedIn ? _c("AutoLogout") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }