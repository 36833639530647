import { ErrorsModel, ErrorResponse } from 'modules/errors'

const state = new ErrorsModel()

const actions = {
  clear ({ state, commit }) {
    if (state.items.length) {
      commit('clear')
    }
  }
}

const mutations = {
  data (state: ErrorsModel, data: ErrorsModel) {
    state.data = data
  },

  clear (state: ErrorsModel) {
    state.clear()
  }
}

const getters = {
  items (state: ErrorsModel) {
    return state.data
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
