var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout__index" },
    [
      _c("h1", [_vm._v("Organisation")]),
      _c("UiBreadcrumbs"),
      _c("UiErrors"),
      _c(
        "div",
        { staticClass: "layout__main", attrs: { id: "view-organization" } },
        [
          _c(
            "div",
            { staticClass: "layout__content" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      { staticClass: "panel" },
                      [
                        _c("h2", [_vm._v("Details")]),
                        _c("div", { staticClass: "organisation__detail" }, [
                          _c(
                            "span",
                            { staticClass: "organisation-detail-label" },
                            [_vm._v("Display Name:")]
                          ),
                          _c("span", [
                            _vm._v(_vm._s(_vm.organization.displayName))
                          ])
                        ]),
                        _c(
                          "div",
                          { staticClass: "organisation__detail mt-2" },
                          [
                            _c(
                              "span",
                              { staticClass: "organisation-detail-label" },
                              [_vm._v("Registered Address:")]
                            ),
                            _c("span", [
                              _vm._v(_vm._s(_vm.organization.registeredAddress))
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "organisation__detail mt-2" },
                          [
                            _c(
                              "span",
                              { staticClass: "organisation-detail-label" },
                              [_vm._v("Phone Number:")]
                            ),
                            _c("span", [
                              _vm._v(_vm._s(_vm.organization.phoneNumber))
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "organisation__detail mt-2" },
                          [
                            _c(
                              "span",
                              { staticClass: "organisation-detail-label" },
                              [_vm._v("Billing Currency:")]
                            ),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.organization.billingCurrency &&
                                    _vm.organization.billingCurrency.name
                                )
                              )
                            ])
                          ]
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "mt-4",
                            on: {
                              click: function($event) {
                                return _vm.$router.push(
                                  "/organization/edit/" + _vm.organization.id
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n              Edit Organisation\n            "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c(
                      "div",
                      { staticClass: "panel" },
                      [
                        _c("h2", [_vm._v("Documents")]),
                        _c(
                          "el-table",
                          { attrs: { data: _vm.documents } },
                          [
                            _c("el-table-column", {
                              attrs: { prop: "name", label: "Name" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var row = ref.row
                                    return _c(
                                      "a",
                                      {
                                        attrs: {
                                          href:
                                            "/organizations/documents/" + row.id
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.downloadDocument(row)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(row.name) +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  }
                                }
                              ])
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "createdAt",
                                label: "Created Date",
                                sortable: "",
                                align: "right"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var row = ref.row
                                    return [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm._f("dateTime")(row.createdAt)
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  }
                                }
                              ])
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "Actions",
                                align: "right",
                                width: "100"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var row = ref.row
                                    var $index = ref.$index
                                    return _c(
                                      "el-button",
                                      {
                                        staticClass: "no-shadow",
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteDocument(
                                              row,
                                              $index
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("Delete\n                ")]
                                    )
                                  }
                                }
                              ])
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "panel mt-4",
                        staticStyle: { "margin-top": "20px" }
                      },
                      [
                        _vm.organization.id
                          ? _c("user-management-form", {
                              attrs: { "organization-id": _vm.organization.id }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      { staticClass: "panel" },
                      [
                        _c("h2", { staticClass: "mb-4" }, [
                          _vm._v("Upload Documents")
                        ]),
                        _c(
                          "ui-upload",
                          {
                            attrs: { accept: ".docx,.doc,.pdf" },
                            on: { submit: _vm.uploadDocument }
                          },
                          [
                            _vm._v("\n              Drop file here or "),
                            _c("em", [_vm._v("click to upload")])
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }