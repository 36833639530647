<template>

  <section class="panel">
    <h2>Negotiation Defaults</h2>
    <el-form label-position="left" label-width="50%" class="mr-4" v-if="model">

      <!-- base values -->
      <el-form-item label="Currency">
        <ui-select :options="options.currencies"
                   v-model="form.defaultCurrency"
                   mode="object"
        />
      </el-form-item>

      <template v-if="canLend">

        <el-form-item label="Collateral Basket">
          <ui-select :options="options.collateral"
                     v-model="form.defaultCollateralBasket"
                     mode="object"
          />
        </el-form-item>
      </template>

    </el-form>
    <SaveChanges @save="save"/>
  </section>

</template>

<script>
import { get } from 'vuex-pathify'
import { clone } from 'utils/object'
import { storeMixin } from '../state/mixin-factory'
import NegotiationDefaults from '../models/NegotiationDefaults'

export default {
  extends: storeMixin('tradingProfile/defaults', {
    model: ''
  }),

  data () {
    return {
      form: {},
      options: {
        currencies: this.$store.state.resources.currencies,
        collateral: this.$store.state.resources.collateral,
        securities: [],
      },
    }
  },

  created () {
    this.form = clone(this.model)
  },

  computed: {
    ...get('organizations', [
      'canLend',
    ]),

    currency () {
      return this.model.defaultCurrency
        ? this.model.defaultCurrency.code
        : 'EUR'
    },

    formData () {
      return new NegotiationDefaults(this.form).toServer()
    }
  },
}

</script>

<style lang="scss">

</style>
