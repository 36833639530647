<template>

  <div class="layout__main" id="create-negotiation">

    <SiteAside label="Options">

      <!-- form -->
      <el-form ref="form"
               :model="form"
               label-position="top"
               @submit.prevent.native=""
      >

        <NegotiationComment v-model="form.comment" />

        <UiSubmit
          @submit="submit"
          @cancel="cancel"
          :canSubmit="canSubmit"
        />

        <UiFieldset label="Private" divided>
          <NegotiationNotes v-model="form.privateNotes" />
        </UiFieldset>

      </el-form>

    </SiteAside>

    <!-- content -->
    <section class="layout__content panel">
      <h2>Rerate Details</h2>

      <UiErrors/>

      <RerateItemsTable ref="table"
                        :data="items.data"
                        role="borrower"
                        action="create"
      />

    </section>

  </div>

</template>

<script>
import RerateItemsTable from '../../components/tables/RerateItemsTable'
import RerateNegotiationItem from '../../models/RerateNegotiationItem'
import factory from './create-factory'

export default {

  extends: factory('rerate'),

  crumb: 'Rerates',

  components: {
    RerateItemsTable,
  },

  methods: {
    load () {
      const { source, id } = this.$route.query
      let items = []
      switch (source) {
        case 'loans':
          items = this.$store.get('loans/selected').map(RerateNegotiationItem.fromLoan)
          const bloombergs = items.map(item => item.loan.bloomberg).filter(bloomberg => bloomberg)
          if (bloombergs.length) {
            return this.$store.dispatch('securities/fetchSecurities', bloombergs)
              .then(() => this.setItems(items))
          }
          return this.setItems(items)

        default:
          console.error('Unsupported conversion:', source)
      }
    },
  }
}
</script>

<style lang="scss">
</style>
