import { route } from '@/app/router/helpers'
import loan from './state/LoanStore'
import loans from './state/LoansStore'

export default {
  stores: {
    loan,
    loans,
  },

  routes: [
    route('/loans', 'loans@Index.vue', {
      children: [
        route('', 'loans@SearchLoans.vue'),
        route('view/:id', 'loans@ViewLoan.vue'),
      ],
    }),
  ],
}

