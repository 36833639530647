import { addClass, } from 'handsontable/es/helpers/dom/element'
import { stopImmediatePropagation } from 'handsontable/es/helpers/dom/event'
import { KEY_CODES } from 'handsontable/es/helpers/unicode'
import BaseEditor, { EditorState } from 'handsontable/es/editors/_baseEditor'

import Handsontable from 'handsontable'

import Vue from 'vue'
import Component from './CheckboxesView'
import { parseArray } from 'utils/string'

/**
 * @private
 * @editor CheckboxesEditor
 * @class CheckboxesEditor
 */
class CheckboxesEditor extends Handsontable.editors.TextEditor {
  // wrapper
  // vm

  /**
   * Returns select's value.
   *
   * @returns {*}
   */
  getValue () {
    return this.vm.output
  }

  /**
   * Sets value in the select element.
   *
   * @param {*} value A new select's value.
   */
  setValue (value) {
    this.vm.value = parseArray(value)
  }

  /**
   * Opens the editor and adjust its size.
   */
  open () {
    this._opened = true
    this.refreshDimensions()
    this.wrapper.style.display = ''
    this.vm.focus()
    // this.addHook('beforeKeyDown', () => this.onBeforeKeyDown())
  }

  /**
   * Closes the editor.
   */
  close () {
    this._opened = false
    this.vm.value = []
    this.wrapper.style.display = 'none'
    this.clearHooks()
  }

  /**
   * Sets focus state on the select element.
   */
  focus () {
    // not needed
    // this.select.focus()
  }

  /**
   * Initializes editor instance, DOM Element and mount hooks.
   */
  init () {
    console.log('init')
    // dom elements
    console.log(this.hot.rootDocument)

    super.init.apply(this)
    Handsontable.editors.TextEditor.prototype.createElements.apply(this, arguments);

    // wrapper
    const wrapper = document.createElement('DIV')
    wrapper.addEventListener('mousedown', function (event) {
      event.preventDefault()
      event.stopImmediatePropagation()
      event.stopPropagation()
    })
    wrapper.className = 'anCheckboxesEditor'
    wrapper.style = {
      position: 'absolute',
      display: 'none',
      left: 0,
      top: 0
    }
    document.body.appendChild(wrapper)

    // mounter
    const mounter = document.createElement('DIV')
    wrapper.appendChild(mounter)

    // this.hot.view.TBODY.addEventListener('focus', console.log, true)
    // console.log(this.TD)

    // vm
    const ComponentCtor = Vue.extend(Component)
    const vm = new ComponentCtor({})
      .$mount(mounter)
      .$on('input', this.onInput.bind(this))
      .$on('enter', this.onEnter.bind(this))
      .$on('cancel', this.onCancel.bind(this))

    // class properties
    this.wrapper = wrapper
    this.vm = vm

    // debug
    window.vm = vm

    // mount etc
    // this.hot.rootElement.appendChild(wrapper)
    this.registerHooks()
  }

  onInput (value) {
    this.getEditedCell().focus()
  }

  onEnter () {
    this.finishEditing(false, true)
  }

  onCancel () {
    this.finishEditing(true)
  }

  /**
   * Binds hooks to refresh editor's size after scrolling of the viewport or resizing of columns/rows.
   *
   * @private
   */
  registerHooks () {
    this.addHook('afterScrollHorizontally', () => this.refreshDimensions())
    this.addHook('afterScrollVertically', () => this.refreshDimensions())
    this.addHook('afterColumnResize', () => this.refreshDimensions())
    this.addHook('afterRowResize', () => this.refreshDimensions())
  }

  /**
   * Prepares editor's meta data and a list of available options.
   *
   * @param {Number} row
   * @param {Number} col
   * @param {Number|String} prop
   * @param {HTMLTableCellElement} td
   * @param {*} originalValue
   * @param {Object} cellProperties
   */
  prepare (row, col, prop, td, originalValue, cellProperties) {
    super.prepare(row, col, prop, td, originalValue, cellProperties)

    // options
    const editorOptions = this.cellProperties.editorOptions
    this.vm.options = typeof editorOptions === 'function'
      ? editorOptions(this.row, this.col, this.prop)
      : editorOptions
  }

  /**
   * Refreshes editor's value using source data.
   *
   * @private
   */
  refreshValue () {
    const sourceData = this.hot.getSourceDataAtCell(this.row, this.prop)
    this.originalValue = sourceData
    this.setValue(sourceData)
    this.refreshDimensions()
  }

  /**
   * Refreshes editor's size and position.
   *
   * @private
   */
  refreshDimensions () {
    if (this.state !== EditorState.EDITING) {
      return
    }

    this.TD = this.getEditedCell()

    // TD is outside of the viewport.
    if (!this.TD) {
      this.close()
      return
    }

    // position editor
    const rect = this.TD.getBoundingClientRect();
    const style = this.wrapper.style
    style.top = rect.bottom + window.scrollY - 1 + 'px'
    style.left = rect.left + window.scrollX - 1 + 'px'
  }

  /**
   * Gets HTMLTableCellElement of the edited cell if exist.
   *
   * @private
   * @returns {HTMLTableCellElement|undefined}
   */
  getEditedCell () {
    const { wtOverlays } = this.hot.view.wt
    const editorSection = this.checkEditorSection()
    let editedCell

    switch (editorSection) {
      case 'top':
        editedCell = wtOverlays.topOverlay.clone.wtTable.getCell({
          row: this.row,
          col: this.col
        })
        this.wrapper.style.zIndex = 101
        break
      case 'corner':
        editedCell = wtOverlays.topLeftCornerOverlay.clone.wtTable.getCell({
          row: this.row,
          col: this.col
        })
        this.wrapper.style.zIndex = 103
        break
      case 'left':
        editedCell = wtOverlays.leftOverlay.clone.wtTable.getCell({
          row: this.row,
          col: this.col
        })
        this.wrapper.style.zIndex = 102
        break
      default:
        editedCell = this.hot.getCell(this.row, this.col)
        this.wrapper.style.zIndex = ''
        break
    }

    return editedCell < 0 ? void 0 : editedCell
  }

  /**
   * onBeforeKeyDown callback.
   *
   * @private
   */
  onBeforeKeyDown (event) {
    const previousOptionIndex = this.vm.selectedIndex - 1
    const nextOptionIndex = this.vm.selectedIndex + 1

    switch (event.keyCode) {
      case KEY_CODES.ARROW_UP:
        if (previousOptionIndex >= 0) {
          this.vm[previousOptionIndex].selected = true
        }

        stopImmediatePropagation(event)
        event.preventDefault()
        break

      case KEY_CODES.ARROW_DOWN:
        if (nextOptionIndex <= this.vm.length - 1) {
          this.vm[nextOptionIndex].selected = true
        }

        stopImmediatePropagation(event)
        event.preventDefault()
        break

      default:
        break
    }
  }
}

export default CheckboxesEditor
