<template>

  <el-upload
    :class="classes"
    action="//localhost"
    :http-request="onRequest"
    :multiple="false"
    :show-file-list="false"
    :accept="accept"
    :drag="isDropzone"
  >
    <template v-if="isDropzone">
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">
        <slot>
          Drop file here or <em>click to upload</em><br>({{ accept.replace(/\./g, '').replace(/,/g, ', ') }})
        </slot>
      </div>
    </template>

    <el-button v-else-if="isButton" slot="trigger" size="mini" type="secondary">{{ text }}</el-button>

    <a :href="href" @click.prevent="" v-else slot="trigger">{{ text }}</a>

  </el-upload>

</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'dropzone',
      validator (value) {
        return ['button', 'dropzone', 'text'].includes(value)
      }
    },

    text: {
      type: String,
      default: 'Select File'
    },

    href: {
      type: String,
      default: '#'
    },

    accept: {
      type: String,
      default: '.xls,.xlsx,.csv,.tsv,.txt'
    }
  },

  computed: {
    classes () {
      return [
        'ui-upload',
        'ui-upload--' + this.type,
      ]
    },

    isDropzone () {
      return this.type === 'dropzone'
    },

    isButton () {
      return this.type === 'button'
    }
  },

  methods: {
    onRequest (request) {
      const data = new FormData()
      data.append('file', request.file)
      this.$emit('submit', data)
    },
  },
}

</script>

<style lang="scss">
.ui-upload {
  &--text {
    display: inline;
  }

  &--dropzone {

    display: block;
    margin-top: 1rem;

    .el-upload {
      display: block;
    }

    .el-upload-dragger {
      width: 100% !important;

      .is-dragover {
        border: 1px solid $color-primary;
      }
    }

    .el-upload-list {
      display: none;
    }
  }
}
</style>
