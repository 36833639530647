export function plural (quantity, single, plural = '', includeQuantity = true) {
  if (Array.isArray(quantity)) {
    quantity = quantity.length
  }
  const word = plural
    ? quantity === 1
      ? single
      : plural
    : single + (quantity === 1 ? '' : 's')
  return includeQuantity
    ? quantity + ' ' + word
    : word
}

export function slugify (value) {
  return String(value || '')
    .toLowerCase()
    .replace(/\W+/g, '-')
    .replace(/^-|-$/g, '')
}

/**
 * Convert a camel case variable to Title Case
 *
 * @param value
 * @return {string}
 */
export function labelify (value) {
  const replace = (match, a, b) => a + ' ' + b.toUpperCase()
  return String(value || '')
    .replace(/^\w/, a => a.toUpperCase())
    .replace(/([a-z])-([a-z])/g, replace)
    .replace(/([a-z])([A-Z])/g, replace)
}

export function capitalize (value, all = false) {
  let rx = all
    ? /\b\w/g
    : /\b\w/
  return String(value || '')
    .replace(rx, c => c.toUpperCase())
}

export function isJsonArray (input) {
  return typeof input === 'string'
    ? input.substr(0, 1) === '[' && input.substr(-1, 1) === ']'
    : false
}

export function parseArray (input, delimiter = '|') {
  if (Array.isArray(input)) {
    return input
  }
  return isJsonArray(input)
    ? JSON.parse(input)
    : String(input || '').split(delimiter)
}
