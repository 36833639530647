<template>

  <article class="dashboard__card"
           tabindex="0"
           :data-type="item.type"
           :data-state="state"
           :data-updated="isUpdated"
           :data-latest="isLatest"
           :data-flagged="isFlagged"
           :data-hidden="isHidden"
           @keydown.enter="navigate"
           @focus="onFocus"
  >
    <div class="dashboard__cardMain">
      <UiBadge v-if="numUnseen" :value="numUnseen"/>

      <div class="dashboard__cardType">
        <span>{{ type }}</span>
      </div>

      <div class="dashboard__cardTitle">
        <label class="link" @click.prevent="toggleFilter(counterpartyName)">{{ counterpartyName }}</label>
      </div>

      <div class="dashboard__cardInfo">
        <p>
          <label>Reference: </label>
          <strong class="link" @click.prevent="toggleFilter(item.name.match(/\w+/))">{{ item.name }}</strong>
        </p>
        <p v-if="!isOpen">
          <label>State: </label>
          <output class="tag" :class="state">{{ state }}</output>
        </p>
        <p v-if="item.extendedSettlementDate">
          <label>Warning:</label>
          <output class="tag warning">Extended Settlement Date</output>
        </p>
        <p v-if="item.type === 'loan'">
          <label>Value: </label>
          <output><span class="symbol">{{ currencyCode }}</span> {{ item.value | integer }}</output>
        </p>
        <p>
          <label>Updated: </label>
          <output :title="updatedAt">{{ updatedAt | dateRelative }}</output>
        </p>
        <p v-if="item.type === 'rerate'">
          <label>Originator: </label>
          <output>{{ loanRole }}</output>
        </p>
        <p v-if="item.sourceList">
          <label>Locate List: </label>
          <strong class="link" @click.prevent="toggleFilter(item.sourceList.name)">{{ item.sourceList.name }}</strong>
        </p>
        <p>
          <label>Messages: </label>
          <output>{{ item.messageCount }}</output>
        </p>
      </div>

    </div>
    <div class="dashboard__cardActions">
      <CardButton :to="route" :type="isOpen ? state : undefined">{{ actionText }}</CardButton>
      <a v-if="numUnseen"
         href="#clear"
         class="el-button--text el-button--mini ml-1"
         @click.prevent="clearNotifications"
         tabindex="-1"
      >Mark as read</a>
      <a v-if="!isOpen"
         href="#show/hide"
         class="el-button--text el-button--mini ml-1"
         @click.prevent="toggleHidden"
         tabindex="-1"
      >{{ isHidden ? 'Unhide' : 'Hide' }}</a>
    </div>
  </article>
</template>

<script>
import Card from './Card'
import CardButton from './CardButton'
import { RecallNegotiation } from 'modules/negotiations'
import { LoanRole, getLoanRoleText, isBorrowerFor } from 'modules/organizations'

export default{
  extends: Card,

  components: {
    CardButton,
  },

  computed: {
    isOpen () {
      return this.item.isOpen
    },

    isResponder () {
      return this.item.isReceived
    },

    isUpdated () {
      return this.$store.get('notifications/getUpdatedForItem', this.item.id).length > 0
    },

    isLatest () {
      const unseen = this.$store.get('notifications/unseenForNegotiations')
      return unseen.length
        ? unseen[0].itemId === this.item.id
        : false
    },

    isFlagged () {
      return this.isResponder && this.item instanceof RecallNegotiation
    },

    isHidden () {
      return this.$store.state.dashboard.hidden.includes(this.item.id)
    },

    numUnseen () {
      return this.$store.get('notifications/getUnseenForItem', this.item.id).length
    },

    organization () {
      return this.$store.state.organizations.current
    },

    type () {
      const item = this.item
      const type = item.type
      return type === 'loan' && isBorrowerFor(item)
        ? 'borrow'
        : type
    },

    counterparty () {
      const item = this.item
      return item.borrowerOrganization.id === this.organization.id
        ? item.lenderOrganization
        : item.borrowerOrganization
    },

    counterpartyName () {
      return this.counterparty.name
    },

    loanRole () {
      return LoanRole[this.item.originatingLoanRole]
    },

    actionText () {
      return this.isOpen
        ? this.isResponder
          ? 'Respond'
          : 'Edit'
        : 'View'
    },

    state () {
      return this.item.isUpdated
        ? 'updated'
        : this.item.isAccepted
          ? 'accepted'
          : this.item.isAbandoned
            ? 'abandoned'
            : this.item.isRejected
              ? 'rejected'
              : 'open'
    },

    route () {
      const { id, type } = this.item
      const action = this.isOpen ? 'edit' : 'view'
      return `/negotiations/${type}s/${action}/${id}`
    }
  },

  methods: {
    clearNotifications () {
      this.$store.dispatch('notifications/clearForItem', this.item.id)
    },

    toggleHidden () {
      this.$store.commit('dashboard/toggleHidden', this.item.id)
    },
  }
}

</script>
