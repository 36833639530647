import store from '@/app/state'
import { ErrorsModel } from '..'

/**
 * Globally commit errors to the store
 */
export function commitErrors (state: ErrorsModel): void {
  store.commit('errors/data', state.data)
}

/**
 * Promise handler to globally clear errors from the store
 * @param response
 */
export function clearErrors (response: any = undefined) {
  if (store.state['errors'].errorCount) {
    store.commit('errors/clear')
  }
  return response
}
