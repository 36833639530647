export default class TickerItem {
  public bloomberg: String

  public quantity: Number

  public rate: String

  public createdAt: Date

  // client-only timestamp of when the item was added, used in visual updates
  public addedAt: number

  constructor (data) {
    this.bloomberg = data.bloomberg
    this.quantity = data.quantity
    this.rate = data.rate
    this.createdAt = new Date(data.createdAt)
    this.addedAt = data.addedAt
  }

  public static fromServer (data) {
    data = { ...data }
    data.bloomberg = data.security.bloomberg.replace(' Equity', '')
    data.rate = (data.rate || 0).toFixed(2) + ' %'
    return new TickerItem(data)
  }
}
