var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout__main", attrs: { id: "loans" } },
    [
      _c("SiteAside", { attrs: { label: "Filters" } }, [_c("LoansFilters")], 1),
      _c(
        "section",
        { staticClass: "layout__content panel" },
        [
          _c("h2", [_vm._v("Results")]),
          _c(
            "el-tabs",
            {
              ref: "tabs",
              model: {
                value: _vm.activeTab,
                callback: function($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab"
              }
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { name: "index" } },
                [
                  _c("template", { slot: "label" }, [
                    _vm._v("\n          All loans "),
                    _vm.pagination.total
                      ? _c("span", { staticClass: "grey-lighter" }, [
                          _vm._v("(" + _vm._s(_vm.pagination.total) + ")")
                        ])
                      : _vm._e()
                  ]),
                  _c(
                    "section",
                    { staticClass: "formControls" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { disabled: _vm.selection.length === 0 },
                          on: { click: _vm.addSelected }
                        },
                        [_vm._v("Add selected\n          ")]
                      ),
                      _c(
                        "div",
                        { staticClass: "formControls__block" },
                        [
                          _c("el-pagination", {
                            staticClass: "inline-block",
                            staticStyle: { "margin-left": "-10px" },
                            attrs: {
                              layout: "prev, pager, next",
                              background: "",
                              "current-page": _vm.pagination.page,
                              "page-size": _vm.pagination.pageSize,
                              total: _vm.pagination.total
                            },
                            on: { "current-change": _vm.onPageChange }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("LoansTable", {
                    staticClass: "mb-4",
                    attrs: {
                      items: _vm.items,
                      "empty-text":
                        "There are no (or no more) results for this filter combination."
                    },
                    on: {
                      "selection-change": _vm.onSelectionChange,
                      "row-click": _vm.editItem
                    }
                  })
                ],
                2
              ),
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    name: "selected",
                    disabled: _vm.selected.length === 0
                  }
                },
                [
                  _c(
                    "template",
                    { slot: "label" },
                    [
                      _vm._v("\n          Selected loans\n          "),
                      _c("UiBadge", { attrs: { value: _vm.selected.length } })
                    ],
                    1
                  ),
                  _c(
                    "section",
                    { staticClass: "formControls" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { disabled: _vm.toRemove.length === 0 },
                          on: { click: _vm.removeSelected }
                        },
                        [_vm._v("Remove selected\n          ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { disabled: _vm.noSelection },
                          on: { click: _vm.promptClearSelected }
                        },
                        [_vm._v("Clear\n          ")]
                      ),
                      _c(
                        "div",
                        { staticClass: "formControls__block" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                disabled: _vm.noSelection
                              },
                              on: { click: _vm.initRerate }
                            },
                            [
                              _vm.canBorrow && !_vm.canRerateSelected
                                ? _c("i", {
                                    staticClass:
                                      "fas fa-exclamation-triangle mr-3"
                                  })
                                : _vm._e(),
                              _vm._v("\n              Re-rate\n            ")
                            ]
                          ),
                          _vm.canBorrow
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    disabled:
                                      _vm.noSelection || !_vm.hasReturnableLoans
                                  },
                                  on: { click: _vm.initReturn }
                                },
                                [
                                  _vm.canBorrow &&
                                  _vm.hasReturnableLoans &&
                                  !_vm.canReturnSelected
                                    ? _c("i", {
                                        staticClass:
                                          "fas fa-exclamation-triangle mr-3"
                                      })
                                    : _vm._e(),
                                  _vm._v("\n              Return\n            ")
                                ]
                              )
                            : _vm._e(),
                          _vm.canLend
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    disabled:
                                      _vm.noSelection || !_vm.hasRecallableLoans
                                  },
                                  on: { click: _vm.initRecall }
                                },
                                [
                                  _vm.canLend &&
                                  _vm.hasRecallableLoans &&
                                  !_vm.canRecallSelected
                                    ? _c("i", {
                                        staticClass:
                                          "fas fa-exclamation-triangle mr-3"
                                      })
                                    : _vm._e(),
                                  _vm._v("\n              Recall\n            ")
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("LoansTable", {
                    attrs: {
                      items: _vm.selected,
                      "empty-text":
                        "No selection. Add items from the previous tab."
                    },
                    on: {
                      "selection-change": _vm.onAddedSelectedChange,
                      "row-click": _vm.editItem
                    }
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }