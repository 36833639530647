<template>
  <span class="tag" :class="`${name}`">{{ text }}</span>
</template>

<script type="ts">
import { LoanState } from 'modules/loans'

export default {
  props: {
    state: {
      type: Number
    }
  },

  computed: {
    name () {
      return LoanState[this.state]
    },

    text () {
      return this.state === LoanState.openPendingConfirmation
        ? 'Open / PC'
        : LoanState[this.state]
    }
  }
}
</script>
