var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dashboard__matrix",
      attrs: { "data-item-count": _vm.items.length, "data-search": _vm.search },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.onEscapeDown($event)
        }
      }
    },
    [
      _c("DashboardFilterMenu", {
        ref: "filter",
        staticClass: "dashboard__filter",
        on: { "search-enter": _vm.onSearchEnter, "search-tab": _vm.onSearchTab }
      }),
      _c(
        "section",
        {
          ref: "matrix",
          staticClass: "dashboard__columns",
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "up", 38, $event.key, [
                  "Up",
                  "ArrowUp"
                ]) &&
                _vm._k($event.keyCode, "down", 40, $event.key, [
                  "Down",
                  "ArrowDown"
                ]) &&
                _vm._k($event.keyCode, "left", 37, $event.key, [
                  "Left",
                  "ArrowLeft"
                ]) &&
                _vm._k($event.keyCode, "right", 39, $event.key, [
                  "Right",
                  "ArrowRight"
                ])
              ) {
                return null
              }
              if ("button" in $event && $event.button !== 0) {
                return null
              }
              if ("button" in $event && $event.button !== 2) {
                return null
              }
              return _vm.focus.onKeyDown($event)
            }
          }
        },
        [
          _vm.hasLocates
            ? _c(
                "DashboardColumn",
                {
                  ref: "locates",
                  attrs: {
                    type: "locates",
                    title: "Locates",
                    items: _vm.locates
                  }
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "p-2",
                      attrs: {
                        size: "mini",
                        type: "primary",
                        to: "/negotiations/locates/create",
                        tabindex: "-1"
                      },
                      on: {
                        click: function($event) {
                          return _vm.$router.push(
                            "/negotiations/locates/create"
                          )
                        }
                      }
                    },
                    [_vm._v("New\n      ")]
                  )
                ],
                1
              )
            : _c("div", { staticClass: "dashboard__col" }),
          _c("DashboardColumn", {
            ref: "requested",
            attrs: {
              type: "requested",
              title: "Requested",
              items: _vm.requested
            }
          }),
          _c("DashboardColumn", {
            ref: "negotiating",
            attrs: {
              type: "negotiating",
              title: "Negotiating",
              items: _vm.negotiating
            }
          }),
          _c(
            "DashboardColumn",
            {
              ref: "completed",
              attrs: {
                type: "completed",
                title: "Completed",
                items: _vm.completed
              }
            },
            [
              _vm.hidden.length
                ? _c(
                    "el-checkbox",
                    {
                      attrs: { tabindex: "-1", disabled: !!_vm.search },
                      model: {
                        value: _vm.showHidden,
                        callback: function($$v) {
                          _vm.showHidden = $$v
                        },
                        expression: "showHidden"
                      }
                    },
                    [_vm._v("Show Hidden\n      ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }