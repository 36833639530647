import { get } from 'utils/collection'
import Api from 'services/Api'
import Currency from '../models/Currency'
import Collateral from '../models/Collateral'
import Fund from '../models/Fund'

/**
 * Convert a flat list of currency conversions into a from > to > rate lookup
 * @param data
 */
function processConversion (data: Array<{from, to, rate}>) {
  return data.reduce((output, input) => {
    const { from, to, rate } = input
    if (!output[from.code]) {
      output[from.code] = {}
    }
    if (!output[to.code]) {
      output[to.code] = {}
    }
    output[from.code][to.code] = rate
    output[to.code][from.code] = 1 / rate
    return output
  }, {})
}

const state = {
  conversions: {},
  currencies: [],
  collateral: [],
  funds: [],
  countries: [],
}

const actions = {
  load ({ commit, dispatch }) {
    // reload conversions every hour
    window.setTimeout(() => {
      dispatch('loadConversions')
    }, 1000 * 60 * 60)

    // load everything now
    return Promise
      .all([
        Api.get(`currencies`),
        Api.get(`currencies/conversions`),
        Api.get(`collateral-baskets`),
        Api.get(`funds`),
        Api.get(`countries`),
      ])
      .then(results => {
        const [currencies, conversions, collateral, funds, countries] = results
        commit('currencies', currencies.data.map(Currency.fromServer))
        commit('conversions', processConversion(conversions.data))
        commit('collateral', collateral.data.map(Collateral.fromServer))
        commit('funds', funds.data.map(Fund.fromServer))
        commit('countries', countries.data.map(country => ({
          name: country,
          value: country
        })))
      })
  },

  loadConversions ({ commit }) {
    Api.get(`currencies/conversions`).then(res => {
      commit('conversions', processConversion(res.data))
    })
  }
}

const mutations = {
  currencies (state, currencies) {
    state.currencies = currencies
  },

  conversions (state, conversions) {
    state.conversions = conversions
  },

  collateral (state, collateral) {
    state.collateral = collateral
  },

  funds (state, funds) {
    state.funds = funds
  },

  countries (state, countries) {
    state.countries = countries
  }
}

const getters = {
  getCurrency: state => code => {
    return get(state.currencies, code, 'code')
  },

  getCollateral: state => code => {
    return get(state.collateral, code, 'code')
  },

  getFund: state => name => {
    return get(state.funds, name, 'name')
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}

export function getCurrency (code: string) {
  return get(state.currencies, code, 'code')
}

export function getCollateral (code: string) {
  return get(state.collateral, code, 'code')
}

export function getFund (name: string) {
  return get(state.funds, name, 'name')
}

export function getCollateralBasketId (code: string): string {
  const collateral = get(state.collateral, code, 'code')
  return collateral
    ? collateral.id
    : ''
}

export function fx (value: number, from: string, to: string) {
  return from !== to
    ? value * state.conversions[from][to]
    : value
}
