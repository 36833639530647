import { getEnumAsLabel } from 'utils/enum'

enum ResourceType {
  LoanNegotiation = 1000,
  RerateNegotiation = 2000,
  RecallNegotiation = 3000,
  ReturnNegotiation = 4000,
  LocateList = 5000,
  AvailabilityList = 6000,
  Organization = 7000,
  LongPositions = 8000,
  Loan = 9000,
  Rerate = 10000,
  Recall = 11000,
  Return = 12000
}

/// this is a lookup for URL segments
const segments = {
  [ResourceType.LoanNegotiation]: 'loan',
  [ResourceType.RerateNegotiation]: 'rerate',
  [ResourceType.RecallNegotiation]: 'recall',
  [ResourceType.ReturnNegotiation]: 'return',
  [ResourceType.LocateList]: 'locate',
  [ResourceType.AvailabilityList]: 'availability',
  [ResourceType.Organization]: 'organization',
  [ResourceType.Loan]: 'loan',
  [ResourceType.LongPositions]: 'long-positions',
  [ResourceType.Rerate]: 'rerate',
  [ResourceType.Recall]: 'recall',
  [ResourceType.Return]: 'return',
}

export function getResourceTypeSegment (type: ResourceType): string {
  return segments[type]
}

export function getResourceTypeString (type: ResourceType): string {
  return ResourceType[type] || ''
}

export function getResourceTypeLabel (type: ResourceType): string {
  return getEnumAsLabel(ResourceType, type)
}

export default ResourceType
