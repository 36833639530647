<template>

  <div class="layout__main" id="home">
    <aside class="layout__aside">
      <div class="flex">
        <el-button @click="$router.push('/organization/create')">Add Organization</el-button>
      </div>
    </aside>
    <section class="layout__content panel">
      <el-table :data="organizations">
        <el-table-column label="Name"
                         prop="name"
        />
        <el-table-column label="Type"
                         prop="organizationType"
                         :formatter="getType"
        />
        <el-table-column label="Action" align="center" width="180">
          <div class="flex" slot-scope="scope">
            <el-button size="small" class="no-shadow" @click="login(scope.row)">View As</el-button>
            <el-button size="small" class="no-shadow" @click="$router.push(`/organization/view/${scope.row.id}`)">Manage</el-button>
          </div>
        </el-table-column>
      </el-table>
    </section>
  </div>

</template>

<script>
import Api from 'services/Api'
import { OrganizationType } from 'modules/organizations'
import { loadArea } from '../../main'

export default {
  data () {
    return {
      organizations: []
    }
  },

  mounted () {
    Api.get('organizations').then(res => {
      this.organizations = res.data
    })
  },

  methods: {
    getType (row) {
      return OrganizationType[row.organizationType]
    },

    login (row) {
      Api.setOrganization(row.id)
      loadArea(this.$store, row.organizationType).then(() => {
        this.$parent.$forceUpdate()
      })
    }
  }
}

</script>
