<template>
  <div class="ui uiFormItem uiSelect">
    <label v-if="label" class="uiLabel">{{ label }}</label>
    <el-select
      ref="select"
      v-model="model"
      :placeholder="placeholder"
      :multiple="multiple"
      :size="size"
      class="block"
      v-bind="$attrs"
      @change="onChange"
      @clear="onClear"
      v-on="listeners"
    >
      <el-option
        v-for="(option, index) in modelOptions"
        :key="index"
        :label="option[labelKey]"
        :value="option[valueKey]">
        <slot v-bind="option">
          {{ option[optionKey || labelKey] }}
        </slot>
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { stringToOptions } from 'utils/ui'

export default {

  inheritAttrs: false,

  props: {
    label: {
      type: String,
    },

    options: {
      type: Array,
      default () {
        return []
      }
    },

    value: {
      type: [Number, String, Array, Object],
    },

    multiple: Boolean,

    /**
     * Allow objects to be passed in and out as values
     */
    mode: {
      type: String,
      default: 'value',
      validator (value) {
        return ['value', 'object'].includes(value)
      }
    },

    valueKey: {
      type: String,
      default: 'id',
    },

    labelKey: {
      type: String,
      default: 'name'
    },

    optionKey: {
      type: String,
      default: 'name'
    },

    placeholder: {
      type: String,
      default: 'Select...',
    },

    size: {
      type: String,
      default: 'mini',
      validator (value) {
        return ['large', 'default', 'small', 'mini'].includes(value)
      }
    },
  },

  data () {
    return {
      input: null,
    }
  },

  computed: {
    modelOptions () {
      if (typeof this.options === 'string') {
        return stringToOptions(this.options)
      }
      return this.options
    },

    listeners () {
      const { input, ...listeners } = this.$listeners
      return listeners
    },

    model: {
      get () {
        let value = this.value
        if (this.mode === 'object') {
          value = value && value[this.valueKey]
        }
        return this.multiple
          ? value || []
          : value
      },
      set (value) {
        if (this.mode === 'object') {
          value = this.options.find(option => option[this.valueKey] === value)
        }
        this.$emit('input', value || undefined)
      }
    },
  },

  mounted () {
    this.$el.querySelector('.el-popper').addEventListener('mouseleave', this.$refs.select.blur)
  },

  methods: {
    onChange (value) {
      this.$emit('change', value)
    },

    onClear () {
      this.$emit('clear')
    }
  }
}
</script>
