import { debounce } from 'lodash'
import Handsontable from 'handsontable'
import { EditorState } from 'handsontable/es/editors/_baseEditor'
import TextEditor from 'handsontable/es/editors/textEditor'
import mediator from './SelectMediator'

const { dom } = Handsontable

export default class SelectEditor extends TextEditor {
  init () {
    this.onInput = debounce(this.onInput.bind(this), 250)
    this.onUpdate = this.onUpdate.bind(this)
    this.setValue = this.setValue.bind(this)
    this.clear = this.clear.bind(this)
  }

  createElements () {
    super.createElements()
    this.addInput()
    this.addClear()
    this.addEditor()
  }

  addInput () {
    this.TEXTAREA = document.createElement('input')
    this.TEXTAREA.tabIndex = -1
    this.TEXTAREA.className = 'handsontableInput'
    Object.assign(this.TEXTAREA.style, {
      width: 0,
      height: 0,
      overflow: 'hidden',
    })
    this.TEXTAREA_PARENT.innerHTML = ''
    this.TEXTAREA_PARENT.append(this.TEXTAREA)
  }

  addClear () {
    const close = document.createElement('span')
    close.className = 'anSelect__clear'
    close.innerHTML = ''
    close.addEventListener('click', this.clear)
    this.TEXTAREA_PARENT.append(close)
  }

  addEditor () {
    this.editor = mediator.init()
    this.editor.$on('update', this.onUpdate)
  }

  prepare (row, col, prop, td, originalValue, cellProperties) {
    super.prepare(row, col, prop, td, originalValue, cellProperties)
    let options = this.cellProperties.editorOptions
    options = typeof options === 'function'
      ? options(this.row, this.col, this.prop)
      : options

    Object.assign(this.editor, options)
    this.editor.selected = originalValue
  }

  getValue () {
    return this.editor.value
  }

  setValue (value) {
    this.TEXTAREA.value = value
    this.editor.input = value
  }

  clear () {
    this.setValue('')
    this.editor.selected = ''
    this.TEXTAREA.focus()
  }

  open (event) {
    super.open()

    // get initial offsets
    const offset = this.TD.getBoundingClientRect()
    let top = Math.ceil(window.pageYOffset + offset.top + dom.outerHeight(this.TD)) - 1
    let left = Math.ceil(window.pageXOffset + offset.left)

    // tweak positions
    // @see https://handsontable.com/docs/7.2.2/tutorial-cell-editor.html#page-creating-custom-editor
    if (this.hot.getSelectedLast()[0] === 0) {
      // top -= 1
    }
    if (this.hot.getSelectedLast()[1] > 0) {
      left -= 1
    }

    // show
    mediator.show(left, top)

    // add input listener
    this.TEXTAREA.addEventListener('input', this.onInput)
  }

  close () {
    mediator.hide()
    this.TEXTAREA.removeEventListener('input', this.onInput)
    this.editor.clear()
    this.editor.$off('update', this.onUpdate)
    super.close()
  }

  onInput (event) {
    this.editor.input = event.target.value
  }

  onUpdate (value) {
    this.setValue(value)
  }

  onBeforeKeyDown (event) {
    if (this.state === EditorState.EDITING) {
      this.editor.onKeyDown(event)
    }
    super.onBeforeKeyDown(event)
  }
}
