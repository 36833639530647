<template>

  <div class="dashboard__matrix"
       :data-item-count="items.length"
       :data-search="search"
       @keydown.esc="onEscapeDown"
  >
    <!-- filters -->
    <DashboardFilterMenu
      ref="filter"
      class="dashboard__filter"
      @search-enter="onSearchEnter"
      @search-tab="onSearchTab"
    />

    <!-- columns -->
    <section
      class="dashboard__columns"
      @keydown.up.down.left.right="focus.onKeyDown"
      ref="matrix"
    >

      <!-- locates -->
      <DashboardColumn v-if="hasLocates"
                       ref="locates"
                       type="locates"
                       title="Locates"
                       :items="locates"
      >
        <el-button class="p-2"
                   size="mini"
                   type="primary"
                   to="/negotiations/locates/create"
                   @click="$router.push('/negotiations/locates/create')"
                   tabindex="-1">New
        </el-button>
      </DashboardColumn>

      <div v-else class="dashboard__col"/>

      <!-- negotiations -->
      <DashboardColumn ref="requested"
                       type="requested"
                       title="Requested"
                       :items="requested"
      />

      <DashboardColumn ref="negotiating"
                       type="negotiating"
                       title="Negotiating"
                       :items="negotiating"
      />

      <DashboardColumn ref="completed"
                       type="completed"
                       title="Completed"
                       :items="completed"
      >
        <el-checkbox v-if="hidden.length"
                     v-model="showHidden"
                     tabindex="-1"
                     :disabled="!!search">Show Hidden
        </el-checkbox>
      </DashboardColumn>

    </section>

  </div>

</template>

<script>
import { get, sync } from 'vuex-pathify'
import { OrganizationMixin } from 'modules/organizations'
import DashboardColumn from './DashboardColumn'
import DashboardFilterMenu from './DashboardFilterMenu'
import focus from '../services/FocusManager'
import { removeTabIndex } from '../services/helpers'

export default {
  mixins: [
    OrganizationMixin
  ],

  components: {
    DashboardFilterMenu,
    DashboardColumn,
  },

  computed: {
    ...sync('dashboard', [
      'search',
      'showHidden',
    ]),

    ...get('dashboard', {
      negotiations: 'items',
      hidden: 'hidden',
    }),

    locates () {
      return this.hasLocates
        ? this.$store.state.locateLists.items
        : []
    },

    requested () {
      return this.negotiations.filter(item => item.isOpen && item.isSent && item.messageCount === 1)
    },

    negotiating () {
      return this.negotiations.filter(item => !this.requested.includes(item) && !this.completed.includes(item))
    },

    completed () {
      return this.negotiations
        .filter(item => !item.isOpen)
        .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
    },

    items () {
      return [...this.locates, ...this.negotiations]
    },

    hasLocates () {
      return this.$store.state.locateLists
    },
  },

  watch: {
    'hidden.length' (value, oldValue) {
      // update tab indexes so tab doesn't focus on the newly-shown checkbox
      removeTabIndex(this.$refs.matrix, 'input')

      // had a bug before with reaching an unrecoverable state... seems to have gone now?
      if (value === 0 && !this.showHidden) {
        // this.showHidden = true
      }
    },
  },

  beforeCreate () {
    this.focus = focus
    this.focus.setMatrix(this)
  },

  methods: {
    onSearchEnter () {
      const refs = this.focus.getRefs()
      if (refs.length === 1) {
        refs[0].navigate()
        setTimeout(() => {
          this.search = ''
        }, 1000)
      }
    },

    onSearchTab () {
      this.focus.focusFirst()
    },

    onEscapeDown () {
      this.$refs.filter.clearSearch()
    }
  }
}

</script>

<style lang="scss">
@import "./styles";

</style>

