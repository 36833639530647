import Hash from 'core/types/Hash'
import Ruleset from '../types/Ruleset'

// predefined options for rule
const options = {
  params: {
    between: 'min,max',
    dimensions: 'width,height',
    required_if: 'target,...values'
  },
  array: {
    oneOf: true,
  }
}

function cast (value: string) {
  return /^\d+$/.test(value)
    ? parseInt(value, 10)
    : value
}

/**
 * Parse a rules expression into an object
 */
export function parseRule (expr: string): Hash {
  // process string
  const rules: Array<string> = expr.split('|')

  // process individual rules
  const output: any = {}
  rules.forEach(function (rule) {
    // skip empty strings
    rule = rule.trim()
    if (rule === '') {
      return
    }

    // parse string
    const [type, args] = rule.split(':')
    const params: Array<string> = args
      ? String(args).split(',')
      : []

    // no args
    if (!args) {
      output[type] = true
    }

    // regex
    else if (type === 'regex') {
      output[type] = new RegExp(params[0])
    }

    // rules with array arguments
    else if (options.array[type]) {
      output[type] = params.map(cast)
    }

    // rules with named params
    else if (options.params[type]) {
      output[type] = {}
      options.params[type].split(',')
        .forEach((a, i) => {
          if (a.startsWith('...')) {
            output[type][a.substr(3)] = params.slice(i).map(cast)
            return
          }
          output[type][a] = cast(params[i])
        })
    }

    // rules with a single parameter (can be shortened)
    else if (params) {
      output[type] = cast(params[0])
    }

    // everything else, true
    else {
      output[type] = '??'
    }
  })

  // return
  return output
}

/**
 * Parse a hash of rules, converting strings but returning existing objects
 */
export function convertRules (ruleset: any): Ruleset {
  return Object
    .keys(ruleset)
    .reduce((config: any, key: string) => {
      const expr: string | Hash = ruleset[key]
      config[key] = typeof expr === 'string'
        ? parseRule(expr)
        : expr
      return config
    }, {})
}
