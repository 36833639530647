import { isPlainObject } from 'utils/object'

export function setRequired (rules, required) {
  Object.keys(required)
    .filter(key => required[key])
    .forEach(field => {
      const rule = rules[field]
      if (isPlainObject(rule)) {
        rule.required = true
      }
      else if (!/\brequired\b/.test(rule)) {
        const rule = rules[field] || ''
        rules[field] = 'required|' + rule
      }
    })
}
