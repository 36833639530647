var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "layout__index profile" },
    [
      _c("h1", [_vm._v("Profile")]),
      _c("ui-breadcrumbs"),
      _c(
        "div",
        { staticClass: "layout__main", attrs: { id: "trading-profile" } },
        [
          _c(
            "div",
            { staticClass: "layout__content" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("NegotiationDefaults"),
                      _vm.canLend
                        ? _c("MarginRequirements", { staticClass: "mt-4" })
                        : _vm._e(),
                      _vm.canLend
                        ? _c("GcRates", { staticClass: "mt-4" })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("SpecialSecurities"),
                      _vm.canLend
                        ? _c("NetDivRequirements", { staticClass: "mt-4" })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }