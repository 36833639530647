import { Lookups } from 'modules/global'
import { getCollateralBasketId } from 'modules/resources'
import { Model } from 'core/models'

export default class AvailabilityItem extends Model {

  public bloomberg: string

  public quantity: number

  public rate: number

  public netDividendRequirement: number

  public collateralBasketCode: string

  public fundName: string

  constructor (data: any = {}) {
    super(data)
    this.bloomberg = data.bloomberg
    this.quantity = data.quantity
    this.rate = data.rate
    this.netDividendRequirement = data.netDividendRequirement
    this.collateralBasketCode = data.collateralBasketCode
    this.fundName = data.fundName
  }

  static fromServer (data: any = {}): AvailabilityItem {
    const item: AvailabilityItem = new AvailabilityItem()
    item.id = data.id
    item.bloomberg = data.security.bloomberg
    item.quantity = data.quantity
    item.rate = data.rate
    item.netDividendRequirement = data.netDividendRequirement
    item.collateralBasketCode = data.collateralBasket
      ? data.collateralBasket.code
      : undefined
    item.fundName = data.fund
      ? data.fund.name
      : undefined
    return item
  }

  static fromTable (data): AvailabilityItem {
    return new AvailabilityItem(data)
  }

  toServer (lookups: Lookups): any {
    const { getSecurity, getFund } = lookups
    const item: AvailabilityItem = this
    const data: any = {}

    // raw data
    data.quantity = item.quantity
    data.rate = item.rate
    data.netDividendRequirement = item.netDividendRequirement
    data.collateralBasketId = getCollateralBasketId(item.collateralBasketCode)

    // lookups
    const security = getSecurity(this.bloomberg)
    data.securityId = security
      ? security.id
      : undefined

    const fund = getFund(this.fundName)
    data.fundId = fund
      ? fund.id
      : undefined

    // return
    return data
  }
}
