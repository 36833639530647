import moment from 'moment'
import { parseDate, today, tomorrow } from './date'

// ---------------------------------------------------------------------------------------------------------------------
// type
// ---------------------------------------------------------------------------------------------------------------------

export function isEmpty (value: any) {
  return value === '' || value === null || value === undefined
}

export function isString (value: any) {
  return typeof value === 'string'
}

export function isNumber (value: any) {
  return typeof value === 'number'
}

export function isNumeric (value: any) {
  return isNumber(value) || (isString(value) && /^-?\d[\d+,]*(\.\d+)?$/.test(value))
}

export function isInteger (value: number) {
  return value === Math.floor(value || 0)
}

// ---------------------------------------------------------------------------------------------------------------------
// comparison
// ---------------------------------------------------------------------------------------------------------------------

export function isZeroOrMore (value: number) {
  return value >= 0
}

export function isOneOrMore (value: number) {
  return value >= 1
}

export function isPercent (value: number) {
  return value >= 0 && value <= 100
}

// ---------------------------------------------------------------------------------------------------------------------
// quantity
// ---------------------------------------------------------------------------------------------------------------------

export function isQuantity (value: number) {
  return isInteger(value) && isZeroOrMore(value)
}

export function isRequiredQuantity (value: any) {
  return isInteger(value) && isOneOrMore(value)
}

export function isSpeculativeQuantity (value: any) {
  return isEmpty(value) || (isInteger(value) && isOneOrMore(value))
}

// ---------------------------------------------------------------------------------------------------------------------
// date
// ---------------------------------------------------------------------------------------------------------------------

export function isWeekday (value) {
  if (typeof value === 'string') {
    value = parseDate(value)
  }
  const date = moment(value)
  const dayOfWeek = date.weekday()
  // moment js defines 6 as Saturday and 0 as Sunday
  return !(dayOfWeek === 6 || dayOfWeek === 0)
}

export function isToday (value) {
  if (typeof value === 'string') {
    value = parseDate(value)
  }
  const date = moment(value).startOf('day')
  return date.isSame(today())
}

export function isFromToday (value) {
  if (typeof value === 'string') {
    value = parseDate(value)
  }
  const date = moment(value)
  return date.isSameOrAfter(today())
}

export function isFromTomorrow (value) {
  if (typeof value === 'string') {
    value = parseDate(value)
  }
  const date = moment(value)
  return date.isSameOrAfter(tomorrow())
}

type afterOrBefore = 'after' | 'before'

/**
 * Checks if a current date is at least n days from than the other date
 *
 * @param current   The current date
 * @param other     The day to compare against
 * @param days      The number of days different
 * @param type      The comparison type; "before" or "after"
 */
export function isDaysFrom (current: string | Date, other: string | Date, days: number, type: afterOrBefore) {
  if (typeof current === 'string') {
    current = parseDate(current)
  }
  if (typeof other === 'string') {
    other = parseDate(other)
  }
  const currentMoment: moment.Moment = moment(current).startOf('day')
  const otherMoment: moment.Moment = moment(other).startOf('day')
  return type === 'after'
    ? currentMoment.isSameOrAfter(otherMoment.add(days, 'day'))
    : currentMoment.isSameOrBefore(otherMoment.subtract(days, 'day'))
}
