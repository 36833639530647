var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout__index", attrs: { id: "page-users" } },
    [
      _c("h1", [_vm._v("User")]),
      _c("UiBreadcrumbs"),
      _c("div", { staticClass: "layout__main", attrs: { id: "user" } }, [
        _c(
          "section",
          { staticClass: "layout__content panel" },
          [
            _c("h2", [_vm._v("Profile")]),
            _c("UiOutput", { attrs: { label: "Name" } }, [
              _vm._v(_vm._s(_vm.user.name))
            ]),
            _c("UiOutput", { attrs: { label: "Organisation" } }, [
              _vm._v(_vm._s(_vm.organization.name))
            ])
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }