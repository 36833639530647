import store from '@/app/state'
import { Currency } from 'modules/resources'

function getDefaultCurrency (): Currency {
  return store.state['tradingProfile'].defaults.defaultCurrency
}

function getDefaultCurrencyCode (): string {
  return store.state['tradingProfile'].defaults.defaultCurrency.code || 'EUR'
}

export {
  getDefaultCurrency,
  getDefaultCurrencyCode,
}
