var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-dialog", {
        staticClass: "autoLogout",
        attrs: {
          title: "Inactivity alert",
          width: "520px",
          visible: _vm.dialogVisible,
          "show-close": false,
          "close-on-click-modal": false,
          "close-on-press-escape": false
        },
        on: { close: _vm.hideWarning },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c("h3", { staticClass: "color-danger" }, [
                  _c("strong", [
                    _vm._v(
                      "You appear to have been inactive for " +
                        _vm._s(_vm.inactivityMinutes) +
                        " minutes."
                    )
                  ])
                ]),
                _c("p", [
                  _vm._v(
                    "As a security precaution you will be logged out in " +
                      _vm._s(_vm.timerValue) +
                      " seconds."
                  )
                ])
              ]
            },
            proxy: true
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.hideWarning }
                  },
                  [_vm._v("Cancel Logout")]
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }