import 'babel-polyfill'
import Vue from 'vue'

// env
import { isDev } from 'config/env'

// vendor
import '@/vendor/handsontable'
import '@/vendor/element-ui'

// services
import './app/services'
import './core/plugins'

// views
import 'assets/styles'
import './core/components'
import './app/views'

// app
import store from './app/state'
import router from './app/router'
import App from './app/App.vue'

// setup
import init from './areas/main'
init()

// setup
Vue.config.productionTip = false

// mount
const app = new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')

// development vars
if (isDev) {
  // @ts-ignore
  window['Vue'] = Vue
  window['app'] = app
  window['store'] = store
}
