import { getDefaultCurrencyCode } from 'modules/trading-profile'
import { UserMixin } from 'modules/organizations'
import focus from '../services/FocusManager'

export default {
  mixins: [
    UserMixin,
  ],

  props: {
    item: Object,
  },

  computed: {
    info () {
      return 'Some info...'
    },

    createdAt () {
      return this.item.createdAt
    },

    updatedAt () {
      return this.item.updatedAt
    },

    currencyCode () {
      return getDefaultCurrencyCode()
    }
  },

  methods: {
    navigate () {
      this.$router.push(this.route)
    },

    onFocus () {
      focus.onFocus(this)
    },

    toggleFilter (value) {
      this.$store.commit('dashboard/toggleSearch', String(value))
    }
  }
}
