import { route } from '@/app/router/helpers'

export default {
  stores: {
    longPositions: require('./state/LongPositionsStore').default,
  },

  routes: [
    route('/long-positions/', 'long-positions@Index.vue', {
      children: [
        route('', 'long-positions@EditLongPositions.vue'),
      ],
    }),
  ]
}
