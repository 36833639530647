import { isEmpty } from 'utils/validators'

function fillDown (selection, overwrite) {
  // objects
  const instance = this.hot.getInstance()
  const settings = instance.getSettings()
  const columns = settings.columns

  // table data
  const data = instance.getSourceData()

  // source coordinates
  const { start, end } = selection[0]
  const top = start.row
  const left = start.col
  const right = end.col
  const bottom = end.row === start.row
    ? data.length - settings.minSpareRows
    : end.row + 1

  // source data
  const readonly = columns.map(col => this.isColumnReadOnly(col))
  const output = data[top]

  // build changes
  const changes = []
  for (let row = top + 1; row < bottom; row++) {
    for (let col = left; col <= right; col++) {
      if (readonly[col]) {
        continue
      }
      const prop = columns[col].data
      const input = data[row][prop]
      const isRemoved = data[row].isRemoved
      if ((overwrite || isEmpty(input)) && !isRemoved) {
        changes.push([row, prop, output[prop]])
      }
    }
  }

  // set changes
  instance.setDataAtRowProp(changes)
}

export default {
  fillDown
}
