import Vue from 'vue'
import VueRouter from 'vue-router'
import VueStore from 'vue-class-store'
import { NavigationOptions, NavigationClient, Configuration } from '@azure/msal-browser'
import { makeMsalService, MaybeAccount } from './msal'

// ---------------------------------------------------------------------------------------------------------------------
// msal
// ---------------------------------------------------------------------------------------------------------------------

/**
 * Custom navigation client for Vue
 */
export class VueNavigationClient extends NavigationClient {
  private router: VueRouter

  constructor (router: VueRouter) {
    super()
    this.router = router
  }

  /**
   * Only called during redirects
   */
  navigateExternal (url: string, options: NavigationOptions): Promise<boolean> {
    return super.navigateExternal(url, options)
  }

  /**
   * Only called during popup completion
   */
  async navigateInternal (url: string, options: NavigationOptions): Promise<boolean> {
    const path = url.replace(location.origin, '')
    options.noHistory
      ? await this.router.replace(path)
      : await this.router.push(path)
    return true
  }
}

export let service: ReturnType<typeof makeMsalService>

// ---------------------------------------------------------------------------------------------------------------------
// store
// ---------------------------------------------------------------------------------------------------------------------

/**
 * Facade to provide reactive state and MSAL logic
 */
@VueStore
class AuthStore {
  protected initialized = false

  public account: MaybeAccount = null

  public error = ''

  get isAuthenticated (): boolean {
    return !!this.account
  }

  get service () {
    return service
  }

  async initialize (router: VueRouter) {
    service.msal.setNavigationClient(new VueNavigationClient(router))
    this.account = await service.initialize()
    this.initialized = true
    return this.account
  }

  async getToken (): Promise<string> {
    return service.getToken()
  }

  async login (): Promise<MaybeAccount> {
    this.error = ''
    return service.login()
      .then(account => {
        this.account = account
        this.error = ''
        return account
      })
      .catch(err => {
        this.error = err.message
        throw(err)
      })
  }

  async logout (): Promise<void> {
    return service.logout().then(() => {
      this.account = null
    })
  }
}

/**
 * Facade instance
 */
export const Auth = new AuthStore()

// @ts-ignore
window.Auth = Auth

// ---------------------------------------------------------------------------------------------------------------------
// plugin
// ---------------------------------------------------------------------------------------------------------------------

/**
 * MSAL plugin options
 */
export interface MsalPluginOptions {
  msal: Configuration,
  scopes: string[]
}

/**
 * MSAL plugin setup
 */
export const plugin = {
  install (vue: typeof Vue, options: MsalPluginOptions) {
    service = makeMsalService(options.msal, options.scopes)
    Vue.prototype.$auth = Auth
  }
}

// add public mixin type
declare module 'vue/types/vue' {
  interface Vue {
    $auth: AuthStore
  }
}
