var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "profile__gcRates panel" },
    [
      _c("h2", [_vm._v("Default GC Rates")]),
      _c("UiErrors", { attrs: { errors: _vm.errors } }),
      _c(
        "p",
        { staticClass: "profile__upload" },
        [
          _vm._v("Click to\n  "),
          _c("UiUpload", {
            attrs: {
              href: "/profile/default-gc-rates/upload",
              type: "text",
              text: "upload"
            },
            on: { submit: _vm.upload }
          }),
          _vm._v("\n    a GC Rates file.\n  ")
        ],
        1
      ),
      _vm._l(_vm.rates, function(fund) {
        return _c(
          "div",
          { key: fund.name, staticClass: "profile__listContainer" },
          [
            _c("h3", { staticClass: "text" }, [_vm._v(_vm._s(fund.name))]),
            _vm._l(fund.countries, function(country) {
              return _c("div", { key: country.name, staticClass: "ml-4" }, [
                _c("h4", { staticClass: "bold" }, [
                  _vm._v(_vm._s(country.name))
                ]),
                _c(
                  "ul",
                  { staticClass: "profile__list" },
                  [
                    _c("li", { staticClass: "header" }, [
                      _c("label", [
                        _c("span", { staticClass: "symbol hidden" }, [
                          _vm._v(_vm._s(_vm.currency))
                        ]),
                        _c("span", { staticClass: "text" }, [
                          _vm._v("For trades up to:")
                        ])
                      ]),
                      _vm._m(0, true)
                    ]),
                    _vm._l(country.tranches, function(tranche, index) {
                      return _c("li", { key: tranche.value }, [
                        _c("label", [
                          _c(
                            "span",
                            {
                              staticClass: "symbol",
                              style: {
                                visibility: tranche.value ? "visible" : "hidden"
                              }
                            },
                            [_vm._v(_vm._s(_vm.currency))]
                          ),
                          tranche.value
                            ? _c("span", { staticClass: "text" }, [
                                _vm._v(_vm._s(_vm._f("numeric")(tranche.value)))
                              ])
                            : _c("span", { staticClass: "text grey" }, [
                                _vm._v("Infinity")
                              ])
                        ]),
                        _c(
                          "output",
                          { staticClass: "mb-1" },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "number",
                                min: 0,
                                step: "0.001",
                                size: "mini"
                              },
                              model: {
                                value: _vm.form.data[tranche.formIndex].rate,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.data[tranche.formIndex],
                                    "rate",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "form.data[tranche.formIndex].rate"
                              }
                            }),
                            _c("span", { staticClass: "symbol" }, [_vm._v("%")])
                          ],
                          1
                        )
                      ])
                    })
                  ],
                  2
                )
              ])
            })
          ],
          2
        )
      }),
      _c("SaveChanges", { on: { save: _vm.save } })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("output", [
      _c("span", { staticClass: "text" }, [_vm._v("Apply this rate:")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }