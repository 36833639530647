import Vue from 'vue'

// simplify Element UI prompt to return just the value
const prompt = Vue.prototype.$prompt
export const $prompt = function (...args) {
  return prompt(...args)
    .then(result => {
      return result.value
        ? result.value.trim()
        : undefined
    })
    .catch(() => {
      return void 0
    })
}

// simplify Element UI confirm to return just the value
const confirm = Vue.prototype.$confirm
export const $confirm = function (...args) {
  return new Promise(function (resolve) {
    confirm(...args)
      .then(result => {
        resolve(true)
      })
      .catch(() => {
        return void 0
      })
  })
}

Vue.prototype.$prompt = $prompt
Vue.prototype.$confirm = $confirm
