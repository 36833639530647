import formatters from 'utils/formatters'
import Vue from 'vue'

Vue.mixin({
  methods: {
    // format a value
    $format (format, value, ...args) {
      return formatters[format](value, ...args)
    },

    // return a formatter function for use in el-table-columns
    $formatCol (format, ...args) {
      return function (row, column, value, index) {
        return formatters[format](value, ...args)
      }
    }
  }
})

/**
 * Add filters to the global filters array
 *
 * @usage
 *
 * {{ value | filter }}
 */
Object.keys(formatters).forEach(format => {
  Vue.filter(format, formatters[format])
})
