<template>

  <el-form class="loanFilters" @submit.prevent="submit">

    <el-collapse v-model="activeNames">
      <el-collapse-item name="information">
        <template slot="title">
          <span class="loanFilters__groupTitle">Information</span>
          <UiBadge :value="filterCounts.information"/>
        </template>
        <ui-input
            label="Reference"
            placeholder="Type..."
            v-model.trim="form.reference"
            clearable
            @keypress.native.enter="submit"
        />
        <ui-select
            v-if="canLend && canBorrow"
            label="Loan Role"
            :options="options.loanRole"
            v-model="form.loanRole"
            valueKey="value"
            clearable
        />
        <ui-select
            label="State"
            :options="options.states"
            v-model="form.states"
            valueKey="value"
            multiple
        />
        <ui-select
            label="Counterparties"
            :options="options.counterparties"
            v-model="form.counterPartyOrganizationIds"
            size="mini"
            multiple
        />

      </el-collapse-item>
      <el-collapse-item name="security">
        <template slot="title">
          <span class="loanFilters__groupTitle">Asset</span>
          <UiBadge :value="filterCounts.security"/>
        </template>

        <ui-select
            label="Asset"
            :options="options.securities"
            v-model="form.securityId"
            labelKey="bloomberg"
            optionKey="bloomberg"
            value-key="id"

            filterable
            clearable
            remote
            :remote-method="searchSecurities"

            placeholder="Search by Bloomberg ticker..."
            size="mini"
            @clear="options.securities = []"
        />

        <ui-select
            label="Country"
            :options="options.countries"
            v-model="form.country"
            valueKey="value"
            clearable
        />

      </el-collapse-item>

      <el-collapse-item name="trade">
        <template slot="title">
          <span class="loanFilters__groupTitle">Trade</span>
          <UiBadge :value="filterCounts.trade"/>
        </template>

        <ui-range
            label="Quantity"
            :minValue.sync="form.quantityFrom"
            :maxValue.sync="form.quantityTo"
        />
        <ui-range
            label="Rate"
            :minValue.sync="form.rateFrom"
            :maxValue.sync="form.rateTo"
            :max="100"
            suffix="%"
        />
        <ui-range
            label="Net Dividend / Margin"
            :minValue.sync="form.netDividendRequirementFrom"
            :maxValue.sync="form.netDividendRequirementTo"
            :max="100"
            suffix="%"
        />
        <ui-select
            label="Currency"
            :options="options.currencies"
            v-model="form.currencyIds"
            labelKey="code"
            optionKey="name"
            multiple
        />
        <ui-select
            label="Collateral"
            :options="options.collateral"
            v-model="form.collateralBasketIds"
            labelKey="code"
            multiple
        />
        <ui-select
            label="Funds"
            :options="options.funds"
            v-model="form.fundIds"
            multiple
        />
      </el-collapse-item>

      <el-collapse-item name="dates">
        <template slot="title">
          <span class="loanFilters__groupTitle">Dates</span>
          <UiBadge :value="filterCounts.dates"/>
        </template>

        <ui-range
            label="Settlement Date"
            :minValue.sync="form.settlementDateFrom"
            :maxValue.sync="form.settlementDateTo"
            type="date"
        />

        <ui-range
            label="Term End Date"
            :minValue.sync="form.termEndDateFrom"
            :maxValue.sync="form.termEndDateTo"
            type="date"
        />
      </el-collapse-item>
    </el-collapse>

    <div class="flex mt-4">
      <el-button @click="reset">Reset</el-button>
      <el-button @click="submit" type="primary">Search</el-button>
    </div>

  </el-form>

</template>

<script>
import { dispatch, commit } from 'vuex-pathify'
import { enumToOptions } from 'utils/ui'
import { LoanState } from 'modules/loans'
import LoanFilters, { filterGroups } from '../models/LoanFilters'
import { LoanRole, OrganizationMixin } from 'modules/organizations'

export default {
  mixins: [
    OrganizationMixin
  ],

  props: {},

  data () {
    return {
      form: new LoanFilters(),
      activeNames: [],
      options: {
        loanRole: enumToOptions(LoanRole),
        counterparties: this.$store.state.organizations.counterparties,
        currencies: this.$store.state.resources.currencies,
        collateral: this.$store.state.resources.collateral,
        funds: this.$store.state.resources.funds,
        countries: this.$store.state.resources.countries,
        states: enumToOptions(LoanState),
        securities: [],
      },
    }
  },

  computed: {
    filterCounts () {
      // helper function
      const count = (form, keys) => {
        return keys
            .filter(key => {
              const value = form[key]
              const state = Array.isArray(value)
                  ? value.length > 0
                  : value === 0
                    ? false
                    : !!value
              return state
            }).length
      }

      // data
      return {
        information: count(this.form, filterGroups.information),
        security: count(this.form, filterGroups.security),
        trade: count(this.form, filterGroups.trade),
        dates: count(this.form, filterGroups.dates),
      }
    }
  },

  methods: {
    has (id) {
      return this.filters.find(filter => filter === id)
    },

    searchSecurities (term) {
      return this.$store
          .dispatch('securities/search', term)
          .then(options => {
            this.options.securities = options
          })
    },

    submit () {
      commit('loans/filters', this.form)
      return dispatch('loans/load')
    },

    reset () {
      this.form = new LoanFilters()
      this.activeNames = []
      return this.submit()
    }
  }
}

</script>

<style lang="scss">
.loanFilters {

  &__groupTitle {
    padding-right: .5em;
  }

  .el-collapse-item__header.is-active {
    .badge {
      background: $grey-lighter;
    }
  }

  .badge {
    // margin: -1.5em 0 0 -.75em;
  }

  .ui {
    margin-bottom: 0.8em;
  }

  .el-collapse-item__wrap {
    padding-left: .5rem;
  }
}

.el-select-dropdown.is-multiple.is-custom,
.el-select-dropdown__item.selected::after {
  display: none;
}
</style>
