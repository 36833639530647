<template>
  <el-table-column
    align="center"
    label="Unseen"
    width="70">
    <template slot-scope="{ row }">
      <UiBadge :value="getUnseen(row.id).length"/>
    </template>
  </el-table-column>
</template>

<script>
export default {
  methods: {
    getUnseen (id) {
      return this.$store.get('notifications/getUnseenForItem', id || this.item.id)
    },
  }
}
</script>
