import {
  Columns,
  makeDate,
  makeQuantity,
  makeQuantityEditor,
  makeRate,
  makeTable, makeText,
  retarget,
} from 'vendor/handsontable'
import { RecallNegotiationItem } from '../../../negotiations'
import RecallNegotiationValidator from '../../validators/RecallNegotiationValidator'

function settings (vm) {
  const { action, role, locked } = vm

  const validator = new RecallNegotiationValidator()

  // NOTE an enum would be better, but string props are clearer in components
  const canLocate = role === 'borrower' && !locked

  const columns = [
    makeText('Reference', 'loan.reference', 110),
    retarget(Columns.security, 'loan.bloomberg'),
    makeQuantity('Loan<br>Quantity', 'loanQuantity'),
    role === 'lender'
      ? makeQuantityEditor('Recalled Quantity', 'quantity')
      : makeQuantity('Recalled Quantity', 'quantity'),
    role === 'borrower'
      ? makeQuantity('Long Position', 'longPosition')
      : null,
    role === 'borrower' && !locked
      ? makeQuantityEditor('To Locate', 'toLocate')
      : null,
    action === 'edit'
      ? makeDate('Settlement Date', 'settlementDate')
      : null,
    makeRate('Rate', 'loan.rate', false),
  ]

  if (!locked) {
    columns.push(...Columns.tradedRates)
  }

  return {
    columns,
    validator,
    schemaClass: RecallNegotiationItem,
    minSpareRows: 0,
  }
}

export default makeTable(settings)
