import { Hash } from 'core/types'
import { ResourceValidator, RulesProvider, ValidationOptions } from 'vendor/vee-validate'
import { setRequired } from './utils'

export default class RerateNegotiationValidator extends ResourceValidator {
  constructor (options: ValidationOptions = {}, local?: boolean) {
    super(new RerateRulesProvider(options), local)
  }
}

export const names: Hash = {
  rate: 'New Rate',
  oldRate: 'Old Rate',
  effectiveFromDate: 'Effective From Date',
}

const baseRules: Hash = {
  rate: 'required|number|between:0,100|max_precision:4|different_to:oldRate',
  effectiveFromDate: 'from_today',
}

const requiredToAccept = {
  rate: true,
  effectiveFromDate: true,
}

const dependents: Hash = {
  rate: 'oldRate',
}

export class RerateRulesProvider extends RulesProvider {

  public options: ValidationOptions

  constructor (options: ValidationOptions) {
    super(names, dependents, options)
  }

  update () {
    const { response } = this.options
    const rules: any = Object.assign({}, baseRules)

    if (response === 'accept') {
      setRequired(rules, requiredToAccept)
    }

    this.rules = rules
  }

}
