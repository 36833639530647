<template>
  <div class="layout__index" id="page-loans">
    <h1>Loans</h1>

    <UiBreadcrumbs/>

    <router-view/>
  </div>
</template>

<script>
export default {

  metaInfo () {
    return {
      title: 'Loans'
    }
  }

}
</script>
