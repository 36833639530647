import { ErrorBag } from '..'

/**
 * Class to hold a 2D array of validation error messages
 */
export default class ErrorsModel {

  public data: any

  constructor (errors: any = {}) {
    this.data = errors
  }

  public static fromData (data: any): ErrorsModel {
    const state: ErrorsModel = new ErrorsModel()
    Object
      .keys(data)
      .forEach(field => {
        state.add(field, data[field])
      })
    return state
  }

  public get items (): Array<ErrorBag> {
    return Object
      .keys(this.data)
      .map(field => this.data[field])
  }

  public get errorCount (): number {
    return Object.keys(this.data).length
  }

  public get messageCount (): number {
    return this.items.reduce((total: number, bag: ErrorBag) => {
      total += bag.messages.length
      return total
    }, 0)
  }

  add (field: string, messages: Array<string> | string) {
    this.data[field] = new ErrorBag(field, messages)
  }

  public clear () {
    this.data = {}
  }
}
