var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      staticClass: "ticker__table no-bg no-hover",
      attrs: {
        size: "mini",
        data: _vm.items,
        "highlight-current-row": false,
        "row-class-name": _vm.getRowClass,
        "row-hover": false
      }
    },
    [
      _c("el-table-column", {
        attrs: {
          prop: "bloomberg",
          label: "Asset",
          width: "80",
          align: "right"
        }
      }),
      _c("el-table-column", {
        attrs: {
          prop: "quantity",
          label: "Quantity",
          width: "70",
          align: "right",
          formatter: _vm.$formatCol("numeric")
        }
      }),
      _c("el-table-column", {
        attrs: { prop: "rate", label: "Rate", width: "60", align: "right" }
      }),
      _c("el-table-column", {
        attrs: {
          prop: "createdAt",
          label: "When",
          width: "60",
          align: "right",
          formatter: _vm.formatDate
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }