import { ResourceValidator, RulesProvider } from 'vendor/vee-validate'
import Hash from 'core/types/Hash'

const names: Hash = {
  bloomberg: 'Asset',
}

export const rules: Hash = {
  bloomberg: '',
}

export default class SpecialSecurityValidator extends ResourceValidator {
  constructor (local?: boolean) {
    super(RulesProvider.create(rules, names), local)
  }
}
