import store from '@/app/state'
import Lookups from '../types/Lookups'

const getSecurity = store.getters['securities/getSecurity']
const getCurrency = store.getters['resources/getCurrency']
const getFund = store.getters['resources/getFund']

const lookups: Lookups = {
  getSecurity,
  getCurrency,
  getFund,
}

export default lookups
