<template>

  <div class="layout__main" id="edit-negotiation">

    <SiteAside v-if="hasStaleWarning">
      <NegotiationConflict :counterParty="counterParty" @reload="reload"/>
    </SiteAside>
    <SiteAside v-else label="Options">

      <el-form ref="form"
               :model="form"
               label-position="top"
               @submit.prevent.native=""
      >

        <NegotiationMessage :message="lastMessage"/>

        <UiFieldset label="Response" v-if="state === NegotiationState.open">
          <NegotiationResponse
            v-model="form.response"
            v-bind="responseData"
          />

          <NegotiationComment v-model="form.comment" />

          <UiSubmit v-bind="submitData" v-on="submitData" />
        </UiFieldset>

        <UiFieldset label="Tools">
          <el-button size="mini" class="is-plain" v-if="isLender && state === NegotiationState.open" @click="fillFromAvailability">Fill from availability</el-button>
          <el-button size="mini" class="is-plain" @click="download">Download</el-button>
        </UiFieldset>

        <UiFieldset label="Notes">
          <NegotiationNotes v-model="privateNotes" type="loan" autosave/>
        </UiFieldset>

      </el-form>

      <NegotiationHistory v-model="messageIndex"
                          :messages="messages"
                          :open="isHistoryVisible"
                          :canToggle="isOpen"
                          @toggle="toggleHistory" />

    </SiteAside>

    <section class="layout__content panel">

      <NegotiationSummary v-bind="summaryData">
        <UiOutput label="Value"><span class="symbol">{{ currencyCode }}</span> {{ value | integer }}</UiOutput>
        <UiOutput label="Specials" v-if="isLender && numSpecials"><span class="bold color-warning-darker">{{ numSpecials }}</span></UiOutput>
      </NegotiationSummary>

      <UiErrors/>

      <!-- current items -->
      <NegotiationItemsTable
        ref="table"
        v-show="canShowLast"
        :data="items.data"
        :role="role"
        :locked="!isOpen"
      />

      <!-- previous items -->
      <NegotiationItemsTable
        v-show="canShowPrevious"
        :data="previous.data"
        :role="role"
        :locked="true"
      />
    </section>

  </div>

</template>

<script>
import debounce from 'lodash/debounce'
import { clone } from 'utils/object'
import { isEmpty } from 'utils/validators'
import { sumLoanValues } from 'modules/loans'
import { getDefaultCurrencyCode } from 'modules/trading-profile'
import NegotiationItemsTable from '../../components/tables/NegotiationItemsTable'
import LoanNegotiationValidator from '../../validators/LoanNegotiationValidator'
import factory from './edit-factory'
import { NegotiationItemState } from 'modules/negotiations'

export default {

  extends: factory('loan', LoanNegotiationValidator),

  crumb: 'Loans',

  components: {
    NegotiationItemsTable,
  },

  data () {
    return {
      value: this.$store.state.negotiations.loan.value,
    }
  },

  computed: {
    currencyCode () {
      return getDefaultCurrencyCode()
    },

    numSpecials () {
      return this.items.data.filter(item => item.specialSecurity).length
    }
  },

  watch: {
    numUpdates: 'updateValue'
  },

  methods: {
    updateValue: debounce(function () {
      const items = this.getItems()
      this.value = sumLoanValues(items)
    }, 100),

    download () {
      this.$store.dispatch('negotiations/loan/download')
    },

    fillFromAvailability () {
      const items = this.getItems()
      this.$store.dispatch('negotiations/loan/getAvailability', items)
        .then(availability => {
          // add all available securities to a dictionary
          const lookup = availability.reduce((output, item) => {
            output[item.bloomberg] = item
            return output
          }, {})

          // convert the input array into an updated output array
          const input = this.$refs.table.getData()
          const output = input.map(target => {

            // build and compare items
            const itemLender = lookup[target.bloomberg]
            const itemBorrower = clone(target)

            // flags
            const isRemoved = itemBorrower.state === NegotiationItemState.removed

            // if the lender has the security...
            if (itemLender) {

              // Null means they have some stock but not quantified so leave the borrower quantity in tack in that case
              if (itemLender.quantity !== null) {

                // if the lender has stock...
                if (itemLender.quantity > 0) {
                  // if quantity is blank, give everything we have
                  if (isEmpty(itemBorrower.quantity)) {
                    itemBorrower.quantity = itemLender.quantity
                  }
                  // otherwise, set the quantity to all we have, or all they want
                  else {
                    itemBorrower.quantity = Math.min(itemLender.quantity, itemBorrower.quantity)
                  }
                }
                // no stock
                else {
                  itemBorrower.quantity = 0
                }
              }
              // always set the highest rate
              if (itemLender.rate) {
                if (itemBorrower.rate < itemLender.rate) {
                  itemBorrower.rate = itemLender.rate
                }
              }
            }

            // if the lender doesn't have the security, and it's not already removed, flag for removal
            else {
              if (!isRemoved) {
                itemBorrower.quantity = 0
              }
            }

            // copy other column values
            if (!isRemoved) { // && itemBorrower.quantity !== 0
              const toSkip = ['id', 'bloomberg', 'quantity', 'rate']
              Object.keys(itemLender).map(key => {
                if (!toSkip.includes(key)) {
                  itemBorrower[key] = itemLender[key]
                }
              })
            }

            // return the updated item
            return itemBorrower
          })

          // update so the app knows what's going on
          this.$refs.table.setData(output)
        })
    },

  }
}
</script>
