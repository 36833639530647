import { toDateIso, toDateString } from 'utils/date'
import { Lookups } from 'modules/global'
import { LoanItem } from 'modules/loans'
import { renameArrayItems } from '../helpers'
import { AbstractItem, NegotiationItemState } from '..'

export default class ReturnNegotiationItem extends AbstractItem {

  public readonly type: string = 'return'

  public quantity: number

  public loanQuantity: number

  public fee: number

  public feeCurrencyCode: string

  public settlementDate: string

  public loan: LoanItem

  public state: NegotiationItemState

  public propertiesUpdated: Array<string>

  constructor (data: any = {}) {
    super(data)
    this.quantity = data.quantity
    this.loanQuantity = data.loanQuantity
    this.fee = data.fee
    this.feeCurrencyCode = data.feeCurrencyCode
    this.loan = data.loan
    this.settlementDate = data.settlementDate
    this.state = data.state
    this.propertiesUpdated = data.propertiesUpdated
  }

  static fromServer (data: any = {}): ReturnNegotiationItem {
    data.loanQuantity = data.previousLoanQuantity
    data.loan = LoanItem.fromServer(data.loan)
    data.feeCurrencyCode = data.feeCurrency
      ? data.feeCurrency.code
      : data.loan.currencyCode
    data.settlementDate = toDateString(data.settlementDate)
    if (data.propertiesUpdated) {
      data.propertiesUpdated = renameArrayItems(data.propertiesUpdated, {
        feeCurrency: 'feeCurrencyCode',
      })
    }
    return new ReturnNegotiationItem(data)
  }

  static fromTable (data: any): ReturnNegotiationItem {
    return new ReturnNegotiationItem(data)
  }

  static fromLoan (loan: LoanItem): ReturnNegotiationItem {
    const data = {
      feeCurrencyCode: loan.currencyCode,
      quantity: loan.quantity,
      loanQuantity: loan.quantity,
      loan: loan,
    }
    return new ReturnNegotiationItem(data)
  }

  toServer (create: boolean = false, lookups: Lookups): any {
    const { getCurrency } = lookups
    const currency = getCurrency(this.feeCurrencyCode)
    const data: any = {
      reference: this.loan.reference, // used only for error handling
      quantity: this.quantity,
      fee: this.fee,
      settlementDate: toDateIso(this.settlementDate, true),
      feeCurrencyId: currency
        ? currency.id
        : undefined
    }
    if (create) {
      data.loanId = this.loan.id
    } else {
      data.id = this.id
    }
    return data
  }
}
