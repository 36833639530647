import { toDateIso, toDateString, today } from '@/core/utils/date'
import { Lookups } from 'modules/global'
import { LoanItem } from '@/modules/loans'
import { AbstractItem, NegotiationItemState, RecallNegotiationItem } from '..'

export default class RerateNegotiationItem extends AbstractItem {

  public readonly type: string = 'rerate'

  public rate: number

  public loanRate: number

  public effectiveFromDate: string

  public loan: LoanItem

  public state: NegotiationItemState

  public propertiesUpdated: Array<string>

  public get oldRate (): number | undefined {
    return this.loan
      ? this.loan.rate
      : undefined
  }

  constructor (data: any = {}) {
    super(data)
    this.rate = data.rate
    this.loanRate = data.loanRate
    this.effectiveFromDate = data.effectiveFromDate
    this.loan = data.loan
    this.state = data.state
    this.propertiesUpdated = data.propertiesUpdated
  }

  static fromServer (data: any = {}): RerateNegotiationItem {
    data.loan = LoanItem.fromServer(data.loan)
    data.loanRate = data.previousLoanRate
    data.effectiveFromDate = data.effectiveFromDate
      ? toDateString(data.effectiveFromDate)
      : undefined
    return new RerateNegotiationItem(data)
  }

  static fromTable (data: any): RerateNegotiationItem {
    return new RerateNegotiationItem(data)
  }

  static fromLoan (loan: LoanItem): RerateNegotiationItem {
    const data = {
      rate: loan.rate,
      loanRate: loan.rate,
      effectiveFromDate: toDateString(today()),
      loan: loan,
    }
    return new RerateNegotiationItem(data)
  }

  static fromRecall (recall: RecallNegotiationItem): RerateNegotiationItem {
    const rerate: RerateNegotiationItem = RerateNegotiationItem.fromLoan(recall.loan)
    rerate.loan.quantity = recall.quantity
    return rerate
  }

  toServer (create?: boolean, lookups?: Lookups): any {
    const data: any = {
      reference: this.loan.reference, // used only for error handling
      rate: this.rate,
      effectiveFromDate: toDateIso(this.effectiveFromDate, true),
    }
    if (create) {
      data.loanId = this.loan.id
    } else {
      data.id = this.id
    }
    return data
  }
}
