export default class TickerFilters {

  securityIds: []

  loanValue: number
  loanValueComparison: string

  loanRate: number
  loanRateComparison: string

  loanCurrency: number

  specialSecurities: boolean

  constructor (data: any = {}) {
    this.securityIds = data.securityIds || []
    this.loanValue = data.loanValue
    this.loanRate = data.loanRate
    this.loanRateComparison = data.loanRateComparison
    this.loanValueComparison = data.loanValueComparison
    this.loanCurrency = data.loanCurrency
    this.specialSecurities = data.specialSecurities
  }

  static fromServer (data) {
    return new TickerFilters(data)
  }
}
