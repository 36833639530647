<template>
  <div class="ui uiFormItem UiRange" :class="`uiRange--${size}`">
    <label class="uiLabel" v-if="label">{{ label }}</label>
    <div class="flex">
      <el-input
        v-if="type === 'number'"
        v-model.number="minModel"
        type="number"
        :size="size"
        :min="min"
        :max="minMax"
        :placeholder="minPlaceholder"
        v-bind="$attrs"
        @change="onMinChange"
      >
        <span v-if="prefix" slot="prefix" style="padding-left: 8px">{{ prefix }}</span>
        <span v-if="suffix" slot="suffix" style="padding-right: 5px">{{ suffix }}</span>
      </el-input>
      <el-date-picker
        v-else
        v-model="minModel"
        type="date"
        :size="size"
        :placeholder="minPlaceholder"
        :picker-options="minDateOptions"
        v-bind="$attrs"
      />

      &nbsp; <span class="uiRange__separator">{{ rangeSeparator }}</span>

      <el-input
        v-if="type === 'number'"
        v-model.number="maxModel"
        type="number"
        :size="size"
        :min="maxMin"
        :max="max"
        :placeholder="maxPlaceholder"
        v-bind="$attrs"
        @change="onMaxChange"
      >
        <span v-if="prefix" slot="prefix" style="padding-left: 8px">{{ prefix }}</span>
        <span v-if="suffix" slot="suffix" style="padding-right: 5px">{{ suffix }}</span>
      </el-input>
      <el-date-picker
        v-else
        v-model="maxModel"
        type="date"
        :size="size"
        :placeholder="maxPlaceholder"
        :picker-options="maxDateOptions"
        v-bind="$attrs"
      />
    </div>
  </div>

</template>

<script>
export default {

  inheritAttrs: false,

  props: {
    type: {
      type: String,
      default: 'number',
      validator (value) {
        return ['number', 'date'].includes(value)
      }
    },

    label: {
      type: String,
    },

    value: {
      type: Array,
      default () {
        return []
      }
    },

    minValue: {
      type: [Number, Date],
      default () {
        return this.value[0]
      }
    },

    maxValue: {
      type: [Number, Date],
      default () {
        return this.value[1]
      }
    },

    min: {
      type: [Number, Date],
      default () {
        return this.type === 'number'
          ? 0
          : undefined
      }
    },

    max: {
      type: [Number, Date],
      default () {
        return this.type === 'number'
          ? undefined
          : undefined
      }
    },

    minPlaceholder: {
      type: String,
      default () {
        return this.type === 'number'
          ? 'Min value'
          : 'Start date'
      }
    },

    maxPlaceholder: {
      type: String,
      default () {
        return this.type === 'number'
          ? 'Max value'
          : 'End date'
      }
    },

    precision: {
      type: Number,
      default: 1
    },

    prefix: String,

    suffix: String,

    size: {
      type: String,
      default: 'mini',
      validator (value) {
        return ['large', 'default', 'small', 'mini'].includes(value)
      }
    },

    rangeSeparator: {
      type: String,
      default: 'to'
    },
  },

  data () {
    return {
      minInput: null,
      maxInput: null,
    }
  },

  computed: {
    minModel: {
      get () { return this.minValue },
      set (value) {
        value = value || undefined
        this.$emit('update:minValue', value)
        this.$emit('input', [value, this.maxValue])
      }
    },

    maxModel: {
      get () { return this.maxValue },
      set (value) {
        value = value || undefined
        this.$emit('update:maxValue', value)
        this.$emit('input', [this.minValue, value])
      }
    },

    minMax () {
      if (this.maxModel !== void 0) {
        return this.maxModel - this.precision
      }
    },

    maxMin () {
      if (this.minModel !== void 0) {
        return this.minModel
      }
    },

    minDateOptions () {
      return {
        disabledDate: (time) => {
          return this.maxValue !== void 0
            ? time.getTime() > this.maxValue
            : false
        }
      }
    },

    maxDateOptions () {
      return {
        disabledDate: (time) => {
          return this.minValue !== void 0
            ? time.getTime() < this.minValue
            : false
        }
      }
    }
  },

  methods: {
    onMinChange (value) {
      if (value !== '') {
        value = parseFloat(value)
        if (value < this.min) {
          this.minModel = this.min
        }
        else if (value > this.maxModel) {
          this.minModel = this.maxModel
        }
      }
    },

    onMaxChange (value) {
      if (value !== '') {
        value = parseFloat(value)
        if (value < this.minModel) {
          this.maxModel = this.minModel
        }
        else if (value > this.max) {
          this.maxModel = this.max
        }
      }
    }
  }
}
</script>

<style>
.uiRange__separator {
  flex-grow: 0;
  padding: 0 5px;
  font-size: 0.7rem;
  vertical-align: middle;
}
</style>
