import Vue from 'vue'
import { clamp } from 'utils/number'
import store from 'state'

function sortTop (a, b) {
  return a.$el.offsetTop > b.$el.offsetTop ? 1 : -1
}

export default new Vue ({
  data () {
    return {
      matrix: null,
      x: 0,
      y: 0,
    }
  },

  computed: {
    search () {
      return store.state.dashboard.search
    },

    refs () {
      // trigger reactivity, even though not using search
      const search = this.search
      const $refs = this.matrix.$refs
      return [
        $refs.locates && $refs.locates.$refs.card,
        $refs.requested.$refs.card,
        $refs.negotiating.$refs.card,
        $refs.completed.$refs.card,
      ]
        .filter(items => items && items.length > 0)
        .map(items => items.sort(sortTop))
    },
  },

  methods: {
    setMatrix (matrix) {
      this.matrix = matrix
    },

    focusFirst () {
      const ref = this.getRef(0, 0)
      if (ref) {
        ref.$el.focus()
      }
    },

    getRef (x = 0, y = 0) {
      const refs = this.refs
      if (refs.length) {
        return refs[x] && refs[x][y]
      }
    },

    getRefs () {
      return this.refs.reduce((output, items) => {
        output.push(...items)
        return output
      }, [])
    },

    onFocus(ref) {
      for (let x = 0; x < this.refs.length; x++) {
        for (let y = 0; y < this.refs[x].length; y++) {
          if (ref === this.refs[x][y]) {
            this.x = x
            this.y = y
            return
          }
        }
      }
    },

    onKeyDown (event) {
      const key = event.key
      const modifiers = event.metaKey || event.ctrlKey || event.altKey
      if (!modifiers && key.startsWith('Arrow')) {
        event.preventDefault()
        let { x, y, refs } = this
        if (key === 'ArrowLeft') x--
        if (key === 'ArrowRight') x++
        if (key === 'ArrowUp') y--
        if (key === 'ArrowDown') y++
        x = clamp(x, 0, refs.length - 1)
        y = clamp(y, 0, refs[x].length - 1)
        refs[x][y].$el.focus()
        this.x = x
        this.y = y
      }
    }
  }
})
