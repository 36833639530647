/**
 * Factory function to creates a Vue component which hosts a custom table component
 */
export default function () {
  return {
    data () {
      return {
        numUpdates: 0,

        items: {
          // table input data
          data: [],

          // table output is different to table input
          isUpdated: false,

          // last table edit validates (validated in table)
          isValid: true,

          // all items validate (validated in parent)
          isComplete: false,
        },
      }
    },

    mounted () {
      // check for table
      if (!this.$refs.table) {
        throw new Error('This component does not have a table reference!')
      }

      // handler for table updates
      this.$refs.table.$on('update', (isUpdated, isValid = true) => {
        this.items.isUpdated = isUpdated
        this.items.isValid = isValid
        this.numUpdates++
        this.onItemsUpdate()
      })
    },

    methods: {
      getItems () {
        return this.$refs.table.getData()
      },

      setItems (items, warn = false) {
        if (items.length === 0) {
          this.$alert('There was a problem loading the source items')
        }
        this.items.data = items
        return this.$refs.table.loadData(items)
      },

      resetItems () {
        this.$refs.table.reset()
      },

      validateItems () {
        return this.$refs.table
          .validate()
          .then(errors => {
            if (errors.length) {
              this.items.isValid = false
            }
            return errors
          })
      },

      onItemsUpdate () {
        // override in child
      }
    }
  }
}
