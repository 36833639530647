import {
  AuthenticationResult,
  BrowserAuthError,
  Configuration,
  InteractionRequiredAuthError,
  PopupRequest,
  PublicClientApplication,
  SilentRequest,
  AccountInfo,
} from '@azure/msal-browser'

/**
 * Type alias for AccountInfo | null
 */
export type MaybeAccount = AccountInfo | null

/**
 * Core MSAL service
 *
 * Creates, configures and exposes a popup-oriented MSAL instance
 */
export function makeMsalService (config: Configuration, scopes: string[]) {
  // msal
  const msal: PublicClientApplication = new PublicClientApplication(config)

  const setAccount = (account: MaybeAccount): MaybeAccount => {
    msal.setActiveAccount(account)
    return account
  }

  /**
   * Initialize and return active account
   */
  async function initialize (): Promise<MaybeAccount> {
    // start msal
    await msal.handleRedirectPromise()

    // grab and set account if in session
    const accounts = msal.getAllAccounts()
    if (accounts && accounts.length) {
      setAccount(accounts[0])
    }

    // return any active account
    return msal.getActiveAccount()
  }

  /**
   * Login
   */
  async function login (): Promise<MaybeAccount> {
    const request: PopupRequest = {
      redirectUri: config.auth.redirectUri,
      scopes,
    }
    return msal
      .loginPopup(request)
      .then(result => {
        // could do something with the AuthResult here if you need to
        console.log('Logged in with', result)

        // set active account
        return setAccount(result.account)
      })
      .catch((error: BrowserAuthError) => {
        // if we get stuck, clear session and attempt to log in again
        if (error.errorCode === 'interaction_in_progress') {
          reset()
          return login()
        }
        throw(new Error(error.errorMessage))
      })
  }

  /**
   * Logout
   */
  async function logout () {
    return msal.logoutPopup({
      mainWindowRedirectUri: config.auth.postLogoutRedirectUri || `/?logout=${Date.now()}`
    })
  }

  /**
   * Get access token
   */
  async function getToken () {
    const request: SilentRequest = {
      scopes
    }
    return msal
      // try getting the token silently
      .acquireTokenSilent(request)

      // attempt login popup if this fails
      .catch(async (error: unknown) => {
        if (error instanceof InteractionRequiredAuthError) {
          return msal.acquireTokenPopup(request)
        }
        throw error
      })
      .then((result: AuthenticationResult) => {
        return result.accessToken
      })
  }

  /**
   * Escape hatch when msal gets stuck
   */
  function reset () {
    sessionStorage.clear()
  }

  return {
    msal,
    initialize,
    getToken,
    login,
    logout,
    reset,
  }
}
