import {
  Columns,
  makeCurrencyPicker,
  makeQuantity,
  makeQuantityEditor,
  makeNetDiv,
  makeTable,
  makeSecurity,
  makeRelatedRates,
} from 'vendor/handsontable'
import { LoanNegotiationItem } from '../../../negotiations'
import LoanNegotiationValidator from 'modules/negotiations/validators/LoanNegotiationValidator'

function settings (vm) {
  const { role, action, locked } = vm

  const validator = new LoanNegotiationValidator()

  // NOTE an enum would be better, but string props are clearer in components
  const isBorrower = role === 'borrower'

  const columns = [
    makeSecurity(!isBorrower),
    Columns.value,
    action === 'edit'
      ? makeQuantity('Quantity Required', 'quantityRequired')
      : null,
    makeQuantityEditor('Quantity', 'quantity', isBorrower),
    Columns.rate,
    makeCurrencyPicker('Loan Ccy', 'loanCurrencyCode'),
    isBorrower
      ? Columns.settlementDateEditor
      : Columns.settlementDate,
    isBorrower
      ? Columns.termEndDateEditor
      : Columns.termEndDate,
    Columns.collateral,
    action === 'edit'
      ? makeNetDiv(role === 'lender')
      : null,
    action === 'edit'
      ? role === 'lender'
        ? Columns.fundPicker
        : Columns.fund
      : null,
  ]

  if (!locked) {
    columns.push(...makeRelatedRates(true))
  }

  return {
    columns,
    validator,
    schemaClass: LoanNegotiationItem,
    minSpareRows: 0,
  }
}

export default makeTable(settings)
