import { Resource } from 'core/models'
import { Organization } from 'modules/organizations'
import { getResourceTypeLabel, getResourceTypeString } from 'modules/global/enums/ResourceType'
import { ResourceType } from 'modules/global'
import { NotificationReason, NotificationState } from '../'

export default class NotificationItem extends Resource {

  public itemId: string

  public type: ResourceType

  public get typeString (): string {
    return getResourceTypeString(this.type)
  }

  public get typeLabel (): string {
    return getResourceTypeLabel(this.type)
  }

  public state: NotificationState

  public reason: NotificationReason

  public fromOrganization: Organization

  public toOrganization: Organization

  public fromUserName: string

  public seenByUserName: string

  public seenAt: Date | undefined

  public createdAt: Date

  public get isDeleted () {
    return this.reason === NotificationReason.deleted
  }

  constructor (data: any = {}) {
    super(data)
    Object.assign(this, data)
  }

  static fromServer (data) {
    data.fromOrganization = Organization.fromServer(data.fromOrganization)
    data.toOrganization = Organization.fromServer(data.toOrganization)
    data.createdAt = new Date(data.createdAt)
    data.seenAt = data.seenAt
      ? new Date(data.seenAt)
      : undefined
    return new NotificationItem(data)
  }
}
