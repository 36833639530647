var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    {
      staticClass: "dashboard__card dashboard__card--locate",
      attrs: {
        tabindex: "0",
        "data-type": "locate",
        "data-updated": _vm.isUpdated
      },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.navigate($event)
        },
        focus: _vm.onFocus
      }
    },
    [
      _c("div", { staticClass: "dashboard__cardMain" }, [
        _vm._m(0),
        _c("div", { staticClass: "dashboard__cardTitle" }, [
          _c(
            "label",
            {
              staticClass: "link",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.toggleFilter(_vm.item.name)
                }
              }
            },
            [_vm._v(_vm._s(_vm.item.name))]
          )
        ]),
        _c("div", { staticClass: "dashboard__cardInfo" }, [
          _c(
            "p",
            [
              _c("label", [_vm._v("Value located: ")]),
              _c("PercentTag", {
                attrs: { value: _vm.item.valuePercentageLocated }
              })
            ],
            1
          ),
          _c("p", [
            _c("label", [_vm._v("Value:")]),
            _c("output", [
              _c("span", { staticClass: "symbol" }, [
                _vm._v(_vm._s(_vm.currencyCode))
              ]),
              _vm._v(" " + _vm._s(_vm._f("integer")(_vm.item.value)))
            ])
          ]),
          _c("p", [
            _c("label", [_vm._v("Created:")]),
            _c("output", [
              _vm._v(_vm._s(_vm._f("dateRelative")(_vm.createdAt)))
            ])
          ]),
          _c("p", [
            _c("label", [_vm._v("Securities:")]),
            _c("output", [_vm._v(_vm._s(_vm.item.itemCount))])
          ]),
          _vm.numNegotiations
            ? _c("p", [
                _c("label", [_vm._v("Negotiations:")]),
                _c("output", [_vm._v(_vm._s(_vm.numNegotiations))])
              ])
            : _vm._e()
        ])
      ]),
      _c(
        "div",
        { staticClass: "dashboard__cardActions" },
        [
          _c("CardButton", { attrs: { to: _vm.route } }, [_vm._v("Edit")]),
          _c(
            "CardButton",
            {
              attrs: {
                to:
                  "/negotiations/loans/create?source=locate&id=" + _vm.item.id,
                disabled: _vm.isDisabled
              }
            },
            [_vm._v("Negotiate\n    ")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dashboard__cardType" }, [
      _c("span", [_vm._v("locate")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }