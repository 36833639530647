var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout__index" },
    [
      _c("h1", [
        _vm._v(_vm._s(_vm._f("capitalize")(_vm.action)) + " Organisation")
      ]),
      _c("UiBreadcrumbs"),
      _c(
        "div",
        { staticClass: "layout__main", attrs: { id: "edit-organization" } },
        [
          _c(
            "div",
            { staticClass: "layout__content" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "panel" }, [
                      _c("h2", [_vm._v("Sections")]),
                      _c("ul", [
                        _c("li", [
                          _c("a", { attrs: { href: "#company" } }, [
                            _vm._v("Company")
                          ])
                        ]),
                        _c("li", [
                          _c("a", { attrs: { href: "#contact" } }, [
                            _vm._v("Contact")
                          ])
                        ]),
                        _c("li", [
                          _c("a", { attrs: { href: "#trading" } }, [
                            _vm._v("Trading")
                          ])
                        ]),
                        _c("li", [
                          _c("a", { attrs: { href: "#regulation" } }, [
                            _vm._v("Regulation")
                          ])
                        ]),
                        _c("li", [
                          _c("a", { attrs: { href: "#notice" } }, [
                            _vm._v("Notice")
                          ])
                        ]),
                        _c("li", [
                          _c("a", { attrs: { href: "#agent" } }, [
                            _vm._v("Agent")
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "max-width": "800px" },
                      attrs: { span: 18 }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "panel" },
                        [
                          _c("h2", [_vm._v("Details")]),
                          _c("OrganizationForm", {
                            attrs: { action: _vm.action }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _c("el-col", { attrs: { span: 6 } })
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }