<template>
  <aside class="layout__aside">
    <UiSticky>
      <h2 v-if="label">{{ label }}</h2>
      <slot></slot>
    </UiSticky>
  </aside>
</template>

<script>
export default {
  props: {
    label: String
  }
}
</script>
