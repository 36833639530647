// library
import 'handsontable/dist/handsontable.full.css'
import Handsontable from 'handsontable'

// styles
import './styles/handsontable.scss'
import './styles/pikadate.scss'

// grid
import SelectEditor from './editors/select/SelectEditor'
import CheckboxesEditor from './editors/checkboxes/CheckboxesEditor'
import NumericEditor from './editors/NumericEditor'
import selectRenderer from './renderers/selectRenderer'
import valueRenderer from './renderers/valueRenderer'
import enumRenderer from './renderers/enumRenderer'

// editors
Handsontable.editors.registerEditor('select', SelectEditor)
Handsontable.editors.registerEditor('checkboxes', CheckboxesEditor)
Handsontable.editors.registerEditor('numeric', NumericEditor)

// renderers
Handsontable.renderers.registerRenderer('select', selectRenderer)
Handsontable.renderers.registerRenderer('value', valueRenderer)
Handsontable.renderers.registerRenderer('enum', enumRenderer)

// factories
export * from './columns/column-utils'
export * from './columns/column-factory'
import * as Columns from './columns/column-presets.js'
export makeTable from './components/table-factory'
export makeTableParent from './components/table-parent'

export {
  Columns
}
