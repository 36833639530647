import Handsontable from 'handsontable'

const NumericEditor = Handsontable.editors.TextEditor.prototype.extend()

NumericEditor.prototype.createElements = function () {
  Handsontable.editors.TextEditor.prototype.createElements.apply(this, arguments)
  this.TEXTAREA.onchange = (event => {
    const value = event.target.value.replace(/,/g, '').trim()
    this.hot.setDataAtCell(this.row, this.col, value || null)
  })
}

export default NumericEditor
