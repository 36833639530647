import { HttpTransportType, HubConnection, HubConnectionBuilder, LogLevel } from '@aspnet/signalr'
import { commit, dispatch } from 'vuex-pathify'
import store from '@/app/state'
import router from '@/app/router'
import ApiConfig from 'config/api'
import { Auth } from 'vendor/msal'
import { getResourceTypeSegment } from 'modules/global'
import { NotificationItem } from 'modules/notifications'
import { canBorrow } from 'modules/organizations'
import ResourceType from 'modules/global/enums/ResourceType'

let connection: HubConnection

function connect () {
  // create connection
  connection = new HubConnectionBuilder()
    .withUrl(ApiConfig.root + 'broadcast-hub', {
        accessTokenFactory: Auth.getToken,
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      }
    )
    .configureLogging(LogLevel.Error)
    .build()

  // error handler
  connection.onclose(() => {
    log('connection lost!')
    return start(true)
  })
}

function setup () {
  // custom notifications
  connection.on('Notification', data => {
    // variables
    const item: NotificationItem = NotificationItem.fromServer(data)
    const type: string = ResourceType[item.type]
    const id: string = item.itemId

    // update log
    commit('notifications/addItem', item)

    // update correct notification array
    const userName = (store as any).get('user.name')
    if (item.fromUserName === userName) {
      // don't add deleted items
      if (!item.isDeleted) {
        commit('notifications/addUpdated', item)
      }
    } else {
      commit('notifications/addUnseen', item)
    }

    // reload data for items
    switch (item.type) {
      case ResourceType.AvailabilityList:
      case ResourceType.LongPositions:
        return

      case ResourceType.LocateList:
        if (!item.isDeleted) {
          return dispatch('locateLists/loadOne', id)
        }
        break
      case ResourceType.LoanNegotiation:
      case ResourceType.RecallNegotiation:
      case ResourceType.RerateNegotiation:
      case ResourceType.ReturnNegotiation:
        // update item if currently loaded

        // variables
        const segment: String = getResourceTypeSegment(item.type)
        const segments: Record<string, string> = {
          loan: 'loans',
          recall: 'recalls',
          rerate: 'rerates',
          return: 'returns',
        }
        const path = `/negotiations/${segments[segment as string]}/edit/${id}`

        // if this item is currently loaded
        if (router.currentRoute.path === path) {
          // if the party which triggered this change is not the current user
          if (item.fromOrganization.id !== (store.state as any).organizations.current.id && item.fromUserName !== (store.state as any).user.name) {
            commit(`negotiations/${segment}/isStale`, true)
          }
        }

        // update dashboard
        return dispatch('dashboard/loadOne', { segment, id })

      case ResourceType.Loan:
      case ResourceType.Rerate:
      case ResourceType.Recall:
      case ResourceType.Return:
        // showing same loan details page
        if (router.currentRoute.path === `/loans/view/${id}`) {
          return dispatch('loan/load', id)
        }

        if (router.currentRoute.path === `/loans`) {
          return dispatch('loans/load', id)
        }

        return

      default:
        console.error('Unknown notification type', item.type)
    }
  })

  connection.on('Ticker', data => {
    return dispatch('ticker/addItem', data, { root: true })
  })
}

function load (full) {
  // just notifications
  const promises = [
    dispatch('notifications/loadUnseen'),
    dispatch('notifications/load'),
  ]

  // everything
  if (full) {
    promises.push(dispatch('dashboard/load'))
    if (canBorrow()) {
      promises.push(dispatch('locateLists/load'))
    }
  }

  // do it
  return Promise.all(promises)
}

function log (...args) {
  console.log('Signalr:', ...args)
}

function start (full = false) {
  log('connecting...')
  try {
    return connection.start()
      .then(() => {
        log('connected!')
        commit('notifications/isConnected', true)
        return load(full)
      })
      .catch(onConnectionError)
  } catch (err) {
    onConnectionError()
  }
}

let timerId

function restart () {
  clearTimeout(timerId)
  timerId = setTimeout(() => {
    return start(true)
  }, 5000)
}

function onConnectionError () {
  log('connection error!')
  commit('notifications/isConnected', false)
  restart()
}

export default {
  init () {
    connect()
    setup()
    return start()
  },

  start
}
