var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout__index", attrs: { id: "page-availability-list" } },
    [
      _c("h1", [_vm._v("Reports")]),
      _c("UiBreadcrumbs"),
      _c(
        "div",
        {
          staticClass: "layout__main",
          attrs: { id: "edit-availability-list" }
        },
        [
          _c(
            "section",
            { staticClass: "layout__content panel" },
            [
              _c("h2", [_vm._v("Reports")]),
              _vm._l(_vm.reports, function(report) {
                return _c(
                  "UiOutput",
                  {
                    key: report.name,
                    attrs: {
                      type: "text",
                      icon: "file-alt",
                      label: report.description
                    }
                  },
                  [
                    _c(
                      "a",
                      {
                        attrs: { href: "/reports/" + report.id },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.download(report)
                          }
                        }
                      },
                      [_vm._v(_vm._s(report.name))]
                    )
                  ]
                )
              })
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }