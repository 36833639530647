import Api from 'services/Api'
import { User } from 'modules/organizations'

const state: User = new User()

const actions = {
  load ({ commit }) {
    return Api.get(`users/current`)
      .then(res => {
        commit('user', User.fromServer(res.data))
      })
  },

  logout () {
    return Api.delete(`users/current/session/token`)
  }
}

const mutations = {
  user (state: User, user: User) {
    Object.assign(state, user)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}

export function getUserName (name: string) {
  return state.name === name
    ? 'you'
    : name
}
