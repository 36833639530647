var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout__index", attrs: { id: "page-loans" } },
    [_c("h1", [_vm._v("Loans")]), _c("UiBreadcrumbs"), _c("router-view")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }