import { Security } from 'modules/securities'
import { Organization } from 'modules/organizations'
import { Collateral, Currency, Fund } from 'modules/resources'
import AbstractLoan from './AbstractLoan'

/**
 * Loan class
 *
 * Loan is the fully-expanded version of a LoanItem
 *
 * It has no "active loan events" which we only show in the table views via LoanItems
 */
export default class Loan extends AbstractLoan {

  public hash: string

  public security: Security

  public currency: Currency

  public collateralBasket: Collateral

  public lenderOrganization: Organization

  public borrowerOrganization: Organization

  public fund: Fund

  constructor (data: any = {}) {
    super(data)
    this.hash = data.hash
    this.security = data.security
    this.currency = data.currency
    this.collateralBasket = data.collateralBasket
    this.lenderOrganization = data.lenderOrganization
    this.borrowerOrganization = data.borrowerOrganization
    this.fund = data.fund
  }

  static fromServer (data: Record<string, any>): Loan {
    data = AbstractLoan.fromServer(data)
    data.security = Security.fromServer(data.security)
    data.currency = Currency.fromServer(data.currency)
    data.collateralBasket = Collateral.fromServer(data.collateralBasket)
    data.lenderOrganization = Organization.fromServer(data.lenderOrganization)
    data.borrowerOrganization = Organization.fromServer(data.borrowerOrganization)
    data.fund = Fund.fromServer(data.fund)
    return new Loan(data)
  }

}
