var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout__main", attrs: { id: "edit-rerate" } },
    [
      _vm.hasStaleWarning
        ? _c(
            "SiteAside",
            [
              _c("NegotiationConflict", {
                attrs: { counterParty: _vm.counterParty },
                on: { reload: _vm.reload }
              })
            ],
            1
          )
        : _c(
            "SiteAside",
            { attrs: { label: "Options" } },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { model: _vm.form, "label-position": "top" },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c("NegotiationMessage", {
                    attrs: { message: _vm.lastMessage }
                  }),
                  _vm.state === _vm.NegotiationState.open
                    ? _c(
                        "UiFieldset",
                        { attrs: { label: "Response" } },
                        [
                          _c(
                            "NegotiationResponse",
                            _vm._b(
                              {
                                model: {
                                  value: _vm.form.response,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "response", $$v)
                                  },
                                  expression: "form.response"
                                }
                              },
                              "NegotiationResponse",
                              _vm.responseData,
                              false
                            )
                          ),
                          _c("NegotiationComment", {
                            model: {
                              value: _vm.form.comment,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "comment", $$v)
                              },
                              expression: "form.comment"
                            }
                          }),
                          _c(
                            "UiSubmit",
                            _vm._g(
                              _vm._b({}, "UiSubmit", _vm.submitData, false),
                              _vm.submitData
                            )
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm.canReturn
                ? _c("UiFieldset", { attrs: { label: "Further Actions" } }, [
                    _c(
                      "div",
                      { staticClass: "flex vertical" },
                      [
                        _c(
                          "router-button",
                          {
                            attrs: {
                              to:
                                "/negotiations/locates/create?source=rerate&id=" +
                                _vm.$route.params.id
                            }
                          },
                          [_vm._v("Locate securities")]
                        ),
                        _c(
                          "router-button",
                          {
                            attrs: {
                              to:
                                "/negotiations/returns/create?source=rerate&id=" +
                                _vm.$route.params.id
                            }
                          },
                          [_vm._v("Return securities")]
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _vm.canRecall
                ? _c("UiFieldset", { attrs: { label: "Further Actions" } }, [
                    _c(
                      "div",
                      { staticClass: "flex vertical" },
                      [
                        _c(
                          "router-button",
                          {
                            attrs: {
                              to:
                                "/negotiations/recalls/create?source=rerate&id=" +
                                _vm.$route.params.id
                            }
                          },
                          [_vm._v("Recall securities")]
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _c(
                "el-form",
                [
                  _c(
                    "UiFieldset",
                    { attrs: { label: "Notes" } },
                    [
                      _c("NegotiationNotes", {
                        attrs: { type: "rerate", autosave: "" },
                        model: {
                          value: _vm.privateNotes,
                          callback: function($$v) {
                            _vm.privateNotes = $$v
                          },
                          expression: "privateNotes"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("NegotiationHistory", {
                attrs: {
                  messages: _vm.messages,
                  open: _vm.isHistoryVisible,
                  canToggle: _vm.isOpen
                },
                on: { toggle: _vm.toggleHistory },
                model: {
                  value: _vm.messageIndex,
                  callback: function($$v) {
                    _vm.messageIndex = $$v
                  },
                  expression: "messageIndex"
                }
              })
            ],
            1
          ),
      _c(
        "section",
        { staticClass: "layout__content panel" },
        [
          _c(
            "NegotiationSummary",
            _vm._b({}, "NegotiationSummary", _vm.summaryData, false),
            [
              _c(
                "UiOutput",
                { staticClass: "capitalize", attrs: { label: "Originator" } },
                [_vm._v(_vm._s(_vm.loanRole))]
              )
            ],
            1
          ),
          _c("UiErrors"),
          _c("RerateItemsTable", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.canShowLast,
                expression: "canShowLast"
              }
            ],
            ref: "table",
            attrs: { data: _vm.items.data, role: _vm.role, locked: !_vm.isOpen }
          }),
          _c("RerateItemsTable", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.canShowPrevious,
                expression: "canShowPrevious"
              }
            ],
            attrs: { data: _vm.previous.data, role: _vm.role, locked: true }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }