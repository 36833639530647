var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ui uiFormItem uiInput" },
    [
      _c("label", { staticClass: "uiLabel" }, [_vm._v(_vm._s(_vm.label))]),
      _c(
        "el-input",
        _vm._g(
          _vm._b(
            {
              ref: "input",
              attrs: { size: _vm.size },
              model: {
                value: _vm.model,
                callback: function($$v) {
                  _vm.model = $$v
                },
                expression: "model"
              }
            },
            "el-input",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }