var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ui uiFormItem uiOutput",
      attrs: { "data-type": _vm.type, "data-icon": _vm.icon }
    },
    [
      _vm.type === "form"
        ? [
            _c("label", { staticClass: "uiLabel" }, [
              _vm._v(_vm._s(_vm.label) + ":")
            ]),
            _c("output", [_vm._t("default"), _vm._v(" ")], 2)
          ]
        : [
            _vm.icon
              ? _c("span", { staticClass: "uiContent__icon" }, [
                  _c("i", { staticClass: "far", class: "fa-" + _vm.icon })
                ])
              : _vm._e(),
            _c("span", { staticClass: "uiOutput__content" }, [
              _c("output", [_vm._t("default"), _vm._v(" ")], 2),
              _c("label", { staticClass: "uiLabel" }, [
                _vm._v(_vm._s(_vm.label))
              ])
            ])
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }