<template>

  <div class="dashboardFilters">
    <el-input
      ref="search"
      v-model="search"
      @keyup.native.esc.prevent="clearSearch"
      @keyup.native.enter.prevent="onEnter"
      @keydown.native.tab="onTab"
      placeholder="Search..."
      clearable
      class="dashboardFilters__search"
    />

    <el-select
      v-model="filters"
      multiple
      collapse-tags
      clearable
      :disabled="!!search"
      placeholder="Filter..."
      class="dashboardFilters__filter"
    >
      <el-option
        v-for="(label, type) in options"
        :key="type"
        :label="getLabel(type)"
        :value="type">
      </el-option>
    </el-select>

    <el-button type="plain"
               class="dashboardFilters__clear shrink is-plain button-square"
               :disabled="!(search || filters.length)"
               @click="clear"
    >&times;</el-button>
  </div>

</template>

<script>
import { sync } from 'vuex-pathify'

const options = {
  loan: 'Loan',
  rerate: 'Rerate',
  return: 'Return',
  recall: 'Recall',
}

export default {
  data () {
    return {
      options,
    }
  },

  computed: {
    filters: sync('dashboard/filters'),
    search: sync('dashboard/search'),
  },

  mounted () {
    this.focusSearch()
  },

  methods: {
    getLabel (type) {
      const count = this.$store.state.dashboard.items.filter(item => item.type === type).length
      const label = options[type]
      return `${label} (${count})`
    },

    focusSearch () {
      this.$refs.search.focus()
    },

    clearSearch () {
      this.search = ''
      this.focusSearch()
    },

    clear () {
      this.filters = []
      this.clearSearch()
    },

    onEnter () {
      this.$emit('search-enter')
    },

    onTab (event) {
      if (this.search !== '') {
        event.preventDefault()
        this.$emit('search-tab')
      }
    }
  }
}

</script>

<style lang="scss">
.dashboardFilters {
  display: flex;

  > * {
    margin-left: .5rem;
  }

  &__search {
    width: 180px;
  }

  &__filter {
    width: 150px;
  }

}
</style>
