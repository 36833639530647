var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "profile__marginRequirements panel" },
    [
      _c("h2", [_vm._v("Margin Requirements")]),
      _c("UiErrors", { attrs: { errors: _vm.errors } }),
      _c(
        "ul",
        { staticClass: "profile__list" },
        [
          _vm._m(0),
          _vm._l(_vm.form.data, function(item) {
            return _vm.canBorrow(item)
              ? _c("li", { key: item.id }, [
                  _c("label", [
                    _c("span", { staticClass: "text" }, [
                      _vm._v(_vm._s(item.counterParty.name))
                    ])
                  ]),
                  _c("output", { staticClass: "mb-2" }, [
                    _c("div", { staticClass: "flex" }, [
                      _c(
                        "div",
                        [
                          _c("el-input", {
                            attrs: {
                              type: "number",
                              min: 1,
                              max: 150,
                              size: "mini"
                            },
                            model: {
                              value: item.margin,
                              callback: function($$v) {
                                _vm.$set(item, "margin", _vm._n($$v))
                              },
                              expression: "item.margin"
                            }
                          }),
                          _c("span", { staticClass: "symbol" }, [_vm._v("%")]),
                          _c(
                            "a",
                            {
                              staticClass: "ml-2 link",
                              staticStyle: { "font-size": ".85em" },
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  item.margin = null
                                }
                              }
                            },
                            [_vm._v("Clear")]
                          )
                        ],
                        1
                      )
                    ])
                  ])
                ])
              : _vm._e()
          })
        ],
        2
      ),
      _c("SaveChanges", { on: { save: _vm.save } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "header" }, [
      _c("label", [
        _c("span", { staticClass: "text" }, [_vm._v("Counterparty:")])
      ]),
      _c("output", [
        _c("span", { staticClass: "text" }, [_vm._v("Requirement:")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }