import { install, register } from 'modules'
import { OrganizationType } from 'modules/organizations'

import borrower from './borrower/main'
import lender from './lender/main'
import system from './system/main'
import guest from './guest/main'

function setupResources (store) {
  install('resources')
  return Promise.all([
    store.dispatch('resources/load'),
  ])
}

function setupCore (store) {
  install('securities')
  install('notifications')
  install('ticker')
  return Promise.all([
    store.dispatch('notifications/init'),
    store.dispatch('ticker/load'),
    store.dispatch('user/load'),
  ])
}

function setupCommon (store) {
  install('trading-profile')
  install('negotiations')
  install('dashboard')
  install('loans')
  install('reports')
  return Promise.all([
    store.dispatch('tradingProfile/load'),
    store.dispatch('organizations/load'),
    store.dispatch('dashboard/load'),
  ])
}

function setupBorrower (store) {
  register(borrower)
  install('locate-lists')
  install('long-positions')
  return Promise.all([
    store.dispatch('locateLists/load')
  ])
}

function setupLender (store) {
  register(lender)
  install('availability-list')
  return Promise.all([])
}

function setupSystem (store) {
  register(system)
  install('errors')
  return Promise.resolve()
}

export function loadArea (store, organizationType) {
  return Promise.resolve()
    .then(() => setupCore(store))
    .then(() => setupCommon(store))
    .then(() => {
      switch (organizationType) {
        case OrganizationType.borrower:
          return setupBorrower(store)

        case OrganizationType.lender:
          return setupLender(store)

        case OrganizationType.borrowerAndLender:
          return setupLender(store).then(() => setupBorrower(store))

        default:
          throw new Error('The store does not contain a current organization')
      }
    })
}

export function initAreas (store) {
  return setupResources(store)
    .then(() => store.dispatch('organizations/init'))
    .then(organizationType => {
      if (organizationType === OrganizationType.system) {
        return setupSystem(store)
      }
      else {
        return loadArea(store, organizationType)
      }
    })
}

export default function () {
  register(guest)
  install('organizations')
  install('errors')
}
