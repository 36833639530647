import UiErrors from './components/UiErrors.vue'
import errors from './state/ErrorsStore'
import router from '@/app/router'
import { clearErrors } from '.'

router.afterEach(clearErrors)

export default {
  stores: {
    errors,
  },

  components: {
    UiErrors,
  }
}
