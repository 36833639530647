import { route } from 'router/helpers'
import loan from './state/LoanNegotiationStore'
import recall from './state/RecallNegotiationStore'
import rerate from './state/RerateNegotiationStore'
import returnStore from './state/ReturnNegotiationStore'

export default {
  stores: {
    negotiations: {
      namespaced: true,
      modules: {
        loan,
        recall,
        rerate,
        return: returnStore,
      }
    },
  },

  routes: [
    route('/negotiations', 'negotiations@Index', {
      children: [
        route('', 'dashboard@Dashboard.vue'),

        route('locates/create', 'locate-lists@EditLocateList.vue'),
        route('locates/edit/:id', 'locate-lists@EditLocateList.vue'),

        route('loans/create', 'negotiations@create/CreateLoanNegotiation.vue'),
        route('loans/edit/:id', 'negotiations@edit/EditLoanNegotiation.vue'),
        route('loans/view/:id', 'negotiations@edit/EditLoanNegotiation.vue'),

        route('rerates/create', 'negotiations@create/CreateRerateNegotiation.vue'),
        route('rerates/edit/:id', 'negotiations@edit/EditRerateNegotiation.vue'),
        route('rerates/view/:id', 'negotiations@edit/EditRerateNegotiation.vue'),

        route('recalls/create', 'negotiations@create/CreateRecallNegotiation.vue'),
        route('recalls/edit/:id', 'negotiations@edit/EditRecallNegotiation.vue'),
        route('recalls/view/:id', 'negotiations@edit/EditRecallNegotiation.vue'),

        route('returns/create', 'negotiations@create/CreateReturnNegotiation.vue'),
        route('returns/edit/:id', 'negotiations@edit/EditReturnNegotiation.vue'),
        route('returns/view/:id', 'negotiations@edit/EditReturnNegotiation.vue'),
      ]
    }),
  ]
}
