<template>

  <div class="layout__main" id="dashboard">

    <section class="layout__content">
      <DashboardMatrix />
    </section>

  </div>

</template>

<script>
import DashboardMatrix from '../components/DashboardMatrix'

export default {
  components: {
    DashboardMatrix,
  },

  metaInfo () {
    return {
      title: 'Dashboard',
      bodyAttrs: {
        crumb: 'Dashboard'
      }
    }
  },
}
</script>
