import Vue from 'vue'
import Api from 'services/Api'
import { Loan, LoanItem, LoanEvent, LoanState } from '..'

class State {
  // using default Record type for the time being, as LoanItem only contains flattened data
  loan: Record<string, any>
  events: LoanEvent[]

  constructor () {
    this.loan = new Loan()
    this.events = []
  }
}

const state = new State()

const actions = {
  load ({ commit, dispatch }, id) {
    return Promise.all([
      dispatch('loadLoan', id),
      dispatch('loadEvents', id),
    ])
  },

  loadLoan ({ commit }, id) {
    return Api.get('loans/' + id)
      .then(res => {
        commit('loan', Loan.fromServer(res.data))
      })
  },

  loadEvents ({ commit }, id) {
    return Api.get(`loans/${id}/events`)
      .then(res => {
        commit('events', res.data.map(LoanEvent.fromServer))
      })
  },

  cancelLoan ({ state, commit }, id) {
    return Api.patch('loans/' + id, { cancel: true, hash: state.loan.hash })
      .then(() => {
        if (state.loan.id === id) {
          $message('Loan cancelled')
          commit('loanState', LoanState.cancelled)
        }
      })
  },

  cancelReturn ({ state, commit, dispatch }, id) {
    // to cancel the return, need to get the return first to get its hash
    return Api.get('returns/' + id).then(res => {
      return Api.patch('returns/' + id, { cancel: true, hash: res.data.hash })
        .then(() => {
          $message('Return cancelled')
        })
    })
  }
}

const mutations = {
  loan (state: State, loan: Loan) {
    state.loan = loan
  },

  loanState (state: State, value: LoanState) {
    state.loan.state = value
  },

  events (state: State, events: LoanEvent[]) {
    state.events = events
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}

function $message (message) {
  Vue.prototype.$message({
    message: message + ` successfully`,
    type: 'success'
  })
}
