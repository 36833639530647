<template>
  <div class="ui uiFormItem uiInput">
    <label class="uiLabel">{{ label }}</label>
    <el-input
      ref="input"
      v-model="model"
      v-bind="$attrs"
      v-on="$listeners"
      :size="size"
    />
  </div>
</template>

<script>
export default {

  inheritAttrs: false,

  props: {
    label: {
      type: String,
    },

    value: {
      type: [Number, String],
    },

    size: {
      type: String,
      default: 'mini',
      validator (value) {
        return ['large', 'default', 'small', 'mini'].includes(value)
      }
    },

  },

  computed: {
    model: {
      get () { return this.value },
      set (value) {
        this.$emit('input', value || undefined)
      }
    },
  },

  methods: {
    focus () {
      this.$refs.input.focus()
    }
  }
}
</script>
