import moment from 'moment'
import numbro from 'numbro'
import { today } from 'utils/date'
import { capitalize, labelify, slugify } from './string'
import { isEmpty } from 'utils/validators'

export const formats = {
  date: 'DD/MM/YYYY',
  dateShort: 'DD/MM',
  time: 'HH:mm',
  timeHuman: 'hh:mma',
  dateHuman: 'Do MMM YYYY',
  dateIso: 'YYYY-MM-DD',
}

// ---------------------------------------------------------------------------------------------------------------------
// date
// ---------------------------------------------------------------------------------------------------------------------

export function formatDate (value, invalid = '') {
  return value
    ? moment(value).format(formats.date)
    : invalid
}

export function formatDateHuman (value, invalid = '') {
  return value
    ? moment(value).format(formats.dateHuman)
    : invalid
}

export function formatDateRelative (value) {
  if (typeof value === 'undefined') {
    return ''
  }
  const date = moment(value)
  return date.isBefore(today())
    ? formatDateTimeHuman(date)
    : date.fromNow()
}

// ---------------------------------------------------------------------------------------------------------------------
// time
// ---------------------------------------------------------------------------------------------------------------------

export function formatTime (value, invalid = '') {
  return value
    ? moment(value).format(formats.time)
    : invalid
}

export function formatTimeHuman (value, invalid = '') {
  return value
    ? moment(value).format(formats.timeHuman)
    : invalid
}

export function formatTimeRelative (value) {
  const date = moment(value)
  return date.isBefore(today())
    ? formatTimeDateHuman(date)
    : date.fromNow()
}

// ---------------------------------------------------------------------------------------------------------------------
// date and time
// ---------------------------------------------------------------------------------------------------------------------

export function formatDateTime (value, invalid = '') {
  return value
    ? moment(value).format(formats.date + ' ' + formats.time)
    : invalid
}

export function formatDateTimeHuman (value, invalid = '', delimiter = ' at ') {
  return value
    ? moment(value).format(`${formats.dateHuman}[${delimiter}]${formats.time}`)
    : invalid
}

export function formatTimeDate (value, invalid = '') {
  return value
    ? moment(value).format(`${formats.time} ${formats.dateShort}`)
    : invalid
}

export function formatTimeDateHuman (value, invalid = '', delimiter = ' on ') {
  return value
    ? moment(value).format(`${formats.time}[${delimiter}]${formats.dateHuman}`)
    : invalid
}

// ---------------------------------------------------------------------------------------------------------------------
// number
// ---------------------------------------------------------------------------------------------------------------------

export function formatInteger (value, prefix = '') {
  return prefix + Math.round(value || 0).toLocaleString()
}

export function formatDecimal (value, prefix = '') {
  return prefix + (value || 0).toFixed(2)
}

export function formatNumeric (value, mantissa = 0) {
  if (isEmpty(value)) {
    return value
  }
  return numbro(value).format({
    thousandSeparated: true,
    mantissa
  })
}

// ---------------------------------------------------------------------------------------------------------------------
// number-like
// ---------------------------------------------------------------------------------------------------------------------

export function formatCurrency (prefix, value, mantissa = 0) {
  return `${prefix} ` + numbro(value).format({
    thousandSeparated: true,
    mantissa: mantissa === true
      ? value < 1000
        ? 2
        : 0
      : mantissa
  })
}

export function formatPercent (value) {
  return value + ' %'
}

export function formatRate (value, places = 4) {
  return value.toFixed(places) + ' %'
}

export default {
  // date
  date: formatDate,
  dateHuman: formatDateHuman,
  dateRelative: formatDateRelative,

  // time
  time: formatTime,
  timeHuman: formatTimeHuman,
  timeRelative: formatTimeRelative,

  // date and time
  dateTime: formatDateTime,
  dateTimeHuman: formatDateTimeHuman,
  timeDate: formatTimeDate,
  timeDateHuman: formatTimeDateHuman,

  // number
  integer: formatInteger,
  decimal: formatDecimal,
  numeric: formatNumeric,

  // number-like
  currency: formatCurrency,
  percent: formatPercent,
  rate: formatRate,

  // string
  capitalize,
  labelify,
  slugify,
}
