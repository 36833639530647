import _ from 'lodash'
import { getSecurity, Security } from 'modules/securities'
import { makeStore } from './store-factory.js'

// make securities store, just like the other factories
const store = makeStore('trading-profile/special-securities', null, Security.fromServer)

// decorate loading of securities to add them to the securities store on load
const load = store.actions.load
store.actions.load = function ({ commit }) {
  return load({ commit }).then(items => {
    commit('securities/items', items, { root: true })
  })
}

// decorate saving of securities to convert table items to security ids on save
const save = store.actions.save
store.actions.save = function ({ state, commit }, items) {
  items = items
    // skip missing
    .filter(item => !!item.bloomberg)

    // convert to actual securities from bloomberg
    .map(item => getSecurity(item.bloomberg))

    // filter blanks
    .filter(item => item && item.id)

    // convert to array of ids
    .map(item => item.id)

  // unique items only
  items = _.uniq(items)

  return save({ state, commit }, items)
}

export default store
