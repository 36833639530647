<template>
  <div id="app" :class="{ hasTools, hasTicker, isUser }">
    <!-- header -->
    <header id="header" v-show="!isLoading">
      <div class="layout__center">
        <SiteNav/>
      </div>
    </header>

    <!-- app -->
    <div id="body" v-show="!isLoading">
      <div class="layout__center">
        <main id="main">
          <router-view/>
<!--          <footer id="footer">This is a footer</footer>-->
        </main>
        <ToolsPanel id="tools" v-if="isUser">
          <Ticker v-if="hasTicker" />
        </ToolsPanel>
      </div>
    </div>

    <!-- modals -->
    <AuthModal />
    <AutoLogout v-if="isLoggedIn"/>
  </div>
</template>

<script>
import Ticker from 'modules/ticker/components/Ticker'
import ToolsPanel from 'components/ToolsPanel'
import AuthModal from 'components/AuthModal'
import SiteNav from './views/components/SiteNav'
import AutoLogout from './views/components/AutoLogout'
import { UserMixin } from 'modules/organizations'

export default {
  mixins: [
    UserMixin,
  ],

  components: {
    AutoLogout,
    ToolsPanel,
    AuthModal,
    SiteNav,
    Ticker
  },

  computed: {
    hasTools () {
      return this.isUser && this.$store.state.settings.showTools
    },

    hasTicker () {
      return this.$store.state.ticker
    },

    isLoading () {
      return this.$store.state.settings.isLoading
    }
  },
}
</script>

<style lang="scss">
.app-loading-spinner {
  .el-loading-spinner {
    top: 45%;
  }
}
</style>
