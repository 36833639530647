<template>

  <section>
    <el-form :rules="rules"
             :model="form"
             label-width="300px"
             label-position="right"
             ref="organizationForm"
             class="organization-form"
             submit.prevent="submit"
    >

      <UiErrors/>

      <!-- company -->
      <UiFieldset label="Company">

        <el-form-item label="Display Name"
                      prop="displayName"
                      class="is-required">
          <el-input v-model="form.displayName"
                    maxlength="100"/>
        </el-form-item>

        <el-form-item label="Legal Entity Name"
                      prop="legalEntityName"
                      class="is-required">
          <el-input v-model="form.legalEntityName"
                    maxlength="100"/>
        </el-form-item>

        <el-form-item label="Legal Entity Identifier (LEI)"
                      prop="legalEntityIdentifierNumber"
                      class="is-required">
          <el-input v-model="form.legalEntityIdentifierNumber" maxlength="100"/>
        </el-form-item>

        <el-form-item label="Company Registration Number"
                      prop="companyRegistrationNumber"
                      class="is-required">
          <el-input v-model="form.companyRegistrationNumber"
                    maxlength="100"/>
        </el-form-item>

      </UiFieldset>


      <!-- contact -->
      <UiFieldset label="Contact">

        <el-form-item label="Registered Address"
                      prop="registeredAddress"
                      class="is-required">
          <el-input type="textarea" v-model="form.registeredAddress"
                    maxlength="1000"/>
        </el-form-item>

        <el-form-item label="Phone Number"
                      prop="phoneNumber"
                      class="is-required">
          <el-input v-model="form.phoneNumber"
                    maxlength="30"/>
        </el-form-item>
      </UiFieldset>

      <!-- Billing -->
      <UiFieldset label="Billing">
        <el-form-item label="Contract Type"
                      prop="contractType"
                      class="is-required">
          <el-input v-model="form.contractType" maxlength="100"/>
        </el-form-item>

        <el-form-item label="Billing Currency"
                      prop="billingCurrencyId"
                      class="is-required">
          <UiSelect v-model="form.billingCurrencyId"
                    :options="billingCurrencyOptions"
                    @blur="validateField('billingCurrencyId')"
                    @change="validateField('billingCurrencyId')"
                    size="small"
          />
        </el-form-item>

      </UiFieldset>

      <!-- trading -->
      <UiFieldset label="Trading">

         <el-form-item label="Participant Role"
                      prop="organizationType"
                      class="is-required">
          <UiSelect v-model="form.organizationType"
                    :options="organizationTypeOptions"
                    @blur="validateField('organizationType')"
                    @change="validateField('organizationType')"
                    size="small"
          />
        </el-form-item>

        <el-form-item label="Participant Sub Role"
                      prop="organizationRole"
                      class="is-required">
          <UiSelect v-model="form.organizationRole"
                    :options="organizationRoleOptions"
                    @blur="validateField('organizationRole')"
                    @change="validateField('organizationRole')"
                    size="small"
          />
        </el-form-item>

        <el-form-item label="UTI Creator"
                      prop="uniqueTradeIdentifierCreator"
                      class="is-required">

          <UiSelect v-model="form.uniqueTradeIdentifierCreator"
                    :options="uniqueTradeIdentifierCreatorOptions"
                    @blur="validateField('uniqueTradeIdentifierCreator')"
                    @change="validateField('uniqueTradeIdentifierCreator')"
                    size="small"
          />
        </el-form-item>

      </UiFieldset>


      <!-- Regulation -->
      <UiFieldset label="Regulation">

        <el-form-item label="Regulator"
                      prop="regulatorName"
                      class="is-required">
          <el-input v-model="form.regulatorName" maxlength="100"/>
        </el-form-item>

        <el-form-item label="Regulator Registration Number"
                      prop="regulatorRegistrationNumber"
                      class="is-required">
          <el-input v-model="form.regulatorRegistrationNumber"
                    maxlength="100"/>
        </el-form-item>

        <el-form-item label="Regulatory Status"
                      prop="regulatoryStatus"
                      class="is-required">
          <UiSelect v-model="form.regulatoryStatus"
                     :options="regulatoryStatusOptions"
                     @blur="validateField('regulatoryStatus')"
                     @change="validateField('regulatoryStatus')"
                     size="small"
          />
        </el-form-item>

      </UiFieldset>


      <!-- Notice -->
      <UiFieldset label="Notice">

        <el-form-item label="Notice Details Address"
                      prop="noticesAddress"
                      class="is-required">

          <el-input type="textarea" v-model="form.noticesAddress" maxlength="1000"/>
        </el-form-item>

        <el-form-item label="Notice Details Phone Number"
                      prop="noticesPhoneNumber"
                      class="is-required">

          <el-input v-model="form.noticesPhoneNumber" maxlength="30"/>
        </el-form-item>
      </UiFieldset>

      <!--  Agent -->
      <UiFieldset label="Agent">

        <el-form-item label="Authorized Agent"
                      prop="authorizedAgentName"
                      class="is-required">
          <el-input v-model="form.authorizedAgentName"
                    maxlength="100"/>
        </el-form-item>

        <el-form-item label="Authorized Agent LEI"
                      prop="authorizedAgentLegalEntityIdentifier"
                      class="is-required">
          <el-input v-model="form.authorizedAgentLegalEntityIdentifier"
                    maxlength="100"/>
        </el-form-item>

        <!-- outside UK -->
        <section>
          <h4>If outside the UK</h4>
          <el-form-item label="Agent for service Address"
                        class="m-1">

            <el-input type="textarea" v-model="form.agentForServiceOutsideUKAddress" maxlength="1000"/>
          </el-form-item>

          <el-form-item label="Agent for service Telephone Number"
                        class="m-1">

            <el-input v-model="form.agentForServiceOutsideUKPhoneNumber" maxlength="30"/>
          </el-form-item>
        </section>

      </UiFieldset>

      <UiErrors/>

      <ui-submit @cancel="action === 'create' ? $router.push('/') : onDone(form.id)"
                 @submit="action === 'create' ? createOrganization() : updateOrganization()"
                 :can-submit="valid"
                 :submit-text="action === 'create' ? 'Create' : 'Update'"
      />
    </el-form>
  </section>
</template>

<script>
import Validator from 'vendor/vee-validate/services/Validator'
import { enumToOptions } from 'utils/ui'
import { makeFormRules } from 'utils/form'
import { Api } from 'services'
import OrganizationForm from '../../models/OrganizationForm'
import { OrganizationRole, RegulatoryStatus, UniqueTradeIdentifier } from 'areas/system'
import UiFieldset from 'core/components/forms/UiFieldset'
import { OrganizationType } from 'modules/organizations'
import UiErrors from 'modules/errors/components/UiErrors'

const validator = new Validator()

const validationRules = {
  legalEntityName: 'required',
  displayName: 'required',
  companyRegistrationNumber: 'required',
  registeredAddress: 'required',
  phoneNumber: 'required',
  legalEntityIdentifierNumber: 'required',
  regulatorName: 'required',
  regulatorRegistrationNumber: 'required',
  regulatoryStatus: 'required',
  organizationType: 'required',
  organizationRole: 'required',
  authorizedAgentName: 'required',
  authorizedAgentLegalEntityIdentifier: 'required',
  uniqueTradeIdentifierCreator: 'required',
  noticesAddress: 'required',
  noticesPhoneNumber: 'required',
  contractType: 'required',
  billingCurrencyId: 'required',
}

const validationNames = {
  legalEntityName: 'Legal Entity Name',
  displayName: 'Display Name',
  companyRegistrationNumber: 'Company Registration Number',
  registeredAddress: 'Registered Address',
  phoneNumber: 'Phone Number',
  legalEntityIdentifierNumber: 'LEI Number',
  regulatorName: 'Regulator',
  regulatorRegistrationNumber: 'Regulator Registration Number',
  regulatoryStatus: 'Regulatory Status',
  organizationType: 'Participant Role',
  organizationRole: 'Participant Sub Role',
  authorizedAgentName: 'Authorized Agent',
  authorizedAgentLegalEntityIdentifier: 'Authorized Agent LEI',
  uniqueTradeIdentifierCreator: 'UTI Creator',
  noticesAddress: 'Notice Address',
  noticesPhoneNumber: 'Notice Phone Number',
  contractType: 'Contract Type',
  billingCurrencyId: 'Billing Currency',
}

export default {
  components: { UiErrors, UiFieldset },
  props: {
    action: {
      type: String,
    },
  },

  mounted () {
    if (this.action === 'edit') {
      Api.get(`organizations/${this.$route.params.id}/details`).then(res => {
        this.form = OrganizationForm.fromServer(res.data)
      })
    }
  },

  watch: {
    form: {
      async handler (form) {
        const errors = await validator.validateModel(form, validationRules, validationNames)
        this.valid = !errors
      },
      deep: true
    }
  },

  data () {
    return {
      form: new OrganizationForm(),
      rules: {
        ...makeFormRules(validationRules, validationNames)
      },
      valid: false
    }
  },

  computed: {
    regulatoryStatusOptions () {
      return enumToOptions(RegulatoryStatus, 'id')
    },

    organizationTypeOptions () {
      function make (id, name) { return { id, name } }
      return [
        make(OrganizationType.borrower, 'Borrower'),
        make(OrganizationType.lender, 'Lender'),
        make(OrganizationType.borrowerAndLender, 'Borrower and Lender'),
      ]
    },

    organizationRoleOptions () {
      return enumToOptions(OrganizationRole, 'id')
    },

    uniqueTradeIdentifierCreatorOptions () {
      return enumToOptions(UniqueTradeIdentifier, 'id')
    },

    billingCurrencyOptions () {
      return this.$store.state.resources.currencies
    }
  },

  methods: {
    validateField (field) {
      this.$refs['organizationForm'].validateField(field, error => error)
    },

    createOrganization () {
      Api.post('/organizations', this.form.toServer())
          .then(res => this.onDone(res.data.id))
    },

    updateOrganization () {
      Api.patch(`/organizations/${this.$route.params.id}`, this.form.toServer())
          .then(res => {
            this.form.hash = res.data.hash
            this.onDone(this.form.id)
          })
    },

    onDone (id) {
      this.$router.push(`/organization/view/${id}`)
    }
  }
}
</script>

<style lang="scss">
.organization-form {

  section {
    margin-bottom: 2rem;
  }

  h4,
  .uiFieldset__legend {
    padding: .5rem;
    border-bottom: 1px solid $grey-lightest;
  }

  .uiFieldset__content {
    padding: .5rem 1rem;
  }

  .el-form-item {
    margin-bottom: 30px;
  }

  textarea {
    min-height: 10em !important;
  }
}
</style>
