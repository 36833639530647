import { isEmpty } from 'utils/validators'

/**
 * Makes a Handsontable validator function
 *
 *  - allows empty cells to validate
 *  - anything else, runs a list of validators over
 *
 * @see https://handsontable.com/docs/7.1.0/Options.html#validator
 *
 * @param   {boolean}   required
 * @param   {Function}  validators
 * @return  {Function}
 */
export function makeValidator (required, ...validators) {
  return function (value, callback) {
    if (!required && isEmpty(value)) {
      return callback(true)
    }
    callback(validators.every(validator => validator(value)))
  }
}

export function retarget (config, data) {
  return { ...config, data }
}

export function lock (config) {
  return { ...config, editor: false }
}

export class Column {
  constructor (data) {
    Object.assign(this, data)
  }

  retarget (data) {
    this.data = data
  }

  lock () {
    this.editor = false
  }
}
