<template>

  <div class="layout__main stats__image" id="home" v-if="showSplash">
  </div>

</template>

<script>
import { BUILD_MODE } from 'config/env'

export default {
  data () {
    return {}
  },

  computed: {
    showSplash () {
      return BUILD_MODE !== 'production'
    }
  },

  methods: {}
}

</script>

<style lang="scss">

.stats__image{
  background-image: url('/assets/img/dashboard_v6.PNG');
  background-size: contain;
  background-repeat: no-repeat;
  height: 100vh;

}
</style>
