import { Route } from 'vue-router'
import router from '@/app/router'
import store from '@/app/state'
import { NotificationItem } from 'modules/notifications'
import config from './config'

// helpers
export function getRouteForItem (item: NotificationItem): string {
  return config[item.type]
    .replace(':action', 'edit')
    .replace(':id', item.itemId)
}

// functions
export function showPageForNotification (item: NotificationItem) {
  return router.push(getRouteForItem(item))
}

function checkNotification (to: Route) {
  return store.dispatch('notifications/clearForItem', to.params.id)
}

// assign route callback
router.afterEach(checkNotification)
