import { get } from 'utils/collection'
import Api from 'services/Api'
import { Locate } from 'modules/locate-lists'
import { lookups } from 'modules/global'
import { handleTableErrors } from 'modules/errors'
import { AvailabilityItem } from 'modules/availability-list'
import { AbstractNegotiationState, LoanNegotiationItem } from '..'
import { makeActions, makeMutations } from './store-factory'

class State extends AbstractNegotiationState {
  public id: string

  public sourceList?: Locate = undefined

  public value?: number = undefined

  public currencyCode: string

  constructor (data: any = {}) {
    super(data)
    this.id = data.id
    this.sourceList = data.sourceList
    this.value = data.value
    this.currencyCode = data.currencyCode
  }

  static fromServer (data) {
    if (data.sourceList) {
      data.sourceList = Locate.fromServer(data.sourceList || {})
    }
    data.currencyCode = data.valueCurrency
      ? data.valueCurrency.code
      : undefined
    return new State(data)
  }
}

const state: State = new State()

const actions = {
  ...makeActions(
    'loans',
    State.fromServer,
    LoanNegotiationItem.fromServer,
    LoanNegotiationItem.fromTable,
    'security',
    undefined,
  ),

  getAvailability (context, items) {
    items = items
      .map(LoanNegotiationItem.fromTable)
      .map(item => item.toServer(true, lookups))
    return Api
      .post(`availability/items/matches-for-negotiation-items`, items)
      .catch(handleTableErrors)
      .then(res => {
        return res.data.map(AvailabilityItem.fromServer)
      })
  },

  download () {
    Api.download(`negotiations/loans/${state.id}/file`, 'Negotiation.csv')
  }
}

const mutations = makeMutations()

const getters = {
  /**
   * Gets the locate list item associated with this negotiation
   */
  locateList (state, getters, rootState) {
    return state.sourceList && rootState.locateLists
      ? get(rootState.locateLists.items, state.sourceList.id)
      : null
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
