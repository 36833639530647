import { Hash } from 'core/types'
import TableError from '../types/TableError'
import RulesProvider from './RulesProvider'
import Validator from './Validator'
import { Actions, Responses, Roles, makeOptions } from '..'

/**
 * Wrapper class to manage validation of API Resources
 *
 * A reusable, configurable service, designed to validate:
 *
 *    - tables
 *    - models
 *    - fields
 *
 * Accounting for:
 *
 *    - variations (actions, responses, roles)
 *    - local or global messages
 *    - one or many messages
 */
export default class ResourceValidator {

  public provider: RulesProvider

  public validator: Validator

  constructor (provider: RulesProvider, local?: boolean) {
    this.validator = new Validator(local)
    this.provider = provider
  }

  validateTable (models: Array<any>): Promise<Array<TableError>> {
    return this.validator.validateModels(models, this.provider.rules, this.provider.names)
  }

  validateModel (model: any): Promise<Hash | null> {
    return this.validator.validateModel(model, this.provider.rules, this.provider.names)
  }

  validateField (value: any, name: string): Promise<null | string | string[]> {
    return this.validator.validateField(value, this.provider.rules[name], this.provider.names[name])
  }

  setOptions (...args: Array<Actions | Responses | Roles>) {
    this.provider.options = makeOptions(...args)
    this.provider.update()
  }
}
