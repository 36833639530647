import { validate } from 'vee-validate'
import { RuleParamConfig, RuleParamSchema } from 'vee-validate/dist/types/types'
import { Hash } from 'core/types'
import { TableError } from '../types'

/**
 * Validation service that validates tables, models and fields
 *
 * It can also customise error message context and number of errors returned
 *
 *  - `local` - local or global message text (defaults to global)
 *  - `all`   - first or all messages for each field (defaults to first)
 *
 *  See constructor for more information
 */
export default class Validator {

  public local: boolean

  public all: boolean

  /**
   * Constructor
   *
   * @param local   Optional boolean to generate `This field ...` or `"field" ...`
   * @param all     Optional boolean to generate one or all error messages for each field
   */
  constructor (local: boolean = false, all: boolean = false) {
    this.local = local
    this.all = all
  }

  /**
   * Validate a collection of models
   *
   * @param models    A hash of key => field value pairs
   * @param ruleset   A hash of key => rule expressions / object pairs
   * @param names     A hash of key => field name / label pairs
   *
   * @return          An array of error hashes string arrays (if there are errors) or null if no errors
   */
  validateModels (models: Array<Hash>, ruleset: Hash, names: Hash): Promise<Array<TableError>> {
    const promises = models.map(model => this.validateModel(model, ruleset, names))
    return Promise
      .all(promises)
      .then(results => {
        const output: Array<TableError> = []
        results.forEach((errors: Hash | null, index: number) => {
          if (errors) {
            output.push({ index, errors })
          }
        })
        return output
      })
  }

  /**
   * Validate a model / row
   *
   * @param model     A hash of key => field value pairs
   * @param ruleset   A hash of key => rule expressions / object pairs
   * @param names     A hash of key => field name / label pairs
   *
   * @return          A hash of string or string arrays (if there are errors) or null if no errors
   */
  validateModel (model: Hash, ruleset: Hash, names: Hash): Promise<Hash | null> {
    const fields = Object
      .keys(ruleset)
      .filter(field => ruleset[field])
    const promises = fields
      .map(field => {
        const options = {
          name: names[field] || field,
          values: model,
          names,
        }
        return this.validateField(model[field], ruleset[field], options)
      })
    return Promise
      .all(promises)
      .then(errors => {
        // build error hash
        const output: Hash = {}
        errors.forEach((error, index) => {
          if (error) {
            const field = fields[index]
            output[field] = error
          }
        })

        // return error hash
        return Object.keys(output).length
          ? output
          : null
      })
  }

  /**
   * Validate a field / value
   *
   * @param value     A value to validate
   * @param rules     A string or hash of rules o validate against
   * @param opts      A hash of VeeValidate options including the field name, or just the field name
   *
   * @return          A string or array of strings (if there are errors) or null if no errors
   */
  validateField (value: any, rules: string | Hash, opts: Hash | string = {}): Promise<null | string | string[]> {
    // main options
    const options: Hash = typeof opts === 'string'
      ? { name: opts }
      : Object.assign({}, opts)

    // options
    options.bails = !this.all
    if (this.local) {
      options.name = 'This field'
    }

    // filter empty string rules
    if (typeof rules === 'string') {
      rules = rules.split('|').filter(rule => rule).join('|')
    }

    return validate(value, rules, options)
      .then(result => {
        if (result.valid) {
          return null
        }
        return this.all
          ? result.errors
          : result.errors[0]
      })
  }
}
