var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ui uiFormItem uiSelect" },
    [
      _vm.label
        ? _c("label", { staticClass: "uiLabel" }, [_vm._v(_vm._s(_vm.label))])
        : _vm._e(),
      _c(
        "el-select",
        _vm._g(
          _vm._b(
            {
              ref: "select",
              staticClass: "block",
              attrs: {
                placeholder: _vm.placeholder,
                multiple: _vm.multiple,
                size: _vm.size
              },
              on: { change: _vm.onChange, clear: _vm.onClear },
              model: {
                value: _vm.model,
                callback: function($$v) {
                  _vm.model = $$v
                },
                expression: "model"
              }
            },
            "el-select",
            _vm.$attrs,
            false
          ),
          _vm.listeners
        ),
        _vm._l(_vm.modelOptions, function(option, index) {
          return _c(
            "el-option",
            {
              key: index,
              attrs: {
                label: option[_vm.labelKey],
                value: option[_vm.valueKey]
              }
            },
            [
              _vm._t(
                "default",
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(option[_vm.optionKey || _vm.labelKey]) +
                      "\n      "
                  )
                ],
                null,
                option
              )
            ],
            2
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }