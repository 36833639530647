<template>

  <section class="profile__marginRequirements panel">
    <h2>Margin Requirements</h2>

    <UiErrors :errors="errors" />

    <ul class="profile__list">
      <li class="header">
        <label>
          <span class="text">Counterparty:</span>
        </label>
        <output>
          <span class="text">Requirement:</span>
        </output>
      </li>
      <li v-for="item in form.data" :key="item.id" v-if="canBorrow(item)">
        <label>
          <span class="text">{{ item.counterParty.name }}</span>
        </label>
        <output class="mb-2">
          <div class="flex">
            <div>
              <el-input v-model.number="item.margin"
                        type="number"
                        :min="1"
                        :max="150"
                        size="mini" />
              <span class="symbol">%</span>
              <a href="#" class="ml-2 link" style="font-size: .85em" @click.prevent="item.margin = null">Clear</a>
            </div>
          </div>
        </output>
      </li>
    </ul>

    <SaveChanges @save="save" />
  </section>

</template>

<script>
import { dataMixin, storeMixin } from '../state/mixin-factory'
import { canBorrow } from 'modules/organizations/enums/OrganizationType'

export default {
  extends: storeMixin('tradingProfile/marginRequirements', {
    data: 'data'
  }),

  methods: {
    canBorrow (item) {
      return canBorrow(item.counterParty.organizationType)
    }
  }
}

</script>

<style lang="scss">

</style>
