var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      staticClass: "align-bottom",
      attrs: { inline: true, model: _vm.form, "label-position": "top" },
      nativeOn: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      }
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "Security" } },
        [
          _c("el-autocomplete", {
            ref: "security",
            attrs: {
              "fetch-suggestions": _vm.search,
              clearable: true,
              "trigger-on-focus": false,
              "highlight-first-item": true,
              placeholder: "Enter a code or name...",
              "popper-class": "security-search"
            },
            on: { select: _vm.onSelect },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("span", [_vm._v(_vm._s(item.code))]),
                    _c("span", [_vm._v(_vm._s(item.name))])
                  ]
                }
              }
            ]),
            model: {
              value: _vm.form.code,
              callback: function($$v) {
                _vm.$set(_vm.form, "code", $$v)
              },
              expression: "form.code"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Quantity" } },
        [
          _c("el-input", {
            attrs: { type: "number", min: 1 },
            model: {
              value: _vm.form.quantity,
              callback: function($$v) {
                _vm.$set(_vm.form, "quantity", _vm._n($$v))
              },
              expression: "form.quantity"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", disabled: !_vm.canSubmit },
              on: { click: _vm.submit }
            },
            [_vm._v(" " + _vm._s(_vm.action) + "\n    ")]
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Value" } },
        [
          _c("el-input", {
            attrs: { disabled: "" },
            model: {
              value: _vm.value,
              callback: function($$v) {
                _vm.value = $$v
              },
              expression: "value"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }