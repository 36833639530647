import store from 'state'
import { formats } from 'utils/formatters'
import Handsontable from 'handsontable'
import { getSecurity, getLastTraded } from 'modules/securities'

// ---------------------------------------------------------------------------------------------------------------------
// Columns
// ---------------------------------------------------------------------------------------------------------------------

export function makeSecurity (special) {
  const options = makeText('Asset', 'bloomberg', 120)
  if (special) {
    options.renderer = function (instance, td, row, col, prop, value, cellProperties) {
      // variables
      const datum = instance.getSourceDataAtRow(row)

      // render
      Handsontable.renderers.TextRenderer.call(this, instance, td, row, col, prop, value, cellProperties)
      if (datum.specialSecurity) {
        td.classList.add('anSpecial')
        td.setAttribute('data-tooltip', 'This asset is special')
      }
    }
  }
  return options
}

/**
 * Create a basic rate column configuration
 *
 * @param   {string}  title     The column title
 * @param   {string}  data      The key of the row data
 * @param   {boolean} editable  Any additional props
 * @param   {number}  decimals  The number of decimals to render
 * @return  {object}            The column configuration
 */
export function makeRate (title, data, editable = true, decimals = 4) {
  return {
    title,
    data,
    width: 80,
    editor: editable
      ? 'numeric'
      : false,
    type: 'numeric',
    validator: !!editable,
    allowInvalid: true,
    className: 'anRight anPercent',
    correctFormat: true,
    numericFormat: {
      pattern: '0.' + '0'.repeat(decimals)
    },
  }
}

export function makeNetDiv (editable) {
  return makeRate('Net Div / Margin', 'netDividendRequirement', editable, 3)
}

export function makeRelatedRate (title, data, showLastTraded) {
  const timestamp = Date.now()
  return {
    ...makeRate(title, data, false, 4),
    renderer: function (instance, td, row, col, prop, value, cellProperties) {
      // variables
      const datum = instance.getSourceDataAtRow(row)
      const bloomberg = datum.bloomberg || (datum.loan && datum.loan.bloomberg) // guess bloomberg rather than comparing
      const security = getSecurity(bloomberg)

      // get value
      value = security && security[data]

      // render
      Handsontable.renderers.NumericRenderer.call(this, instance, td, row, col, prop, value, cellProperties)

      // last-traded
      if (security && showLastTraded) {
        const lastTraded = getLastTraded(bloomberg)
        if (lastTraded > timestamp) {
          td.classList.add('anTraded')
          td.setAttribute('data-tooltip', 'This security has just been traded')
        }
      }
    }
  }
}

export function makeRelatedRates (active) {
  return [
    makeRelatedRate('Last Traded Rate', 'lastTradedRate', active),
    makeRelatedRate('Last Week Average Traded Rate', 'lastWeekAverageTradedRate'),
  ]
}

/**
 * Create a basic enum column configuration
 *
 * @param   {string}  title     The column title
 * @param   {string}  data      The key of the row data
 * @param   {number}  width     The column width
 * @param   {enum}    enumData  The enum data
 * @return  {object}            The column configuration
 */
export function makeEnum (title, data, enumData, width = 75) {
  return {
    title,
    data,
    enum: enumData,
    width: 80,
    editor: false,
    renderer: 'enum',
  }
}

/**
 *
 * @param   {string}    title
 * @param   {string}    data
 * @param   {Function}  validator
 * @return  {object}
 */
export function makeDateEditor (title, data, validator) {
  const config = {
    title,
    data,
    type: 'date',
    width: 110,
    dateFormat: formats.date,
    validator: true, // makeValidator(false, ...validators),
    correctFormat: true,
    defaultDate: '0/0/0',
  }

  // @see https://github.com/dbushell/Pikaday#configuration
  config.datePickerConfig = {
    firstDay: 1,
    showWeekNumber: false,
    disableWeekends: true,
    numberOfMonths: 1,
    disableDayFn (date) {
      // return !config.validator(date)
      return !validator(date)
    }
  }

  return config
}

export function makeDate (title, data) {
  return {
    title,
    data,
    type: 'date',
    dateFormat: formats.date,
    width: 110,
    editor: false,
  }
}

export function makeText (title, data, width = 75, editable = false) {
  return {
    title,
    data,
    width,
    type: 'text',
    editor: editable,
    validator: false,
  }
}

export function makeThousands (title, data, width = 80, editable = true) {
  return {
    title,
    data,
    width,
    editor: editable
      ? 'numeric'
      : false,
    type: 'numeric',
    validator: !!editable,
    allowEmpty: true,
    numericFormat: {
      pattern: '0,0'
    },
  }
}

/**
 *
 * @param {string}    title
 * @param {string}    data
 * @param {boolean}   warnIfOver
 * @return {{editor, isSpeculative: *, data, width, validator: *, title, type, numericFormat}}
 */
export function makeQuantityEditor (title = 'Quantity', data = 'quantity', warnIfOver = true) {
  const config = {
    ...makeThousands(title, data),
    validator: true,
  }

  // warning for over-borrowing
  if (warnIfOver) {
    config.renderer = function (instance, td, row, col, prop, value, cellProperties) {
      const datum = instance.getSourceDataAtRow(row)
      Handsontable.renderers.NumericRenderer.call(this, instance, td, row, col, prop, value, cellProperties)
      if (datum.quantity > datum.quantityRequired) {
        td.classList.add('anWarning')
        td.setAttribute('data-tooltip', 'Quantity is more than you require')
      }
    }
  }

  // return
  return config
}

export function makeQuantity (title = 'Quantity', data = 'quantity', editable = false) {
  return makeThousands(title, data, 80, editable)
}

export function makeCurrencyPicker (title, data) {
  return {
    title,
    data,
    renderer: 'autocomplete',
    editor: 'select',
    width: 65,
    // validator: true,
    editorOptions: {
      showValue: 'below',
      keyLabel: 'name',
      keyValue: 'code',
      source: store.state.resources.currencies
    }
  }
}
