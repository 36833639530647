var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      attrs: { data: _vm.items },
      on: {
        "row-click": function(row) {
          return _vm.$emit("edit", row)
        }
      }
    },
    [
      _c("el-table-column", { attrs: { label: "Name", prop: "name" } }),
      _c("el-table-column", {
        attrs: { label: "Securities", prop: "itemCount" }
      }),
      _c("el-table-column", {
        attrs: {
          label: "Created At",
          prop: "createdAt",
          formatter: _vm.$formatCol("dateTime")
        }
      }),
      _c("el-table-column", {
        attrs: { label: "Remove", align: "center", width: "100" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "a",
                  {
                    staticClass: "icon-link",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.$emit("remove", row)
                      }
                    }
                  },
                  [_c("i", { staticClass: "el-icon-delete" })]
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }