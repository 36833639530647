<template>

  <div class="negotiationComment">
    <UiText v-model="input"
            placeholder="Optional public comment..."
            show-word-limit
            maxlength="100"
            multiline
            :help="help"
            help-icon="exclamation-triangle"
    />
  </div>

</template>

<script>
export default {
  props: {
    value: String,
  },

  computed: {
    input: {
      get () { return this.value },
      set (value) { this.$emit('input', value) },
    },

    help () {
      return this.input.length
        ? 'Note: this comment will be read by the counterparty; only include information you are authorised to share.'
        : ''
    }
  },
}
</script>

<style lang="scss">
.negotiationComment .uiText__help {
  color: $color-danger;
}
</style>
