<template>
  <el-table size="mini"
            class="ticker__table no-bg no-hover"
            :data="items"
            :highlight-current-row="false"
            :row-class-name="getRowClass"
            :row-hover="false"
  >
    <el-table-column prop="bloomberg"
                     label="Asset"
                     width="80"
                     align="right"
    />
    <el-table-column prop="quantity"
                     label="Quantity"
                     width="70"
                     align="right"
                     :formatter="$formatCol('numeric')"
    />
    <el-table-column prop="rate"
                     label="Rate"
                     width="60"
                     align="right"
    />
    <el-table-column prop="createdAt"
                     label="When"
                     width="60"
                     align="right"
                     :formatter="formatDate"
    />
  </el-table>
</template>

<script>
import { isToday } from 'utils/validators'
import { formatNumeric, formatRate, formatTimeDate } from 'utils/formatters'

export default {

  data () {
    return {
      isUpdating: false,
      lastUpdatedAt: Date.now(),
    }
  },

  computed: {
    items () {
      return this.$store.state.ticker
        ? this.$store.state.ticker.items
        : []
    }
  },

  watch: {
    items (items, oldItems) {
      // set the time updated
      this.lastUpdatedAt = Date.now()

      // only SignalR-added items will have the addedAt property
      // checking for this prevents an unwelcome flash when the page loads
      if (items.length && items[0].addedAt) {
        // start the animation
        this.isUpdating = true
        setTimeout(() => {
          this.isUpdating = false
        }, 2000)
      }
    }
  },

  methods: {
    formatNumeric (row) {
      return formatNumeric(row.quantity, 0)
    },

    formatDate (row, props, val) {
      const [time, date] = formatTimeDate(val).split(' ')
      return isToday(val) ? time : date
    },

    getRowClass ({ row, rowIndex }) {
      // variables
      const lastUpdatedAt = this.lastUpdatedAt - 1000
      const itemAddedAt = row.addedAt

      // classes
      let classes = 'ticker__item '
      if (itemAddedAt > lastUpdatedAt) {
        classes += this.isUpdating
          ? 'ticker__item--updating '
          : 'ticker__item--updated '
      }

      return classes
    },
  },

}
</script>

<style lang="scss">
$updated-fg: $color-success;
$updated-bg: mix($color-success, yellow, 50%);

.ticker__table {

  &, .el-table__body-wrapper {
    overflow: visible;
  }

  td {
    padding: 2px 0;
  }

  .cell {
    padding: 0 4px;
  }

  tr {
    transition: all 4s;

    &.ticker__item--updating {
      font-weight: bold;
      background-color: transparentize($updated-bg, .5);
      transition: all 0.2s;
    }

    &.ticker__item--updated {
      font-weight: bold;
      color: $updated-fg;
      transition: all 2s;
      transition-delay: 1s;
      background: #FFFFFF99;
    }

  }
}

</style>
