import uniq from 'lodash/uniq'

/**
 * Fill a model with values from other objects. Does not add any new keys, unlike Object.assign()
 *
 * @param   {object}    model
 * @param   {object[]}  values
 * @returns {object}
 */
export function fill (model, ...values) {
  // reduce all passed values into one object
  const input = values.reduce((output, input) => Object.assign(output, input), {})

  // assign values only where keys exist
  Object.keys(input).forEach(key => {
    if (key in model) {
      model[key] = input[key]
    }
  })
  return model
}

/**
 * Tests whether a passed value is an Object or Array
 *
 * @param   {*}       value   The value to be assessed
 * @returns {boolean}         Whether the value is an Object or Array
 */
export function isObject (value) {
  return !!value && typeof value === 'object'
}

/**
 * Tests whether a passed value is an Object
 *
 * @param   {*}       value   The value to be assessed
 * @returns {boolean}         Whether the value is a true Object
 */
export function isPlainObject (value) {
  return isObject(value) && !Array.isArray(value)
}

/*
export function clone (data) {
  return JSON.parse(JSON.stringify(data))
}
*/

export function clone (item) {
  // null, undefined values check
  if (!item) {
    return item
  }

  // result
  let result

  // normalizing primitives if someone did new String('aaa'), or new Number('444');
  const types = [Number, String, Boolean]
  types.forEach(function (type) {
    if (item instanceof type) {
      result = type(item)
    }
  })

  if (typeof result === 'undefined') {
    // array
    if (Array.isArray(item)) {
      result = []
      item.forEach(function (child, index, array) {
        result[index] = clone(child)
      })
    }

    // object
    else if (typeof item == 'object') {
      // node
      if (item.nodeType && typeof item.cloneNode == 'function') {
        result = item.cloneNode(true)
      }

      // clone
      else if (typeof item.clone == 'function') {
        result = item.clone()
      }

      // literal
      else if (!item.prototype) {
        if (item instanceof Date) {
          result = new Date(item)
        }
        else {
          // it is an object literal
          result = {}
          for (var i in item) {
            result[i] = clone(item[i])
          }
        }
      }

      // other
      else {
        // depending what you would like here,
        // just keep the reference, or create new object
        result = item
      }
    }
    else {
      result = item
    }
  }

  return result
}

/**
 * Assigns only values to a target object
 *
 * @param target
 * @param values
 */
export function assign (target, ...values) {
  values = Object.assign({}, ...values)
  Object.keys(values)
    .forEach(key => {
      if (typeof target[key] !== 'function') {
        target[key] = values[key]
      }
    })
}

/**
 * Tests whether a passed value is an Object and has the specified key
 *
 * @param   {Object}   obj    The source object
 * @param   {string}   key    The key to check that exists
 * @returns {boolean}         Whether the predicate is satisfied
 */
export function hasKey (obj, key) {
  return isObject(obj) && key in obj
}

/**
 * Gets an array of keys from a value
 *
 * The function handles various types:
 *
 * - string - match all words
 * - object - return keys
 * - array  - return a string array of its values
 *
 * @param   {*}       value   The value to get keys from
 * @returns {Array}
 */
export function getKeys (value) {
  return !value
    ? []
    : Array.isArray(value)
      ? value.map(key => String(key))
      : typeof value === 'object'
        ? Object.keys(value)
        : typeof value === 'string'
          ? value.match(/\w+/g) || []
          : []
}

/**
 * Gets a value from an object, based on a path to the property
 *
 * @param   {Object}                obj     The Object to get the value from
 * @param   {string|Array|Object}  [path]   The optional path to a sub-property
 * @returns {*}
 */
export function getValue (obj, path) {
  let value = obj
  const keys = getKeys(path)

  keys.every(function (key) {
    const valid = isPlainObject(value) && value.hasOwnProperty(key)
    value = valid ? value[key] : void 0
    return valid
  })
  return value
}

/**
 * Sets a value on an object, based on a path to the property
 *
 * @param   {Object}                state   The Object to set the value on
 * @param   {string|Array|Object}   path    The path to a sub-property
 * @param   {*}                     value   The value to set
 * @param   {boolean}               create  Create the value if it does not exist
 */
export function setValue (state, path, value, create = false) {
  const keys = path.split('.')
  return keys.reduce((obj, key, index) => {
    if (!obj) {
      return false
    }
    if (index === keys.length - 1) {
      obj[key] = value
      return true
    }
    if (!isObject(obj[key]) || !(key in obj)) {
      if (create) {
        obj[key] = {}
      }
      else {
        return false
      }
    }
    return obj[key]
  }, state)
}

/**
 * Checks an object has a property, based on a path to the property
 *
 * @param   {Object}                obj     The Object to check the value on
 * @param   {string|Array|Object}   path    The path to a sub-property
 * @returns {boolean}                       Boolean true or false
 */
export function hasValue (obj, path) {
  let keys = getKeys(path)
  if (isObject(obj)) {
    while (keys.length) {
      let key = keys.shift()
      if (key && hasKey(obj, key)) {
        obj = obj[key]
      }
      else {
        return false
      }
    }
    return true
  }
  return false
}

/**
 * Compares two objects to see if the values are loosely equal (empty values considered equal)
 * @param   {object}  aObj
 * @param   {object}  bObj
 * @param   {array}   keys
 * @return  {boolean}
 */
export function isLooseEqual (aObj, bObj, keys = undefined) {
  aObj = clone(aObj)
  bObj = clone(bObj)
  keys = keys || uniq([...Object.keys(aObj), ...Object.keys(bObj)])
  return keys.every(key => {
    const a = aObj[key]
    const b = bObj[key]
    // lodash isEqual compares non-primitives properly, and we augment with "Nil" values
    return _.isEqualWith(a, b, function (a, b) {
      const aIsNull = a === undefined || a === null || a === ''
      const bIsNull = b === undefined || b === null || b === ''
      if (aIsNull && bIsNull) {
        return true
      }
    })
  })
}
