import Vue from 'vue'

/**
 * Vue directive to allow SHIFT + Click for Element UI Table "select" column type
 */
Vue.directive('el-table-select', {
  bind: function (el, binding, vnode) {

    function onKey (event) {
      if (event.key === 'Shift') {
        isShiftDown = event.type === 'keydown'
        if (event.type === 'keyup' && selectedLast) {
          selectedFirst = selectedLast
          table.setCurrentRow(selectedFirst)
        }
      }
    }

    function onRowSelect (selection, selected) {
      // select first
      if (!isShiftDown) {
        table.setCurrentRow(selected)
        selectedFirst = selected
        selectedLast = null
      }

      // select last
      else {
        // new state
        const state = selected === selectedLast
          ? selection.includes(selected)
          : true

        // deselect previous
        if (selectedLast) {
          updateSelection(selectedLast, false)
        }

        // select current
        updateSelection(selected, state)

        // update
        selectedLast = selected
      }

    }

    function updateSelection (selectedLast, state) {
      // variables
      const data = table.tableData

      // indices
      const indexFirst = data.indexOf(selectedFirst)
      const indexLast = data.indexOf(selectedLast)

      // update rows if shift
      let min = Math.min(indexLast, indexFirst)
      let max = Math.max(indexLast, indexFirst)
      for (let i = min; i <= max; i++) {
        if (selectable(data[i], i)) {
          table.$nextTick(() => table.toggleRowSelection(data[i], state))
        }
      }

      // optionally ensure first stays selected
      if (binding.modifiers.first) {
        table.toggleRowSelection(selectedFirst, true)
      }
    }

    // variables
    let selectedFirst
    let selectedLast
    let isShiftDown

    // objects
    const table = vnode.componentInstance
    const selectable = vnode.componentOptions.children[0].componentOptions.propsData.selectable || (() => true)

    // add listeners
    table.$on('select', onRowSelect)
    document.addEventListener('keydown', onKey)
    document.addEventListener('keyup', onKey)

    // remove listeners when table is removed
    table.$options.destroyed.push(function () {
      table.$off('select', onRowSelect)
      document.removeEventListener('keydown', onKey)
      document.removeEventListener('keyup', onKey)
    })

  }
})
