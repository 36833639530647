import { commitErrors, ErrorBag, ErrorResponse, ErrorsModel } from '..'
import IErrorsMap from 'modules/errors/models/IErrorsMap'

/**
 * Convert table validation errors:
 *
 *  - from `itemsUpdated[n].field` => [ message ]
 *  - to `<reference / bloomberg` => messages[]
 */
export function convertTableErrors (response: ErrorResponse): any {
  // objects
  const errors: ErrorBag = response.errors.data
  const output: IErrorsMap = {}

  // loop over errors and append to output
  Object
    .keys(errors)
    .forEach(path => {
      // variables
      const error = errors[path]

      // add group
      if (!output[path]) {
        output[path] = []
      }

      // Change Item 860 - Only display the server error message
      // as some field names are interpreted differently in the API
      // and therefore their property name doesn't always convey the correct meaning

      // add message
      error.messages.forEach(message => {
        output[path].push(message)
      })
    })

  // return
  return output
}

/**
 * Handle a table items validation error response
 *
 * Converts the errors hash to something more useful for humans
 */
export function handleTableErrors (response: ErrorResponse): Promise<ErrorResponse> {
  if (response.errors && response.errors.data) {
    // convert incoming data
    const data = convertTableErrors(response)

    // attach to response
    response.errors = ErrorsModel.fromData(data)

    // commit to global store
    commitErrors(response.errors)

    // reject in case it needs to be re-catched
    return Promise.reject(response)
  }
  return Promise.resolve(response)
}
