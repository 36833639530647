<template>
  <el-table class="loans-table"
            :data="items"
            :highlight-current-row="false"
            :data-selected-length="selected.length"
            @selection-change="onSelectionChange"
            @row-click="onRowClick"
            v-bind="$attrs"
            v-el-table-select
  >
    <el-table-column type="selection"
                     :selectable="isSelectable"
                     width="30"
    />
    <el-table-column label="Reference"
                     prop="reference"
                     width="90"
                     sortable
    />
    <el-table-column v-if="hasRole" label="Role"
                     width="80"
                     sortable
    >
      <template slot-scope="scope">
        {{ getRole(scope.row) }}
      </template>
    </el-table-column>
    <el-table-column label="Counterparty"
                     width="180"
                     sortable
    >
      <template slot-scope="scope">
        {{ getCounterparty(scope.row) }}
      </template>
    </el-table-column>
    <el-table-column label="Asset"
                     prop="bloomberg"
                     width="120"
                     sortable
    />
    <el-table-column label="Qty"
                     prop="quantity"
                     width="70"
                     align="right"
                     sortable
                     :formatter="$formatCol('numeric')"
    />
    <el-table-column label="Rate"
                     prop="rate"
                     width="65"
                     align="right"
                     sortable
                     :formatter="$formatCol('rate')"
    />
    <!--
        <el-table-column label="Settled"
                         prop="settlementDate"
                         width="90"
                         sortable
        />
    -->
    <el-table-column label="Term end"
                     prop="termEndDate"
                     width="75"
                     sortable
    />
    <el-table-column label="Net Div / Margin"
                     prop="netDividendRequirement"
                     align="right"
                     width="100"
                     sortable
                     :formatter="$formatCol('rate', 3)"
    />
    <el-table-column label="Ccy"
                     prop="currencyCode"
                     width="70"
                     sortable
    />
    <el-table-column label="Collateral"
                     prop="collateralBasket.code"
                     width="80"
                     sortable
    />
    <el-table-column label="Fund"
                     prop="fundName"
                     sortable
    />

    <el-table-column label="State"
                     prop="state"
                     sortable>
      <template slot-scope="scope">
        <LoanStateTag :state="scope.row.state"/>
      </template>
    </el-table-column>
    <el-table-column label="Activity"
                     prop="hasActiveLoanEvents"
                     sortable>
      <template slot-scope="scope">
        <span v-if="scope.row.hasActiveLoanEvents" class="tag">{{ getEvents(scope.row) }}</span>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { isBorrowerFor, OrganizationMixin, getLoanRoleText } from 'modules/organizations'
import { LoanState } from 'modules/loans'
import LoanStateTag from '../components/LoanStateTag'

export default {
  mixins: [
    OrganizationMixin,
  ],

  components: {
    LoanStateTag,
  },

  props: {
    items: Array,
  },

  data () {
    return {
      selected: [],
    }
  },

  computed: {
    hasRole () {
      return this.canLend && this.canBorrow
    }
  },

  methods: {
    onSelectionChange (selected) {
      this.selected = selected
      this.$emit('selection-change', selected)
    },

    onRowClick (loan, column, event) {
      if (column.type !== 'selection' && this.selected.length === 0) {
        this.$emit('row-click', loan)
      }
    },

    isSelectable (row) {
      const isSelectable = row.state === LoanState.open
      if (this.canBorrow) {
        return isSelectable
      }

      if (this.canLend) {
        return isSelectable && !row.termEndDate
      }

      return true
    },

    getRole (row) {
      return getLoanRoleText(row)
    },

    getCounterparty (row) {
      return isBorrowerFor(row)
        ? row.lenderName
        : row.borrowerName
    },

    getEvents (row) {
      const events = row.activeLoanEvents
      return Object.keys(events)
        .map(key => {
          const value = events[key]
          const text = value > 1 ? ` (${value})` : ''
          return key.replace('Agreed', '') + text
        })
        .join(', ')
    },
  }
}
</script>

<style lang="scss">
  .el-table.loans-table {

    .cell {
      padding-left: 5px;
      padding-right: 5px;
    }

    td.is-right .cell {
      padding-right: 15px;
    }

    &:not([data-selected-length="0"]) .hover-row > td {
      background-color: initial !important;
    }

    &[data-selected-length="0"] tbody td:not(:first-child) {
      cursor: pointer;
    }
  }
</style>
