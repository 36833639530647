<template>
  <el-table :data="items"
            :row-class-name="getClass"
            empty-text="No new notifications"
            class="notifications-table"
            @row-click="onRowClick"
  >
    <el-table-column label="Unseen"
                     align="center"
                     width="50"
    >
      <template slot-scope="{ row }">
        <i v-if="getUnseen(row)" class="badge notification">1</i>
      </template>
    </el-table-column>

    <el-table-column label="Active"
                     align="center"
                     width="50"
    >
      <template slot-scope="{ row }">
        <i v-if="isActive(row)" class="el-icon-success"></i>
      </template>
    </el-table-column>

    <el-table-column label="Date / Time"
                     width="150"
                     prop="createdAt"
                     class-name="uncapitalised"
                     :formatter="$formatCol('dateRelative')"
    />

    <el-table-column label="From"
                     class-name="uncapitalised"
    >
      <template slot-scope="{ row }">
        {{ getUserFor(row, 'fromUserName') }}
      </template>
    </el-table-column>

    <el-table-column label="Type"
                     prop="typeLabel"
                     width="130">
    </el-table-column>

    <el-table-column label="Name / Reference"
                     width="160">
      <template slot-scope="{ row }">
        {{ getName(row) }}
      </template>
    </el-table-column>

    <el-table-column label="Reason"
                     width="80">
      <template slot-scope="{ row }">
        {{ getReason(row.reason) }}
      </template>
    </el-table-column>

    <el-table-column label="Seen By">
      <template slot-scope="{ row }">
        {{ getUserFor(row, 'seenByUserName') }}
      </template>
    </el-table-column>

    <el-table-column label="Seen At"
                     prop="seenAt"
                     width="150"
                     :formatter="$formatCol('dateRelative')"
    />

  </el-table>
</template>


<script>
import { OrganizationMixin, UserMixin } from 'modules/organizations'
import { NotificationReason } from '../'
import ElTableColumnUnseen from '../components/ElTableColumnUnseen'

export default {
  mixins: [
    OrganizationMixin,
    UserMixin,
  ],

  components: {
    ElTableColumnUnseen
  },

  props: {
    items: Array,
  },

  methods: {
    getUserFor (row, prop) {
      const userName = row[prop]
      return userName
        ? userName === this.userName
          ? 'You'
          : userName + ' at ' + row.fromOrganization.name
        : ''
    },

    getUnseen (item) {
      return !item.seenByUserName && this.userName !== item.fromUserName
    },

    getItem (notification) {
      return this.$store.get('notifications/getItemForNotification', notification)
    },

    isActive (notification) {
      return !!this.getItem(notification)
    },

    getName (notification) {
      const item = this.getItem(notification)
      return item && item.name
    },

    getClass ({ row }) {
      return this.isActive(row)
        ? 'is-loaded'
        : 'not-loaded'
    },

    getReason (value) {
      return NotificationReason[value].replace(/([a-z])([A-Z])/g, '$1 $2')
    },

    onRowClick (item) {
      this.$emit('edit', item)
    }
  }
}
</script>

<style lang="scss">
.notifications-table {

  tr {
    cursor: pointer;
  }

  .cell {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .not-loaded td {
    // color: $grey-lighter;
  }

  i.el-icon-success {
    font-size: 19px;
    line-height: 18px;
    vertical-align: -2px;
    color: $grey;
  }

}
</style>
