var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("UiFieldset", { attrs: { label: "Last Message" } }, [
    _c(
      "div",
      { staticClass: "negotiationMessages negotiationMessages--single" },
      [
        _c(
          "div",
          { staticClass: "negotiationMessages__info uiLabel capitalize" },
          [
            _c("span", { staticClass: "negotiationMessages__time" }, [
              _vm._v(_vm._s(_vm._f("dateRelative")(_vm.createdAt)))
            ]),
            _vm._v("  -\n      "),
            _c("span", { staticClass: "negotiationMessages__sender" }, [
              _vm._v(_vm._s(_vm.getSenderText(_vm.message)))
            ])
          ]
        ),
        _c("div", { staticClass: "negotiationMessages__message capitalize" }, [
          _vm.message.comment
            ? _c("output", { staticClass: "negotiationMessages__comment" }, [
                _vm._v(_vm._s(_vm.message.comment))
              ])
            : _c("output", { staticClass: "color-info-darkest" }, [
                _vm._v("No comment")
              ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }