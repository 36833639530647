import Api from 'services/Api'
import { handleTableErrors } from 'modules/errors'
import { lookups } from 'modules/global'
import LongPosition from '../models/LongPosition'

function state () {
  return {
    id: '',
    items: []
  }
}

const actions = {
  load ({ commit, dispatch }) {
    commit('clear')
    const path = `/long-positions`
    return Api.get(`${path}`)
      .then(res => {
        // no content
        if (res.status === 204) {
          return []
        }

        // content
        commit('id', res.data.id)
        return Api.get(`${path}/items?pageSize=0`).then(res => {
          // items
          const rawItems = res.data.items
          const items = rawItems.map(LongPosition.fromServer)

          // pass raw securities to securities store so prices are available
          dispatch('updateSecurities', rawItems)

          // commit flattened data to list
          commit('items', items)
          return items
        })
      })
  },

  upload ({ state, commit, dispatch }, data) {
    return Api
      .post(`/long-positions/file`, data)
      .then(res => dispatch('load'))
  },

  updateItems ({ state, commit, dispatch, rootGetters }, items) {
    // build payload for adding
    const payload = items
      .filter(item => item.bloomberg && item.quantity)
      .map(item => {
        return LongPosition
          .fromTable(item)
          .toServer(lookups)
      })
      .filter(item => item.securityId)

    // add
    return Api.post(`/long-positions`, payload).catch(handleTableErrors)
  },

  updateSecurities ({ commit }, items) {
    // pass raw securities to securities store so prices are available
    commit('securities/items', items.map(item => item.security), { root: true })
  }

}

const mutations = {
  clear (oldState) {
    Object.assign(oldState, state())
  },

  id (state, value) {
    state.id = value
  },

  items (state, items) {
    state.items = items
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
