import Vue from 'vue'
import { plugin, Auth } from 'vendor/msal'

// services
import { options as authOptions } from 'config/auth'
import apiConfig from '../config/api'
import Api from './Api'

// use
Vue.use(plugin, authOptions)
Vue.use(Api, Auth, apiConfig.root)

export {
  Auth,
  Api,
}

// @ts-ignore
window.Auth = Auth
