import ValidationOptions from '../interfaces/ValidationOptions'
import { Actions, Responses, Roles } from '../types'

export function makeOptions (...args: Array<Actions | Responses | Roles>) {
  const output: ValidationOptions = {}
  args.forEach(arg => {
    if (arg === 'create' || arg === 'edit') {
      output.action = arg
    }
    else if (arg === 'reply' || arg === 'accept') {
      output.response = arg
    }
    else if (arg === 'borrower' || arg === 'lender') {
      output.role = arg
    }
  })
  return output
}
