var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard__col", attrs: { "data-type": _vm.type } },
    [
      _c("div", { staticClass: "dashboard__colHeader" }, [
        _c("h2", [_vm._v(_vm._s(_vm.title))]),
        _c("div", { staticClass: "controls" }, [_vm._t("default")], 2),
        _c("small", [_vm._v(_vm._s(_vm.info))])
      ]),
      _c(
        "div",
        { staticClass: "dashboard__colBody" },
        _vm._l(_vm.visibleItems, function(item) {
          return _c(_vm.component, {
            key: item.id,
            ref: "card",
            refInFor: true,
            tag: "component",
            attrs: { item: item }
          })
        }),
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }