import { Hash } from 'core/types'

/**
 * Validation rules provider class
 *
 * Manages rules, generation of rules, labels, cross-field and custom options
 */
export default class RulesProvider {

  // a hash of rules for each model property
  public rules: Hash = {}

  // a hash of labels for each model property
  public names: Hash = {}

  // a hash of fields so HoT knows which fields to use for cross-field validation
  public dependents: Hash = {}

  // any validator specific options (can be re-typed in subclasses)
  public options: Hash = {}

  public constructor (names: Hash = {}, dependents: Hash = {}, options: Hash = {}) {
    this.names = names
    this.dependents = dependents
    this.options = options
    this.update()
  }

  /**
   * Convenience creator function for simple rules providers without options & update() function
   *
   * @param rules
   * @param names
   * @param dependents
   */
  public static create (rules: Hash, names: Hash, dependents: Hash = {}) {
    const provider = new RulesProvider(names, dependents)
    provider.rules = rules
    return provider
  }

  public update (): void {
    // subclasses should update rules
  }
}
