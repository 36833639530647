var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    _vm._b(
      {
        directives: [{ name: "el-table-select", rawName: "v-el-table-select" }],
        staticClass: "loans-table",
        attrs: {
          data: _vm.items,
          "highlight-current-row": false,
          "data-selected-length": _vm.selected.length
        },
        on: {
          "selection-change": _vm.onSelectionChange,
          "row-click": _vm.onRowClick
        }
      },
      "el-table",
      _vm.$attrs,
      false
    ),
    [
      _c("el-table-column", {
        attrs: { type: "selection", selectable: _vm.isSelectable, width: "30" }
      }),
      _c("el-table-column", {
        attrs: {
          label: "Reference",
          prop: "reference",
          width: "90",
          sortable: ""
        }
      }),
      _vm.hasRole
        ? _c("el-table-column", {
            attrs: { label: "Role", width: "80", sortable: "" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _vm._v(
                        "\n      " + _vm._s(_vm.getRole(scope.row)) + "\n    "
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              3121946288
            )
          })
        : _vm._e(),
      _c("el-table-column", {
        attrs: { label: "Counterparty", width: "180", sortable: "" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _vm._v(
                  "\n      " + _vm._s(_vm.getCounterparty(scope.row)) + "\n    "
                )
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: "Asset", prop: "bloomberg", width: "120", sortable: "" }
      }),
      _c("el-table-column", {
        attrs: {
          label: "Qty",
          prop: "quantity",
          width: "70",
          align: "right",
          sortable: "",
          formatter: _vm.$formatCol("numeric")
        }
      }),
      _c("el-table-column", {
        attrs: {
          label: "Rate",
          prop: "rate",
          width: "65",
          align: "right",
          sortable: "",
          formatter: _vm.$formatCol("rate")
        }
      }),
      _c("el-table-column", {
        attrs: {
          label: "Term end",
          prop: "termEndDate",
          width: "75",
          sortable: ""
        }
      }),
      _c("el-table-column", {
        attrs: {
          label: "Net Div / Margin",
          prop: "netDividendRequirement",
          align: "right",
          width: "100",
          sortable: "",
          formatter: _vm.$formatCol("rate", 3)
        }
      }),
      _c("el-table-column", {
        attrs: { label: "Ccy", prop: "currencyCode", width: "70", sortable: "" }
      }),
      _c("el-table-column", {
        attrs: {
          label: "Collateral",
          prop: "collateralBasket.code",
          width: "80",
          sortable: ""
        }
      }),
      _c("el-table-column", {
        attrs: { label: "Fund", prop: "fundName", sortable: "" }
      }),
      _c("el-table-column", {
        attrs: { label: "State", prop: "state", sortable: "" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [_c("LoanStateTag", { attrs: { state: scope.row.state } })]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: "Activity", prop: "hasActiveLoanEvents", sortable: "" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                scope.row.hasActiveLoanEvents
                  ? _c("span", { staticClass: "tag" }, [
                      _vm._v(_vm._s(_vm.getEvents(scope.row)))
                    ])
                  : _vm._e()
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }