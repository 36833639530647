import { route } from '@/app/router/helpers'

export default {
  stores: {
    availabilityList: require('./state/AvailabilityListStore').default,
  },

  routes: [
    route('/availability-list/', 'availability-list@Index.vue', {
      children: [
        route('', 'availability-list@EditAvailabilityList.vue'),
      ],
    }),
  ]
}
