import Vue from 'vue'

export function removeTabIndex (parent, selector) {
  Vue.nextTick(() => {
    const elements = parent.querySelectorAll(selector)
    if (elements) {
      Array.from(elements).forEach(element => {
        element.setAttribute('tabindex', '-1')
      })
    }
  })
}
