import Api from 'services/Api'
import Pagination from 'models/Pagination'
import LoanItem from '../../loans/models/LoanItem'
import LoansFilters from '../models/LoanFilters'

class State {
  items: Array<LoanItem>
  activeTab: string
  selection: Array<LoanItem>
  selected: Array<LoanItem>
  filters: LoansFilters
  pagination: Pagination

  constructor () {
    this.items = []
    this.activeTab = 'index'
    this.selection = []
    this.selected = []
    this.filters = new LoansFilters()
    this.pagination = Pagination.create(20)
  }
}

function state () {
  return new State()
}

const actions = {
  load ({ state, commit }) {
    const payload = {
      ...state.filters,
      ...state.pagination.toServer(),
      excludeIds: state.selected.map(item => item.id)
    }
    return Api
      .get('loans', payload)
      .then(res => {
        commit('pagination', Pagination.fromServer(res.data))
        commit('items', res.data.items.map(LoanItem.fromServer))
      })
  },
}

const mutations = {
  items (state: State, items) {
    state.items = items
  },

  activeTab (state: State, tab) {
    state.activeTab = tab
  },

  selection (state: State, items) {
    state.selection = items
  },

  selected (state: State, items) {
    state.selected = items
  },

  filters (state: State, filters) {
    state.pagination.page = 1
    state.filters = new LoansFilters(filters)
  },

  reset (state: State) {
    state.filters = new LoansFilters()
    state.activeTab = 'index'
    state.selected = []
    state.selection = []
  },

  pagination (state: State, data) {
    state.pagination = new Pagination(data)
  },

  page (state: State, page) {
    state.pagination.page = page
  },

  clearTermLoans (state: State) {
    state.selected = state.selected.filter(loan => !loan.termEndDate)
  },
}

const getters = {
  items (state) {
    return state.selected.length
      ? state.items.filter(item => !state.selected.find(sel => sel.id === item.id))
      : state.items
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
