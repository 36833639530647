var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.messages.length > 1
    ? _c(
        "UiFieldset",
        { attrs: { label: "History" } },
        [
          !_vm.canToggle || _vm.open
            ? _c(
                "div",
                { staticClass: "negotiationMessages" },
                [
                  _c(
                    "el-radio-group",
                    {
                      nativeOn: {
                        keydown: [
                          function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "down", 40, $event.key, [
                                "Down",
                                "ArrowDown"
                              ])
                            ) {
                              return null
                            }
                            $event.preventDefault()
                            return (function(e) {
                              return e
                            })($event)
                          },
                          function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "up", 38, $event.key, [
                                "Up",
                                "ArrowUp"
                              ])
                            ) {
                              return null
                            }
                            $event.preventDefault()
                            return (function(e) {
                              return e
                            })($event)
                          }
                        ]
                      },
                      model: {
                        value: _vm.input,
                        callback: function($$v) {
                          _vm.input = $$v
                        },
                        expression: "input"
                      }
                    },
                    _vm._l(_vm.messages, function(message, index) {
                      return _c(
                        "el-radio",
                        {
                          key: message.id,
                          staticClass: "negotiationMessages__item",
                          class: {
                            hasComment: message.comment,
                            isReceived: !message.isSent
                          },
                          attrs: { label: index }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "negotiationMessages__info uiLabel capitalize"
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "negotiationMessages__time" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("dateRelative")(message.createdAt)
                                    )
                                  )
                                ]
                              ),
                              _vm._v("  -\n          "),
                              _c(
                                "span",
                                { staticClass: "negotiationMessages__sender" },
                                [_vm._v(_vm._s(_vm.getSenderText(message)))]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "negotiationMessages__message capitalize"
                            },
                            [_vm._v(_vm._s(message.comment || "No message"))]
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.canToggle
            ? _c(
                "el-button",
                {
                  staticClass: "is-plain",
                  attrs: { size: "mini", type: "plain" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.$emit("toggle")
                    }
                  }
                },
                [
                  !_vm.open
                    ? _c("span", [_vm._v(_vm._s(_vm.toggleLabel))])
                    : _c("span", [_vm._v("Hide")])
                ]
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }