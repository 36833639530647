import Hash from 'core/types/Hash'
import { Collateral, Currency } from 'modules/resources'

export default class NegotiationDefaults {

  public defaultCurrency: Currency

  public defaultCollateralBasket: Collateral

  constructor (data: Hash = {}) {
    this.defaultCurrency = data.defaultCurrency || new Currency()
    this.defaultCollateralBasket = data.defaultCollateralBasket || new Collateral()
  }

  static fromServer (data: Hash = {}) {
    const output = {
      defaultCurrency: Currency.fromServer(data.defaultCurrency || {}),
      defaultCollateralBasket: Collateral.fromServer(data.defaultCollateralBasket || {}),
    }
    return new NegotiationDefaults(output)
  }

  toServer () {
    return {
      defaultCurrencyId: this.defaultCurrency.id,
      defaultCollateralBasketId: this.defaultCollateralBasket.id,
    }
  }
}
