<template>
  <div class="layout__main" id="edit-locate-list">

    <SiteAside label="Options">

      <el-form ref="form"
               :model="form"
               label-position="top"
               label-width="120px"
               @submit.prevent.native=""
      >
        <UiFieldset label="Data">

          <UiInput ref="name" label="Name" v-model="form.name" maxlength="50"/>

          <div>
            <UiUpload class="mt-4" auto @submit="upload"/>
          </div>

          <div class="flex">
            <el-button v-if="items.isUpdated" :disabled="!items.isValid" @click="submit" type="primary">Save</el-button>
            <el-button v-else @click="submit" type="primary">OK</el-button>
            <el-button v-if="isEditing" @click="negotiate" type="primary" :disabled="!items.isValid || model.isLocated">Negotiate</el-button>
            <el-button @click="cancel">Exit</el-button>
            <el-button v-if="isEditing" @click="discard">Discard</el-button>
          </div>

        </UiFieldset>

      </el-form>

      <UiFieldset label="Information" v-if="isEditing && relatedNegotiations.length">

        <h4>Related Negotiations</h4>
        <ul>
          <li v-for="item in relatedNegotiations">
            <router-link :to="`/negotiations/loans/edit/${item.id}`">{{ item.createdAt | dateRelative }} to
              {{ item.lenderOrganization.name }}
            </router-link>
          </li>
        </ul>

      </UiFieldset>

    </SiteAside>

    <section class="layout__content panel">

      <ContentHeader title="Assets">
        <UiOutput label="Total">{{ items.data.length | integer }}</UiOutput>
        <UiOutput label="Value"><span class="symbol">{{ currencyCode }}</span> {{ value | integer }}</UiOutput>
        <UiOutput label="Value % located">{{ model.valuePercentageLocated }}</UiOutput>
        <UiOutput label="Volume % located">{{ model.volumePercentageLocated }}</UiOutput>
        <UiOutput label="Average % located">{{ model.averagePercentageLocated }}</UiOutput>
      </ContentHeader>

      <UiErrors/>
      <LocateListTable ref="table" :data="items.data" />
    </section>

  </div>

</template>

<script>
import debounce from 'lodash/debounce'
import { dispatch, get } from 'vuex-pathify'
import { makeTableParent } from 'vendor/handsontable'
import { formatDateTimeHuman } from 'utils/formatters'
import { sumLoanValues } from 'modules/loans'
import { getDefaultCurrencyCode } from 'modules/trading-profile'
import LocateListTable from '../components/LocateListTable'
import { LocateItem } from '..'

export default {

  extends: makeTableParent(),

  crumb: 'Edit Locate List',

  components: {
    LocateListTable,
  },

  metaInfo () {
    return {
      title: 'Locate'
    }
  },

  data () {
    return {
      value: 0,
      form: {
        id: '',
        name: '',
      },
    }
  },

  computed: {
    action () {
      return this.$route.path.split('/')[3]
    },

    source () {
      return this.$route.query.source
    },

    isEditing () {
      return this.action === 'edit'
    },

    currencyCode () {
      return getDefaultCurrencyCode()
    },

    model: get('locateList'),

    relatedNegotiations () {
      return this.$store.get('dashboard/getNegotiationsForLocateList', this.form.id)
    },
  },

  watch: {
    numUpdates: 'updateValue',
    model (model) {
      this.value = model ? model.value : 0
    },
  },

  beforeRouteEnter (to, from, next) {
    return to.params.id
      ? dispatch('locateList/load', to.params.id)
        .then(() => {
          !to.query.source
            ? dispatch('locateList/loadItems', to.params.id).then(next)
            : next()
        })
      : next()
  },

  mounted () {
    this.source || this.isEditing
      ? this.load()
      : this.$refs.name.focus()
  },

  methods: {
    updateValue: debounce(function () {
      const items = this.getItems()
      this.value = sumLoanValues(items, 'quantityRequired')
    }, 100),

    load () {
      const list = this.$store.state.locateList
      this.form.id = list.id
      this.form.name = list.name

      // check to see if we need to load other resources
      const { source, id } = this.$route.query
      switch (source) {
        // copy loans
        case 'loans':
          this.setItems(this.$store.state.loans.selected)
          break

        // load and convert recalls
        case 'recall':
          return dispatch('negotiations/recall/load', id).then(items => {
            this.setItems(items.map(item => LocateItem.fromLoan(item.loan)))
          })

        // load and convert rerates
        case 'rerate':
          return dispatch('negotiations/rerate/load', id).then(items => {
            this.setItems(items.map(item => LocateItem.fromLoan(item.loan)))
          })

        default:
          this.setItems(list.items)
      }
    },

    submit () {
      const items = this.getItems()
      if (items.length) {
        switch (this.action) {
          case 'edit':
            return this.$store
              .dispatch('locateList/updateItems', { items, form: this.form })
              .then(this.onDone)

          case 'create':
            this.checkName()
            return this.$store
              .dispatch('locateLists/create', { items, form: this.form })
              .then(this.onDone)

          default:
            return this.onDone()
        }
      }
      return this.onDone()
    },

    upload (data) {
      switch (this.action) {
        case 'create':
          this.checkName()
          data.append('name', this.form.name)
          return this.$store
            .dispatch('locateLists/createFromFile', data)
            .then(id => {
              this.$router.push(`/negotiations/locates/edit/${id}`)
            })

        case 'edit':
          return this.$store
            .dispatch('locateList/upload', data)
            .then(items => {
              this.setItems(items)
            })
      }
    },

    cancel () {
      this.onDone()
    },

    negotiate () {
      this.submit().then(() => {
        this.$router.push(`/negotiations/loans/create?source=locate&id=${this.form.id}`)
      })
    },

    discard () {
      return this.$confirm('Are you sure you want to remove this list?')
        .then(() => {
          return this.$store
            .dispatch('locateLists/remove', this.form.id)
            .then(this.onDone)
        })
    },

    checkName () {
      if (!this.form.name.trim()) {
        this.form.name = formatDateTimeHuman(new Date())
      }
    },

    onDone () {
      this.$router.push('/negotiations')
    }
  }
}
</script>
