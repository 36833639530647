<template>

  <div class="anSelect__list" :data-value-position="showValue">
    <ul class="el-select-dropdown__list" v-loading="isLoading">
      <template v-if="filtered.length">
        <SelectOption
            v-for="option in filtered"
            :key="option[keyValue]"
            :value="option[keyValue]"
            :labelHtml="getOptionHtml(option[keyLabel])"
            :valueHtml="showValue && getOptionHtml(option[keyValue])"
            :class="getOptionClass(option)"
            :show-value="showValue"
            @mousedown="onOptionClick(option)"
        />
      </template>
      <SelectOption v-else-if="firstRun"
                    labelHtml="No items"
                    valueHtml="Type to initiate a search"
                    :show-value="showValue || 'below'" />
      <SelectOption v-else
                    labelHtml="No results"
                    valueHtml="You may need to modify your search criteria"
                    :show-value="showValue || 'below'" />
    </ul>
  </div>

</template>

<script>
import SelectOption from './SelectOption'

export default {
  components: {
    SelectOption,
  },

  props: {
    source: {
      type: [Array, Function],
      default () {
        return [
          { label: 'No data!', value: '' }
        ]
      }
    },

    input: {
      type: String,
      default: '',
    },

    selected: {
      type: String,
      default: '',
    },

    keyLabel: {
      type: String,
      default: 'label'
    },

    keyValue: {
      type: String,
      default: 'value'
    },

    showValue: {
      string: '',
      validator (value) {
        return ['right', 'below'].includes(value)
      }
    }
  },

  data () {
    return {
      value: '',
      options: [],
      focused: '',
      isLoading: false
    }
  },

  computed: {
    trimmedInput () {
      return (this.input || '').trim()
    },

    filtered () {
      // don't filter ajax-requested options
      if (this.isAsync) {
        return this.options
      }

      // if no input, return options
      const input = this.trimmedInput.toLowerCase()
      if (!input) {
        return this.options
      }

      // filter
      return this.options.filter(option => {
        const foundInValue = this.showValue && option[this.keyValue].toLowerCase().includes(input)
        const foundInLabel = option[this.keyLabel].toLowerCase().includes(input)
        return foundInLabel || foundInValue
      })
    },

    isAsync () {
      return typeof this.source === 'function'
    },

    firstRun () {
      return this.isAsync && this.trimmedInput === '' && this.options.length === 0
    }
  },

  watch: {
    source: 'onSource',
    input: 'onInput',
    focused: 'update',
    selected (value) {
      this.value = value
    },
  },

  methods: {
    setFocus (value) {
      // specific item
      if (typeof value === 'string') {
        this.focused = value
      }

      // prev / next
      else if (typeof value === 'number') {
        // items
        const filtered = this.filtered

        // no items to focus
        if (filtered.length === 0) {
          return
        }

        // focus something...
        const option = this.getOption(this.focused) || this.getOption(this.selected)
        let index = filtered.indexOf(option)

        // no currently-focused item
        if (index === -1) {
          index = value < 0
            ? filtered.length - 1
            : 0
          this.focused = filtered[index][this.keyValue]
        }

        // currently-focused
        else {
          index += value
          if (index > filtered.length - 1) {
            index = 0
          }
          if (index < 0) {
            index = filtered.length - 1
          }
          this.focused = filtered[index][this.keyValue]
        }
      }
    },

    getOption (value) {
      return this.filtered.find(option => option[this.keyValue] === value)
    },

    getOptionClass (option) {
      return {
        selected: option[this.keyValue] === this.selected,
        hover: option[this.keyValue] === this.focused,
      }
    },

    getOptionHtml (text) {
      const input = this.trimmedInput
      if (input && text) {
        const rx = new RegExp(`(${input})`, 'gi')
        return text.replace(rx, '<strong>$1</strong>')
      }
      return text || '&nbsp;'
    },

    update () {
      // variables
      let option

      // if we have input, focus the most likely option
      option = this.getOption(this.focused) || this.getOption(this.selected)
      if (this.trimmedInput && !option && this.filtered.length > 0) {
        this.focused = this.filtered[0][this.keyValue]
      }

      // if we have an option, set the value
      option = this.getOption(this.focused) || this.getOption(this.selected)
      this.value = option
        ? option[this.keyValue]
        : ''
    },

    onSource () {
      const source = this.source
      if (this.isAsync) {
        this.isLoading = true
        source(this.trimmedInput, options => {
          this.options = options
          this.isLoading = false
          this.update()
        })
      } else {
        this.options = source
        this.update()
      }
    },

    onInput () {
      this.isAsync
        ? this.onSource()
        : this.update()
    },

    onOptionClick (option) {
      this.selected = option[this.keyValue]
    },

    /**
     * This handler is currently called from outside the component, by SelectMediator
     * @param event
     */
    onKeyDown (event) {
      const { key } = event
      const cancel = function () {
        event.preventDefault()
        event.stopImmediatePropagation()
      }

      if (key === 'ArrowUp') {
        this.setFocus(-1)
        return cancel()
      }
      else if (key === 'ArrowDown') {
        this.setFocus(1)
        return cancel()
      }
    },

    clear () {
      this.input = ''
      this.value = ''
      this.focused = ''
      this.selected = ''
    }

  }
}
</script>

<style lang="scss">
.anSelect {

  &__container {
    position: absolute;
    z-index: 9999;
    width: auto;
    border: 1px solid #DDD;
    background: white;
    box-shadow: 0 5px 10px #00000022;
  }

  &__clear {
    position: absolute;
    right: 0;
    top: 0;
    padding: 6px;
    padding-right: 8px;
    font-weight: bold;
    color: #DDDDDD;
    &:hover {
      color: black;
    }
    &:after {
      content: '×';
      height: 20px;
      width: 14px;
      font-size: 18px;
      line-height: 20px;
      display: block;
      text-align: center;
    }
  }

  &__item--value {
    color: #AAA;
    display: none;
  }

  &__list[data-value-position="right"] &__item--value {
    display: block;
    float: right;
    padding-left: 20px
  }

  &__list[data-value-position="below"] {

    .anSelect__item {
      height: 46px;
      &:not(.selected) strong {
        color: $grey-darker;
        border-bottom: 1px solid $color-warning;
        // background: mix($color-info-lightest, transparent, 50);
      }
    }

    .anSelect__item--label {
      position: relative;
      top: -1px;
    }

    .anSelect__item--value {
      display: block;
      font-size: 0.8em;
      line-height: 0.7em;
      margin-top: -5px;
    }
  }

}
</style>
