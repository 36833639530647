import { get } from 'vuex-pathify'

export default {
  computed: {
    isSystem: get('organizations/isSystem'),

    canBorrow () {
      return get('organizations/current').canBorrow()
    },

    canLend () {
      return get('organizations/current').canLend()
    },
  }
}
