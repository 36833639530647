var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout__main", attrs: { id: "edit-long-positions" } },
    [
      _c(
        "SiteAside",
        { attrs: { label: "Options" } },
        [
          _c(
            "UiFieldset",
            { attrs: { label: "Data" } },
            [
              _c("UiUpload", {
                attrs: { auto: "" },
                on: { submit: _vm.upload }
              }),
              _c(
                "div",
                { staticClass: "flex" },
                [
                  _vm.items.isUpdated
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            disabled: !_vm.items.isValid,
                            type: "primary"
                          },
                          on: { click: _vm.submit }
                        },
                        [_vm._v("Save")]
                      )
                    : _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.onDone }
                        },
                        [_vm._v("OK")]
                      ),
                  _c("el-button", { on: { click: _vm.cancel } }, [
                    _vm._v("Exit")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "section",
        { staticClass: "layout__content panel" },
        [
          _c("h2", [_vm._v("Assets")]),
          _c("UiErrors"),
          _c("LongPositionsTable", {
            ref: "table",
            attrs: { data: _vm.items.data }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }