import { ResourceType } from 'modules/global'

export default {
  [ResourceType.LoanNegotiation]: '/negotiations/loans/:action/:id',
  [ResourceType.RerateNegotiation]: '/negotiations/rerates/:action/:id',
  [ResourceType.RecallNegotiation]: '/negotiations/recalls/:action/:id',
  [ResourceType.ReturnNegotiation]: '/negotiations/returns/:action/:id',
  [ResourceType.LocateList]: '/negotiations/locates/:action/:id',
  [ResourceType.AvailabilityList]: '/availability-list',
  [ResourceType.Loan]: '/loans/view/:id',
}
