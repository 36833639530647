import ErrorsModel from './ErrorsModel'
import IErrorMap from './IErrorsMap'

export default class ErrorResponse {
  title: string

  errors: ErrorsModel

  payload: any

  status: number = 400

  constructor (data: any) {
    this.title = data.title
    this.errors = data.errors
    this.payload = data.payload
    this.status = data.status
  }

  static fromServer (data: any, payload: any): ErrorResponse {
    const errors = ErrorsModel.fromData(data.errors)
    return new ErrorResponse({ ...data, payload, errors })
  }
}
