import { AbstractNegotiation } from '..'

export default class RerateNegotiation extends AbstractNegotiation {

  public readonly type: string = 'rerate'

  public readonly priority: number = 3

  constructor (data: any = {}) {
    super(data)
  }

  static fromServer (data): RerateNegotiation {
    return new RerateNegotiation(data)
  }

}
