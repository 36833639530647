var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tools", class: { "is-open": _vm.isOpen } }, [
    _c("div", { staticClass: "tools__container" }, [_vm._t("default")], 2),
    _c(
      "div",
      { staticClass: "tools__lock" },
      [
        _c("UiIcon", {
          attrs: { icon: "lock|lock-open", state: _vm.isOpen },
          on: { click: _vm.toggle }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }