var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      staticClass: "loanFilters",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      }
    },
    [
      _c(
        "el-collapse",
        {
          model: {
            value: _vm.activeNames,
            callback: function($$v) {
              _vm.activeNames = $$v
            },
            expression: "activeNames"
          }
        },
        [
          _c(
            "el-collapse-item",
            { attrs: { name: "information" } },
            [
              _c(
                "template",
                { slot: "title" },
                [
                  _c("span", { staticClass: "loanFilters__groupTitle" }, [
                    _vm._v("Information")
                  ]),
                  _c("UiBadge", {
                    attrs: { value: _vm.filterCounts.information }
                  })
                ],
                1
              ),
              _c("ui-input", {
                attrs: {
                  label: "Reference",
                  placeholder: "Type...",
                  clearable: ""
                },
                nativeOn: {
                  keypress: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.submit($event)
                  }
                },
                model: {
                  value: _vm.form.reference,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form,
                      "reference",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.reference"
                }
              }),
              _vm.canLend && _vm.canBorrow
                ? _c("ui-select", {
                    attrs: {
                      label: "Loan Role",
                      options: _vm.options.loanRole,
                      valueKey: "value",
                      clearable: ""
                    },
                    model: {
                      value: _vm.form.loanRole,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "loanRole", $$v)
                      },
                      expression: "form.loanRole"
                    }
                  })
                : _vm._e(),
              _c("ui-select", {
                attrs: {
                  label: "State",
                  options: _vm.options.states,
                  valueKey: "value",
                  multiple: ""
                },
                model: {
                  value: _vm.form.states,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "states", $$v)
                  },
                  expression: "form.states"
                }
              }),
              _c("ui-select", {
                attrs: {
                  label: "Counterparties",
                  options: _vm.options.counterparties,
                  size: "mini",
                  multiple: ""
                },
                model: {
                  value: _vm.form.counterPartyOrganizationIds,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "counterPartyOrganizationIds", $$v)
                  },
                  expression: "form.counterPartyOrganizationIds"
                }
              })
            ],
            2
          ),
          _c(
            "el-collapse-item",
            { attrs: { name: "security" } },
            [
              _c(
                "template",
                { slot: "title" },
                [
                  _c("span", { staticClass: "loanFilters__groupTitle" }, [
                    _vm._v("Asset")
                  ]),
                  _c("UiBadge", { attrs: { value: _vm.filterCounts.security } })
                ],
                1
              ),
              _c("ui-select", {
                attrs: {
                  label: "Asset",
                  options: _vm.options.securities,
                  labelKey: "bloomberg",
                  optionKey: "bloomberg",
                  "value-key": "id",
                  filterable: "",
                  clearable: "",
                  remote: "",
                  "remote-method": _vm.searchSecurities,
                  placeholder: "Search by Bloomberg ticker...",
                  size: "mini"
                },
                on: {
                  clear: function($event) {
                    _vm.options.securities = []
                  }
                },
                model: {
                  value: _vm.form.securityId,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "securityId", $$v)
                  },
                  expression: "form.securityId"
                }
              }),
              _c("ui-select", {
                attrs: {
                  label: "Country",
                  options: _vm.options.countries,
                  valueKey: "value",
                  clearable: ""
                },
                model: {
                  value: _vm.form.country,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "country", $$v)
                  },
                  expression: "form.country"
                }
              })
            ],
            2
          ),
          _c(
            "el-collapse-item",
            { attrs: { name: "trade" } },
            [
              _c(
                "template",
                { slot: "title" },
                [
                  _c("span", { staticClass: "loanFilters__groupTitle" }, [
                    _vm._v("Trade")
                  ]),
                  _c("UiBadge", { attrs: { value: _vm.filterCounts.trade } })
                ],
                1
              ),
              _c("ui-range", {
                attrs: {
                  label: "Quantity",
                  minValue: _vm.form.quantityFrom,
                  maxValue: _vm.form.quantityTo
                },
                on: {
                  "update:minValue": function($event) {
                    return _vm.$set(_vm.form, "quantityFrom", $event)
                  },
                  "update:min-value": function($event) {
                    return _vm.$set(_vm.form, "quantityFrom", $event)
                  },
                  "update:maxValue": function($event) {
                    return _vm.$set(_vm.form, "quantityTo", $event)
                  },
                  "update:max-value": function($event) {
                    return _vm.$set(_vm.form, "quantityTo", $event)
                  }
                }
              }),
              _c("ui-range", {
                attrs: {
                  label: "Rate",
                  minValue: _vm.form.rateFrom,
                  maxValue: _vm.form.rateTo,
                  max: 100,
                  suffix: "%"
                },
                on: {
                  "update:minValue": function($event) {
                    return _vm.$set(_vm.form, "rateFrom", $event)
                  },
                  "update:min-value": function($event) {
                    return _vm.$set(_vm.form, "rateFrom", $event)
                  },
                  "update:maxValue": function($event) {
                    return _vm.$set(_vm.form, "rateTo", $event)
                  },
                  "update:max-value": function($event) {
                    return _vm.$set(_vm.form, "rateTo", $event)
                  }
                }
              }),
              _c("ui-range", {
                attrs: {
                  label: "Net Dividend / Margin",
                  minValue: _vm.form.netDividendRequirementFrom,
                  maxValue: _vm.form.netDividendRequirementTo,
                  max: 100,
                  suffix: "%"
                },
                on: {
                  "update:minValue": function($event) {
                    return _vm.$set(
                      _vm.form,
                      "netDividendRequirementFrom",
                      $event
                    )
                  },
                  "update:min-value": function($event) {
                    return _vm.$set(
                      _vm.form,
                      "netDividendRequirementFrom",
                      $event
                    )
                  },
                  "update:maxValue": function($event) {
                    return _vm.$set(
                      _vm.form,
                      "netDividendRequirementTo",
                      $event
                    )
                  },
                  "update:max-value": function($event) {
                    return _vm.$set(
                      _vm.form,
                      "netDividendRequirementTo",
                      $event
                    )
                  }
                }
              }),
              _c("ui-select", {
                attrs: {
                  label: "Currency",
                  options: _vm.options.currencies,
                  labelKey: "code",
                  optionKey: "name",
                  multiple: ""
                },
                model: {
                  value: _vm.form.currencyIds,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "currencyIds", $$v)
                  },
                  expression: "form.currencyIds"
                }
              }),
              _c("ui-select", {
                attrs: {
                  label: "Collateral",
                  options: _vm.options.collateral,
                  labelKey: "code",
                  multiple: ""
                },
                model: {
                  value: _vm.form.collateralBasketIds,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "collateralBasketIds", $$v)
                  },
                  expression: "form.collateralBasketIds"
                }
              }),
              _c("ui-select", {
                attrs: {
                  label: "Funds",
                  options: _vm.options.funds,
                  multiple: ""
                },
                model: {
                  value: _vm.form.fundIds,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "fundIds", $$v)
                  },
                  expression: "form.fundIds"
                }
              })
            ],
            2
          ),
          _c(
            "el-collapse-item",
            { attrs: { name: "dates" } },
            [
              _c(
                "template",
                { slot: "title" },
                [
                  _c("span", { staticClass: "loanFilters__groupTitle" }, [
                    _vm._v("Dates")
                  ]),
                  _c("UiBadge", { attrs: { value: _vm.filterCounts.dates } })
                ],
                1
              ),
              _c("ui-range", {
                attrs: {
                  label: "Settlement Date",
                  minValue: _vm.form.settlementDateFrom,
                  maxValue: _vm.form.settlementDateTo,
                  type: "date"
                },
                on: {
                  "update:minValue": function($event) {
                    return _vm.$set(_vm.form, "settlementDateFrom", $event)
                  },
                  "update:min-value": function($event) {
                    return _vm.$set(_vm.form, "settlementDateFrom", $event)
                  },
                  "update:maxValue": function($event) {
                    return _vm.$set(_vm.form, "settlementDateTo", $event)
                  },
                  "update:max-value": function($event) {
                    return _vm.$set(_vm.form, "settlementDateTo", $event)
                  }
                }
              }),
              _c("ui-range", {
                attrs: {
                  label: "Term End Date",
                  minValue: _vm.form.termEndDateFrom,
                  maxValue: _vm.form.termEndDateTo,
                  type: "date"
                },
                on: {
                  "update:minValue": function($event) {
                    return _vm.$set(_vm.form, "termEndDateFrom", $event)
                  },
                  "update:min-value": function($event) {
                    return _vm.$set(_vm.form, "termEndDateFrom", $event)
                  },
                  "update:maxValue": function($event) {
                    return _vm.$set(_vm.form, "termEndDateTo", $event)
                  },
                  "update:max-value": function($event) {
                    return _vm.$set(_vm.form, "termEndDateTo", $event)
                  }
                }
              })
            ],
            2
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flex mt-4" },
        [
          _c("el-button", { on: { click: _vm.reset } }, [_vm._v("Reset")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v("Search")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }