var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "anSelect__list",
      attrs: { "data-value-position": _vm.showValue }
    },
    [
      _c(
        "ul",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading"
            }
          ],
          staticClass: "el-select-dropdown__list"
        },
        [
          _vm.filtered.length
            ? _vm._l(_vm.filtered, function(option) {
                return _c("SelectOption", {
                  key: option[_vm.keyValue],
                  class: _vm.getOptionClass(option),
                  attrs: {
                    value: option[_vm.keyValue],
                    labelHtml: _vm.getOptionHtml(option[_vm.keyLabel]),
                    valueHtml:
                      _vm.showValue && _vm.getOptionHtml(option[_vm.keyValue]),
                    "show-value": _vm.showValue
                  },
                  on: {
                    mousedown: function($event) {
                      return _vm.onOptionClick(option)
                    }
                  }
                })
              })
            : _vm.firstRun
            ? _c("SelectOption", {
                attrs: {
                  labelHtml: "No items",
                  valueHtml: "Type to initiate a search",
                  "show-value": _vm.showValue || "below"
                }
              })
            : _c("SelectOption", {
                attrs: {
                  labelHtml: "No results",
                  valueHtml: "You may need to modify your search criteria",
                  "show-value": _vm.showValue || "below"
                }
              })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }