<template>

  <div class="layout__main" id="create-negotiation">

    <SiteAside label="Options">

      <UiFieldset label="Source">
        <UiOutput label="Locate List">
          <router-link :to="`/negotiations/locates/edit/${form.sourceListId}`">{{ sourceListName || '...' }}</router-link>
        </UiOutput>
      </UiFieldset>

      <!-- form -->
      <el-form ref="form"
               :model="form"
               label-position="top"
               @submit.prevent.native=""
      >

        <!-- lenders -->
        <UiFieldset label="Lenders" class="lender__checkboxes">

          <UiCheckboxes
            label="With agreements"
            v-model="form.lenderIds"
            :options="lendersWithAgreements"
          >
            <template v-slot="option">
              {{ option.name }} {{ option.id === excludedLenderId ? '(excluded)' : '' }}
              <PercentTag v-if="option.rankedValuePercentageMatch !== undefined"
                          :value="option.rankedValuePercentageMatch"
                          :class="{ success: option.isBestRankedMatch }"
                          class="ml-2"
              />
              <PercentTag v-if="option.valuePercentageMatch !== undefined"
                          :value="option.valuePercentageMatch"
                          :class="{ success: option.isBestPercentageMatch }"
              />
            </template>
          </UiCheckboxes>

          <!-- this is a repeat of the previous array, but only used here so haven't bothered creating a component -->
          <UiCheckboxes
            v-if="lendersWithoutAgreements"
            label="Without agreements"
            v-model="form.lenderIds"
            :options="lendersWithoutAgreements"
          >
            <template v-slot="option">
              {{ option.name }}
              <PercentTag v-if="option.rankedValuePercentageMatch !== undefined"
                          :value="option.rankedValuePercentageMatch"
                          :class="{ success: option.isBestRankedMatch }"
                          class="ml-2"
              />
              <PercentTag v-if="option.valuePercentageMatch !== undefined"
                          :value="option.valuePercentageMatch"
                          :class="{ success: option.isBestPercentageMatch }"
              />
            </template>
          </UiCheckboxes>

        </UiFieldset>

        <UiFieldset label="Check Availability">
          <div class="flex mb-4 checkAvailability">

            <!-- preferred lender dropdown -->
            <el-select v-model="preferredLenderId"
                       placeholder="Preferred Lender"
                       size="mini"
                       @change="options.scores ? getScores() : null">
              <el-option
                v-for="item in preferredLenders"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                :disabled="item.disabled">
              </el-option>
            </el-select>

            <!-- button -->
            <el-button size="mini"
                       style="flex-grow: 0"
                       plain
                       class="inline-block ml-2"
                       @click="getScores"
            >Check...</el-button>

            <!-- popup -->
            <el-popover
              placement="top"
              trigger="click"
              width="600"
            >
              <div class="checkAvailability__popup">
                <h3>Check Availability</h3>
                <p><strong>You can check the availability of the required securities against lenders in the system.</strong></p>
                <p>The system returns two scores for each lender:</p>
                <ul>
                  <li>
                    <strong>Total Value Percentage Match (left)</strong>
                    <br>Calculates the value matched divided by the total value of the negotiation.
                  </li>
                  <li>
                    <strong>Ranked Value Percentage Match (right)</strong>
                    <br>Uses an algorithm to prioritise lenders who can supply 100% of requested securities first.
                  </li>
                </ul>
                <p>Any rows without a quantity will be excluded from both calculations.</p>
                <p>You can specify a <strong>Preferred Lender</strong> to prioritise in the ranked algorithm.</p>
              </div>

              <!-- help button -->
              <el-button size="mini"
                         slot="reference"
                         class="is-square ml-2"
                         plain
              ><i class="fas fa-question"></i></el-button>

            </el-popover>
          </div>
        </UiFieldset>

        <UiFieldset label="Submit">

          <el-checkbox v-model="form.useSmartUpdating">Use "Smart Updating"</el-checkbox>

          <NegotiationComment v-model="form.comment" />

          <UiSubmit
            @submit="submit"
            @cancel="cancel"
            :canSubmit="canSubmit"
          />

        </UiFieldset>

        <UiFieldset label="Private" divided>
          <NegotiationNotes v-model="form.privateNotes" />
        </UiFieldset>

      </el-form>
    </SiteAside>

    <!-- content -->
    <section class="layout__content panel">
      <h2>Negotiation Details</h2>

      <UiErrors/>

      <NegotiationItemsTable ref="table"
                             role="borrower"
                             action="create"
                             :data="items.data"
      />
    </section>

  </div>

</template>

<script>
import { dispatch } from 'vuex-pathify'
import Api from 'services/Api'
import LoanNegotiationItem from '../../models/LoanNegotiationItem'
import NegotiationItemsTable from '../../components/tables/NegotiationItemsTable'
import PercentTag from 'modules/global/components/PercentTag'
import factory from './create-factory'

export default {

  extends: factory('loan'),

  crumb: 'Loans',

  components: {
    NegotiationItemsTable,
    PercentTag,
  },

  data () {
    return {
      form: {
        sourceListId: '',
        useSmartUpdating: true,
        lenderIds: [],
      },
      sourceListName: '',
      excludedLenderId: '',
      preferredLenderId: '',
      options: {
        scores: [],
      },
    }
  },

  computed: {
    lenders () {
      // helpers
      function maxOption (options, prop) {
        return options.reduce((value, option) => {
          if (option[prop] > value) {
            value = option[prop]
          }
          return value
        }, 0)
      }

      // variables
      const bestPercentageMatch = maxOption(this.options.scores, 'valuePercentageMatch')
      const bestRankedMatch = maxOption(this.options.scores, 'rankedValuePercentageMatch')

      // return lenders
      return this.$store.state.organizations.counterparties
        .filter(organization => organization.canLend())
        .map((lender, index) => {
          const { valuePercentageMatch, rankedValuePercentageMatch } = this.options.scores[index] || {}
          return {
            ...lender,
            disabled: !lender.tradingAllowed || lender.id === this.excludedLenderId,
            valuePercentageMatch,
            rankedValuePercentageMatch,
            isBestPercentageMatch: valuePercentageMatch > 0 && valuePercentageMatch === bestPercentageMatch,
            isBestRankedMatch: rankedValuePercentageMatch > 0 && rankedValuePercentageMatch === bestRankedMatch,
          }
        })
    },

    preferredLenders () {
      return this.lenders.filter(item => item.tradingAllowed && !item.disabled)
    },

    lendersWithAgreements () {
      return this.lenders.filter(item => item.tradingAllowed)
    },

    lendersWithoutAgreements () {
      return this.lenders.filter(item => !item.tradingAllowed)
    },

    canSubmit () {
      return this.items.isValid && this.form.lenderIds.length > 0
    },
  },

  methods: {
    load () {
      const { source, id } = this.$route.query
      switch (source) {
        case 'locate':
          return dispatch('locateList/load', id)
            .then(list => {
              this.form.sourceListId = id
              this.sourceListName = list.name
              this.excludedLenderId = list.excludedLender && list.excludedLender.id
              if (list.isLocated) {
                const options = {
                  type: 'warning',
                  showClose: false
                }
                return this.$alert(`You have already located all securities for list "${list.name}".`, options).then(this.cancel)
              }
              return dispatch('locateList/loadItems', id)
            })
            .then(items => {
              if (items) {
                items = items
                  .filter(item => item.isRequired)
                  .map(LoanNegotiationItem.fromLocateItem)
                this.setItems(items)
              }
            })

        case 'recall':
          return dispatch('negotiations/recall/load', id).then(() => {
            const items = this.$store
              .get('negotiations/recall/items')
              .map(LoanNegotiationItem.fromRecallItem)
              .filter(item => item.quantity > 0)
            this.setItems(items)
          })

        default:
          console.error('Unsupported conversion:', source)
      }
    },

    submit () {
      const form = this.form
      const payload = {
        sourceListId: form.sourceListId,
        counterPartyOrganizationIds: form.lenderIds,
        useSmartUpdating: form.useSmartUpdating,
        comment: form.comment,
        privateNotes: form.privateNotes,
        items: this.getItems()
      }
      return dispatch('negotiations/loan/create', payload)
        .then(this.onDone)
    },

    getScores () {
      // FIXME possibly move some of this to the store...
      const preferredCounterPartyOrganizationId = this.preferredLenderId
      const counterPartyOrganizationIds = this.lenders.map(lender => lender.id)
      const items = this.getItems()
        .map(item => {
          const security = this.$store.get('securities/getSecurity', item.bloomberg)
          return {
            securityId: security
              ? security.id
              : null,
            quantity: item.quantity
              ? item.quantity
              : null
          }
        })
        .filter(item => item.securityId)
      const payload = {
        preferredCounterPartyOrganizationId,
        counterPartyOrganizationIds,
        items,
      }
      return Api.post('/availability/matches', payload)
        .then(res => {
          if (res.data) {
            this.options.scores = res.data
          }
        })
    },
  }
}
</script>

<style lang="scss">
.lender__checkboxes {
  .tag {
    float: right;
    font-size: 0.7em;
    vertical-align: middle;
    padding: 1px 7px;
  }
}

.checkAvailability {

  > span {
    flex-grow: 0;
  }

  &__popup {
    font-size: 0.9em;

    h3 {
      margin-bottom: 0.2em !important;
    }
    h3 + p {
      padding-top: 0;
      margin-top: 0;
      color: $color-primary;
      font-size: 1.1em;
    }

    h3, p, li {
      margin-bottom: .5em;
    }
  }
}
</style>
