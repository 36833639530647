var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { staticClass: "ui-breadcrumbs" },
    [
      _vm._t(
        "default",
        [
          _vm._l(_vm.segments, function(segment) {
            return [
              segment.path
                ? _c(
                    "a",
                    {
                      key: segment.path,
                      staticClass: "ui-breadcrumbs__item",
                      attrs: { href: segment.path },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.$router.push(segment.path)
                        }
                      }
                    },
                    [_vm._v(_vm._s(segment.text))]
                  )
                : _c(
                    "span",
                    { key: segment.path, staticClass: "ui-breadcrumbs__item" },
                    [_vm._v(_vm._s(segment.text))]
                  )
            ]
          })
        ],
        { segments: _vm.segments }
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }