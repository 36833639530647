<template>
  <div class="layout__index" id="page-negotiations">
    <h1>Notifications</h1>

    <UiBreadcrumbs/>

    <div class="layout__main" id="view-negotiation">
      <SiteAside label="Options">
        <div class="p-4">
          <div>
            <el-checkbox v-model="showUnseenOnly">Show {{ showUnseenOnly ? 'all' : 'unseen only' }}</el-checkbox>
          </div>
          <div class="mt-4" v-if="unseen">
            <el-button style="margin-left: 23px"
                       size="mini"
                       :disabled="unseen.length === 0"
                       @click="clearAllUnseen">Clear unseen</el-button>
          </div>
        </div>
      </SiteAside>

      <section class="layout__content panel">
        <h2>Items</h2>

        <div v-if="!isConnected" class="mt-4">
          <el-alert
            class="centered"
            title="Live notifications are paused due to network issues. Please be patient as we reconnect..."
            type="error"
            show-icon>
          </el-alert>
        </div>

        <el-pagination
          class="inline-block"
          layout="prev, pager, next"
          background
          :current-page="pagination.page"
          :page-size="pagination.pageSize"
          :total="pagination.total"
          @current-change="onPageChange"
        />

        <hr>
        <NotificationsTable :items="items" @edit="viewItem"/>
      </section>
    </div>
  </div>
</template>

<script>
import NotificationsTable from '../components/NotificationsTable'
import { get, sync, dispatch } from 'vuex-pathify'

export default {
  components: {
    NotificationsTable
  },

  metaInfo () {
    return {
      title: 'Notifications'
    }
  },

  computed: {
    ...get('notifications', [
      'unseen',
      'items',
      'pagination',
      'isConnected',
    ]),

    ...sync('notifications', [
      'showUnseenOnly'
    ]),
  },

  watch: {
    showUnseenOnly () {
      this.onPageChange(1)
    }
  },

  methods: {
    onPageChange (page) {
      return dispatch('notifications/load', page)
    },

    viewItem (item) {
      this.$store.dispatch('notifications/view', item.id)
    },

    reconnect () {
      this.$store.dispatch('notifications/reconnect')
    },

    clearAllUnseen () {
      return dispatch('notifications/clearAllUnseen')
    }
  }

}
</script>
