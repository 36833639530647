enum OrganizationType {
  borrower = 1,
  lender = 2,
  borrowerAndLender = 3,
  system = 99,
}

export default OrganizationType

export function canBorrow (value: number) {
  return [OrganizationType.borrower, OrganizationType.borrowerAndLender].includes(value)
}

export function canLend (value: number) {
  return [OrganizationType.lender, OrganizationType.borrowerAndLender].includes(value)
}
