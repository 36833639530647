<template>
  <div class="layout__index" id="page-negotiations-dashboard">
    <h1>{{ title }}</h1>

    <UiBreadcrumbs/>

    <router-view :key="$route.fullPath"/>
  </div>
</template>

<script>
import { makeTitle } from '../helpers'

export default {

  metaInfo () {
    return {
      title: 'Negotiations'
    }
  },

  computed: {
    title () {
      return makeTitle(this.$route)
    }
  }

}
</script>
