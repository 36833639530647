import { MsalPluginOptions } from 'vendor/msal/types'
import { makeMsalConfig } from 'vendor/msal'

export const options: MsalPluginOptions = {
  msal: makeMsalConfig({
    clientId: process.env.VUE_APP_AUTH_CLIENT_ID as string,
    authority: process.env.VUE_APP_AUTH_AUTHORITY as string,
    redirectUri: `${location.origin}/auth/redirect/login`,
  }),
  scopes: [
    process.env.VUE_APP_AUTH_SCOPE as string
  ],
}

export const redirects = {
  login: '/auth/redirect/login',
  logout: '/auth/redirect/logout',
}
