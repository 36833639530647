var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "el-form",
        {
          ref: "organizationForm",
          staticClass: "organization-form",
          attrs: {
            rules: _vm.rules,
            model: _vm.form,
            "label-width": "300px",
            "label-position": "right",
            "submit.prevent": "submit"
          }
        },
        [
          _c("UiErrors"),
          _c(
            "UiFieldset",
            { attrs: { label: "Company" } },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "is-required",
                  attrs: { label: "Display Name", prop: "displayName" }
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "100" },
                    model: {
                      value: _vm.form.displayName,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "displayName", $$v)
                      },
                      expression: "form.displayName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "is-required",
                  attrs: { label: "Legal Entity Name", prop: "legalEntityName" }
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "100" },
                    model: {
                      value: _vm.form.legalEntityName,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "legalEntityName", $$v)
                      },
                      expression: "form.legalEntityName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "is-required",
                  attrs: {
                    label: "Legal Entity Identifier (LEI)",
                    prop: "legalEntityIdentifierNumber"
                  }
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "100" },
                    model: {
                      value: _vm.form.legalEntityIdentifierNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "legalEntityIdentifierNumber", $$v)
                      },
                      expression: "form.legalEntityIdentifierNumber"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "is-required",
                  attrs: {
                    label: "Company Registration Number",
                    prop: "companyRegistrationNumber"
                  }
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "100" },
                    model: {
                      value: _vm.form.companyRegistrationNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "companyRegistrationNumber", $$v)
                      },
                      expression: "form.companyRegistrationNumber"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "UiFieldset",
            { attrs: { label: "Contact" } },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "is-required",
                  attrs: {
                    label: "Registered Address",
                    prop: "registeredAddress"
                  }
                },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", maxlength: "1000" },
                    model: {
                      value: _vm.form.registeredAddress,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "registeredAddress", $$v)
                      },
                      expression: "form.registeredAddress"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "is-required",
                  attrs: { label: "Phone Number", prop: "phoneNumber" }
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "30" },
                    model: {
                      value: _vm.form.phoneNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "phoneNumber", $$v)
                      },
                      expression: "form.phoneNumber"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "UiFieldset",
            { attrs: { label: "Billing" } },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "is-required",
                  attrs: { label: "Contract Type", prop: "contractType" }
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "100" },
                    model: {
                      value: _vm.form.contractType,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "contractType", $$v)
                      },
                      expression: "form.contractType"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "is-required",
                  attrs: {
                    label: "Billing Currency",
                    prop: "billingCurrencyId"
                  }
                },
                [
                  _c("UiSelect", {
                    attrs: {
                      options: _vm.billingCurrencyOptions,
                      size: "small"
                    },
                    on: {
                      blur: function($event) {
                        return _vm.validateField("billingCurrencyId")
                      },
                      change: function($event) {
                        return _vm.validateField("billingCurrencyId")
                      }
                    },
                    model: {
                      value: _vm.form.billingCurrencyId,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "billingCurrencyId", $$v)
                      },
                      expression: "form.billingCurrencyId"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "UiFieldset",
            { attrs: { label: "Trading" } },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "is-required",
                  attrs: { label: "Participant Role", prop: "organizationType" }
                },
                [
                  _c("UiSelect", {
                    attrs: {
                      options: _vm.organizationTypeOptions,
                      size: "small"
                    },
                    on: {
                      blur: function($event) {
                        return _vm.validateField("organizationType")
                      },
                      change: function($event) {
                        return _vm.validateField("organizationType")
                      }
                    },
                    model: {
                      value: _vm.form.organizationType,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "organizationType", $$v)
                      },
                      expression: "form.organizationType"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "is-required",
                  attrs: {
                    label: "Participant Sub Role",
                    prop: "organizationRole"
                  }
                },
                [
                  _c("UiSelect", {
                    attrs: {
                      options: _vm.organizationRoleOptions,
                      size: "small"
                    },
                    on: {
                      blur: function($event) {
                        return _vm.validateField("organizationRole")
                      },
                      change: function($event) {
                        return _vm.validateField("organizationRole")
                      }
                    },
                    model: {
                      value: _vm.form.organizationRole,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "organizationRole", $$v)
                      },
                      expression: "form.organizationRole"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "is-required",
                  attrs: {
                    label: "UTI Creator",
                    prop: "uniqueTradeIdentifierCreator"
                  }
                },
                [
                  _c("UiSelect", {
                    attrs: {
                      options: _vm.uniqueTradeIdentifierCreatorOptions,
                      size: "small"
                    },
                    on: {
                      blur: function($event) {
                        return _vm.validateField("uniqueTradeIdentifierCreator")
                      },
                      change: function($event) {
                        return _vm.validateField("uniqueTradeIdentifierCreator")
                      }
                    },
                    model: {
                      value: _vm.form.uniqueTradeIdentifierCreator,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "uniqueTradeIdentifierCreator", $$v)
                      },
                      expression: "form.uniqueTradeIdentifierCreator"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "UiFieldset",
            { attrs: { label: "Regulation" } },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "is-required",
                  attrs: { label: "Regulator", prop: "regulatorName" }
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "100" },
                    model: {
                      value: _vm.form.regulatorName,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "regulatorName", $$v)
                      },
                      expression: "form.regulatorName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "is-required",
                  attrs: {
                    label: "Regulator Registration Number",
                    prop: "regulatorRegistrationNumber"
                  }
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "100" },
                    model: {
                      value: _vm.form.regulatorRegistrationNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "regulatorRegistrationNumber", $$v)
                      },
                      expression: "form.regulatorRegistrationNumber"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "is-required",
                  attrs: {
                    label: "Regulatory Status",
                    prop: "regulatoryStatus"
                  }
                },
                [
                  _c("UiSelect", {
                    attrs: {
                      options: _vm.regulatoryStatusOptions,
                      size: "small"
                    },
                    on: {
                      blur: function($event) {
                        return _vm.validateField("regulatoryStatus")
                      },
                      change: function($event) {
                        return _vm.validateField("regulatoryStatus")
                      }
                    },
                    model: {
                      value: _vm.form.regulatoryStatus,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "regulatoryStatus", $$v)
                      },
                      expression: "form.regulatoryStatus"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "UiFieldset",
            { attrs: { label: "Notice" } },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "is-required",
                  attrs: {
                    label: "Notice Details Address",
                    prop: "noticesAddress"
                  }
                },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", maxlength: "1000" },
                    model: {
                      value: _vm.form.noticesAddress,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "noticesAddress", $$v)
                      },
                      expression: "form.noticesAddress"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "is-required",
                  attrs: {
                    label: "Notice Details Phone Number",
                    prop: "noticesPhoneNumber"
                  }
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "30" },
                    model: {
                      value: _vm.form.noticesPhoneNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "noticesPhoneNumber", $$v)
                      },
                      expression: "form.noticesPhoneNumber"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "UiFieldset",
            { attrs: { label: "Agent" } },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "is-required",
                  attrs: {
                    label: "Authorized Agent",
                    prop: "authorizedAgentName"
                  }
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "100" },
                    model: {
                      value: _vm.form.authorizedAgentName,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "authorizedAgentName", $$v)
                      },
                      expression: "form.authorizedAgentName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "is-required",
                  attrs: {
                    label: "Authorized Agent LEI",
                    prop: "authorizedAgentLegalEntityIdentifier"
                  }
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "100" },
                    model: {
                      value: _vm.form.authorizedAgentLegalEntityIdentifier,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.form,
                          "authorizedAgentLegalEntityIdentifier",
                          $$v
                        )
                      },
                      expression: "form.authorizedAgentLegalEntityIdentifier"
                    }
                  })
                ],
                1
              ),
              _c(
                "section",
                [
                  _c("h4", [_vm._v("If outside the UK")]),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "m-1",
                      attrs: { label: "Agent for service Address" }
                    },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea", maxlength: "1000" },
                        model: {
                          value: _vm.form.agentForServiceOutsideUKAddress,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "agentForServiceOutsideUKAddress",
                              $$v
                            )
                          },
                          expression: "form.agentForServiceOutsideUKAddress"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "m-1",
                      attrs: { label: "Agent for service Telephone Number" }
                    },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "30" },
                        model: {
                          value: _vm.form.agentForServiceOutsideUKPhoneNumber,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "agentForServiceOutsideUKPhoneNumber",
                              $$v
                            )
                          },
                          expression: "form.agentForServiceOutsideUKPhoneNumber"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("UiErrors"),
          _c("ui-submit", {
            attrs: {
              "can-submit": _vm.valid,
              "submit-text": _vm.action === "create" ? "Create" : "Update"
            },
            on: {
              cancel: function($event) {
                _vm.action === "create"
                  ? _vm.$router.push("/")
                  : _vm.onDone(_vm.form.id)
              },
              submit: function($event) {
                _vm.action === "create"
                  ? _vm.createOrganization()
                  : _vm.updateOrganization()
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }