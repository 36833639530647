import { AxiosRequestConfig, AxiosResponse } from 'axios'
import Api from 'services/Api'

import progress from 'nprogress'
import 'nprogress/nprogress.css'
import './progress.scss'

let count = 0

// @see http://ricostacruz.com/nprogress/
progress.configure({
  minimum: 0.3,
  easing: 'ease',
  speed: 800,
  showSpinner: false
})

function onRequest (request: AxiosRequestConfig) {
  count++
  if (count === 1) {
    progress.start()
  }
  return request
}

function onResponse (response: AxiosResponse) {
  count--
  if (count < 0) {
    count = 0
  }
  count === 0
    ? progress.done()
    : progress.inc()
  return response
}

function onError (error) {
  count--
  progress.done()
  return Promise.reject(error)
}

Api.axios.interceptors.request.use(onRequest)
Api.axios.interceptors.response.use(onResponse, onError)
