/**
 * Parse a string into a select options object
 *
 * @usage   stringToOptions('1:dave|2:paul|3:tammy')
 * @usage   stringToOptions('1:dave,2:paul,3:tammy')
 * @usage   stringToOptions('1:dave,2:paul,3:tammy', 'value', 'label')
 *
 * @output  [ { key: '1', name: 'dave' },
 *            { key: '2', name: 'paul' },
 *            { key: '3', name: 'tammy' } ]
 *
 * @param   {string}  value       The options to parse
 * @param   {string}  valueKey    Alternative id key
 * @param   {string}  labelKey    Alternative label key
 * @return  {Array<Object>}
 */

export function stringToOptions (value, valueKey = 'id', labelKey = 'name') {
  const match = value.match(/[,|]/)
  return value
    .split(match)
    .map(option => {
      const [value, label] = option.split(':')
      return {
        [valueKey]: value,
        [labelKey]: label
      }
    })
}

/**
 * Convert an enum into a select options object
 *
 * @param   {string}  obj         The options to parse
 * @param   {string}  valueKey    Alternative id key
 * @param   {string}  labelKey    Alternative label key
 * @return  {Array<Object>}
 */
export function enumToOptions (obj, valueKey = 'value', labelKey = 'name') {
  return Object
    .keys(obj)
    .reduce((options, key) => {
      const value = obj[key]
      if (typeof value === 'number') {
        options.push({
          [valueKey]: value,
          [labelKey]: key,
        })
      }
      return options
    }, [])
}
