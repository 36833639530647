<template>

  <div class="layout__main" id="edit-recall">

    <SiteAside v-if="hasStaleWarning">
      <NegotiationConflict :counterParty="counterParty" @reload="reload"/>
    </SiteAside>
    <SiteAside v-else label="Options">

      <NegotiationMessage :message="lastMessage"/>

      <el-form v-if="isLender && state === NegotiationState.open"
               ref="form"
               :model="form"
               label-position="top"
               @submit.prevent.native="">

        <UiFieldset label="Response" v-if="state === NegotiationState.open">

          <NegotiationResponse
            v-model="form.response"
            v-bind="responseData"
            :showAccept="false"
          />

          <NegotiationComment v-model="form.comment" />

          <UiSubmit v-bind="submitData" v-on="submitData" />
        </UiFieldset>

      </el-form>

      <UiFieldset v-if="mustAccept && state === NegotiationState.open" label="Response">
        <div class="flex vertical">
          <el-button type="primary"
                     :disabled="!isValid"
                     @click="submit({createReturn: true})"
          >Accept</el-button>
        </div>
        <div class="uiText__help" style="">
          <i class="mr-1 fas fa-exclamation-triangle"></i>
          <span>Note: clicking Accept will create a return of the recalled loans and for any fields which have a
            To Locate value of more than 0, will create a new Locate List of those Securities and quantities.</span>
        </div>
      </UiFieldset>

      <el-form>
        <UiFieldset label="Notes">
          <NegotiationNotes v-model="privateNotes" type="recall" autosave/>
        </UiFieldset>
      </el-form>

      <NegotiationHistory v-model="messageIndex"
                          :messages="messages"
                          :open="isHistoryVisible"
                          :canToggle="isOpen"
                          @toggle="toggleHistory" />

    </SiteAside>

    <section class="layout__content panel">

      <NegotiationSummary v-bind="summaryData" />

      <UiErrors/>

      <!-- current items -->
      <RecallItemsTable
        ref="table"
        v-show="canShowLast"
        :data="items.data"
        :role="role"
        :locked="!isOpen"
      />

      <!-- previous items -->
      <RecallItemsTable
        v-show="canShowPrevious"
        :data="previous.data"
        :role="role"
        :locked="true"
      />
    </section>

  </div>

</template>

<script>
import { get } from 'vuex-pathify'
import RecallItemsTable from '../../components/tables/RecallItemsTable'
import factory from './edit-factory'
import RecallNegotiationValidator from '../../validators/RecallNegotiationValidator'

export default {

  extends: factory('recall', RecallNegotiationValidator),

  crumb: 'Recalls',

  components: {
    RecallItemsTable,
  },

  computed: {
    mustAccept () {
      return this.isBorrower
    },

    ...get('negotiations/recall', [
      'extendedSettlementDate',
    ]),
  },

  methods: {
    // recalls have no validation logic, so we submit and cancel directly
    validate () {
      return Promise.resolve(true)
    },

    cancel () {
      this.onDone()
    },

    onSubmit (res) {
      // filter table items for those to be relocated
      const items = this.getItems().filter(item => item.toLocate > 0)

      // optionally create a locate list
      if (items.length) {
        const locates = {}

        // combine duplicate securities into a single line
        items.forEach(item => {
          const locate = {
            bloomberg: item.loan.bloomberg,
            quantityRequired: item.toLocate
          }
          const cached = locates[locate.bloomberg]
          if (cached) {
            cached.quantityRequired += locate.quantityRequired
          }
          else {
            locates[locate.bloomberg] = locate
          }
        })

        const payload = {
          form: {
            name: `Securities from Recall ${this.name}`,
            excludedLenderId: this.lenderOrganization.id,
          },
          items: Object.values(locates)
        }

        console.log(payload)

        return this.$store
          .dispatch('locateLists/create', payload)
          .then(() => this.onDone(res))
      }

      // otherwise, return
      return this.onDone(res)
    },
  }

}
</script>
