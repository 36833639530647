import { formats } from './formatters'
import moment from 'moment'

// FIXME - right now this is hardcoded because Date-Fns 1.x doesn't parse UK dates properly
export function parseDate (value) {
  const [d, m, y] = value.split('/')
  return new Date(y, m - 1, d)
}

export function isParsableDate (value) {
  const format = formats.date.replace(/\w/g, '\\d')
  return new RegExp(`^${format}$`).test(value)
}

/**
 * Convert a date into an ISO or date string
 *
 * @param   {string|Date}   date            A date or parsable string date
 * @param   {boolean}       asDateString    Output as a date-only string, with no timezone conversion
 * @return  {string}
 */
export function toDateIso (date, asDateString = false) {
  if (isParsableDate(date)) {
    date = parseDate(date)
  }

  return date instanceof Date
    ? asDateString
      ? toDateString(date, true)
      : date.toISOString()
    : undefined
}

export function toDateString (date, iso = false) {
  return moment(date).format(iso ? formats.dateIso : formats.date)
}

export function toTimeString (date) {
  return moment(date).format(formats.time)
}

export function yesterday () {
  return moment().startOf('day').subtract(1, 'day')
}

export function today () {
  return moment().startOf('day')
}

export function tomorrow () {
  return moment().startOf('day').add(1, 'day')
}
