<template>

  <section class="profile__netDivs panel">
    <h2>Default Net Dividend / Margin Requirements</h2>

    <UiErrors :errors="errors" />

    <p class="profile__upload">Click to
      <!--
      <a href="/profile/default-net-dividend-requirements/download" @click.prevent="download('default-net-dividend-requirements')">download</a>
      or
      -->
      <UiUpload href="/profile/default-net-dividend-requirements/upload" type="text" text="upload" @submit="upload"/>
      a Net Dividend / Margin Requirements file.
    </p>

    <div v-for="fund in dividends" :key="fund.name" class="profile__listContainer">
      <h3 class="text">{{ fund.name }}</h3>
      <ul class="profile__list">
        <li class="header">
          <label>
            <span class="text">Country:</span>
          </label>
          <output>
            <span class="text">Requirement:</span>
          </output>
        </li>
        <li v-for="country in fund.countries" :key="country.name">
          <label>
            <span class="text">{{ country.name }}</span>
          </label>
          <output class="mb-1">
            <el-input v-model.number="form.data[country.formIndex].netDividendRequirement"
                      type="number"
                      :min="0"
                      step="0.001"
                      size="mini" />
            <span class="symbol">%</span>
          </output>
        </li>
      </ul>
    </div>
    <SaveChanges @save="save" />
  </section>

</template>

<script>
import { dataMixin, storeMixin } from '../state/mixin-factory'

export default {
  extends: storeMixin('tradingProfile/netDivs', {
    data: 'data'
  }),

  computed: {
    dividends () {
      const items = this.data
      if (!items || items.length === 0) {
        return {}
      }

      let formIndex = 0
      return items.reduce((output, model) => {
        // variables
        const fund = model.fund.name
        const country = model.country
        const rate = model.netDividendRequirement

        // fund
        if (!output[fund]) {
          output[fund] = {
            name: fund,
            countries: {}
          }
        }

        // country
        output[fund].countries[country] = {
          formIndex: formIndex++,
          name: country,
          rate,
        }

        // output
        return output
      }, {})
    },
  },
}

</script>

<style lang="scss">

</style>
