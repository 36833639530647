import Axios, { AxiosInstance } from 'axios'
import qs from 'qs'
import Vue from 'vue'
import download from 'js-file-download'
import { commitErrors, clearErrors, ErrorResponse } from 'modules/errors'

function wrapError (handler, path, options) {
  return function (error) {
    return handler(error, path, options)
  }
}

function handleError (code: number, message: string, url?: string) {
  let html = `<p>${message}</p>`
  if (url) {
    html += `<p class="url">${url}</p>`
  }
  const type = code > 0 ? 'Error' : 'Warning'
  Vue.prototype.$notify({
    type: type.toLowerCase(),
    title: (code || '') + ' ' + type,
    dangerouslyUseHTMLString: true,
    message: html,
    duration: 0,
  })
}

const Api: any = {

  auth: null,
  axios: null,
  error: '',

  install (Vue, auth, baseURL: string) {
    this.auth = auth
    this.error = ''
    this.onAuthError = this.onAuthError.bind(this)
    this.onApiError = this.onApiError.bind(this)
    this.axios = Axios.create({
      baseURL,
    })
    this.axios.interceptors.response.use(function (response) {
      // Do something with response data
      return response
    }, function (error) {
      // Do something with response error
      // console.log('Axios error:', error)
      return Promise.reject(error)
    })
    Vue.prototype.$api = this
  },

  setOrganization (organizationId) {
    this.axios.defaults.headers.common['x-an-organization-id'] = organizationId
  },

  call (path, options) {
    return this.auth
      .getToken()
      .catch(wrapError(this.onAuthError, path, options))
      .then(accessToken => {
        if (!options.headers) {
          options.headers = {}
        }
        options.headers.Authorization = `Bearer ${accessToken}`
        return this
          .axios(path, options)
          .catch(wrapError(this.onApiError, path, options))
      })
  },

  async get (path, params = {}, responseType) {
    return this.call(path, {
      method: 'get',
      params,
      responseType: responseType || 'json',
      paramsSerializer (params) {
        return qs.stringify(params)
      }
    })
  },

  async post (path, data) {
    clearErrors()
    return this.call(path, { method: 'post', data })
  },

  async patch (path, data) {
    clearErrors()
    return this.call(path, { method: 'patch', data })
  },

  async delete (path, data) {
    clearErrors()
    return this.call(path, { method: 'delete', data })
  },

  async upload (path, data) {
    clearErrors()
    return this.call(path, {
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data,
    })
  },

  async download (path, filename = 'download.txt') {
    return this.get(path, {}, 'blob').then(res => {
      const header = res.request.getResponseHeader('Content-Disposition')
      if (header) {
        const matches = header.match(/filename=([^;]+)/)
        if (matches) {
          filename = matches[1]
        }
      }
      download(res.data, filename.split('"').join(''))
    })
  },

  onAuthError (error, path, options) {
    // debug
    console.warn('Auth error:', error)

    if (error.error === 'login_required') {
      this.auth.reauthorize()
    }

    if (error.response && error.response.status === 403) {
      // return this.auth.login()
    }

    // re-throw error
    throw(error)
  },

  onApiError (error, path, options) {
    // debug
    console.warn('Api error:', error)

    // handle client errors
    if (/^function \w+Error/.test(String(error.constructor))) {
      return Promise.reject(error)
    }

    // if no response
    if (!error.response) {
      handleError(0, 'Unable to reach API endpoint', this.axios.defaults.baseURL + path)
      return
    }

    // variables
    const { request, response } = error
    const { status, statusText, data } = response
    const url = request.responseURL

    // defaults
    this.error = statusText

    // error
    if (status === 400) {
      console.log('400 error: ', response)
      handleError(400, `System error - please contact <a href = "mailto: support@asterisknetworks.com">support@asterisknetworks.com</a> for assistance`, url)
    }

    // not logged in
    else if (status === 401) {
      let message = 'Not Authorised'
      const headerMessage = error.response.headers['x-asterisknetworks-information']
      if (headerMessage) {
        message = message.concat(' - ', headerMessage)
      }
      handleError(401, message, url)
    }

    // forbidden
    else if (status === 403) {
      handleError(403, 'User Not Authorised For Operation', url)
    }

    // conflict
    else if (status === 409) {
      handleError(409, data.detail)
      return Promise.reject(error)
    }

    // gone
    else if (status === 410) {
      handleError(0, 'This item does not exist or has been deleted.')
    }

    else if (status === 422) {
      // validation
      if (data.errors) {
        const response = ErrorResponse.fromServer(data, options.data)
        commitErrors(response.errors)
        return Promise.reject(response)
      }

      else {
        console.log('422 error: ', response)
        handleError(422, `System error - please contact <a href = "mailto: support@asterisknetworks.com">support@asterisknetworks.com</a> for assistance`, url)
      }
    }

    // server error
    else if (status === 500) {
      console.log('500 error: ', response)
      handleError(500, `System error - please contact <a href = "mailto: support@asterisknetworks.com">support@asterisknetworks.com</a> for assistance`, url)
    }

    // anything else
    else {
      handleError(0, `${status}: ${statusText}`, url)
    }

  }
}

export default Api
