import Vue from 'vue'
import { storage } from 'utils/storage'
import { capitalize } from 'utils/string'
import { hook, redirect } from 'router/helpers'
import { initAreas } from 'areas/main'
import store from '../state'
import router from 'router/index'
import { Auth } from 'vendor/msal'

const AUTH = {
  login: '/auth/login',
  loginSuccess: '/auth/login/success',
  loginRedirect: '/auth/redirect/login',
  logout: '/auth/logout',
  logoutRedirect: '/auth/redirect/logout',
}

const UNGUARDED = [
  '/',
  '/about',
  ...Object.values(AUTH)
]

let isInitialized = false

let nextRoute = '/'

export const routes = [
  hook(AUTH.login, handleLoginRequest),
  hook(AUTH.loginSuccess, handleLoginSuccess),
  hook(AUTH.logout, handleLogoutRequest),
  redirect(AUTH.logoutRedirect, '/'),
]

function showLoader (state: boolean | string = true) {
  void store.dispatch('settings/showLoader', state)
}

function showError (error) {
  console.log(error)
  // Only show errors that are not a result of the user cancelling login/password reset
  if (!error.message.includes('cancelled')) {
    Vue.prototype.$notify({
      type: 'warning',
      title: error.name || 'Login error',
      dangerouslyUseHTMLString: true,
      message: capitalize(error.message),
      duration: 20000,
    })
  }
}

// ---------------------------------------------------------------------------------------------------------------------
// handlers
// ---------------------------------------------------------------------------------------------------------------------

/**
 * Fired on all routes
 */
export async function handleRouteChange (to, from, next) {
  // flags
  const isAuthenticated = Auth.isAuthenticated
  const isGuarded = !UNGUARDED.includes(to.path)
  const isHook = to.path.startsWith('/auth')

  // if authorising, let the hooks handle it
  if (isHook) {
    return next()
  }

  // if first run, initialise auth
  if (!isInitialized) {
    isInitialized = true
    return handleLoad(to, from, next)
  }

  // if authenticated, we can access all routes
  if (isAuthenticated) {
    return next()
  }

  // if not authenticated, and guarded, force a login
  if (isGuarded) {
    return handleLoginRequest(to, from, next)
  }

  // any other route, we should be able to access
  return next()
}

// ---------------------------------------------------------------------------------------------------------------------
// login
// ---------------------------------------------------------------------------------------------------------------------

async function handleLoginRequest (to, from, next) {
  let path = to.path
  Vue.prototype.$session.set('route', to.path)
  showLoader('Logging in')
  return Auth.login()
    .then(async result => {
      console.log('Authenticated as:', result)
      await loadData()
      if (path.startsWith('/auth')) {
        path = '/'
      }
      nextRoute = path
      next(AUTH.loginSuccess)
    })
    .catch(error => {
      showLoader(false)
      showError(error)
      return next('/')
    })
}

async function handleLoginSuccess (to, from, next) {
  next()
  /**
   * @hack: with the popup login, because the URL doesn't change, the logged-out
   * router-view component (i.e. Home) is not replaced when the popup returns control
   * to the app; the end result is that although we are now "logged in" the route itself
   * hasn't changed (as it would have been using the redirect) and so the router-view
   * doesn't automatically update the component to the logged-in version of home.
   *
   * Unfortunately, I can't find a way to force a route update (using force: true
   * doesn't work) but navigating to an empty route and back again replaces the
   * existing router view content.
   *
   * It's ugly... but it works at the expense of a few lines of code.
   */
  Vue.nextTick(() => router.replace(nextRoute))
}

// ---------------------------------------------------------------------------------------------------------------------
// logout
// ---------------------------------------------------------------------------------------------------------------------

async function handleLogoutRequest (to, from, next) {
  // log out user from Asterisk API
  await store.dispatch('user/logout')

  // trigger open tabs to log out
  storage.set('deletedAt', new Date())

  // clear storage unless this is a "soft" logout (see AutoLogout.vue)
  if (!to.query.soft) {
    storage.clear()
  }

  // log out of MSAL
  return Auth.logout()
    // hack: reloading the window to get around route issues
    .then(() => window.location.href = '/')
}

// ---------------------------------------------------------------------------------------------------------------------
// loading
// ---------------------------------------------------------------------------------------------------------------------

async function handleLoad (to, from, next) {
  // initialize authentication
  await Auth.initialize(router)

  // attempt to resume any previous session
  if (Auth.isAuthenticated) {
    try {
      await loadData()
    }
    catch (err) {
      await Auth.logout()
      return (next(false))
    }
    return next(to.path)
  }

  // if not logged in, go back to the route change handler
  else {
    return handleRouteChange(to, from, next)
  }
}

async function loadData () {
  console.log('Loading user data...')
  showLoader(true)
  return initAreas(store)
    .then(data => {
      // record last login time
      storage.set('updatedAt', new Date())

      // use storage events to auto-logout all tabs if session cleared
      storage.on('deletedAt', function () {
        return Auth.logout()
      })

      // hide loader and finish
      showLoader(false)
      console.log('Loaded user data!')
      isInitialized = true
      return data
    })
}
