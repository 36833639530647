import { LoanRole } from 'modules/organizations'
import { Organization } from '@/modules/organizations'
import { NegotiationMessageDirection, NegotiationState } from '..'
import { Resource } from 'core/models'

export default abstract class AbstractNegotiation extends Resource {
  // model-specific
  public readonly type?: string

  public readonly priority?: number

  // negotiation
  public name?: string

  public state?: NegotiationState

  public messageCount?: number

  public latestMessageDirection?: NegotiationMessageDirection

  public privateNotes?: string

  // relations
  public createdByUserName?: string

  public updatedByUserName?: string

  public lenderOrganization?: Organization

  public borrowerOrganization?: Organization

  public originatingLoanRole?: LoanRole

  // getters - mainly used in components
  public get isOpen (): boolean {
    return this.state === NegotiationState.open
  }

  public get isAccepted (): boolean {
    return this.state === NegotiationState.accepted
  }

  public get isAbandoned (): boolean {
    return this.state === NegotiationState.abandoned
  }

  public get isRejected (): boolean {
    return this.state === NegotiationState.rejected
  }

  public get isCompleted (): boolean {
    return this.isAccepted || this.isRejected || this.isAbandoned
  }

  public get isSent (): boolean {
    return this.latestMessageDirection === NegotiationMessageDirection.sent
  }

  public get isReceived (): boolean {
    return this.latestMessageDirection === NegotiationMessageDirection.received
  }

  public get isUpdated (): boolean {
    return this.isOpen && this.isReceived
  }

  protected constructor (data: any) {
    // model
    super(data)

    // negotiation
    this.name = data.name
    this.state = data.state
    this.messageCount = data.messageCount
    this.latestMessageDirection = data.latestMessageDirection
    this.privateNotes = data.privateNotes

    // messaging
    this.createdByUserName = data.createdByUserName
    this.updatedByUserName = data.updatedByUserName
    this.lenderOrganization = new Organization(data.lenderOrganization)
    this.borrowerOrganization = new Organization(data.borrowerOrganization)
    this.originatingLoanRole = data.originatingLoanRole
  }
}
