import Vue from 'vue'
import { ErrorResponse } from '..'
import UiErrors from '../components/UiErrors.vue'

/**
 * Promise handler to show Element UI errors message popdown
 * @param response
 */
export function showErrors (response: ErrorResponse) {
  const { errors } = response
  const vnode = makeVNode(errors)

  // show message
  Vue.prototype.$message({
    type: 'error',
    duration: 0,
    showClose: true,
    dangerouslyUseHTMLString: true,
    message: vnode,
  })

  // reject to halt promise chain
  return Promise.reject(response)
}

/**
 * Hide errors message popdown
 */
export function hideErrors () {
  Vue.prototype.$message.closeAll()
}

function makeVNode (errors) {
  const el = new Vue({})
  const h = el.$createElement
  return h(UiErrors, {
    props: {
      errors
    }
  })
}
