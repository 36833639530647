import { toDateIso, toDateString } from 'utils/date'
import { Lookups } from 'modules/global'
import { LoanItem } from 'modules/loans'
import { LocateItem } from 'modules/locate-lists'
import { renameArrayItems } from '../helpers'
import { AbstractItem, NegotiationItemState, RecallNegotiationItem } from '..'
import { getCollateralBasketId } from 'modules/resources'

export default class LoanNegotiationItem extends AbstractItem {

  public readonly type: string = 'loan'

  public bloomberg: string

  public quantity: number | undefined

  public quantityRequired: number | undefined

  public rate: number

  public loanCurrencyCode: string

  public settlementDate: string

  public termEndDate: string

  public netDividendRequirement: number

  public collateralBasketCode: string

  public fundName: string

  public fundId: string

  public specialSecurity: boolean

  public state: NegotiationItemState

  public propertiesUpdated: Array<string>

  public get isRemoved () {
    return this.state === NegotiationItemState.removed
  }

  constructor (data: any = {}) {
    super(data)
    this.bloomberg = data.bloomberg
    this.quantity = data.quantity
    this.quantityRequired = data.quantityRequired
    this.rate = data.rate
    this.loanCurrencyCode = data.loanCurrencyCode
    this.termEndDate = data.termEndDate
    this.settlementDate = data.settlementDate
    this.netDividendRequirement = data.netDividendRequirement
    this.collateralBasketCode = data.collateralBasketCode || ''
    this.fundName = data.fundName
    this.fundId = data.fundId
    this.specialSecurity = data.specialSecurity
    this.state = data.state
    this.propertiesUpdated = data.propertiesUpdated
  }

  static fromServer (data: any = {}): LoanNegotiationItem {
    data.bloomberg = data.security.bloomberg
    data.loanCurrencyCode = data.loanCurrency
      ? data.loanCurrency.code
      : undefined
    data.settlementDate = data.settlementDate
      ? toDateString(data.settlementDate)
      : undefined
    data.termEndDate = data.termEndDate
      ? toDateString(data.termEndDate)
      : undefined
    data.collateralBasketCode = data.collateralBasket
      ? data.collateralBasket.code
      : undefined
    data.fundName = data.fund
      ? data.fund.name
      : undefined
    data.fundId = data.fund
      ? data.fund.id
      : undefined
    if (data.propertiesUpdated) {
      data.propertiesUpdated = renameArrayItems(data.propertiesUpdated, {
        loanCurrency: 'loanCurrencyCode',
        collateralBasket: 'collateralBasketCode',
        fund: 'fundName',
      })
    }
    return new LoanNegotiationItem(data)
  }

  static fromTable (data: any): LoanNegotiationItem {
    return new LoanNegotiationItem(data)
  }

  static fromLocateItem (data: LocateItem): LoanNegotiationItem {
    const item: LoanNegotiationItem = new LoanNegotiationItem()
    item.bloomberg = data.bloomberg
    item.quantity = data.quantityRemaining
    item.settlementDate = data.settlementDate
    return item
  }

  static fromRecallItem (data: RecallNegotiationItem): LoanNegotiationItem {
    const item: LoanNegotiationItem = new LoanNegotiationItem()
    const loan: LoanItem = data.loan
    item.bloomberg = loan.bloomberg
    item.quantity = data.quantity
    item.rate = loan.rate
    item.termEndDate = loan.termEndDate
      ? toDateString(loan.termEndDate)
      : undefined
    item.netDividendRequirement = loan.netDividendRequirement
    return item
  }

  toServer (create: boolean, lookups: Lookups): any {
    const { getSecurity, getCurrency, getFund } = lookups
    const item: LoanNegotiationItem = this
    const data: any = {}

    // raw data
    data.id = item.id
    data.bloomberg = item.bloomberg
    data.quantity = item.quantity
    data.rate = item.rate
    data.settlementDate = item.settlementDate
      ? toDateIso(item.settlementDate, true)
      : null
    data.termEndDate = item.termEndDate
      ? toDateIso(item.termEndDate, true)
      : null
    data.netDividendRequirement = item.netDividendRequirement
    data.collateralBasketId = getCollateralBasketId(this.collateralBasketCode)

    // lookups
    const security = getSecurity(this.bloomberg)
    data.securityId = security
      ? security.id
      : undefined

    const currency = getCurrency(this.loanCurrencyCode)
    data.loanCurrencyId = currency
      ? currency.id
      : undefined

    // NOTE fund lookup is different for lender and borrower because a) borrowers don't edit funds
    // and b) borrowers don't have a global list of funds. Because of this, the lender will look up
    // the fund id from the global list, but the borrower will just pass any passed fund id back
    const fund = getFund(this.fundName)
    data.fundId = fund
      ? fund.id
      : this.fundId

    // return
    return data
  }
}
