<template>
  <el-table :data="events"
            class="no-bg no-hover no-border"
            size="large"
  >
    <el-table-column label="Date"
                     prop="eventDate"
                     width="150"
                     :formatter="$formatCol('date')"/>

    <el-table-column label="Event"
                     width="200"
    >
      <template slot-scope="{ row }">
        {{ getLoanEventTypeLabel(row.eventType) }}
      </template>
    </el-table-column>

    <el-table-column label="Actions" width="200">
      <template slot-scope="{ row }">

        <!-- view -->
        <router-link
          v-if="canView(row)"
          class="el-button el-button--mini"
          :to="getViewLink(row)">View
        </router-link>

        <!-- cancel -->
        <el-button
          v-if="canCancel(row)"
          size="mini"
          @click="cancel(row)">Cancel
        </el-button>

      </template>
    </el-table-column>

  </el-table>
</template>

<section v-if="canCancel">
  <h2>Actions</h2>
  <div class="mt-2">
    <el-button size="mini" @click="cancelLoan">Cancel this loan</el-button>
  </div>
</section>

<script>
import { getLoanEventTypeLabel, getLoanEventTypeString, LoanEventType, ResourceType } from 'modules/global'
import { Loan, LoanState } from 'modules/loans'

export default {
  props: {
    loan: Loan,

    events: {
      type: Array,
      required: true,
    }
  },

  staticData () {
    return {
      ResourceType
    }
  },

  methods: {
    getLoanEventTypeLabel (type) {
      return getLoanEventTypeLabel(type)
    },

    canView (loanEvent) {
      return !!loanEvent.relatedNegotiationId
    },

    canCancel (loanEvent) {
      return loanEvent.canCancel
    },

    cancel (loanEvent) {
      // options
      const label = getLoanEventTypeString(loanEvent.eventType)
      const message = `Are you sure you want to cancel this ${label} ?`

      // confirm
      this.$confirm(message, { type: 'warning' }).then(() => {
        switch (loanEvent.eventType) {
          case LoanEventType.LoanAgreed:
            return this.$store.dispatch('loan/cancelLoan', this.loan.id)

          case LoanEventType.ReturnAgreed:
            return this.$store.dispatch('loan/cancelReturn', loanEvent.relatedItemId)
              .then(this.refresh)
        }
      })
    },

    getViewLink (loanEvent) {
      const { eventType, relatedNegotiationId } = loanEvent
      const eventTypeString = getLoanEventTypeString(eventType)
      return `/negotiations/${eventTypeString}s/view/${relatedNegotiationId}`
    },

    navigate (loanEvent) {
      this.$router.push(this.getLink(loanEvent))
    },

    refresh () {
      this.$store.dispatch('loan/loadEvents', this.loan.id)
    }
  }
}
</script>
