var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "authModal",
      attrs: { width: "440px", visible: _vm.visible, "show-close": false },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [_c("h2", [_vm._v("Session Expired")])]
          },
          proxy: true
        },
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "div",
                { staticClass: "flex flex-between" },
                [
                  _vm.shouldSave
                    ? _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.saveSession,
                            callback: function($$v) {
                              _vm.saveSession = $$v
                            },
                            expression: "saveSession"
                          }
                        },
                        [_vm._v("Save my changes")]
                      )
                    : _c("span", [_vm._v(" ")]),
                  _c(
                    "el-button",
                    {
                      staticClass: "shrink",
                      attrs: { type: "primary" },
                      on: { click: _vm.next }
                    },
                    [_vm._v("Continue")]
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("div", { staticClass: "content" }, [
        _c("p", [_vm._v("To continue, you'll need to log in again.")]),
        _vm.shouldSave
          ? _c(
              "div",
              [
                _c("el-alert", { attrs: { type: "info", closable: false } }, [
                  _c("p", [
                    _vm._v(
                      "You can optionally save any changes you have made and continue with them once you have logged in again."
                    )
                  ]),
                  _c("p", [
                    _vm._v(
                      "If you choose not to save your changes then anything you have changed will be lost."
                    )
                  ])
                ])
              ],
              1
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }