var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-table-column", {
    attrs: { align: "center", label: "Unseen", width: "70" },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var row = ref.row
          return [
            _c("UiBadge", { attrs: { value: _vm.getUnseen(row.id).length } })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }