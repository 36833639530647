<template>

  <div class="negotiationConflict">
    <el-alert title="Data conflict!"
              type="warning"
              :closable="false"
              show-icon>
      <p>{{ counterParty.name }} updated the negotiation, and their changes must be loaded to continue.</p>
      <p>You can choose to reapply <em>your</em> changes after <em>their</em> changes have been loaded.</p>
      <p>You can use the table's "undo" feature (CTRL+Z) if you need to.</p>
      <p>
        <el-button type="primary" @click="$emit('reload', true)">Reload and apply your changes</el-button>
      </p>
      <p>
        <el-button @click="$emit('reload')">Reload and discard your changes</el-button>
      </p>
    </el-alert>
  </div>

</template>

<script>
import { Organization } from 'modules/organizations'

export default {
  props: {
    counterParty: Organization,
  },
}

</script>

<style lang="scss">
.negotiationConflict {
  margin-top: -.6rem;

  .el-alert {
    padding-top: 1rem;
    align-items: start;
    background: white !important;
  }

  .el-button {
    width: 100%;
  }

  p {
    margin: .3em 0 1em;
    font-size: 14px;
    color: $grey-darker;
  }
}
</style>
