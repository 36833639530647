<template>
  <div class="layout__index" id="page-users">
    <h1>User</h1>

    <UiBreadcrumbs/>

    <div class="layout__main" id="user">

      <section class="layout__content panel">
        <h2>Profile</h2>

        <UiOutput label="Name">{{ user.name }}</UiOutput>
        <UiOutput label="Organisation">{{ organization.name }}</UiOutput>

      </section>

    </div>

  </div>
</template>

<script>
import { get } from 'vuex-pathify'

export default {

  metaInfo () {
    return {
      title: 'User'
    }
  },

  computed: {
    user: get('user'),
    organization: get('organizations/current'),
  },

}
</script>

