<template>
  <div class="ui uiFieldset" :class="{ divided }">
    <fieldset class="uiFieldset__fieldset">
      <a class="anchor" :name="anchor"></a>
      <legend class="uiFieldset__legend" v-if="label || $slots.label">
        <slot name="label"><h3 class="uiFieldset__heading">{{ label }}</h3></slot>
      </legend>
      <div class="uiFieldset__content">
        <slot></slot>
      </div>
    </fieldset>
  </div>
</template>

<script>
import { slugify } from 'utils/string'

export default {
  props: {
    label: String,
    divided: Boolean,
  },

  computed: {
    anchor () {
      return slugify(this.label)
    }
  }
}
</script>

<style lang="scss">

.uiFieldset {
  &.divided {
    margin-top: 1rem;
    border-top: 1px solid #CCC;
    padding-top: .5rem;
  }

  &__fieldset {
    border: none;
    padding: 0;
    margin: 10px 0;
  }

  &__legend {
    display: block;
    box-sizing: border-box;
    width: 100%;
    margin: 10px 0;
/*
    border-bottom: 1px solid mix($grey-lighter, $grey-lightest, 20%);
    padding-bottom: .5rem;
    margin-bottom: .5rem;
    margin-left: -.75rem;
    padding-left: .75rem;
*/
  }

  &__heading {
    margin: 0;
  }

  &__content {
    margin-left: .75rem;

    > * {
      margin-bottom: .5rem;
    }
  }
}
</style>
