import LoanEventType from 'modules/global/enums/LoanEventType'
import ResourceType from 'modules/global/enums/ResourceType'

export default class LoanEvent {

  public active: boolean

  public eventDate: string

  public eventType: LoanEventType

  /// the ID of the negotiation that caused this event
  public relatedNegotiationId?: string

  /// the resource type of the negotiation that caused this event
  public relatedNegotiationType?: ResourceType

  /// the ID of the item in the negotiation that caused this event
  public relatedNegotiationItemId?: string

  /// the ID of the item that was generated as a result of the loan event
  public relatedItemId?: string

  /// the resource type of the item that was generated as a result of the loan event
  public relatedItemType?: ResourceType

  /// the loan event is cancellable or not
  public canCancel: boolean

  constructor (data: Record<string, any> = {}) {
    this.active = data.active
    this.eventDate = data.eventDate
    this.eventType = data.eventType
    this.relatedNegotiationId = data.relatedNegotiationId
    this.relatedNegotiationType = data.relatedNegotiationType
    this.relatedNegotiationItemId = data.relatedNegotiationItemId
    this.relatedItemId = data.relatedItemId
    this.relatedItemType = data.relatedItemType
    this.canCancel = data.canCancel
  }

  public static fromServer (data: any) {
    return new LoanEvent(data)
  }
}
