<template>
  <div class="tools" :class="{ 'is-open' : isOpen }">
    <div class="tools__container">
      <slot></slot>
    </div>
    <div class="tools__lock">
      <UiIcon icon="lock|lock-open" :state="isOpen" @click="toggle"/>
    </div>
  </div>
</template>

<script>
import { sync } from 'vuex-pathify'

export default {
  computed: {
    isOpen: sync('settings/showTools')
  },

  methods: {
    toggle () {
      this.isOpen = !this.isOpen
      this.$nextTick(() => {
        window.dispatchEvent(new Event('resize'))
      })
    }
  }
}
</script>

<style lang="scss">

.tools {

  &__container {
    padding: 175px 20px 0 20px;
    position: fixed;
  }

  &__lock {
    position: absolute;
    right: 10px;
    top: 60px;
    display: none;
  }
}

@media only screen and (max-width: $width-wide) {

  .tools__lock {
    display: block;
    padding: 10px;
    i.is-checked {
      // offset lock animation
      position: relative;
      left: -4px;
    }
  }

}
</style>
