enum NotificationReason {
  created = 1,
  updated = 2,
  accepted = 3,
  rejected = 4,
  deleted = 5,
  cancelled = 6
}

export default NotificationReason
