import Vue from 'vue'
import Api from 'services/Api'
import { has, add, get, remove, update } from 'utils/collection'
import Locate from '../models/Locate'
import LocateItem from 'modules/locate-lists/models/LocateItem'
import { lookups } from 'modules/global'

class State {
  items: Array<Locate>

  constructor () {
    this.items = []
  }
}

const state = new State()

const actions = {
  load ({ commit }) {
    return Api
      .get('locate-lists?pageSize=0')
      .then(res => commit('items', res.data.items.map(Locate.fromServer)))
  },

  loadOne ({ state, commit }, id) {
    return Api
      .get(`locate-lists/` + id)
      .then(res => {
        const item = Locate.fromServer(res.data)
        const action = has(state.items, item.id)
          ? 'update'
          : 'add'
        commit(action, item)
      })
  },

  create ({ commit, dispatch, rootGetters }, { items, form }) {
    return Api
      .post('locate-lists/', {
        ...form,
        items: items
          .filter(item => item.bloomberg)
          .map(item => {
            return LocateItem
              .fromTable(item)
              .toServer(lookups)
            })
        })
        .then(res => {
          const model = Locate.fromServer(res.data)
          commit('add', model)
          return model
        })
  },

  createFromFile ({ state, dispatch, commit }, data) {
    return Api
      .post('/locate-lists/file', data)
      .then(res => {const error = res.headers['x-asterisknetworks-information']
        if (error) {
          Vue.prototype.$notify({
            title: 'Unknown Assets',
            message: error,
            type: 'warning',
            duration: 0,
            dangerouslyUseHTMLString: true,
          })
        }

        return res.data.id
      })
  },


  add ({ commit }, name) {
    return Api
      .post('locate-lists/', { name })
      .then(res => {
        const model = Locate.fromServer(res.data)
        commit('add', model)
        return model
      })
  },

  update ({ commit }, form) {
    return Api
      .patch(`/locate-lists/${form.id}`, form)
      .then(res => commit('update', res.data))
  },

  remove ({ state, commit }, id) {
    return Api
      .delete('locate-lists/' + id)
      .then(res => commit('remove', id))
  },
}

const getters = {
  getItemById: (state: State) => (id: string): Locate => {
    return get(state.items, id)
  }
}

const mutations = {
  items (state, items) {
    state.items = items
  },

  add (state, item) {
    add(state.items, item, 0)
  },

  update (state, item) {
    update(state.items, item.id, item)
  },

  remove (state, id) {
    remove(state.items, id)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
