import { Collateral } from 'modules/resources'
import { LoanEvent } from '..'
import AbstractLoan from 'modules/loans/models/AbstractLoan'

/**
 * LoanItem class
 *
 * LoanItem is the flattened version of fully-expanded Loan data
 *
 * It is designed to be shown in the Loans table and everywhere else where we only need top-level info
 */
export default class LoanItem extends AbstractLoan {

  public bloomberg: string

  public currencyCode: string

  public collateralBasket: Collateral

  public lenderId: string

  public lenderName: string

  public borrowerId: string

  public borrowerName: string

  public fundName: string

  public activeLoanEvents: Record<string, LoanEvent>

  public get hasActiveLoanEvents () {
    return this.activeLoanEvents && Object.keys(this.activeLoanEvents).length > 0
  }

  constructor (data: any = {}) {
    super(data)
    this.bloomberg = data.bloomberg
    this.currencyCode = data.currencyCode
    this.collateralBasket = data.collateralBasket || new Collateral()
    this.lenderId = data.lenderOrganization && data.lenderOrganization.id
    this.lenderName = data.lenderOrganization && data.lenderOrganization.name
    this.borrowerId = data.borrowerOrganization && data.borrowerOrganization.id
    this.borrowerName = data.borrowerOrganization && data.borrowerOrganization.name
    this.fundName = data.fundName
    this.activeLoanEvents = data.activeLoanEvents
  }

  static fromServer (data: Record<string, any>): LoanItem {
    data = AbstractLoan.fromServer(data)
    data.bloomberg = data.security.bloomberg
    data.currencyCode = data.currency.code
    data.fundName = data.fund.name
    data.lenderId = data.lenderOrganization.id
    data.lenderName = data.lenderOrganization.name
    data.borrowerName = data.borrowerOrganization.name
    data.borrowerId = data.borrowerOrganization.id
    return new LoanItem(data)
  }

}
