import { dispatch, get, sync } from 'vuex-pathify'
import { cloneDeep, merge } from 'lodash'
import { last } from 'utils/collection'
import { labelify } from 'utils/string'
import { makeTableParent } from 'vendor/handsontable'
import { MessageType, NegotiationItemState, NegotiationState } from 'modules/negotiations'
import { isBorrowerFor, isLenderFor, OrganizationMixin } from 'modules/organizations'
import { makeTitle } from '../../helpers'
import {
  NegotiationComment,
  NegotiationHistory,
  NegotiationMessage,
  NegotiationNotes,
  NegotiationResponse,
  NegotiationConflict,
  NegotiationSummary
} from '../../components/messages'
import { getLoanRole, getLoanRoleText } from 'modules/organizations/state/OrganizationsStore'

const component = {
  name: 'EditPage',

  extends: makeTableParent(),

  mixins: [
    OrganizationMixin,
  ],

  components: {
    NegotiationComment,
    NegotiationHistory,
    NegotiationMessage,
    NegotiationResponse,
    NegotiationConflict,
    NegotiationSummary,
    NegotiationNotes,
  },

  metaInfo () {
    return {
      title: makeTitle(this.$route)
    }
  },

  staticData () {
    return {
      NegotiationState,
      NegotiationItemState,
    }
  },

  data () {
    return {
      isHistoryVisible: false,
      hasStaleWarning: false,
      previous: {
        cached: [],
        data: [],
      },
      form: {
        response: 'reject',
        comment: '',
      },
    }
  },

  computed: {
    // -------------------------------------------------------------------------------------------------------------------
    // FLAGS
    // -------------------------------------------------------------------------------------------------------------------

    isOpen () {
      return this.state === NegotiationState.open
    },

    isValid () {
      return this.items.isValid
    },

    isUpdated () {
      return this.items.isUpdated
    },

    hasComment () {
      return this.form.comment !== ''
    },

    isComplete () {
      return this.items.isComplete
    },

    isShowingLast () {
      return this.messageIndex === this.messages.length - 1
    },

    canSubmit () {
      return this.isShowingLast && (this.items.isValid || this.form.response === 'reject')
    },

    // -------------------------------------------------------------------------------------------------------------------
    // RELATIONSHIPS
    // -------------------------------------------------------------------------------------------------------------------

    isResponder () {
      const message = last(this.messages)
      return message && message.isReceived
    },

    isSender () {
      const message = last(this.messages)
      return message && message.isSent
    },

    isBorrower () {
      return isBorrowerFor(this)
    },

    isLender () {
      return isLenderFor(this)
    },

    isOriginator () {
      return getLoanRole(this) === this.originatingLoanRole
    },

    role () {
      return getLoanRoleText(this)
    },

    counterParty () {
      return this.isLender
        ? this.borrowerOrganization
        : this.lenderOrganization
    },

    // -------------------------------------------------------------------------------------------------------------------
    // TEXT
    // -------------------------------------------------------------------------------------------------------------------

    canReply () {
      return this.isValid && (this.isUpdated || this.hasComment)
    },

    replyInfo () {
      const action = this.replyText.toLowerCase()
      if (!this.isValid) {
        return `You can't ${action} when there are validation errors`
      }
      if (!this.isUpdated && !this.hasComment) {
        return `You can't ${action} without changes or a new message`
      }
      const thing = this.isUpdated ? 'changes' : 'message'
      return `Your ${thing} will be sent to the counterparty`
    },

    replyText () {
      return this.isSender
        ? 'Update'
        : 'Counter'
    },

    canAccept () {
      return this.isComplete && this.isValid && !this.isSender && !this.isUpdated
    },

    acceptInfo () {
      if (!this.isValid) {
        return 'You can\'t accept when there are validation errors'
      }
      if (!this.isComplete) {
        return 'You can\'t accept until all fields are filled'
      }
      if (this.isSender) {
        return 'You can\'t accept when waiting for a response'
      }
      if (this.isUpdated) {
        return 'You can\'t accept when making changes'
      }
      return 'Accept these changes to complete the negotiation'
    },

    canReject () {
      return true
    },

    rejectText () {
      return this.isSender && this.isOriginator
        ? 'Abandon'
        : 'Reject'
    },

    rejectInfo () {
      if (this.isResponder) {
        return 'Reject this update to end the negotiation'
      }
      if (this.isOriginator) {
        return 'Abandon this negotiation'
      }
      return 'Reject this negotiation'
    },

    // -------------------------------------------------------------------------------------------------------------------
    // COMPONENT DATA
    // -------------------------------------------------------------------------------------------------------------------

    summaryData () {
      return {
        title: `${labelify(this.namespace)} Negotiation Details`,
        name: this.name,
        role: this.canBorrow && this.canLend
          ? this.role
          : '',
        counterParty: this.counterParty,
        createdAt: this.createdAt,
        isOpen: this.isOpen,
        stateText: NegotiationState[this.state]
      }
    },

    responseData () {
      return {
        canReply: this.canReply,
        canAccept: this.canAccept,
        canReject: this.canReject,
        replyText: this.replyText,
        rejectText: this.rejectText,
        replyInfo: this.replyInfo,
        acceptInfo: this.acceptInfo,
        rejectInfo: this.rejectInfo,
      }
    },

    submitData () {
      const submitTexts = {
        reject: this.rejectText,
        reply: this.replyText,
        accept: 'Accept',
      }
      return {
        submitText: submitTexts[this.form.response],
        canSubmit: this.canSubmit,
        canReset: this.isUpdated,
        submit: this.submit,
        cancel: this.cancel,
        reset: this.reset,
      }
    },

    // -------------------------------------------------------------------------------------------------------------------
    // PREVIOUS ITEMS
    // -------------------------------------------------------------------------------------------------------------------

    canShowPrevious () {
      // show when previous data is loaded
      return !this.isShowingLast && this.previous.data.length > 0
    },

    canShowLast () {
      // show when is last, or before previous data is loaded
      return this.isShowingLast || this.previous.data.length === 0
    },

    lastMessage () {
      return this.messages[this.messages.length - 1] || {} // return empty object for when
    }

  },

  watch: {
    'messageIndex': 'showMessage',

    'form.comment' (value, oldValue) {
      if (value.replace(/^\s|\s$/g, '') === '') {
        this.updateResponse()
      }
      else if (oldValue === '') {
        this.form.response = 'reply'
      }
    },

    isStale (value) {
      if (value) {
        // if the user has updated, show a warning
        if (this.isUpdated) {
          this.hasStaleWarning = true
        }

        // otherwise, automatically update their changes
        else {
          this.reload()
          this.$notify({
            title: 'Warning',
            message: `${this.counterParty.name} has updated the negotiation`,
            type: 'warning',
            duration: 0,
          })
        }
      }
    },
  },

  methods: {
    // -------------------------------------------------------------------------------------------------------------------
    // DATA
    // -------------------------------------------------------------------------------------------------------------------

    initialize (namespace) {
      const items = this.$store.get(`negotiations/${namespace}/items`)
      this.setItems(items)
      this.validate()
    },

    onItemsUpdate () {
      this.validate().then(this.updateResponse)
    },

    validate () {
      // get data
      const data = this.getItems().filter(item => !item.isRemoved)

      // validation options for accepting (can't use role because function also returns "unknown")
      const role = this.isLender ? 'lender' : 'borrower'
      this.$options.validator.setOptions('edit', 'accept', role)

      // validate
      return this.$options.validator.validateTable(data)
        .then(errors => {
          this.items.isComplete = errors.length === 0
          return this.isComplete
        })
    },

    updateResponse () {
      // mustAccept is an override added in return and recall sub-components
      if (this.mustAccept) {
        this.form.response = 'accept'
      }
      else if (this.canReply) {
        this.form.response = 'reply'
      }
      else if (this.canAccept) {
        this.form.response = 'accept'
      }
      else {
        this.form.response = 'reject'
      }
    },

    toggleHistory () {
      this.isHistoryVisible = !this.isHistoryVisible
      if (!this.isHistoryVisible) {
        this.messageIndex = this.messages.length - 1
      }
    },

    // -------------------------------------------------------------------------------------------------------------------
    // FORM
    // -------------------------------------------------------------------------------------------------------------------

    cancel () {
      if (this.items.isUpdated) {
        return this.$confirm('You have edited items. Are you sure you want to cancel?')
          .then(this.onDone)
      }
      this.onDone()
    },

    reset () {
      return this.$confirm('Are you sure you want to reset?')
        .then(this.resetItems)
    },

    // -------------------------------------------------------------------------------------------------------------------
    // SUCCESS HANDLERS
    // -------------------------------------------------------------------------------------------------------------------

    onDone () {
      this.$router.push('/negotiations')
    },

    onError (res) {
      console.log(res)
    },

  }
}

function makeComputed (namespace) {
  return {
    namespace () {
      return namespace
    },

    ...sync(`negotiations/${namespace}`, [
      'messageIndex',
      'privateNotes',
      'isStale',
    ]),

    ...get(`negotiations/${namespace}`, [
      'hash',
      'createdAt',
      'updatedAt',
      'originatingLoanRole',
      'borrowerOrganization',
      'lenderOrganization',
      'name',
      'state',
      'messages',
    ]),
  }
}

function makeMethods (namespace) {
  return {
    /**
     * Reloads page with new data, but attempts to apply current edits
     */
    reload (withChanges) {
      const load = () => {
        this.hasStaleWarning = false
        return this.$store.dispatch(`negotiations/${namespace}/load`, this.$route.params.id).then(() => {
          this.initialize(namespace)
        })
      }

      // load without applying changes
      if (!withChanges) {
        return load()
      }

      // load, and attempt to apply changes
      else {
        // build hash (indexed by bloomberg) of changes
        // we're indexing by bloomberg as we may not be able to rely on indices if items have been deleted
        const oldData = this.getItems()
        const oldChanges = this.$refs.table.changes.getIndexedItems().reduce((output, item) => {
          const bloomberg = oldData[item.index].bloomberg
          output[bloomberg] = item.item
          return output
        }, {})

        // load new data
        load().then(() => {
          // apply changes if we can still edit
          if (this.isOpen) {
            // build dataset of changes
            const newData = this.getItems()
            const newChanges = newData.map(item => {
              return oldChanges[item.bloomberg] || {}
            })

            // apply changes
            this.$refs.table.setData(newChanges)
          }
        })
      }
    },

    /**
     * Submit to server
     * @param   {object}  [serverOptions]   An optional options object, used to pass additional flags on completion
     * @return  {Promise<T | void>}
     */
    submit (serverOptions = {}) {
      const response = this.form.response
      const responseText = response === 'reject'
        ? this.isOriginator ? 'abandon' : 'reject' // MessageType does not have an `abandoned` state
        : response

      // sanity check for server options
      if (serverOptions instanceof MouseEvent) {
        throw new Error('Invalid server options parameter: expected Object or undefined; received MouseEvent')
      }

      // if replying, send items, comment and notes
      if (response === 'reply') {
        const payload = {
          negotiationHash: this.hash,
          items: this.getItems(),
          comment: this.form.comment,
        }
        return dispatch(`negotiations/${namespace}/updateItems`, payload)
          .then(this.onDone)
          .catch(this.onError)
      }

      // if accepting or rejecting, send additional options
      else {
        // const message = `Are you sure you want to ${responseText} this ${namespace} negotiation?`
        const message = `Are you sure you want to ${responseText} this negotiation?`
        const messageOptions = {
          type: response === 'accept'
            ? 'success'
            : 'warning'
        }
        this.$confirm(message, messageOptions).then(() => {
          const payload = {
            ...serverOptions,
            negotiationHash: this.hash,
            comment: this.form.comment,
            messageType: response === 'reject'
              ? MessageType.reject
              : MessageType.accept
          }
          return dispatch(`negotiations/${namespace}/completeNegotiation`, payload)
            .then(this.onSubmit)
            .catch(this.onError)
        })
      }
    },

    /**
     * Callback for after submit
     * @param res
     * @return {*|void}
     */
    onSubmit (res) {
      return this.onDone(res)
    },

    /**
     * Function to show message items
     *
     * @parent table-parent.js
     * @param index
     */
    showMessage (index) {
      // kill previous data so canShowLast works
      if (this.isShowingLast) {
        this.previous.data = []
        return
      }

      // if we have cached items, show them
      const items = this.previous.cached[index]
      if (items) {
        this.previous.data = items
      }

      // otherwise, load them
      else {
        const id = this.messages[index].id
        this.$store.dispatch(`negotiations/${namespace}/fetchItems`, id)
          .then(items => {
            this.previous.cached[index] = items
            this.previous.data = items
          })
      }
    },
  }
}

function makeHooks (namespace) {
  return {
    beforeRouteEnter (to, from, next) {
      return dispatch(`negotiations/${namespace}/load`, to.params.id)
        .then(next)
    },

    mounted () {
      // negotiation-specific options
      this.initialize(namespace)

      // focus edit field
      const message = this.$el.querySelector('textarea')
      if (message) {
        message.focus()
      }
    }
  }
}

export default function (namespace, Validator) {
  // properties
  const output = cloneDeep(component)

  // methods
  if (namespace) {
    merge(output.computed, makeComputed(namespace))
    merge(output.methods, makeMethods(namespace))
    merge(output, makeHooks(namespace))
  }

  // validator
  output.validator = new Validator

  // component
  return output
}
