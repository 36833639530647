var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.inDevelopment
    ? _c(
        "pre",
        { staticClass: "debug" },
        [_vm._v("  "), _vm._t("default"), _vm._v("\n")],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }