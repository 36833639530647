import { LoanRole } from '@/modules/organizations'
import { AbstractNegotiation } from '..'

export default class RecallNegotiation extends AbstractNegotiation {

  public readonly type: string = 'recall'

  public readonly priority: number = 1

  public readonly originatingLoanRole: LoanRole = LoanRole.lender

  public extendedSettlementDate: boolean = false

  constructor (data: any = {}) {
    super(data)
    this.extendedSettlementDate = data.extendedSettlementDate
  }

  static fromServer (data: any): RecallNegotiation {
    return new RecallNegotiation(data)
  }

}
