const s = localStorage

export const storage = {
  set (name, value) {
    s.setItem(name, JSON.stringify(value))
  },

  get (name, defaultValue ?: any) {
    const value = s.getItem(name)
    return typeof value === 'string'
      ? JSON.parse(value)
      : defaultValue
  },

  remove (name) {
    s.removeItem(name)
  },

  clear () {
    s.clear()
  },

  isEmpty () {
    return s.length === 0
  },

  on (key, handler) {
    return window.addEventListener('storage', event => {
      if (event.key === key) {
        handler(event)
      }
    })
  },
}
