<template>
  <pre v-if="inDevelopment" class="debug">
    <slot></slot>
  </pre>
</template>

<script>

export default {
  computed: {
    inDevelopment () {
      return process.env.NODE_ENV !== 'production'
    }
  },
}

</script>

<style lang="scss">

</style>
