/* eslint-disable */
/* tslint:disable */

export default {
  code: 'en',
  messages: {
    // matching
    required            : '{_field_} is required',
    required_if         : '{_field_} is required',
    required_when       : '{_field_} is required when {_target_} is present',
    confirmed           : '{_field_} confirmation does not match',
    excluded            : '{_field_} is not a valid value',

    // comparisons
    different           : '{_field_} must be different to the current value',
    different_to        : '{_field_} must be different to {_target_}',
    same_as             : '{_field_} must match {_target_}',

    // text
    alpha               : '{_field_} may only contain alphabetic characters',
    alpha_num           : '{_field_} may only contain alpha-numeric characters',
    alpha_dash          : '{_field_} may contain alpha-numeric characters as well as dashes and underscores',
    alpha_spaces        : '{_field_} may only contain alphabetic characters as well as spaces',

    // text ranges
    min                 : '{_field_} must be at least {length} characters',
    max                 : '{_field_} may not be greater than {length} characters',
    length              : '{_field_} must be {length} long',

    // patterns
    email               : '{_field_} must be a valid email',
    regex               : '{_field_} format is invalid',

    // numbers
    number              : '{_field_} must be a number',
    numeric             : '{_field_} may only contain numeric characters',
    integer             : '{_field_} must be a whole number',
    digits              : '{_field_} must be numeric and exactly contain {length} digits',
    max_precision       : '{_field_} may not be greater than {length} decimal places',

    // number comparisons
    non_zero            : '{_field_} cannot be 0',
    less_than           : '{_field_} must be less than {_target_}',
    more_than           : '{_field_} must be more than {_target_}',
    equal_or_less_than  : '{_field_} must be equal or less than {_target_}',
    equal_or_more_than  : '{_field_} must be equal or more than {_target_}',

    // number ranges
    max_value           : '{_field_} must be {max} or less',
    min_value           : '{_field_} must be {min} or more',
    between             : '{_field_} must be between {min} and {max}',

    // dates
    from_today          : '{_field_} must be today or later',
    from_tomorrow       : '{_field_} must be tomorrow or later',
    earlier_than        : '{_field_} must be earlier than {_target_}',
    later_than          : '{_field_} must be later than {_target_}',

    // choices
    oneOf               : '{_field_} is not a valid value',
    picked              : '{_field_} must contain at least {count} item(s)',

    // files
    mimes               : '{_field_} must have a valid file type',
    ext                 : '{_field_} is not a valid file',
    image               : '{_field_} must be an image',
    size                : '{_field_} size must be less than {size}KB',
    dimensions          : '{_field_} must be {width} pixels by {height} pixels',
  }
}
