var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout__index", attrs: { id: "page-negotiations" } },
    [
      _c("h1", [_vm._v("Notifications")]),
      _c("UiBreadcrumbs"),
      _c(
        "div",
        { staticClass: "layout__main", attrs: { id: "view-negotiation" } },
        [
          _c("SiteAside", { attrs: { label: "Options" } }, [
            _c("div", { staticClass: "p-4" }, [
              _c(
                "div",
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.showUnseenOnly,
                        callback: function($$v) {
                          _vm.showUnseenOnly = $$v
                        },
                        expression: "showUnseenOnly"
                      }
                    },
                    [
                      _vm._v(
                        "Show " +
                          _vm._s(_vm.showUnseenOnly ? "all" : "unseen only")
                      )
                    ]
                  )
                ],
                1
              ),
              _vm.unseen
                ? _c(
                    "div",
                    { staticClass: "mt-4" },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "23px" },
                          attrs: {
                            size: "mini",
                            disabled: _vm.unseen.length === 0
                          },
                          on: { click: _vm.clearAllUnseen }
                        },
                        [_vm._v("Clear unseen")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ])
          ]),
          _c(
            "section",
            { staticClass: "layout__content panel" },
            [
              _c("h2", [_vm._v("Items")]),
              !_vm.isConnected
                ? _c(
                    "div",
                    { staticClass: "mt-4" },
                    [
                      _c("el-alert", {
                        staticClass: "centered",
                        attrs: {
                          title:
                            "Live notifications are paused due to network issues. Please be patient as we reconnect...",
                          type: "error",
                          "show-icon": ""
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c("el-pagination", {
                staticClass: "inline-block",
                attrs: {
                  layout: "prev, pager, next",
                  background: "",
                  "current-page": _vm.pagination.page,
                  "page-size": _vm.pagination.pageSize,
                  total: _vm.pagination.total
                },
                on: { "current-change": _vm.onPageChange }
              }),
              _c("hr"),
              _c("NotificationsTable", {
                attrs: { items: _vm.items },
                on: { edit: _vm.viewItem }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }