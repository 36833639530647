/**
 * Class to represent a single field and one or more error messages
 */
export default class ErrorBag {
  public field: string

  public messages: Array<string>

  constructor (field: string, messages: string | Array<string>) {
    this.field = field
    this.messages = Array.isArray(messages)
      ? messages
      : [messages]
  }

  add (message: string) {
    this.messages.push(message)
  }

  all () {
    return this.messages
  }

  first () {
    return this.messages[0]
  }
}
