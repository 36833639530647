import Hash from 'core/types/Hash'
import { isPlainObject } from 'utils/object'

/**
 * Utility class to set and get values in a matrix
 */
export default class RecordSet {

  public items: Array<Hash | undefined>

  constructor (freeze: boolean = false) {
    this.items = []
    if (freeze) {
      Object.freeze(this)
    }
  }

  set (index: number, value: any): any
  set (index: number, prop: string, value?: any): any
  set (index: number, prop: any, value?: any): any {
    // object passed
    if (isPlainObject(prop)) {
      this.items[index] = prop
      return prop
    }

    // undefined
    if (typeof value === 'undefined') {
      return this.remove(index, prop)
    }

    // value passed
    const item = this.items[index]
    if (!item) {
      this.items[index] = {}
    }
    // @ts-ignore
    this.items[index][prop] = value
    return this.items[index]
  }

  get (index: number, prop?: string): any {
    const item = this.items[index]
    return prop
      ? item && item[prop]
      : item
  }

  remove (index: number, prop?: string): any {
    const item = this.items[index]
    if (item) {
      if (prop) {
        const hasProp = prop in item
        if (hasProp) {
          delete item[prop]
        }
        if (Object.keys(item).length === 0) {
          this.items[index] = undefined
        }
        return true
      }
      else {
        this.items[index] = undefined
        return true
      }
    }
    return false
  }

  clear (): RecordSet {
    this.items.length = 0
    return this
  }

  setItems (items: Array<Hash | undefined>): RecordSet {
    this.clear()
    items.forEach((item: Hash | undefined, index: number) => {
      if (isPlainObject(item) || item === undefined) {
        this.items[index] = item
      }
    })
    return this
  }

  getItems (): Array<Hash> {
    return this.items.filter(item => !!item) as Array<Hash>
  }

  getIndexedItems () {
    return this.items.reduce((items, item, index) => {
      if (item) {
        // @ts-ignore
        items.push({ index, item })
      }
      return items
    }, [])
  }

  getRows (): Array<Hash> {
    const items: Array<Hash> = []
    for (let i = 0; i < this.items.length; i++) {
      items[i] = this.items[i] || {}
    }
    return items
  }

  isEmpty (): boolean {
    return this.length === 0
  }

  get length (): number {
    return this.getItems().length
  }

}
