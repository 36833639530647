<template>

  <div class="ui uiFormItem uiCheckboxes">
    <el-form-item :prop="field">
      <div class="ui uiLabel">{{ label }}</div>
      <el-checkbox-group
        v-model="model"
        class="vertical"
      >
        <el-checkbox
          v-for="option in modelOptions"
          :key="option[valueKey]"
          :label="option[valueKey]"
          :disabled="option.disabled"
          class="flex"
        >
          <span class="el-checkbox__text">
            <slot v-bind="option">
              {{ option[labelKey] }}
            </slot>
          </span>
        </el-checkbox>
      </el-checkbox-group>
    </el-form-item>
  </div>

</template>

<script>
import { stringToOptions } from 'utils/ui'

export default {

  inheritAttrs: false,

  props: {
    label: {
      type: String,
    },

    field: {
      type: String,
    },

    options: {
      type: Array,
      default () {
        return []
      }
    },

    value: {
      type: Array,
    },

    valueKey: {
      type: String,
      default: 'id',
    },

    labelKey: {
      type: String,
      default: 'name'
    },
  },

  computed: {
    modelOptions () {
      if (typeof this.options === 'string') {
        return stringToOptions(this.options)
      }
      return this.options
    },

    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value || [])
      }
    },
  },
}
</script>


<style lang="scss">
.uiCheckboxes {
  .uiLabel {
    margin-left: 0;
  }

  // element ui fix
  .el-checkbox:last-child {
    margin-right: 30px;
  }

  .el-form-item__label {
    padding-bottom: 0;
    color: $grey;
  }

  .el-checkbox__label {
    display: inline-block;
    width: 100%;
  }
}
</style>
