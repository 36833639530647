import { Model } from 'core/models'

export default class Fund extends Model {
  public name: string

  constructor (data: any = {}) {
    super(data)
    this.name = data.name
  }

  static fromServer (data) {
    return new Fund(data)
  }

  clone () {
    return new Fund(this)
  }
}
