import Model from './Model'

export default class Resource extends Model {

  public createdAt: Date

  public updatedAt: Date

  constructor (data) {
    super(data)
    this.createdAt = new Date(data.createdAt)
    if (data.updatedAt) {
      this.updatedAt = new Date(data.updatedAt)
    }
  }
}
