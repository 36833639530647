<template>

  <div class="ui uiFormItem flex mt-4">
    <el-button @click="$emit('submit')"
               type="primary"
               :disabled="!canSubmit">{{ submitText | capitalize }}</el-button>

    <el-button v-if="$listeners.cancel"
               @click="$emit('cancel')">{{ cancelText | capitalize }}</el-button>

    <el-button v-if="$listeners.reset"
               @click="$emit('reset')"
               :disabled="!canReset">{{ resetText | capitalize }}</el-button>
  </div>

</template>

<script>
import { capitalize } from 'utils/string'

export default {
  filters: {
    capitalize
  },

  props: {
    submitText: {
      type: String,
      default: 'Submit'
    },

    cancelText: {
      type: String,
      default: 'Exit'
    },

    resetText: {
      type: String,
      default: 'Reset'
    },

    canSubmit: {
      type: Boolean,
      default: true,
    },

    canReset: {
      type: Boolean,
      default: true,
    },
  }
}
</script>
