import { Columns, makeQuantity, makeQuantityEditor, makeTable } from 'vendor/handsontable'
import LocateItem from '../models/LocateItem'
import LocateValidator from '../validators/LocateValidator'

function settings (vm) {
  const validator = new LocateValidator()

  const columns = [
    Columns.securityPicker,
    makeQuantityEditor('Quantity', 'quantityRequired'),
    Columns.value,
    makeQuantity('Located', 'quantityLocated'),
    Columns.settlementDateEditor,
    Columns.toLocate,
    ...Columns.tradedRates,
  ]

  return {
    columns,
    validator,
    schemaClass: LocateItem,
    minSpareRows: 1,
  }
}

export default makeTable(settings)
