var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("output", { class: { tag: true, success: _vm.model === 100 } }, [
    _vm._v(_vm._s(_vm.model) + "%")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }