import { ResourceValidator, RulesProvider, ValidationOptions } from 'vendor/vee-validate'
import { setRequired } from './utils'
import { Hash } from 'core/types'

export default class LoanNegotiationValidator extends ResourceValidator {
  constructor (options: ValidationOptions = {}, local?: boolean) {
    super(new NegotiationRulesProvider(options), local)
  }
}

type fields = 'bloomberg' | 'quantity' | 'rate' | 'loanCurrencyCode' | 'settlementDate' | 'termEndDate' | 'netDividendRequirement' | 'collateralBasketCode' | 'fundName'
export type Fields = Record<fields, any>

export const names: Fields = {
  bloomberg: 'Bloomberg',
  quantity: 'Quantity',
  rate: 'Rate',
  loanCurrencyCode: 'CurrencyCode',
  settlementDate: 'Settlement Date',
  termEndDate: 'Term End Date',
  netDividendRequirement: 'Net Dividend Requirement',
  collateralBasketCode: 'Collateral Basket',
  fundName: 'Fund Name',
}

const baseRules: Record<fields, string | any> = {
  bloomberg: 'required',
  quantity: 'number|integer|min_value:0',
  rate: 'number|between:0,100|max_precision:4',
  loanCurrencyCode: 'alpha', // |oneOf:EUR,USD,GBP
  settlementDate: 'from_today|earlier_than:termEndDate',
  termEndDate: 'from_tomorrow|later_than:settlementDate',
  netDividendRequirement: 'number|between:0,1000|max_precision:3',
  collateralBasketCode: '',
  fundName: '',
}

const dependents: Hash = {
  settlementDate: 'termEndDate',
  termEndDate: 'settlementDate',
}

const requiredToAccept: Fields = {
  bloomberg: true,
  quantity: true,
  rate: true,
  loanCurrencyCode: true,
  settlementDate: true,
  termEndDate: false,
  netDividendRequirement: true,
  collateralBasketCode: true,
  fundName: true,
}

export class NegotiationRulesProvider extends RulesProvider {

  public options: ValidationOptions

  constructor (options?: ValidationOptions) {
    super(names, dependents, options)
  }

  update (): void {
    const { action, response } = this.options
    let rules: Fields = Object.assign({}, baseRules)

    if (action === 'create') {
      rules.quantity = 'non_zero|min_value:1'
    }

    else if (action === 'edit') {
      if (response === 'accept') {
        setRequired(rules, requiredToAccept)
      }
    }

    this.rules = rules
  }

}
