<template>
  <div class="layout__main" id="edit-availability-list">

    <SiteAside label="Options">

      <UiFieldset label="Data">
        <UiUpload auto @submit="upload"/>

        <div class="flex">
          <el-button v-if="items.isUpdated" :disabled="!items.isValid" @click="submit" type="primary">Save</el-button>
          <el-button v-else @click="onDone" type="primary">OK</el-button>
          <el-button @click="cancel">Exit</el-button>
        </div>
      </UiFieldset>

    </SiteAside>

    <section class="layout__content panel">
      <h2>Assets</h2>
      <UiErrors />
      <AvailabilityListTable ref="table" :data="items.data" />
    </section>

  </div>

</template>

<script>
import { dispatch } from 'vuex-pathify'
import { makeTableParent } from 'vendor/handsontable'
import AvailabilityListTable from '../components/AvailabilityListTable'
import UiErrors from 'modules/errors/components/UiErrors'

export default {

  extends: makeTableParent(),

  crumb: 'Edit',

  components: {
    UiErrors,
    AvailabilityListTable,
  },

  metaInfo () {
    return {
      title: 'Edit Availability List'
    }
  },

  beforeRouteEnter (to, from, next) {
    return dispatch('availabilityList/load', to.params.id).then(next)
  },

  mounted () {
    this.items.data = this.$store.state.availabilityList.items
  },

  methods: {
    submit () {
      const items = this.getItems()
      return dispatch('availabilityList/updateItems', items)
        .then(this.onDone)
        .catch(this.onError)
    },

    upload (data) {
      return this.$store
        .dispatch('availabilityList/upload', data)
        .then(this.setItems)
    },

    cancel () {
      if (this.items.isUpdated) {
        return this.$confirm('You have edited items. Are you sure you want to cancel?')
          .then(this.onDone)
      }
      this.onDone()
    },

    onDone () {
      this.$router.push('/negotiations')
    },

    onError (res) {
      console.log(res)
    },
  }
}
</script>
