import { ResourceValidator, RulesProvider, ValidationOptions } from 'vendor/vee-validate'
import Hash from 'core/types/Hash'

export default class RecallNegotiationValidator extends ResourceValidator {
  constructor (options: ValidationOptions = {}, local?: boolean) {
    super(new RecallRulesProvider(options), local)
  }
}

export const names: Hash = {
  quantity: 'Quantity',
  loanQuantity: 'Loan Quantity',
  toLocate: 'To Locate',
}

const baseRules: Hash = {
  quantity: 'required|integer|min_value:1|equal_or_less_than:loanQuantity',
  toLocate: 'required|integer|min_value:0',
}

const dependents: Hash = {
  quantity: 'loanQuantity',
}

export class RecallRulesProvider extends RulesProvider {

  public options: ValidationOptions

  constructor (options?: ValidationOptions) {
    super(names, dependents, options)
  }

  update (): void {
    const { action } = this.options
    let rules: any = Object.assign({}, baseRules)

    if (action === 'edit') {
      rules.quantity = rules.quantity.replace('min_value:1', 'min_value:0')
    }

    this.rules = rules
  }

}
