import { toDateString } from 'utils/date'
import { Resource } from 'core/models'
import { LoanState } from 'modules/loans'

export default class AbstractLoan extends Resource {

  public reference: string

  public updatedByUserName: string

  public quantity: number

  public rate: number

  /// the requested settlement date
  public settlementDate: string

  /// the date when the loan actually settled
  public settledDate: string

  /// if the loan is a term loan, when it should be returned
  public termEndDate: string

  public netDividendRequirement: number

  public state: LoanState

  constructor (data: Record<string, any>) {
    super(data)
    this.reference = data.reference
    this.updatedByUserName = data.updatedByUserName
    this.quantity = data.quantity
    this.rate = data.rate
    this.settledDate = data.settledDate
    this.settlementDate = data.settlementDate
    this.termEndDate = data.termEndDate
    this.netDividendRequirement = data.netDividendRequirement
    this.state = data.state
  }

  static fromServer (data: Record<string, any>): Record<string, any> {
    data.termEndDate = data.termEndDate
      ? toDateString(data.termEndDate)
      : ''
    data.settlementDate = data.settlementDate
      ? toDateString(data.settlementDate)
      : ''
    data.settledDate = data.settledDate
      ? toDateString(data.settledDate)
      : ''
    return data
  }
}
