import { route } from 'router/helpers'
import tradingProfile from './state/TradingProfileStore'

export default {
  stores: {
    tradingProfile,
  },

  routes: [
    route('/profile', 'trading-profile@Profile.vue')
  ]
}
