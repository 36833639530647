<template>

  <section class="profile__securities panel">
    <h2>Specials</h2>

    <UiErrors :errors="errors"/>

    <p class="profile__upload">Click to
      <!--
      <a href="/profile/special-securities/download" @click.prevent="download('special-securities')">download</a>
      or
      -->
      <UiUpload href="/profile/special-securities/upload" type="text" text="upload" @submit="upload"/>
      an Assets file.
    </p>
    <SpecialSecuritiesTable ref="table" :data="items.data" />
    <SaveChanges @save="save" />
  </section>

</template>

<script>
import { makeTableParent } from 'vendor/handsontable'
import { storeMixin } from '../state/mixin-factory'
import SpecialSecuritiesTable from '../components/SpecialSecuritiesTable'
import { clone } from 'utils/object'

const module = 'tradingProfile/specials'

export default {
  extends: storeMixin(module, {
    data: 'data'
  }),

  mixins: [
    makeTableParent()
  ],

  components: {
    SpecialSecuritiesTable,
  },

  mounted () {
    this.update()
  },

  // this additional watch (vs the storeMixin watch) is needed because
  // the table mixin uses `items.data` vs just `data`
  watch: {
    data (value) {
      this.items.data = clone(value)
    }
  },

  methods: {
    save () {
      return this.$store.dispatch(module + '/save', this.getItems())
        .then(data => {
          this.items.data = clone(data)
          return this.errors = null
        })
        .catch(errors => {
          return this.errors = errors
        })
    },

    update () {
      this.items.data = clone(this.data)
    }
  }
}
</script>

<style lang="scss">

</style>
