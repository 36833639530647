<template>

  <div class="layout__main" id="loans">

    <div class="layout__content">

      <UiErrors class="mt-2"/>

      <el-row :gutter="20">

        <el-col :span="7">
          <div class="panel">
            <LoanStateTag :state="loan.state" class="loan__status"/>

            <h2>Information</h2>
            <UiOutput label="Reference">{{ loan.reference }}</UiOutput>
            <UiOutput label="Counterparty">
              <span v-if="isLender">{{ loan.borrowerOrganization.name }}</span>
              <span v-else>{{ loan.lenderOrganization.name }}</span>
            </UiOutput>
            <UiOutput label="Role">{{ isLender ? 'Lender' : 'Borrower' }}</UiOutput>
          </div>
        </el-col>

        <el-col :span="7">
          <div class="panel">
            <h2>Asset</h2>
            <UiOutput label="Name">{{ loan.security.name }}</UiOutput>
            <UiOutput label="Bloomberg">{{ loan.security.bloomberg }}</UiOutput>
            <UiOutput label="Sedol">{{ loan.security.sedol }}</UiOutput>
          </div>

          <div class="panel">
            <h2>Trade</h2>
            <UiOutput label="Quantity">{{ loan.quantity }}</UiOutput>
            <UiOutput label="Net Div / Margin">{{ $format('rate', loan.netDividendRequirement, 3) }}</UiOutput>
            <UiOutput label="Rate">{{ $format('rate', loan.rate) }}</UiOutput>
            <UiOutput label="Fund">{{ loan.fund.name }}</UiOutput>
            <UiOutput label="Currency">{{ loan.currency.name }}</UiOutput>
            <UiOutput label="Collateral">{{ loan.collateralBasket.name }} ( {{ loan.collateralBasket.code }} )</UiOutput>
          </div>
        </el-col>

        <el-col :span="10">
          <div class="panel">
            <h2>Dates</h2>
            <UiOutput label="Trade Agreed">{{ $format('dateTime', loan.createdAt) }}</UiOutput>
            <UiOutput label="Settlement Date">{{ loan.settlementDate || '-' }}</UiOutput>
            <UiOutput label="Settled Date">{{ loan.settledDate || '-' }}</UiOutput>
            <UiOutput label="Term end date">{{ loan.termEndDate || '-' }}</UiOutput>
            <UiOutput label="Closed Date">{{ loan.closedDate || '-' }}</UiOutput>
          </div>

          <div class="panel">
            <h2>History</h2>
            <LoanEventsList :loan="loan" :events="events" />
          </div>
        </el-col>

      </el-row>

    </div>

  </div>

</template>

<script>
import { get, dispatch } from 'vuex-pathify'
import { isLenderFor, UserMixin } from 'modules/organizations'
import LoanStateTag from 'modules/loans/components/LoanStateTag'
import LoanEventsList from '../components/LoanEventsList'
import { LoanState } from '..'

export default {
  components: {
    LoanEventsList,
    LoanStateTag,
  },

  mixins: [
    UserMixin,
  ],

  metaInfo () {
    return {
      title: 'View Loan',
      bodyAttrs: {
        crumb: 'View'
      }
    }
  },

  computed: {
    ...get('loan', [
      'loan',
      'events'
    ]),

    isLender () {
      return isLenderFor(this.loan)
    },

    stateText () {
      return LoanState[this.loan.state]
    },
  },

  beforeRouteEnter (to, from, next) {
    return dispatch('loan/load', to.params.id).then(next)
  },
}
</script>

<style lang="scss">
#loans {
  .panel {
    margin-bottom: 1rem;
  }

  .uiOutput {
    margin-bottom: 1rem;
  }

  .loan__status {
    position: absolute;
    top: 10px;
    right: 10px;
    text-transform: capitalize;
  }
}
</style>
