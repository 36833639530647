<template>
  <div class="layout__index">
    <h1>Organisation</h1>

    <UiBreadcrumbs/>
    <UiErrors/>

    <div class="layout__main" id="view-organization">

      <div class="layout__content">

        <el-row :gutter="20">

          <el-col :span="6">
            <div class="panel">

              <h2>Details</h2>
              <div class="organisation__detail">
                <span class="organisation-detail-label">Display Name:</span>
                <span>{{organization.displayName}}</span>
              </div>
              <div class="organisation__detail mt-2">
                <span class="organisation-detail-label">Registered Address:</span>
                <span>{{organization.registeredAddress}}</span>
              </div>
              <div class="organisation__detail mt-2">
                <span class="organisation-detail-label">Phone Number:</span>
                <span>{{organization.phoneNumber}}</span>
              </div>
              <div class="organisation__detail mt-2">
                <span class="organisation-detail-label">Billing Currency:</span>
                <span>{{ organization.billingCurrency && organization.billingCurrency.name }}</span>
              </div>
              <el-button class="mt-4"
                         @click="$router.push(`/organization/edit/${organization.id}`)">
                Edit Organisation
              </el-button>

            </div>
          </el-col>

          <el-col :span="12">
            <div class="panel">

              <h2>Documents</h2>

              <el-table :data="documents">
                <el-table-column prop="name" label="Name">
                  <a slot-scope="{row}"
                     :href="`/organizations/documents/${row.id}`"
                     @click.prevent="downloadDocument(row)">
                    {{row.name}}
                  </a>
                </el-table-column>
                <el-table-column prop="createdAt"
                                 label="Created Date"
                                 sortable
                                 align="right">
                  <template slot-scope="{row}">
                    {{row.createdAt | dateTime}}
                  </template>
                </el-table-column>
                <el-table-column label="Actions" align="right" width="100">
                  <el-button slot-scope="{row, $index}" class="no-shadow" @click="deleteDocument(row, $index)">Delete
                  </el-button>
                </el-table-column>
              </el-table>

            </div>

            <div class="panel mt-4" style="margin-top: 20px">
              <user-management-form v-if="organization.id" :organization-id="organization.id"/>
            </div>

          </el-col>

          <el-col :span="6">
            <div class="panel">

              <h2 class="mb-4">Upload Documents</h2>
              <ui-upload @submit="uploadDocument" accept=".docx,.doc,.pdf">
                Drop file here or <em>click to upload</em>
              </ui-upload>

            </div>
          </el-col>

        </el-row>

      </div>

    </div>

  </div>
</template>

<script>
import Api from 'services/Api'
import UiUpload from 'core/components/forms/UiUpload'
import UserManagementForm from '../components/forms/UserManagementForm'

export default {
  components: { UserManagementForm, UiUpload },
  metaInfo () {
    return {
      title: 'Organisation'
    }
  },

  data () {
    return {
      organization: {},
      documents: []
    }
  },

  mounted () {
    Api.get(`organizations/${this.$route.params.id}/details`)
      .then(res => this.organization = res.data)
    Api.get(`organizations/${this.$route.params.id}/documents`)
      .then(res => this.documents = res.data.map(document => ({
        ...document,
        path: `/organizations/documents/${document.id}/file`,
      })))
  },

  methods: {
    uploadDocument (data) {
      const uploadNewDocument = () => {
        return Api.post(`organizations/${this.$route.params.id}/documents`, data)
          .then(res => {
            const document = res.data
            document.path = `organizations/documents/${document.id}/file`
            this.documents.push(document)
          })
      }
      const fileName = data.get('file').name
      const messageOptions = {
        dangerouslyUseHTMLString: true,
        title: 'Replace existing document?',
        type: 'info',
        showCancelButton: true,
        cancelButtonText: 'Upload Anyway',
        confirmButtonText: 'Replace Existing',
        showClose: false,
        closeOnClickModal: false,
        message: `A document with the name of <strong>${fileName}</strong> already exists <br> Would you like to upload anyway or replace existing document`
      }
      const existingDocument = this.documents.find(document => document.name === fileName)

      if (existingDocument) {
        return this.$msgbox(messageOptions)
          .then(() => this.deleteDocument(existingDocument, this.documents.indexOf(existingDocument)))
          .then(() => uploadNewDocument())
          .catch(() => uploadNewDocument())
      }
      return uploadNewDocument()
    },

    downloadDocument (document) {
      Api.download(document.path, document.name)
    },

    deleteDocument (document, index) {
      return Api.delete(`organizations/documents/${document.id}`)
        .then(() => this.documents.splice(index, 1))
    }
  }
}
</script>

<style lang="scss" scoped>
.layout__content {
  background: none;
}

.organisation__detail {
  display: flex;
  justify-content: space-between;
}

.organisation-detail-label {
  color: #8492a6;
  font-size: 13px;
}

.organisation-documents {
  border-left: 1px solid $grey-lightest;
  border-right: 1px solid $grey-lightest;
}

</style>
