<template>
  <div class="layout__index">
    <h1>{{ action | capitalize }} Organisation</h1>

    <UiBreadcrumbs />

    <div class="layout__main" id="edit-organization">

      <div class="layout__content">

        <el-row :gutter="20">

          <el-col :span="6">
            <div class="panel">
              <h2>Sections</h2>
              <ul>
                <li><a href="#company">Company</a></li>
                <li><a href="#contact">Contact</a></li>
                <li><a href="#trading">Trading</a></li>
                <li><a href="#regulation">Regulation</a></li>
                <li><a href="#notice">Notice</a></li>
                <li><a href="#agent">Agent</a></li>
              </ul>
            </div>
          </el-col>

          <el-col :span="18" style="max-width: 800px">
            <div class="panel">
              <h2>Details</h2>
              <OrganizationForm :action="action" />
            </div>
          </el-col>

          <el-col :span="6">
          </el-col>

        </el-row>

      </div>
    </div>

  </div>
</template>

<script>
import OrganizationForm from '../components/forms/OrganizationForm'

export default {
  components: {
    OrganizationForm,
  },

  metaInfo () {
    return {
      title: 'Organisation'
    }
  },

  props: {
    action: String
  },
}
</script>

<style lang="scss">
#edit-organization {
  ul li {
    font-size: 14px;
  }
}
</style>
