import { dispatch } from 'vuex-pathify'
import { makeTableParent } from 'vendor/handsontable'
import { makeTitle } from 'modules/negotiations/helpers'
import { OrganizationMixin } from 'modules/organizations'
import { NegotiationComment, NegotiationNotes } from '../../components/messages'

export default function (namespace) {
  return {
    name: 'CreatePage',

    extends: makeTableParent(),

    mixins: [
      OrganizationMixin,
    ],

    components: {
      NegotiationComment,
      NegotiationNotes,
    },

    metaInfo () {
      return {
        title: makeTitle(this.$route)
      }
    },

    data () {
      return {
        form: {
          comment: '',
          privateNotes: '',
        },
      }
    },

    computed: {
      canSubmit () {
        return this.items.isValid
      }
    },

    mounted () {
      this.load()
    },

    methods: {

      load () {
        throw new Error('This method must be overridden')
      },

      submit () {
        const form = this.form
        const payload = {
          ...form,
          items: this.getItems()
        }
        return dispatch(`negotiations/${namespace}/create`, payload)
          .then(this.onDone)
      },

      cancel () {
        if (this.items.isUpdated) {
          this.$confirm('You have made changes. Are you sure you want to cancel?')
            .then(this.onDone)
        }
        else {
          this.onDone()
        }
      },

      onDone () {
        this.$router.push('/negotiations')
      },

    }
  }
}
