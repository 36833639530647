<template>
  <ContentHeader :title="title">
    <UiOutput v-if="!isOpen" label="State" class="capitalize">
      <span class="uppercase tag" :class="stateText">{{ stateText }}</span>
    </UiOutput>
    <UiOutput v-if="role" label="Role" class="capitalize">{{ role }}</UiOutput>
    <UiOutput label="Reference">{{ name }}</UiOutput>
    <UiOutput label="Counterparty">{{ counterParty.name }}</UiOutput>
    <UiOutput label="Created">{{ createdAt | dateTimeHuman }}</UiOutput>
    <slot></slot>
  </ContentHeader>
</template>

<script>
import { Organization } from 'modules/organizations'

export default {
  props: {
    title: String,
    name: String,
    role: String,
    counterParty: Organization,
    createdAt: Date,
    isOpen: Boolean,
    stateText: String,
  }
}
</script>
