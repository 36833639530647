import { Lookups } from 'modules/global'
import { Model } from 'core/models'

export default class LongPosition extends Model {

  public bloomberg: string

  public quantity: number

  constructor (data: any = {}) {
    super(data)
    this.bloomberg = data.bloomberg
    this.quantity = data.quantity
  }

  static fromServer (data: any = {}): LongPosition {
    const item: LongPosition = new LongPosition()
    item.id = data.id
    item.bloomberg = data.security.bloomberg
    item.quantity = data.quantity
    return item
  }

  static fromTable (data): LongPosition {
    return new LongPosition(data)
  }

  toServer (lookups: Lookups): any {
    const { getSecurity } = lookups
    const item: LongPosition = this
    const data: any = {}

    // raw data
    data.quantity = item.quantity

    // lookups
    const security = getSecurity(this.bloomberg)
    data.securityId = security
      ? security.id
      : undefined

    // return
    return data
  }
}
