var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout__main", attrs: { id: "edit-locate-list" } },
    [
      _c(
        "SiteAside",
        { attrs: { label: "Options" } },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-position": "top",
                "label-width": "120px"
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "UiFieldset",
                { attrs: { label: "Data" } },
                [
                  _c("UiInput", {
                    ref: "name",
                    attrs: { label: "Name", maxlength: "50" },
                    model: {
                      value: _vm.form.name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name"
                    }
                  }),
                  _c(
                    "div",
                    [
                      _c("UiUpload", {
                        staticClass: "mt-4",
                        attrs: { auto: "" },
                        on: { submit: _vm.upload }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _vm.items.isUpdated
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                disabled: !_vm.items.isValid,
                                type: "primary"
                              },
                              on: { click: _vm.submit }
                            },
                            [_vm._v("Save")]
                          )
                        : _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.submit }
                            },
                            [_vm._v("OK")]
                          ),
                      _vm.isEditing
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                disabled:
                                  !_vm.items.isValid || _vm.model.isLocated
                              },
                              on: { click: _vm.negotiate }
                            },
                            [_vm._v("Negotiate")]
                          )
                        : _vm._e(),
                      _c("el-button", { on: { click: _vm.cancel } }, [
                        _vm._v("Exit")
                      ]),
                      _vm.isEditing
                        ? _c("el-button", { on: { click: _vm.discard } }, [
                            _vm._v("Discard")
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.isEditing && _vm.relatedNegotiations.length
            ? _c("UiFieldset", { attrs: { label: "Information" } }, [
                _c("h4", [_vm._v("Related Negotiations")]),
                _c(
                  "ul",
                  _vm._l(_vm.relatedNegotiations, function(item) {
                    return _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          {
                            attrs: { to: "/negotiations/loans/edit/" + item.id }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm._f("dateRelative")(item.createdAt)) +
                                " to\n            " +
                                _vm._s(item.lenderOrganization.name) +
                                "\n          "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  }),
                  0
                )
              ])
            : _vm._e()
        ],
        1
      ),
      _c(
        "section",
        { staticClass: "layout__content panel" },
        [
          _c(
            "ContentHeader",
            { attrs: { title: "Assets" } },
            [
              _c("UiOutput", { attrs: { label: "Total" } }, [
                _vm._v(_vm._s(_vm._f("integer")(_vm.items.data.length)))
              ]),
              _c("UiOutput", { attrs: { label: "Value" } }, [
                _c("span", { staticClass: "symbol" }, [
                  _vm._v(_vm._s(_vm.currencyCode))
                ]),
                _vm._v(" " + _vm._s(_vm._f("integer")(_vm.value)))
              ]),
              _c("UiOutput", { attrs: { label: "Value % located" } }, [
                _vm._v(_vm._s(_vm.model.valuePercentageLocated))
              ]),
              _c("UiOutput", { attrs: { label: "Volume % located" } }, [
                _vm._v(_vm._s(_vm.model.volumePercentageLocated))
              ]),
              _c("UiOutput", { attrs: { label: "Average % located" } }, [
                _vm._v(_vm._s(_vm.model.averagePercentageLocated))
              ])
            ],
            1
          ),
          _c("UiErrors"),
          _c("LocateListTable", {
            ref: "table",
            attrs: { data: _vm.items.data }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }