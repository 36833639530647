<template>
  <div class="contentHeader">
    <h2 class="contentHeader__title">{{ title }}</h2>
    <div class="contentHeader__items">
      <slot></slot>
    </div>
  </div>

</template>

<script>
export default {
  props: {
    title: String,
  }
}
</script>

<style lang="scss">
.contentHeader {

  padding-bottom: .5rem;

  &__items {
    padding-top: .2rem;

    > * {
      display: inline-block;
      padding-right: 3rem;
    }
  }
}
</style>
