var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasItems
    ? _c(
        "div",
        { staticClass: "uiErrors" },
        [
          _c(
            "el-alert",
            {
              attrs: {
                title: _vm.title,
                closable: false,
                type: "error",
                "show-icon": ""
              }
            },
            [
              _c(
                "div",
                { staticClass: "uiErrors__fields" },
                _vm._l(_vm.items, function(item) {
                  return _c("div", [
                    _c("label", { staticClass: "uiErrors__field" }, [
                      _vm._v(_vm._s(item.field))
                    ]),
                    _c(
                      "ul",
                      { staticClass: "uiErrors__messages" },
                      _vm._l(item.messages, function(message) {
                        return _c("li", { staticClass: "uiErrors__message" }, [
                          _vm._v(_vm._s(message))
                        ])
                      }),
                      0
                    )
                  ])
                }),
                0
              )
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }