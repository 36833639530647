var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboardFilters" },
    [
      _c("el-input", {
        ref: "search",
        staticClass: "dashboardFilters__search",
        attrs: { placeholder: "Search...", clearable: "" },
        nativeOn: {
          keyup: [
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              $event.preventDefault()
              return _vm.clearSearch($event)
            },
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              $event.preventDefault()
              return _vm.onEnter($event)
            }
          ],
          keydown: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
            ) {
              return null
            }
            return _vm.onTab($event)
          }
        },
        model: {
          value: _vm.search,
          callback: function($$v) {
            _vm.search = $$v
          },
          expression: "search"
        }
      }),
      _c(
        "el-select",
        {
          staticClass: "dashboardFilters__filter",
          attrs: {
            multiple: "",
            "collapse-tags": "",
            clearable: "",
            disabled: !!_vm.search,
            placeholder: "Filter..."
          },
          model: {
            value: _vm.filters,
            callback: function($$v) {
              _vm.filters = $$v
            },
            expression: "filters"
          }
        },
        _vm._l(_vm.options, function(label, type) {
          return _c("el-option", {
            key: type,
            attrs: { label: _vm.getLabel(type), value: type }
          })
        }),
        1
      ),
      _c(
        "el-button",
        {
          staticClass: "dashboardFilters__clear shrink is-plain button-square",
          attrs: {
            type: "plain",
            disabled: !(_vm.search || _vm.filters.length)
          },
          on: { click: _vm.clear }
        },
        [_vm._v("×")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }