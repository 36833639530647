import { AbstractNegotiation } from '..'
import { LoanRole } from 'modules/organizations'

export default class ReturnNegotiation extends AbstractNegotiation {

  public readonly type: string = 'return'

  public readonly priority: number = 2

  public readonly originatingLoanRole: LoanRole = LoanRole.borrower

  constructor (data: any = {}) {
    super(data)
  }

  static fromServer (data): ReturnNegotiation {
    return new ReturnNegotiation(data)
  }
}
