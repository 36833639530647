var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "aside",
    { staticClass: "layout__aside" },
    [
      _c(
        "UiSticky",
        [
          _vm.label ? _c("h2", [_vm._v(_vm._s(_vm.label))]) : _vm._e(),
          _vm._t("default")
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }