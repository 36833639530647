<template>
  <section class="user-management-form">

    <div class="user-management-form__header">
      <h2>Users</h2>
      <el-switch v-model="showDisabledUsers" active-text="Show Disabled Users"></el-switch>
    </div>

    <el-form :rules="formRules" :model="form">

      <el-table ref="organizationTable"
                :data="users"
                :row-class-name="rowClass"
                class="user-management-table"
                width="20%"
      >
        <el-table-column prop="name"
                         label="Name">
          <template slot-scope="{ row, $index }">
            <el-form-item v-if="selectedRow === $index"
                          prop="name"
                          class="mt-4">
              <el-input v-model="form.name"
                        ref="nameInput"
                        placeholder="Name"
                        size="mini"
                        @keydown.native.esc="cancelEdit(row, $index)">
              </el-input>
            </el-form-item>
            <span v-else>{{row.name}}</span>
          </template>
        </el-table-column>

        <el-table-column prop="emailAddress"
                         label="Email Address">
          <template slot-scope="{ row, $index }">
            <el-form-item v-if="selectedRow === $index"
                          prop="emailAddress"
                          class="mt-4">
              <el-input v-model="form.emailAddress"
                        size="mini"
                        @keydown.native.esc="cancelEdit(row, $index)">
              </el-input>
            </el-form-item>
            <span v-else>{{row.emailAddress}}</span>
          </template>
        </el-table-column>

        <el-table-column prop="organizationAdmin"
                         label="Admin"
                         width="70"
                         align="center">
          <template slot-scope="{ row, $index }">
            <el-checkbox v-if="selectedRow === $index" v-model="form.organizationAdmin"/>
            <UiIcon v-else-if="row.organizationAdmin" icon="check"/>
          </template>
        </el-table-column>

        <el-table-column prop="enabled"
                         label="Enabled"
                         width="70"
                         align="center">
          <template slot-scope="{ row, $index }">
            <el-checkbox v-if="selectedRow === $index" v-model="form.enabled"/>
            <UiIcon v-else-if="row.enabled" icon="check"/>
          </template>
        </el-table-column>

        <el-table-column  prop="userType"
                          label="User Type"
                          width="100"
                          align="center"> 
          <template slot-scope="{ row, $index }">
            <span v-if="row.userType === 2">Machine</span>
            <span v-else>Person</span>
          </template>
        </el-table-column>

        <el-table-column label="Actions"
                         width="170"
                         align="right">
          <template slot-scope="{row, $index}">
            <el-button class="no-shadow"
                       :disabled="selectedRow === $index && !valid"
                       @click="selectedRow === $index
                        ? updateUser()
                        : startEdit($index)">
              {{ selectedRow === $index ? 'Save' : 'Edit' }}
            </el-button>
            <el-button v-if="selectedRow === $index"
                       class="no-shadow"
                       @click="cancelEdit(row, $index)">
              Cancel
            </el-button>
            <el-button v-if="selectedRow !== $index"
                       class="no-shadow"
                       @click="deleteUser($index)">
              Delete
            </el-button>
          </template>
        </el-table-column>

      </el-table>

      <div class="mt-4">
        <el-button :disabled="selectedRow >= 0"
                   @click="addUser"
                   id="addUserButton">
          Add User
        </el-button>
      </div>

    </el-form>

  </section>
</template>

<script>
import { Api } from 'services/index'
import { makeFormRules } from 'utils/form'
import Validator from 'vendor/vee-validate/services/Validator'

const validator = new Validator()

const validationRules = {
  name: 'required',
  emailAddress: 'required'
}

const validationNames = {
  name: 'Name',
  emailAddress: 'Email Address'
}

function makeUser () {
  return {
    name: '',
    emailAddress: '',
    organizationAdmin: false,
    enabled: true,
  }
}

export default {
  props: {
    organizationId: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      users: [],
      selectedRow: -1,
      form: makeUser(),
      formRules: makeFormRules(validationRules, validationNames),
      valid: false,
      showDisabledUsers: false,
    }
  },

  watch: {
    form: {
      async handler (form) {
        const errors = await validator.validateModel(form, validationRules, validationNames)
        this.valid = !errors
      },
      deep: true
    }
  },

  mounted () {
    Api.get(`/organizations/${this.organizationId}/users?showDisabled=true`).then(res => this.users = res.data)
  },

  methods: {
    addUser () {
      this.users.push(makeUser())
      this.selectedRow = this.users.length - 1
      this.$nextTick(() => this.$refs.nameInput.focus())
    },

    updateUser () {
      const onUpdate = type => res => {
        this.users[this.selectedRow] = res.data
        this.cancelEdit(res.data, this.selectedRow)
        this.$message({
          message: `User record successfully ${type}`,
          type: 'success'
        })
        if (type === 'created') {
          this.$nextTick(() => document.getElementById('addUserButton').focus())
        }
      }

      return this.form.id
        ? Api.patch(`/users/${this.form.id}`, this.form).then(onUpdate('updated'))
        : Api.post(`organizations/${this.organizationId}/users`, this.form).then(onUpdate('created'))
    },

    startEdit (index) {
      this.selectedRow = index
      Object.assign(this.form, this.users[index])
      this.$nextTick(() => {
        this.$refs.nameInput.focus()
      })
    },

    cancelEdit (user, index) {
      if (!user.id || (!user.enabled && !this.showDisabledUsers)) {
        this.users.splice(index, 1)
      }
      this.selectedRow = -1
      this.form = makeUser()
    },

    deleteUser (index) {
      const onDelete = res => {
        this.users.splice(index, 1)
        this.$message({
          message: `User successfully deleted`,
          type: 'success'
        })
      }
      Api.delete(`/users/${this.users[index].id}`, this.form).then(onDelete())
    },

    rowClass ({ row, rowIndex }) {
      return {
        'row--hidden': !this.showDisabledUsers && !row.enabled,
        'row--editing': rowIndex === this.selectedRow,
      }
    }
  }
}
</script>

<style lang="scss">
.user-management-form__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-management-table {

  .row--hidden {
    display: none;
  }

  & th,
  & td,
  & .cell {
    text-transform: none !important;
  }

  tr .cell { 
    padding-left: 21px;
  }

  .row--editing {
    td {
      padding: 0;
    }

    .cell {
      margin-top: -10px;
      margin-bottom: -11px;
    }

    input[type=text] {
      margin-left: -11px;
      font-size: 11px;
    }
  }
}


</style>
