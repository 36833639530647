var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      staticClass: "notifications-table",
      attrs: {
        data: _vm.items,
        "row-class-name": _vm.getClass,
        "empty-text": "No new notifications"
      },
      on: { "row-click": _vm.onRowClick }
    },
    [
      _c("el-table-column", {
        attrs: { label: "Unseen", align: "center", width: "50" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var row = ref.row
              return [
                _vm.getUnseen(row)
                  ? _c("i", { staticClass: "badge notification" }, [
                      _vm._v("1")
                    ])
                  : _vm._e()
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: "Active", align: "center", width: "50" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var row = ref.row
              return [
                _vm.isActive(row)
                  ? _c("i", { staticClass: "el-icon-success" })
                  : _vm._e()
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: {
          label: "Date / Time",
          width: "150",
          prop: "createdAt",
          "class-name": "uncapitalised",
          formatter: _vm.$formatCol("dateRelative")
        }
      }),
      _c("el-table-column", {
        attrs: { label: "From", "class-name": "uncapitalised" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var row = ref.row
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.getUserFor(row, "fromUserName")) +
                    "\n    "
                )
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: "Type", prop: "typeLabel", width: "130" }
      }),
      _c("el-table-column", {
        attrs: { label: "Name / Reference", width: "160" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var row = ref.row
              return [_vm._v("\n      " + _vm._s(_vm.getName(row)) + "\n    ")]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: "Reason", width: "80" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var row = ref.row
              return [
                _vm._v(
                  "\n      " + _vm._s(_vm.getReason(row.reason)) + "\n    "
                )
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: { label: "Seen By" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var row = ref.row
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.getUserFor(row, "seenByUserName")) +
                    "\n    "
                )
              ]
            }
          }
        ])
      }),
      _c("el-table-column", {
        attrs: {
          label: "Seen At",
          prop: "seenAt",
          width: "150",
          formatter: _vm.$formatCol("dateRelative")
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }