import Vue from 'vue'
import Api from 'services/Api'
import { get, sync } from 'vuex-pathify'
import { isObject, clone } from 'utils/object'

const $message = (type, message) => Vue.prototype.$message({ type, message })

/**
 * Create base properties for component to load and save a resource using a model
 * @param url
 * @param model
 * @param Model
 */
export function dataMixin (url, model, Model) {

  return {
    data () {
      return {
        [model]: null,
        errors: null,
      }
    },

    mounted () {
      this.load()
    },

    methods: {
      load () {
        return Api.get(url).then(this.onLoad)
      },

      save () {
        Api.post(url, this[model].toServer())
          .then(res => {
            $message('success', 'Data saved OK')
            this.onLoad(res)
          })
          .catch(() => $message('error', 'There was a problem saving the data'))
      },

      upload (type, file) {
        Api.post(`${url}/file`, file)
          .then(res => {
            this.onLoad(res)
            this.errors = null
          })
          .catch(error => {
            this.errors[type] = error.errors
          })
      },

      onLoad (res) {
        this[model] = Model.fromServer(res.data)
      },
    }
  }

}

import SaveChanges from '../components/SaveChanges'

/**
 * Create base properties for component to interact with a Vuex store that manages resources
 *
 *  - store manages data
 *  - component edits form.xxx
 *  - component watches, loads, saves and manages errors
 *
 * Basic flow is:
 *
 *  1. provide map of properties to manage
 *  2. initialise `form.*` with null mapped values
 *  3. read top level `*` mapped values from store
 *  4. watch top level `*` mapped values, and update `form.*` on change
 *
 * Saving, or uploading data results in the component updating each time the store updates, i.e.:
 *
 *  - load
 *  - save
 *  - upload
 *
 * @param module
 * @param mapping
 */
export function storeMixin (module, mapping = { items: 'data' }) {
  return {
    components: {
      SaveChanges
    },

    data () {
      return {
        // initialize writeable form values, i.e. `form.<mapping.key>`
        form: Object.keys(mapping).reduce((output, key) => {
          output[key] = null
          return output
        }, {}),

        // errors
        errors: null,
      }
    },

    computed: {
      // read-only mapped properties from store, i.e. $store.<module>.state.<mapping.key>
      ...get(module, mapping),

      // form data ready to be sent to the server; this can be overridden by in parent components
      formData () {
        return Array.isArray(this.form.data)
          ? this.form.data.map(item => item.toServer ? item.toServer() : item)
          : this.form
      }
    },

    // watch read-only mapped properties from store, and update form on change
    watch: Object.keys(mapping).reduce((output, key) => {
      output[key] = {
        immediate: true,
        handler: function (value) {
          this.form[key] = clone(value)
        }
      }
      return output
    }, {}),

    methods: {
      load () {
        return this.$store.dispatch(module + '/load')
      },

      save () {
        return this.$store.dispatch(module + '/save', this.formData)
          .then(() => {
            return this.errors = null
          })
          .catch(errors => {
            return this.errors = errors
          })
      },

      download (resource) {
        return Api.download(`trading-profile/${resource}/file`)
      },

      upload (file) {
        return this.$store.dispatch(module + '/upload', file)
          .then(() => {
            this.errors = null
          })
          .catch(error => {
            this.errors = error.errors
          })
      },
    }
  }
}
