var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout__index", attrs: { id: "page-borrower" } },
    [_c("h1", [_vm._v("Borrower")]), _c("UiBreadcrumbs"), _vm._m(0)],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "layout__main" }, [
      _c("section", { staticClass: "layout__content panel" }, [
        _c("p", [_vm._v("Borrower-specific page")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }