<template>

  <div class="dashboard__col" :data-type="type">
    <div class="dashboard__colHeader">
      <h2>{{ title }}</h2>
      <div class="controls"><slot></slot></div>
      <small>{{ info }}</small>
    </div>
    <div class="dashboard__colBody">
      <component v-for="item in visibleItems"
                 ref="card"
                 :key="item.id"
                 :is="component"
                 :item="item"
      />
    </div>
  </div>

</template>

<script>
import { get } from 'vuex-pathify'
import { sortBy } from 'utils/collection'
import { plural } from 'utils/string'
import NegotiationCard from './NegotiationCard'
import LocateCard from './LocateCard'

function itemHasText (item, term) {
  const fields = item.type === 'locate'
      ? [item.name]
      : [
        item.name,
        item.sourceList && item.sourceList.name,
        item.borrowerOrganization.name,
        item.lenderOrganization.name,
      ]
  return fields.join(',').toLowerCase().includes(term.toLowerCase())
}

export const cardTypes = {
  locates: LocateCard,
  requested: NegotiationCard,
  negotiating: NegotiationCard,
  completed: NegotiationCard,
}

export default {
  props: {
    title: String,

    type: {
      type: String,
      validator (value) {
        return Object.keys(cardTypes).includes(value)
      }
    },

    items: Array,
  },

  computed: {
    ...get('dashboard', [
      'search',
      'filters',
      'hidden',
      'showHidden',
    ]),

    component () {
      return cardTypes[this.type]
    },

    isCompleted () {
      return this.type === 'completed'
    },

    // show
    visibleItems () {
      let items

      // if the user is searching, show everything
      if (this.search) {
        items = this.items.filter(item => itemHasText(item, this.search))
      }

      // otherwise, respect current filters
      else {
        items = this.filteredItems
          .filter(item => !this.hiddenItems.includes(item))
      }

      // sort
      items = items
        .sort(sortBy('updatedAt', true))
        .sort(sortBy('priority'))

      // return
      return items
    },

    // show
    filteredItems () {
      return this.filters.length
        ? this.items.filter(item => this.filters.includes(item.type))
        : this.items
    },

    // hide
    hiddenItems () {
      if (this.isCompleted) {
        if (!this.showHidden) {
          return this.items.filter(item => this.hidden.includes(item.id))
        }
      }
      return []
    },

    info () {
      // counts
      const totalCount = this.items.length
      const visibleCount = this.visibleItems.length
      const filteredCount = totalCount - this.filteredItems.length
      const hiddenCount = this.hiddenItems.length

      // text
      let actionText = ''
      const itemsText = visibleCount
        ? `${plural(visibleCount, 'item')}`
        : 'No items'

      // if searching, filtered and hidden are discounted
      if (this.search) {
        actionText = totalCount - visibleCount + ' excluded'
      }

      // otherwise, count filtered and hidden
      else {
        if (filteredCount) {
          actionText = (filteredCount) + ' filtered'
        }
        if (hiddenCount) {
          if (actionText) {
            actionText += ', '
          }
          actionText += hiddenCount + ' hidden'
        }
      }

      // collate final text
      if (actionText) {
        actionText = ` (${actionText})`
      }
      return itemsText + actionText
    },
  },
}

</script>

<style lang="scss">
</style>
