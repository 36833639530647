export default class Pagination {
  public total: number
  public pageSize: number
  public page: number
  public lastPage: number
  public from: number
  public to: number

  constructor (data: any = {}) {
    this.total = data.total || 0
    this.pageSize = 20
    this.page = data.page || 1
    this.lastPage = data.lastPage || 1
    this.from = data.from
    this.to = data.to
  }

  static create (pageSize: number = 20): Pagination {
    return new Pagination({ pageSize })
  }

  static fromServer (data: any) {
    return new Pagination(data)
  }

  toServer (): any {
    return {
      page: this.page,
      pageSize: this.pageSize,
    }
  }
}
