import { make } from 'vuex-pathify'
import Api from 'services/Api'
import Pagination from 'models/Pagination'
import TickerFilters from '../models/TickerFilters'
import TickerItem from '../models/TickerItem'

class State {
  items: Array<TickerItem>
  filters: TickerFilters
  pagination: Pagination

  constructor () {
    this.items = []
    this.filters = new TickerFilters()
    this.pagination = Pagination.create(getPageSize())
  }
}

const state = new State()

const actions = {
  load ({ state, commit }) {
    const { page, pageSize } = state.pagination
    const params = {
      ...state.filters,
      pageSize,
      page,
    }
    return Api.get('ticker', params)
      .then(res => {
        const items = res.data.items
        const securities = items.map(item => item.security)
        commit('items', items.map(TickerItem.fromServer))
        commit('pagination', Pagination.fromServer(res.data))
        commit('securities/items', securities, { root: true })
      })
  },

  // should only ever be called from NotificationsService as it updates lastTraded info
  addItem ({ commit }, data) {
    // update last-traded
    commit('securities/lastTraded', data.security.bloomberg, { root: true })

    // add ticker item
    const item: TickerItem = TickerItem.fromServer(data)
    item.addedAt = Date.now()
    commit('item', item)

    // trigger table renders
    window.dispatchEvent(new Event('resize'))
  }
}

const mutations = {
  ...make.mutations(state),

  filters (state: State, filters: TickerFilters | any) {
    state.filters = new TickerFilters(filters)
  },

  items (state: State, items: Array<TickerItem>) {
    state.items = items
  },

  item (state: State, item: TickerItem) {
    state.items.unshift(item)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}

export function getPageSize () {
  const rowHeight = 28
  const winHeight = window.innerHeight
  const winOffset = 345
  const pageSize = Math.floor((winHeight - winOffset) / rowHeight)
  return pageSize > 2
    ? pageSize
    : 2
}
