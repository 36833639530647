var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "layout__main", attrs: { id: "home" } }, [
    _c(
      "section",
      { staticClass: "layout__content panel" },
      [
        _c("div", { staticClass: "hero__img mb-4" }),
        _c("h2", [_vm._v("Welcome to the Asterisk Networks Platform")]),
        _c("p", [
          _vm._v(
            "\n      Asterisk Networks is the first fintech platform for peer-to-peer global securities lending and repo \n      transaction negotiation. Our platform mitigates settlement risk whilst providing accessibility, \n      price transparency, discovery and cost efficiencies.\n    "
          )
        ]),
        _c("p", [
          _vm._v(
            "\n      By integrating our innovative technology platform with other service providers such as IHS Markit, \n      Broadridge and TradingApps, we’ve provided a groundbreaking collaboration allowing participants to \n      directly negotiate transactions with the benefit of real-time price data plus, straight through \n      processing and tri-party collateral management.\n    "
          )
        ]),
        _c("p", [
          _vm._v(
            "\n      Asterisk Networks has been established as a response to the growing demand for greater transparency in \n      the securities lending marketplace, establishing a competitive advantage by giving participants the \n      opportunity to increase utilisation and revenue whilst creating greater transparency, flexibility, safety and control.\n    "
          )
        ]),
        _vm._m(0),
        _c(
          "router-link",
          {
            staticClass: "el-button el-button--primary el-button--small mt-4",
            attrs: { to: "/auth/login" }
          },
          [_vm._v("Log in")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("\n      Thank you\n      "),
      _c("br"),
      _vm._v("\n      Tammy Phillips – Chief Executive Officer\n    ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }