/**
 * Dynamic Index page generator
 * @param id      The id of the page, used as CSS hooks
 * @param title   The title of the page, used in the HTML
 */
export default function (id: string, title: string): any {
  return {
    name: 'Index',

    metaInfo () {
      return {
        title
      }
    },

    render (h, context) {
      const props = {
        class: 'layout__index',
        attrs: {
          id: `page-${id}`
        }
      }

      return h('div', props, [
        h('h1', title),
        h('UiBreadcrumbs'),
        h('router-view')
      ])
    }
  }
}
