var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout__index", attrs: { id: "page-home" } },
    [
      _vm.$auth.isAuthenticated
        ? [_c("h1", [_vm._v("Home")]), _c("UiBreadcrumbs")]
        : _vm._e(),
      _c("home")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }