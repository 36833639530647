var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "negotiationNotes" },
    [
      _c(
        "UiText",
        _vm._b(
          {
            attrs: {
              placeholder: "Optional private notes...",
              multiline: "",
              maxlength: "1000"
            },
            model: {
              value: _vm.input,
              callback: function($$v) {
                _vm.input = $$v
              },
              expression: "input"
            }
          },
          "UiText",
          _vm.info,
          false
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }