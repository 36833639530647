var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ui uiFormItem uiCheckboxes" },
    [
      _c(
        "el-form-item",
        { attrs: { prop: _vm.field } },
        [
          _c("div", { staticClass: "ui uiLabel" }, [_vm._v(_vm._s(_vm.label))]),
          _c(
            "el-checkbox-group",
            {
              staticClass: "vertical",
              model: {
                value: _vm.model,
                callback: function($$v) {
                  _vm.model = $$v
                },
                expression: "model"
              }
            },
            _vm._l(_vm.modelOptions, function(option) {
              return _c(
                "el-checkbox",
                {
                  key: option[_vm.valueKey],
                  staticClass: "flex",
                  attrs: {
                    label: option[_vm.valueKey],
                    disabled: option.disabled
                  }
                },
                [
                  _c(
                    "span",
                    { staticClass: "el-checkbox__text" },
                    [
                      _vm._t(
                        "default",
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(option[_vm.labelKey]) +
                              "\n          "
                          )
                        ],
                        null,
                        option
                      )
                    ],
                    2
                  )
                ]
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }