<template>

  <el-form :inline="true"
           class="align-bottom"
           :model="form"
           label-position="top"
           @submit.prevent.native="submit"
  >
    <el-form-item label="Security">
      <el-autocomplete
        ref="security"
        v-model="form.code"
        :fetch-suggestions="search"
        :clearable="true"
        :trigger-on-focus="false"
        :highlight-first-item="true"
        placeholder="Enter a code or name..."
        popper-class="security-search"
        @select="onSelect"
      >
        <template v-slot="{ item }">
          <span>{{ item.code }}</span>
          <span>{{ item.name }}</span>
        </template>
      </el-autocomplete>
    </el-form-item>

    <el-form-item label="Quantity">
      <el-input v-model.number="form.quantity" type="number" :min="1"/>
    </el-form-item>

    <el-form-item>
      <el-button type="primary" @click="submit" :disabled="!canSubmit"> {{ action }}
      </el-button>
    </el-form-item>

    <el-form-item label="Value">
      <el-input v-model="value" disabled/>
    </el-form-item>

  </el-form>

</template>

<script>
import { dispatch, get } from 'vuex-pathify'
import LocateItem from 'modules/locate-lists/models/LocateItem'
import { fill } from 'utils/object'

function reset () {
  return {
    form: {
      id: null,
      code: '',
      quantity: 1,
    },
  }
}

export default {
  components: {},

  props: {
    existing: Array,
    default () {
      return []
    }
  },

  data () {
    return reset()
  },

  computed: {
    locate () {
      const { code, quantity, id } = this.form
      const data = this.$store.get('securities/getSecurity', code)
      return new LocateItem({ ...data, quantity, id })
    },

    value () {
      return '' // this.locate.currency + ' ' + this.locate.value.toFixed()
    },

    action () {
      return this.existing.includes(this.locate.code)
        ? 'Update'
        : 'Add'
    },

    canSubmit () {
      return this.locate && this.locate.price
    }
  },

  methods: {
    edit (item) {
      const { code, quantity, id } = item
      this.form.id = id
      this.form.quantity = quantity
      if (this.$store.get('securities/getSecurity', code)) {
        this.form.code = code
      }
      else {
        return dispatch('securities/fetch', code).then(() => {
          this.form.code = code
        })
      }
    },

    search (term, cb) {
      if (term) {
        this.$store.dispatch('securities/search', term).then(cb)
      }
    },

    onSelect ({ code }) {
      this.form.code = code
    },

    submit () {
      if (this.canSubmit) {
        this.$emit(this.action.toLowerCase(), this.locate)
        this.clear()
      }
    },

    clear () {
      Object.assign(this, reset())
      this.$refs.security.focus()
    }
  },
}

</script>

<style lang="scss">
.el-popper.security-search {

  width: auto !important;

  span {
    display: inline-block;
  }

  li span:first-child {
    width: 60px;
    padding-right: 8px;
    border-right: 1px solid #EEE;
    margin-right: 14px;
  }
}

</style>
