import { Columns, makeTable } from 'vendor/handsontable'
import { Security } from 'modules/securities'
import SpecialSecurityValidator from '../validators/SpecialSecurityValidator'

function settings () {
  const validator = new SpecialSecurityValidator()

  const columns = [
    Columns.securityPicker,
  ]

  return {
    columns,
    validator,
    schemaClass: Security,
    minSpareRows: 1,
  }
}

export default makeTable(settings)
