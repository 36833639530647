<template>
  <section class="profile__gcRates panel">
    <h2>Default GC Rates</h2>

    <UiErrors :errors="errors" />

    <p class="profile__upload">Click to
    <!--
      <a href="/profile/default-gc-rates/download" @click.prevent="download('default-gc-rates')">download</a>
      or
    -->
      <UiUpload href="/profile/default-gc-rates/upload" type="text" text="upload" @submit="upload"/>
      a GC Rates file.
    </p>

    <div v-for="fund in rates" :key="fund.name" class="profile__listContainer">
      <h3 class="text">{{ fund.name }}</h3>
      <div class="ml-4" v-for="country in fund.countries" :key="country.name">
        <h4 class="bold">{{ country.name }}</h4>
        <ul class="profile__list">
          <li class="header">
            <label>
              <span class="symbol hidden">{{ currency }}</span>
              <span class="text">For trades up to:</span>
            </label>
            <output>
              <span class="text">Apply this rate:</span>
            </output>
          </li>
          <li v-for="(tranche, index) in country.tranches" :key="tranche.value">
            <label>
              <span class="symbol" :style="{ visibility: tranche.value ? 'visible' : 'hidden' }">{{ currency }}</span>
              <span v-if="tranche.value" class="text">{{ tranche.value | numeric }}</span>
              <span v-else class="text grey">Infinity</span>
            </label>
            <output class="mb-1">
              <el-input v-model.number="form.data[tranche.formIndex].rate"
                        type="number"
                        :min="0"
                        step="0.001"
                        size="mini" />
              <span class="symbol">%</span>
            </output>
          </li>
        </ul>
      </div>
    </div>
    <SaveChanges @save="save"/>
  </section>
</template>

<script>
import { storeMixin } from '../state/mixin-factory'

export default {
  extends: storeMixin('tradingProfile/gcRates', {
    data: 'data'
  }),

  computed: {
    currency () {
      const defaultCurrency = this.$store.state.tradingProfile.defaults.defaultCurrency
      return defaultCurrency
        ? defaultCurrency.code
        : 'EUR'
    },

    rates () {
      const items = this.data
      if (!items || items.length === 0) {
        return {}
      }

      let formIndex = 0
      return items.reduce((output, model) => {
        // variables
        const fund = model.fund.name
        const country = model.country
        const value = model.maxLoanValue
        const rate = model.rate

        // references
        let $fund
        let $country

        // fund
        if (!output[fund]) {
          output[fund] = {
            name: fund,
            countries: {}
          }
        }
        $fund = output[fund]

        // country
        if (!$fund.countries[country]) {
          $fund.countries[country] = {
            name: country,
            tranches: []
          }
        }
        $country = $fund.countries[country]

        // tranches
        $country.tranches.push({ value, rate, formIndex: formIndex++ })

        // output
        return output
      }, {})
    },
  }
}
</script>

<style scoped>

</style>
