<template>

  <el-dialog class="authModal"
             width="440px"
             :visible="visible"
             :show-close="false">
    <!-- header -->
    <template v-slot:title>
      <h2>Session Expired</h2>
    </template>

    <!-- content -->
    <div class="content">
      <p>To continue, you'll need to log in again.</p>
      <div v-if="shouldSave">
        <el-alert type="info" :closable="false">
          <p>You can optionally save any changes you have made and continue with them once you have logged in again.</p>
          <p>If you choose not to save your changes then anything you have changed will be lost.</p>
        </el-alert>
      </div>
    </div>

    <template v-slot:footer>
      <div class="flex flex-between">
        <el-checkbox v-if="shouldSave" v-model="saveSession">Save my changes</el-checkbox>
        <span v-else>&nbsp;</span>
        <el-button class="shrink" type="primary" @click="next">Continue</el-button>
      </div>
    </template>
  </el-dialog>

</template>

<script>

export default {
  data () {
    return {
      visible: false,
      saveSession: true,
      shouldSave: false,
    }
  },

  mounted () {
    window.authorize = this.authorize
    this.$auth.reauthorize = this.show
  },

  methods: {
    show () {
      this.visible = true
      this.shouldSave = this.$session.hasHandlers()
    },

    next () {
      if (this.saveSession) {
        this.$session.save().then(() => {
          this.$router.push('/auth/login')
        })
      } else {
        this.$router.push('/auth/login')
      }
    },
  }
}

</script>

<style lang="scss">
  .authModal {

    .el-dialog {
      border-radius: 4px;
      overflow: hidden;

      &__header {
        h2 {
          margin-bottom: 0;
        }
      }

      &__body {
        padding: 0 20px 20px;
        word-break: unset;

        p {
          margin: .5em 0 1em;
        }
      }

      &__footer {
        padding: 20px;
        border-top: 1px solid $color-info;
        background: $color-info-lighter;
        text-align: left;
      }

    }

    h2 {
      margin-top: .5rem;
      font-weight: bold;
    }

    i {
      height: 28px;
      width: 80px;
      font-size: 80px;
      color: $color-success;
    }
  }

</style>
