import { Model } from 'core/models'

export default class Report extends Model {

  public name: string

  public description: string

  public get path () {
    return `reports/${this.id}/file`
  }

  constructor (data: any) {
    super(data)
    this.name = data.name
    this.description = data.description || 'No description'
  }

  public static fromServer (data: any) {
    return new Report(data)
  }
}
