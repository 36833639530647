var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-upload",
    {
      class: _vm.classes,
      attrs: {
        action: "//localhost",
        "http-request": _vm.onRequest,
        multiple: false,
        "show-file-list": false,
        accept: _vm.accept,
        drag: _vm.isDropzone
      }
    },
    [
      _vm.isDropzone
        ? [
            _c("i", { staticClass: "el-icon-upload" }),
            _c(
              "div",
              { staticClass: "el-upload__text" },
              [
                _vm._t("default", [
                  _vm._v("\n        Drop file here or "),
                  _c("em", [_vm._v("click to upload")]),
                  _c("br"),
                  _vm._v(
                    "(" +
                      _vm._s(
                        _vm.accept.replace(/\./g, "").replace(/,/g, ", ")
                      ) +
                      ")\n      "
                  )
                ])
              ],
              2
            )
          ]
        : _vm.isButton
        ? _c(
            "el-button",
            {
              attrs: { slot: "trigger", size: "mini", type: "secondary" },
              slot: "trigger"
            },
            [_vm._v(_vm._s(_vm.text))]
          )
        : _c(
            "a",
            {
              attrs: { slot: "trigger", href: _vm.href },
              on: {
                click: function($event) {
                  $event.preventDefault()
                }
              },
              slot: "trigger"
            },
            [_vm._v(_vm._s(_vm.text))]
          )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }