import { isDev } from 'config/env'

export function validateArray (values: any[]) {
  // validator
  const validator = function (value) {
    if (values.includes(value)) {
      return true
    }
    if (isDev) {
      const label = name
        ? `"${name}" `
        : ''
      console.warn(`The prop ${label}value "${value}" is invalid; expected one of "${values.join('", "')}"`)
    }
  }

  // optionally allow the user to chain a name
  let name = ''
  validator.for = function (value) {
    name = value
    return validator
  }

  // return the validator
  return validator
}

