<template>
  <UiFieldset label="Last Message">

    <div class="negotiationMessages negotiationMessages--single">
      <div class="negotiationMessages__info uiLabel capitalize">
        <span class="negotiationMessages__time">{{ createdAt | dateRelative }}</span>  -
        <span class="negotiationMessages__sender">{{ getSenderText(message) }}</span>
      </div>
      <div class="negotiationMessages__message capitalize">
        <output v-if="message.comment" class="negotiationMessages__comment">{{ message.comment }}</output>
        <output v-else class="color-info-darkest">No comment</output>
      </div>
    </div>

  </UiFieldset>
</template>

<script>
import { getUserName } from 'modules/organizations'

export default {
  props: {
    message: Object,
  },

  data () {
    return {
      intervalId: 0,
      tick: 0
    }
  },

  computed: {
    createdAt () {
      // access tick to trigger reactive updates
      const tick = this.tick
      return this.message.createdAt
    }
  },

  mounted () {
    this.intervalId = setInterval(() => this.tick++, 5000)
  },

  destroyed () {
    clearInterval(this.intervalId)
  },

  methods: {
    getSenderText (message) {
      return message.isSent
        ? 'sent by ' + getUserName(message.originatingUserName)
        : 'from ' + message.originatingUserName
    },

  }
}
</script>

<style lang="scss">
.negotiationMessages{

  &--single {
    padding-top: 2px;
    .negotiationMessages__message {
      margin-top: .5em;
    }
  }


  &__comment {
    display: block;
    padding: 0.6em 1em;
    font-size: 14px;
    background: $color-primary;
    border-radius: 5px;
    line-height: 1.6;
    color: white;
  }
}
</style>
