var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ticker" },
    [
      _c(
        "div",
        { staticClass: "ticker__header flex align-center" },
        [
          _c("h2", { staticClass: "ticker__title" }, [_vm._v("Ticker")]),
          _c("span"),
          _c("UiIcon", {
            staticClass: "ticker__searchIcon",
            attrs: { icon: "search" },
            on: {
              click: function($event) {
                return _vm.$refs.modal.open()
              }
            }
          })
        ],
        1
      ),
      _c("ticker-table"),
      _c("el-pagination", {
        staticClass: "ticker__pagination mt-4",
        attrs: {
          layout: "prev, pager, next",
          background: "",
          small: "",
          "hide-on-single-page": "",
          "pager-count": 5,
          "page-size": _vm.pageSize,
          "current-page": _vm.page,
          total: _vm.total
        },
        on: { "current-change": _vm.onPageChange }
      }),
      _c("ticker-filter-modal", {
        ref: "modal",
        on: {
          update: _vm.onFiltersUpdate,
          close: function($event) {
            _vm.modal = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }