<template>
  <UiFieldset label="History" v-if="messages.length > 1">

    <div class="negotiationMessages" v-if="!canToggle || open">
      <el-radio-group
        v-model="input"
        @keydown.down.native.prevent="e => e"
        @keydown.up.native.prevent="e => e"
      >
        <el-radio v-for="(message, index) in messages"
                  :key="message.id"
                  :label="index"
                  class="negotiationMessages__item"
                  :class="{ hasComment: message.comment, isReceived: !message.isSent }"
        >
          <div class="negotiationMessages__info uiLabel capitalize">
            <span class="negotiationMessages__time">{{ message.createdAt | dateRelative }}</span>  -
            <!--<i class="ml-1 mr-1 fas " :class="getDirectionIcon(message)"></i>-->
            <span class="negotiationMessages__sender">{{ getSenderText(message) }}</span>
          </div>
          <div class="negotiationMessages__message capitalize">{{ message.comment || 'No message' }}</div>
        </el-radio>
      </el-radio-group>
    </div>

    <el-button v-if="canToggle" size="mini" type="plain" class="is-plain" @click.prevent="$emit('toggle')">
      <span v-if="!open">{{ toggleLabel}}</span>
      <span v-else>Hide</span>
    </el-button>

  </UiFieldset>
</template>

<script>
import { getUserName } from 'modules/organizations'
import { plural } from 'utils/string'

export default {
  props: {
    messages: Array,
    canToggle: Boolean,
    open: Boolean,
    value: Number,
  },

  computed: {
    input: {
      get () { return this.value },
      set (value) { this.$emit('input', value) },
    },

    toggleLabel () {
      return `Show ${plural(this.messages, ' message')}...`
    }
  },

  methods: {
    getSenderText (message) {
      return message.isSent
        ? 'sent by ' + getUserName(message.originatingUserName)
        : 'from ' + message.originatingUserName
    },

    getDirectionIcon (message) {
      return message.isSent
        ? 'fa-angle-double-right'
        : 'fa-angle-double-left'
    },

  }
}
</script>

<style lang="scss">

.negotiationMessages {
  padding-top: 10px;

  .el-radio {
    display: flex;
    margin-bottom: 12px;
  }

  .el-radio__input {
    margin-top: 19px;
    flex-grow: 0;
  }

  .el-radio__label {
    flex-grow: 1;
    vertical-align: top;
    padding-right: 10px;
    white-space: normal;
    width: auto;
  }
}

.negotiationMessages {

  &__item {
    display: block;
    margin: 0 0 5px 0;
    line-height: 1.3em;

    &:hover .negotiationMessages__message {}

    &.is-checked .negotiationMessages__message {}

  }

  &__info {
    margin: 0;
  }

  &__sender {
    font-weight: bold;
    color: $color-primary;
  }

  &__message {
    padding-top: 4px;
    line-height: 1.4em;
    color: $grey-darkest;
  }
}
</style>
