var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    {
      staticClass: "el-button el-button--default el-button--mini ui-button",
      class: _vm.className,
      attrs: { to: _vm.to, disabled: _vm.disabled, tabindex: "-1" }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }