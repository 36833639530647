import { route } from 'router/helpers'
import organizations from './state/OrganizationsStore'
import user from './state/UserStore'
export default {
  stores: {
    organizations,
    user,
  },

  routes: [
    route('/user', 'organizations@User.vue'),
  ]
}
