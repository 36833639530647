import { ResourceValidator, RulesProvider } from 'vendor/vee-validate'
import Hash from 'core/types/Hash'

export const names: Hash = {
  bloomberg: 'Asset',
  quantityRequired: 'Quantity',
}

export const rules: Hash = {
  bloomberg: '',
  quantityRequired: 'integer|min_value:1',
}

export default class LocateValidator extends ResourceValidator {
  constructor (local?: boolean) {
    super(RulesProvider.create(rules, names), local)
  }
}
