var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "profile__netDivs panel" },
    [
      _c("h2", [_vm._v("Default Net Dividend / Margin Requirements")]),
      _c("UiErrors", { attrs: { errors: _vm.errors } }),
      _c(
        "p",
        { staticClass: "profile__upload" },
        [
          _vm._v("Click to\n    "),
          _c("UiUpload", {
            attrs: {
              href: "/profile/default-net-dividend-requirements/upload",
              type: "text",
              text: "upload"
            },
            on: { submit: _vm.upload }
          }),
          _vm._v("\n    a Net Dividend / Margin Requirements file.\n  ")
        ],
        1
      ),
      _vm._l(_vm.dividends, function(fund) {
        return _c(
          "div",
          { key: fund.name, staticClass: "profile__listContainer" },
          [
            _c("h3", { staticClass: "text" }, [_vm._v(_vm._s(fund.name))]),
            _c(
              "ul",
              { staticClass: "profile__list" },
              [
                _vm._m(0, true),
                _vm._l(fund.countries, function(country) {
                  return _c("li", { key: country.name }, [
                    _c("label", [
                      _c("span", { staticClass: "text" }, [
                        _vm._v(_vm._s(country.name))
                      ])
                    ]),
                    _c(
                      "output",
                      { staticClass: "mb-1" },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "number",
                            min: 0,
                            step: "0.001",
                            size: "mini"
                          },
                          model: {
                            value:
                              _vm.form.data[country.formIndex]
                                .netDividendRequirement,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.form.data[country.formIndex],
                                "netDividendRequirement",
                                _vm._n($$v)
                              )
                            },
                            expression:
                              "form.data[country.formIndex].netDividendRequirement"
                          }
                        }),
                        _c("span", { staticClass: "symbol" }, [_vm._v("%")])
                      ],
                      1
                    )
                  ])
                })
              ],
              2
            )
          ]
        )
      }),
      _c("SaveChanges", { on: { save: _vm.save } })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "header" }, [
      _c("label", [_c("span", { staticClass: "text" }, [_vm._v("Country:")])]),
      _c("output", [
        _c("span", { staticClass: "text" }, [_vm._v("Requirement:")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }