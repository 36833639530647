import Hash from 'core/types/Hash'
import { Organization } from 'modules/organizations'

export default class MarginRequirement {
  public counterParty: Organization

  public margin: number | null

  constructor (data: Hash) {
    this.counterParty = data.counterParty
    this.margin = data.margin
  }

  static fromServer (data: Hash) {
    const output: Hash = {
      counterParty: Organization.fromServer(data.counterPartyOrganization),
      margin: data.margin
    }
    return new MarginRequirement(output)
  }

  toServer () {
    return {
      counterPartyId: this.counterParty.id,
      margin: this.margin,
    }
  }

  clone () {
    return new MarginRequirement(this)
  }
}
