import { Model } from 'core/models'

export default class Currency extends Model {
  public code: string

  public name: string

  constructor (data: Record<string, any> = {}) {
    super(data)
    this.code = data.code || ''
    this.name = data.name || ''
  }

  static fromServer (data: Record<string, any> = {}) {
    return new Currency(data)
  }
}
