<template>
  <el-table :data="items"
            @row-click="row => $emit('edit', row)">
    <el-table-column label="Name"
                     prop="name"/>
    <el-table-column label="Securities"
                     prop="itemCount"
    />
    <el-table-column label="Created At"
                     prop="createdAt"
                     :formatter="$formatCol('dateTime')"
    />
    <el-table-column label="Remove"
                     align="center"
                     width="100"
                     v-slot="{ row }">
      <a class="icon-link"
         @click.stop="$emit('remove', row)">
        <i class="el-icon-delete"></i>
      </a>
    </el-table-column>
  </el-table>
</template>


<script>
export default {
  props: {
    items: Array,
  },
}
</script>
