<template>

  <div @keydown.enter="onEnter"
       @keydown.down.prevent.stop="onDown"
       @keydown.up.prevent.stop="onUp"
  >
    <el-checkbox-group
      v-model="input"
      class="vertical"
      @input="onInput"
      @keydown.enter="onEnter"
    >
      <el-checkbox ref="checkbox"
                   v-for="option in options"
                   :label="option[valueKey]"
                   :key="option[valueKey]"
                   @change.native.stop="e => {}"
      >
        {{ option[labelKey] || 'Invalid `labelKey` prop'}}
      </el-checkbox>
    </el-checkbox-group>
    <hr>
    <div class="button-holder">
      <el-button @click="onEnter" type="primary">Update All</el-button>
      <el-button @click="$emit('cancel')">Cancel</el-button>
    </div>
  </div>

</template>

<script>
function item (value, label) {
  return { label, value }
}

export default {

  props: {
    options: {
      type: Array,
      default () {
        return [
          item(1, 'One'),
          item(2, 'Two'),
          item(3, 'Three'),
        ]
      }
    },

    value: {
      type: Array,
      default () {
        return []
      }
    },

    labelKey: {
      type: String,
      default: 'label'
    },

    valueKey: {
      type: String,
      default: 'value'
    },
  },

  data () {
    return {
      focusIndex: 0,
      input: [],
    }
  },

  computed: {
    output () {
      return this.options
        .map(option => option[this.valueKey])
        .filter(value => this.input.includes(value))
        .join('|')
    }
  },

  watch: {
    value (value) {
      this.input = value
    },
  },

  methods: {
    onInput () {
      this.$emit('input', this.output)
    },

    onEnter () {
      this.$emit('enter')
    },

    onUp () {
      this.focusIndex--
      if (this.focusIndex < 0) {
        this.focusIndex = this.options.length - 1
      }
      this.focus()
    },

    onDown () {
      this.focusIndex++
      if (this.focusIndex >= this.options.length) {
        this.focusIndex = 0
      }
      this.focus()
    },

    focus () {
      this.$refs.checkbox[this.focusIndex].$el.focus()
    },

    clear () {
      this.input = []
      this.focusIndex = 0
    },

  }
}
</script>

<style lang="scss">
.anCheckboxesEditor {
  border: 1px solid #DDD;
  position: absolute;
  background: #FFF;
  padding: 7px 10px;
  z-index: 500;
  line-height: 1.7em;

  hr {
    height: 1px;
    border: none;
    background: #EEE
  }

  .button-holder {
    display: flex;

    > button {
      flex: 1;
    }
  }

  // disable animation as it's weird when the checkboxes fade in when the dropdown shows
  .el-checkbox__inner,
  .el-checkbox__inner:after {
    transition: none !important;
  }

}

</style>
