<template>

  <div class="layout__main" id="edit-return">

    <SiteAside v-if="hasStaleWarning">
      <NegotiationConflict :counterParty="counterParty" @reload="reload"/>
    </SiteAside>
    <SiteAside v-else label="Options">

      <el-form ref="form"
               :model="form"
               label-position="top"
               @submit.prevent.native="">

        <NegotiationMessage :message="lastMessage"/>

        <UiFieldset label="Response" v-if="!mustAccept && state === NegotiationState.open">
          <NegotiationResponse
            v-model="form.response"
            v-bind="responseData"
          />

          <NegotiationComment v-model="form.comment" />

          <UiSubmit v-bind="submitData" v-on="submitData" />
        </UiFieldset>

      </el-form>

      <UiFieldset v-if="mustAccept && state === NegotiationState.open" label="Response">
        <div class="flex">
          <el-button @click="submit()" type="primary">Accept</el-button>
        </div>
      </UiFieldset>

      <el-form>
        <UiFieldset label="Notes">
          <NegotiationNotes v-model="privateNotes" type="return" autosave/>
        </UiFieldset>
      </el-form>

      <NegotiationHistory v-model="messageIndex"
                          :messages="messages"
                          :open="isHistoryVisible"
                          :canToggle="isOpen"
                          @toggle="toggleHistory" />

    </SiteAside>

    <section class="layout__content panel">

      <NegotiationSummary v-bind="summaryData">
        <UiOutput label="Loan types">{{ isTerm ? 'Term' : 'Non-term' }}</UiOutput>
      </NegotiationSummary>

      <UiErrors/>

      <template>
        <!-- current items -->
        <ReturnItemsTable
          ref="table"
          v-show="canShowLast"
          :data="items.data"
          :role="role"
          :locked="!isOpen"
          :is-term="isTerm"
        />

        <!-- previous items -->
        <ReturnItemsTable
          v-show="canShowPrevious"
          :data="previous.data"
          :role="role"
          :locked="true"
          :is-term="isTerm"
        />
      </template>

    </section>

  </div>

</template>

<script>
import ReturnItemsTable from '../../components/tables/ReturnItemsTable'
import factory from './edit-factory'
import ReturnNegotiationValidator from '../../validators/ReturnNegotiationValidator'

export default {

  extends: factory('return', ReturnNegotiationValidator),

  crumb: 'Returns',

  components: {
    ReturnItemsTable,
    ReturnValidator: ReturnNegotiationValidator,
  },

  computed: {
    mustAccept () {
      return this.isLender && !this.isTerm
    },

    isTerm () {
      const item = this.items.data[0]
      return item
        ? !!item.loan.termEndDate
        : false
    },
  },

  mounted () {
    this.initialize('return')
    if (!this.isTerm || this.mustAccept) {
      this.form.response = 'accept'
    }
  }
}
</script>
