import { Resource } from 'core/models'
import { NegotiationMessageDirection } from '..'
import MessageType from 'modules/negotiations/enums/MessageType'

export default class NegotiationMessage extends Resource {
  public negotiationId: string

  // negotiation
  public messageType: MessageType

  public direction: NegotiationMessageDirection

  public comment: string

  public itemCount: number

  public originatingUserName: string

  public createdAt: Date

  public modifiedAt: Date

  public get isSent () {
    return this.direction === NegotiationMessageDirection.sent
  }

  public get isReceived () {
    return this.direction === NegotiationMessageDirection.received
  }

  constructor (data: any = {}) {
    super(data)
    this.negotiationId = data.negotiationId
    this.messageType = data.messageType
    this.direction = data.direction
    this.comment = data.comment
    this.itemCount = data.itemCount
    this.originatingUserName = data.originatingUserName
    this.createdAt = new Date(data.createdAt)
    this.modifiedAt = new Date(data.modifiedAt)
  }

  static fromServer (data) {
    return new NegotiationMessage(data)
  }
}
