var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout__main", attrs: { id: "create-negotiation" } },
    [
      _c(
        "SiteAside",
        { attrs: { label: "Options" } },
        [
          _c(
            "UiFieldset",
            { attrs: { label: "Source" } },
            [
              _c(
                "UiOutput",
                { attrs: { label: "Locate List" } },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to:
                          "/negotiations/locates/edit/" + _vm.form.sourceListId
                      }
                    },
                    [_vm._v(_vm._s(_vm.sourceListName || "..."))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.form, "label-position": "top" },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "UiFieldset",
                {
                  staticClass: "lender__checkboxes",
                  attrs: { label: "Lenders" }
                },
                [
                  _c("UiCheckboxes", {
                    attrs: {
                      label: "With agreements",
                      options: _vm.lendersWithAgreements
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(option) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(option.name) +
                                " " +
                                _vm._s(
                                  option.id === _vm.excludedLenderId
                                    ? "(excluded)"
                                    : ""
                                ) +
                                "\n            "
                            ),
                            option.rankedValuePercentageMatch !== undefined
                              ? _c("PercentTag", {
                                  staticClass: "ml-2",
                                  class: { success: option.isBestRankedMatch },
                                  attrs: {
                                    value: option.rankedValuePercentageMatch
                                  }
                                })
                              : _vm._e(),
                            option.valuePercentageMatch !== undefined
                              ? _c("PercentTag", {
                                  class: {
                                    success: option.isBestPercentageMatch
                                  },
                                  attrs: { value: option.valuePercentageMatch }
                                })
                              : _vm._e()
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.form.lenderIds,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "lenderIds", $$v)
                      },
                      expression: "form.lenderIds"
                    }
                  }),
                  _vm.lendersWithoutAgreements
                    ? _c("UiCheckboxes", {
                        attrs: {
                          label: "Without agreements",
                          options: _vm.lendersWithoutAgreements
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(option) {
                                return [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(option.name) +
                                      "\n            "
                                  ),
                                  option.rankedValuePercentageMatch !==
                                  undefined
                                    ? _c("PercentTag", {
                                        staticClass: "ml-2",
                                        class: {
                                          success: option.isBestRankedMatch
                                        },
                                        attrs: {
                                          value:
                                            option.rankedValuePercentageMatch
                                        }
                                      })
                                    : _vm._e(),
                                  option.valuePercentageMatch !== undefined
                                    ? _c("PercentTag", {
                                        class: {
                                          success: option.isBestPercentageMatch
                                        },
                                        attrs: {
                                          value: option.valuePercentageMatch
                                        }
                                      })
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2149885583
                        ),
                        model: {
                          value: _vm.form.lenderIds,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "lenderIds", $$v)
                          },
                          expression: "form.lenderIds"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c("UiFieldset", { attrs: { label: "Check Availability" } }, [
                _c(
                  "div",
                  { staticClass: "flex mb-4 checkAvailability" },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "Preferred Lender",
                          size: "mini"
                        },
                        on: {
                          change: function($event) {
                            _vm.options.scores ? _vm.getScores() : null
                          }
                        },
                        model: {
                          value: _vm.preferredLenderId,
                          callback: function($$v) {
                            _vm.preferredLenderId = $$v
                          },
                          expression: "preferredLenderId"
                        }
                      },
                      _vm._l(_vm.preferredLenders, function(item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: {
                            label: item.name,
                            value: item.id,
                            disabled: item.disabled
                          }
                        })
                      }),
                      1
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "inline-block ml-2",
                        staticStyle: { "flex-grow": "0" },
                        attrs: { size: "mini", plain: "" },
                        on: { click: _vm.getScores }
                      },
                      [_vm._v("Check...")]
                    ),
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "top",
                          trigger: "click",
                          width: "600"
                        }
                      },
                      [
                        _c("div", { staticClass: "checkAvailability__popup" }, [
                          _c("h3", [_vm._v("Check Availability")]),
                          _c("p", [
                            _c("strong", [
                              _vm._v(
                                "You can check the availability of the required securities against lenders in the system."
                              )
                            ])
                          ]),
                          _c("p", [
                            _vm._v(
                              "The system returns two scores for each lender:"
                            )
                          ]),
                          _c("ul", [
                            _c("li", [
                              _c("strong", [
                                _vm._v("Total Value Percentage Match (left)")
                              ]),
                              _c("br"),
                              _vm._v(
                                "Calculates the value matched divided by the total value of the negotiation.\n                "
                              )
                            ]),
                            _c("li", [
                              _c("strong", [
                                _vm._v("Ranked Value Percentage Match (right)")
                              ]),
                              _c("br"),
                              _vm._v(
                                "Uses an algorithm to prioritise lenders who can supply 100% of requested securities first.\n                "
                              )
                            ])
                          ]),
                          _c("p", [
                            _vm._v(
                              "Any rows without a quantity will be excluded from both calculations."
                            )
                          ]),
                          _c("p", [
                            _vm._v("You can specify a "),
                            _c("strong", [_vm._v("Preferred Lender")]),
                            _vm._v(" to prioritise in the ranked algorithm.")
                          ])
                        ]),
                        _c(
                          "el-button",
                          {
                            staticClass: "is-square ml-2",
                            attrs: {
                              slot: "reference",
                              size: "mini",
                              plain: ""
                            },
                            slot: "reference"
                          },
                          [_c("i", { staticClass: "fas fa-question" })]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c(
                "UiFieldset",
                { attrs: { label: "Submit" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.form.useSmartUpdating,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "useSmartUpdating", $$v)
                        },
                        expression: "form.useSmartUpdating"
                      }
                    },
                    [_vm._v('Use "Smart Updating"')]
                  ),
                  _c("NegotiationComment", {
                    model: {
                      value: _vm.form.comment,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "comment", $$v)
                      },
                      expression: "form.comment"
                    }
                  }),
                  _c("UiSubmit", {
                    attrs: { canSubmit: _vm.canSubmit },
                    on: { submit: _vm.submit, cancel: _vm.cancel }
                  })
                ],
                1
              ),
              _c(
                "UiFieldset",
                { attrs: { label: "Private", divided: "" } },
                [
                  _c("NegotiationNotes", {
                    model: {
                      value: _vm.form.privateNotes,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "privateNotes", $$v)
                      },
                      expression: "form.privateNotes"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "section",
        { staticClass: "layout__content panel" },
        [
          _c("h2", [_vm._v("Negotiation Details")]),
          _c("UiErrors"),
          _c("NegotiationItemsTable", {
            ref: "table",
            attrs: { role: "borrower", action: "create", data: _vm.items.data }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }