/**
    Helper function to make Element UI Form components set of validtion rules,
    using vee-validate Validator service

    @param validationRules   A hash of key => rule expressions / object pairs
    @param validationNames   A hash of key => field name / label pairs

    @return {object}         Element UI validation rules
**/
import Validator from 'vendor/vee-validate/services/Validator'

const validator = new Validator()

export function makeFormRules (validationRules = {}, validationNames = {}) {
  const formRules = {}
  Object.keys(validationRules).forEach(rule => {
    formRules[rule] = {
      validator: async (formRule, value, callback) => {
        const error = await validator.validateField(value, validationRules[rule], validationNames[rule])

        if (error) {
          return callback(error)
        }

        return callback()
      },
      trigger: 'blur'
    }
  })
  return formRules
}
