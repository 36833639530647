import { toDateString } from 'utils/date'
import { Lookups } from 'modules/global'
import { LoanItem } from 'modules/loans'
import { AbstractItem, NegotiationState, RerateNegotiationItem } from '..'

export default class RecallNegotiationItem extends AbstractItem {

  public readonly type: string = 'recall'

  public quantity: number

  public loanQuantity: number

  public loan: LoanItem

  public settlementDate: string

  public state: NegotiationState

  public longPosition: number

  public toLocate: number

  constructor (data: any = {}) {
    super(data)
    this.quantity = data.quantity
    this.loanQuantity = data.loanQuantity
    this.loan = data.loan
    this.settlementDate = data.settlementDate
    this.state = data.state
    this.longPosition = data.longPosition
    this.toLocate = data.toLocate
  }

  static fromServer (data: any = {}): RecallNegotiationItem {
    data.loan = LoanItem.fromServer(data.loan)
    data.loanQuantity = data.previousLoanQuantity
    data.settlementDate = toDateString(data.settlementDate)
    data.toLocate = data.quantity - (data.longPosition || 0)
    return new RecallNegotiationItem(data)
  }

  static fromTable (data: any): RecallNegotiationItem {
    return new RecallNegotiationItem(data)
  }

  static fromLoan (loan: LoanItem): RecallNegotiationItem {
    const data = {
      loanQuantity: loan.quantity,
      quantity: loan.quantity,
      loan: loan,
    }
    return new RecallNegotiationItem(data)
  }

  static fromRerate (rerate: RerateNegotiationItem): RecallNegotiationItem {
    const recall = RecallNegotiationItem.fromLoan(rerate.loan)
    recall.quantity = rerate.loan.quantity
    return recall
  }

  toServer (create?: boolean, lookups?: Lookups): any {
    const data: any = {
      reference: this.loan.reference, // used only for error handling
      quantity: this.quantity,
    }
    if (create) {
      data.loanId = this.loan.id
    } else {
      data.id = this.id
    }
    return data
  }
}
