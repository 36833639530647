import Vue from 'vue'
import Meta from 'vue-meta'
import Router, { RouterOptions } from 'vue-router'

import * as auth from './auth'
import routes from './routes'

Vue.use(Router)
Vue.use(Meta)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    ...routes,
    ...auth.routes,
  ]
} as RouterOptions)

router.beforeEach(auth.handleRouteChange)

export default router
