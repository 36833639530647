import { Columns, makeQuantityEditor, makeTable } from 'vendor/handsontable'
import LongPosition from '../models/LongPosition'
import LongPositionValidator from '../validators/LongPositionValidator'

function settings () {
  const validator = new LongPositionValidator()

  const columns = [
    Columns.securityPicker,
    makeQuantityEditor('Quantity', 'quantity'),
    Columns.value,
  ]

  return {
    columns,
    validator,
    schemaClass: LongPosition,
    minSpareRows: 1,
  }
}

export default makeTable(settings)
