var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "negotiationConflict" },
    [
      _c(
        "el-alert",
        {
          attrs: {
            title: "Data conflict!",
            type: "warning",
            closable: false,
            "show-icon": ""
          }
        },
        [
          _c("p", [
            _vm._v(
              _vm._s(_vm.counterParty.name) +
                " updated the negotiation, and their changes must be loaded to continue."
            )
          ]),
          _c("p", [
            _vm._v("You can choose to reapply "),
            _c("em", [_vm._v("your")]),
            _vm._v(" changes after "),
            _c("em", [_vm._v("their")]),
            _vm._v(" changes have been loaded.")
          ]),
          _c("p", [
            _vm._v(
              'You can use the table\'s "undo" feature (CTRL+Z) if you need to.'
            )
          ]),
          _c(
            "p",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("reload", true)
                    }
                  }
                },
                [_vm._v("Reload and apply your changes")]
              )
            ],
            1
          ),
          _c(
            "p",
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.$emit("reload")
                    }
                  }
                },
                [_vm._v("Reload and discard your changes")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }